import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import {
  selectedUser,
  currentUser,
  selectFpcPrimaryCareData,
  selectPrimaryCareData,
  selectPrimaryCareError,
  selectFpcPrimaryCareError,
} from 'scripts/selectors/profile-service-selectors';
import {
  getIsPcpEligibleAkaGated,
  getIsTermedMedical,
  getPrimaryCareDentistInfo,
  getPrimaryCareDentistStatusText,
  getPrimaryCareProviderStatusText,
  getSelectedUserPrimaryCareProviderInfo,
} from 'scripts/features/beta-dashboard/pcp/pcp-strip-utils';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';
import {
  getFpcPrimaryCare as getFpcPrimaryCareThunk,
  getPrimaryCare as getPrimaryCareThunk,
} from 'scripts/thunks/profile-service-thunks';
import { IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import { IPrimaryCareFpc, IPrimaryCarePerMember, IProfileUser } from 'scripts/api/profile/profile.interfaces';
import {
  IPrimaryCareProviderInfo,
  ISelectedUserPrimaryCareDentistInfo,
  PrimaryCareStatusText,
} from 'scripts/features/beta-dashboard/pcp/pcp-strip.interfaces';
import { isMR } from 'scripts/util/user/user';
import { isTermedForCoverageType } from 'scripts/util/profile/profile';
import { CoverageType } from 'scripts/api/api.interfaces';
import {
  getPrimaryCareProviderInfoError,
  getPrimaryCareProviderInfoLoading,
  getPrimaryCareProviderInfoSuccess,
} from 'scripts/reducers/primary-care-provider-reducer';
import { shouldSeePcpSchedulingWidget } from 'scripts/features/pcp-scheduling-widget/pcp-scheduling-widget';

const createPrimaryCareProfilerInfo =
  (featureFlags: IFeatureFlags) =>
  (
    currentUser: IProfileUser,
    selectedUser: IProfileUser,
    primaryCarePerMember: IPrimaryCarePerMember,
    fpcPrimaryCare: IPrimaryCareFpc,
  ): IPrimaryCareProviderInfo => {
    const selectedUserPrimaryCareProviderInfo = getSelectedUserPrimaryCareProviderInfo(featureFlags)(
      selectedUser,
      primaryCarePerMember,
      fpcPrimaryCare,
    );

    const {
      ARCADE_FEATURES_EI_VIRTUAL_VISIT_PCP_ROW_ITEM: isEIVirtualVisitPCPRowItemOn,
      ARCADE_FEATURES_MR_SELECT_PCP_ENABLED: isMRSelectPcpEnabled,
    } = featureFlags;

    const isPcdEligible = selectedUser.memberFeatures.pcdEligible;

    const primaryCareProviderStatusText =
      selectedUserPrimaryCareProviderInfo && getPrimaryCareProviderStatusText(selectedUserPrimaryCareProviderInfo);

    const isTermedMedical = getIsTermedMedical(selectedUser);

    const isTermedMedicalWithExistingSavedPcp =
      isTermedMedical &&
      !(
        primaryCareProviderStatusText === PrimaryCareStatusText.Change ||
        primaryCareProviderStatusText === PrimaryCareStatusText.ChangeInProgress
      );

    const getSelectedUserPrimaryCareDentistInfo = (): ISelectedUserPrimaryCareDentistInfo | undefined => {
      return (
        (isPcdEligible &&
          primaryCarePerMember &&
          getPrimaryCareDentistInfo(primaryCarePerMember[selectedUser.dependentSeqNum])) ||
        undefined
      );
    };

    const isTermedDental = isTermedForCoverageType(CoverageType.Dental, selectedUser.planCoverages);

    const selectedUserPrimaryCareDentistInfo = getSelectedUserPrimaryCareDentistInfo();
    const primaryCareDentistStatusText =
      selectedUserPrimaryCareDentistInfo && getPrimaryCareDentistStatusText(selectedUserPrimaryCareDentistInfo);
    const isTermedDentalWithExistingSavedPcd =
      isTermedDental && primaryCareDentistStatusText !== PrimaryCareStatusText.Change;

    const showPrimaryCareDentistTile =
      isPcdEligible && !isTermedDentalWithExistingSavedPcd && !!selectedUserPrimaryCareDentistInfo;

    const showPrimaryCareProviderTile =
      !!selectedUserPrimaryCareProviderInfo &&
      !isTermedMedicalWithExistingSavedPcp &&
      ((isMR(currentUser) && isMRSelectPcpEnabled) || !isMR(currentUser));

    const showVirtualVisitsTile =
      !isTermedMedical &&
      isEIVirtualVisitPCPRowItemOn &&
      selectedUser.planCoverages.some(coverage => coverage.planFeatures.isDVCN) &&
      !selectedUser.memberFeatures.hasVirtualVisitsIneligibleProduct;
    // ARC-11111: the PcpStripFindProvidersTile should show for M&R users in addition to the PcpStripVirtualVisitsTile, but should show
    // in place of the PcpStripVirtualVisitsTile for the other LOBs per ARC-8654
    const showFindProvidersTile = !isTermedMedical && (isMR(selectedUser) || !showVirtualVisitsTile);

    // TODO sort out consistent object construction, as currently we do things like assign an empty object for
    // PCP / PCD, or return a PCP / PCD but set display to false.
    return {
      showPrimaryCareProviderTile,
      showPrimaryCareDentistTile,
      showVirtualVisitsTile,
      showFindProvidersTile,
      selectedUserPrimaryCareProviderInfo,
      selectedUserPrimaryCareDentistInfo,
      primaryCareProviderStatusText,
      primaryCareDentistStatusText,
    };
  };

export function getPrimaryCareProviderInfo(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const getFpcPrimaryCare = (): Promise<any> => getFpcPrimaryCareThunk()(dispatch, getState);
    const getPrimaryCare = (isPcdEligible): Promise<any> => getPrimaryCareThunk(isPcdEligible)(dispatch, getState);
    const getPrimaryCarePromise =
      (featureFlags: IFeatureFlags) =>
      (selectedProfile: IProfileUser): Promise<any> => {
        const isPcpEligibleAkaGated = getIsPcpEligibleAkaGated(selectedProfile);
        const {
          ARCADE_FEATURES_FPC_PCP_FOR_GATED: isFpcPcpForGatedOn,
          ARCADE_FEATURES_MR_SELECT_PCP_ENABLED: isMRSelectPcpEnabled,
        } = featureFlags;
        if (!isMR(selectedProfile) || isMRSelectPcpEnabled) {
          const isPcdEligible = selectedProfile.memberFeatures.pcdEligible;
          if (isPcpEligibleAkaGated) {
            if (isFpcPcpForGatedOn || shouldSeePcpSchedulingWidget(featureFlags, selectedProfile)) {
              return Promise.all([getFpcPrimaryCare(), getPrimaryCare(isPcdEligible)]);
            } else {
              return getPrimaryCare(isPcdEligible);
            }
          } else {
            if (isPcdEligible) {
              return Promise.all([getFpcPrimaryCare(), getPrimaryCare(isPcdEligible)]);
            } else {
              return getFpcPrimaryCare();
            }
          }
        } else {
          return Promise.resolve();
        }
      };

    try {
      dispatch(getPrimaryCareProviderInfoLoading());
      const selectedUserProfile = selectedUser.selectProfile(getState());
      const currentUserProfile = currentUser.selectProfile(getState());
      const featureFlags = selectFeatureFlags(getState());
      await getPrimaryCarePromise(featureFlags)(selectedUserProfile);
      if (selectPrimaryCareError(getState()) || selectFpcPrimaryCareError(getState())) {
        dispatch(getPrimaryCareProviderInfoError());
      } else {
        dispatch(
          getPrimaryCareProviderInfoSuccess(
            createPrimaryCareProfilerInfo(featureFlags)(
              currentUserProfile,
              selectedUserProfile,
              selectPrimaryCareData(getState()),
              selectFpcPrimaryCareData(getState()),
            ),
          ),
        );
      }
    } catch (e) {
      console.error(`error while getting primary care information: ${e}`);
      dispatch(getPrimaryCareProviderInfoError());
    }
  };
}
