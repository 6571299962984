import React, { FunctionComponent, useState } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectConfig, selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { mobileOnly, desktopOnly } from 'scripts/styles/breakpoints';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { Feature } from 'scripts/ui/feature/feature';
import { trackFeatures } from 'scripts/util/constants/track-features';
import { RdsIcon } from '@rally/energon-react';
import { $SrOnly, container } from 'scripts/styles/utilities';

const $NotificationContainer = styled.div`
  background-color: ${fromTheme('clrOrangeLight')};
  border-bottom: 1px solid ${fromTheme('clrNeutral95')};
  font-weight: ${fromTheme('fwMedium')};
  left: 0;
  padding: 10px 0;
  width: 100%;
  ${mobileOnly`
    padding: 10px 20px;
  `}
`;

const $Container = styled.div`
  ${container};
  align-items: center;
  display: flex;
  float: none;
  width: 100%;
`;

const $MessageContainer = styled.div`
  flex: 1 1 auto;
`;

const $Message = styled.span`
  margin-right: 5px;
  padding-right: 10px;
`;

const $IconInfo = styled(RdsIcon)`
  display: flex;
  fill: ${fromTheme('clrGreen')};
  margin-right: 10px;
  ${desktopOnly`
    span {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  `}
  ${mobileOnly`
    span {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  `}
`;

const $CloseBtn = styled.button`
  background: transparent;
  border: 0;
  flex: 0 0 30px;
  height: 30px;
  outline: 0;
  position: relative;
  width: 30px;
  &:before,
  &:after {
    background: ${fromTheme('clrPrimary')};
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 30px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export interface INotificationProps {
  isNotificationOn: boolean;
  notificationMessage: string;
}

export const RawNotification: FunctionComponent<INotificationProps> = props => {
  const { isNotificationOn, notificationMessage } = props;
  const notificationKey = 'arcade.notification.dismissed';
  const sessionStorageValue = sessionStorage.getItem(notificationKey);
  const isDismissedInit = typeof sessionStorageValue === 'string' ? JSON.parse(sessionStorageValue) : false;
  const [isDismissed, setDismissed] = useState(isDismissedInit);

  const toggleDismissed = (): void => {
    setDismissed(!isDismissed);
    sessionStorage.setItem(notificationKey, 'true');
  };

  const { t } = useTranslation(Dictionary.NOTIFICATION);

  if (!isNotificationOn || isDismissed) {
    return null;
  }

  return (
    <Feature featureId={trackFeatures.notification}>
      <$NotificationContainer>
        <$Container>
          <$IconInfo aria-hidden="true" name="system-info-circle"></$IconInfo>
          <$MessageContainer>
            <$Message>{notificationMessage}</$Message>
          </$MessageContainer>
          <$CloseBtn onClick={toggleDismissed}>
            <$SrOnly>{t('CLOSE_NOTIFICATION')}</$SrOnly>
          </$CloseBtn>
        </$Container>
      </$NotificationContainer>
    </Feature>
  );
};

const mapStateToProps = (state: IReduxState): INotificationProps => {
  return {
    isNotificationOn: selectFeatureFlags(state).ARCADE_FEATURES_NOTIFICATION,
    notificationMessage: selectConfig(state).ARCADE_WEB_NOTIFICATION_MESSAGE,
  };
};

export const NotificationComponent = connect(mapStateToProps)(RawNotification);
