import { AxiosRequestConfig } from 'axios';
import { IResource } from 'scripts/util/resource/resource.interfaces';

export enum CoverageType {
  BehavioralHealth = 'BEHAVIORAL_HEALTH',
  Dental = 'DENTAL',
  Medical = 'MEDICAL',
  Rx = 'RX',
  Vision = 'VISION',
}

export enum CoverageTypeCode {
  Dental = 'D',
  EhbDental = 'DS',
  HospitalIndemnity = 'HIP',
  HospitalIndemnityRider = 'HIPRIDER',
  MA = 'MA',
  MAPD = 'MAPD',
  Medical = 'M',
  MedicareSupplement = 'MEDSUPP',
  MedicareSupplementRider = 'MEDSUPPRIDER',
  PDP = 'PDP',
  PHIP = 'PHIP',
  SSP = 'SSP',
  Unknown = 'UNKNOWN',
}

export enum RelationshipType {
  Dependent = 'DEPENDENT',
  DomesticPartner = 'DOMESTIC_PARTNER',
  Other = 'OTHER',
  Retiree = 'RETIREE',
  Spouse = 'SPOUSE',
  Subscriber = 'SUBSCRIBER',
}

export enum CoverageStatus {
  Active = 'ACTIVE',
  Future = 'FUTURE',
  Termed = 'TERMED',
}

export enum LinkTarget {
  Blank = '_blank',
  Self = '_self',
}

export interface IFhCookie {
  sessionId: string;
  lastUpdated: string;
  timeToIdleSeconds: string;
}

export interface ITimePeriod {
  startDate: string;
  endDate?: string;
}

export interface ICoverageTimePeriod {
  startDate: string;
  endDate?: string;
  status: CoverageStatus;
}

export interface IFullName {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
}

export interface ICurrencyAmount {
  iso4217: string;
  value: number;
}

export interface ILink {
  href?: string | { pathname: string; search: string };
  resource?: IResource;
  text?: string;
  target?: LinkTarget;
}

export interface IAxiosRequestConfig extends AxiosRequestConfig {
  ignoreLoadingBar?: boolean;
  localizedCacheKey?: boolean;
  cacheName?: CacheName;
  maxAge?: number;
  /**
   * When true, any API call that would normally check for a cached response will instead bypass the cache on read.  Results
   * from the request will still update the cache during response handling.
   */
  ignoreCache?: boolean;
}

export interface IRequestShortcutConfig extends ng.IRequestShortcutConfig {
  ignoreLoadingBar?: boolean;
}

export interface IResponse<T> {
  data: T;
  config: any;
  status: number;
  statusText: string;
  headers?: Record<string, string>;
  arcadeDataUpdated?: string;
}

export interface IEmptyResponse extends IResponse<void> {}

export enum CacheName {
  Activate = 'activate',
  Advantage = 'advantage',
  ClaimNotes = 'claimNotes',
  Claims = 'claims',
  ClaimsEOB = 'claimsEOB',
  ClaimsFinancial = 'claimsFinancial',
  ClaimsHealthcare = 'claimsHealthcare',
  ClaimsNeedAttention = 'claimsNeedAttention',
  ClaimsRallyPay = 'claimsRallyPay',
  Documents = 'documents',
  FpcPrimaryCareInformation = 'fpcPrimaryCareInformation',
  Incentives = 'incentives',
  Ledger = 'ledger',
  Mpe = 'mpe',
  Onboarding = 'onboarding',
  Plans = 'plans',
  Profile = 'profile',
  ProfilePreferences = 'profilePreferences',
  RealTimeOfferCount = 'realTimeOfferCount',
  SpeakEasy = 'speakEasy',
  Steps = 'steps',
  Structure = 'structure',
  TelemedicineEligibility = 'telemedicineEligibility',
  TelemedicineEligibilityV4 = 'telemedicineEligibilityV4',
  Taco = 'taco',
  Targeting = 'targeting',
  Tracking = 'tracking',
  User = 'user',
}

export enum PcpEligibility {
  Y = 'Y',
  N = 'N',
  O = 'O',
}
