import React, { FC, useEffect, useCallback } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useQueryParams } from 'scripts/hooks/use-query-params/use-query-params';
import { changeLocale } from 'scripts/thunks/locale-thunks';
import { selectId } from 'scripts/selectors/locale-selectors';
import { constructParams } from 'scripts/util/uri/construct-params';
import { defaultLocale, getLocale } from 'scripts/util/locale/locale';
import { IWithChildren, MapStateToProps } from 'scripts/features/general.interfaces';
import { useHistory } from 'scripts/hooks/use-history/use-history';
import { IProfile, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { selectProfileData } from 'scripts/selectors/profile-service-selectors';
import { getPopulationByProfile } from 'scripts/util/population/population';
import { MyUhcLocaleKey } from 'scripts/util/constants/i18n.constants';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';

export interface ILocaleProps extends ReduxProps, IWithChildren {
  shouldRedirect: boolean;
}

/**
 * Reads locale from URL search params which is how other applications pass locale to Dashboard (ex. Activate to Dashboard),
 */
export const RawLocale: FC<ILocaleProps> = props => {
  const { shouldRedirect, currentLocale, profile, updateLocale, children } = props;
  const history = useHistory();
  const params = useQueryParams<{ locale?: string }>();
  const uhcLocaleKey = localStorage.getItem(MyUhcLocaleKey);
  const enableTransperfect = useSelector(selectFeatureFlags).ARCADE_FEATURES_TRANSPERFECT_TRANSLATION_ENABLED;
  const enableTransperfectCns = useSelector(selectFeatureFlags).ARCADE_FEATURES_TRANSPERFECT_TRANSLATION_CNS_ENABLED;
  const redirectWithoutLocale = useCallback(() => {
    delete params.locale;
    history.replace({ search: constructParams(params) });
  }, []);

  useEffect(() => {
    if (!shouldRedirect) return;

    let isUserMr = false;
    let isUserCs = false;
    if (profile) {
      const population = getPopulationByProfile(profile.currentUser);
      isUserMr = population?.lineOfBusiness === LineOfBusiness.MR;
      isUserCs = population?.lineOfBusiness === LineOfBusiness.CS;
    }

    if (!currentLocale) {
      const newLocale = isUserMr
        ? enableTransperfect
          ? getLocale(uhcLocaleKey)
          : defaultLocale
        : isUserCs && enableTransperfectCns
        ? getLocale(uhcLocaleKey)
        : getLocale(params.locale);
      updateLocale(newLocale.id);
    }

    if (params.locale) {
      redirectWithoutLocale();
    }
  }, [currentLocale, shouldRedirect, profile]);

  return <>{children}</>;
};

interface ILocaleMapStateToProps {
  currentLocale: string;
  profile: IProfile;
}

const mapStateToProps: MapStateToProps<ILocaleMapStateToProps> = state => ({
  currentLocale: selectId(state),
  profile: selectProfileData(state),
});

const connector = connect(mapStateToProps, { updateLocale: changeLocale });
type ReduxProps = ConnectedProps<typeof connector>;
export const Locale = connector(RawLocale);
