import { createSelector } from '@reduxjs/toolkit';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IUserState } from 'scripts/reducers/user-service-reducer';

const selectUser = (state: IReduxState): IUserState => state.user;

const selectInfo = createSelector(selectUser, user => user.info);

export const selectInfoData = createSelector(selectInfo, info => info.data);

export const selectExpiresAt = createSelector(selectInfoData, info => info?.expiresAt);

export const selectServerTime = createSelector(selectInfoData, info => info?.serverTime);

const selectHeartbeat = createSelector(selectUser, user => user.heartbeat);

export const selectHeartbeatData = createSelector(selectHeartbeat, heartbeat => heartbeat.data);

export const selectHeartbeatLoading = createSelector(selectHeartbeat, heartbeat => heartbeat.loading);

export const selectHeartbeatError = createSelector(selectHeartbeat, heartbeat => heartbeat.error);

export const selectRallyId = createSelector(selectHeartbeatData, heartbeat => heartbeat?.rallyId);

export const selectEligibilityId = createSelector(selectHeartbeatData, heartbeat => heartbeat?.eligibilityId);

export const selectIsSuperUser = createSelector(selectHeartbeatData, heartbeat => heartbeat?.isSuperUser);

export const selectSessionIdp = createSelector(selectHeartbeatData, heartbeat => heartbeat?.sessionIdp);

export const selectRallyPayUserToken = createSelector(selectUser, user => user.rallyPayUserToken);

export const selectRallyPayUserTokenData = createSelector(selectRallyPayUserToken, token => token.data);

export const selectRallyPayUserTokenError = createSelector(selectRallyPayUserToken, token => token.error);

export const selectRallyPayUserTokenLoading = createSelector(selectRallyPayUserToken, token => token.loading);

export const selectPlanToken = createSelector(selectUser, user => user?.planToken);
