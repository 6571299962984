import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILedgerState {
  accounts: IStateData<ILedgerAccount[]>;
}

export const initialState: ILedgerState = {
  accounts: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const ledger = createSlice({
  name: 'ledger',
  initialState,
  reducers: {
    getAccountsSuccess: (state: ILedgerState, action: PayloadAction<ILedgerAccount[]>): void => {
      state.accounts.data = action.payload;
      state.accounts.error = false;
      state.accounts.loading = false;
    },
    getAccountsLoading: (state: ILedgerState): void => {
      state.accounts.loading = true;
    },
    getAccountsError: (state: ILedgerState): void => {
      state.accounts.data = undefined;
      state.accounts.error = true;
      state.accounts.loading = false;
    },
  },
  extraReducers: {},
});

export const { getAccountsSuccess, getAccountsLoading, getAccountsError } = ledger.actions;

export default ledger.reducer;
