import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'scripts/ui/modal/modal';
import { $ExplanationModalBody } from 'scripts/ui/modal/explanation-modal-body';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { Feature } from 'scripts/ui/feature/feature';
import { trackFeatures } from 'scripts/util/constants/track-features';
import { withInnerHTML } from 'scripts/hoc/with-inner-html/with-inner-html';
import { selectClientConfigData } from 'scripts/selectors/targeting-service-selectors';
import { getClientConfig } from 'scripts/thunks/targeting-service-thunks';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { getEiAccountSummaryExplanations } from 'scripts/features/modals/account-summary/account-summary-explanation-utils';
import { withLocaleAwareClientConfig } from 'scripts/hoc/with-locale-aware-client-config/with-locale-aware-client-config';
import { usePageTracking } from 'scripts/hooks/use-page-tracking/use-page-tracking';
import { PagesData } from 'scripts/config/pages-data';
import { PageTags } from 'scripts/api/tracking/tracking.interfaces';
interface IAccountSummaryExplanationProps extends IExplanationStateToProps, IExplanationDispatchToProps {}

interface IExplanationDispatchToProps {
  getClientConfig: () => void;
}

interface IExplanationStateToProps {
  clientConfig: IClientConfig;
}

const getDeductibleCustomLabel = (clientConfig?: IClientConfig): string | void =>
  clientConfig?.customLabels?.customAccumulatorLabels?.inNetwork?.FAMILY_DEDUCTIBLE ||
  clientConfig?.customLabels?.customAccumulatorLabels?.inNetwork?.INDIVIDUAL_DEDUCTIBLE;

const getOOPMaxCustomLabel = (clientConfig?: IClientConfig): string | void =>
  clientConfig?.customLabels?.customAccumulatorLabels?.inNetwork?.FAMILY_OOP ||
  clientConfig?.customLabels?.customAccumulatorLabels?.inNetwork?.INDIVIDUAL_OOP;

export const RawEIAccountSummaryExplanation: React.FunctionComponent<IAccountSummaryExplanationProps> = props => {
  const { clientConfig } = props;
  const ParagraphWithInnerHTML = withInnerHTML<HTMLHeadingElement>('p');

  useEffect(() => {
    if (!clientConfig) {
      getClientConfig();
    }
  }, []);

  const { t } = useTranslation([Dictionary.ACCOUNT_SUMMARY, Dictionary.COMMON]);

  const customExplanation = getEiAccountSummaryExplanations(clientConfig);

  const suppressDeductibleExplanation = !!clientConfig?.suppressions?.suppressDeductibleExplanation;
  const modalTitle = suppressDeductibleExplanation ? 'OOP_MAX_EXPLANATION_LABEL' : 'DEDUCTIBLE_AND_OOP_HEADER';
  const deductibleLabel = getDeductibleCustomLabel(clientConfig) || t(`${Dictionary.COMMON}:DEDUCTIBLE`);
  const oopMaxLabel = getOOPMaxCustomLabel(clientConfig) || t(`${Dictionary.ACCOUNT_SUMMARY}:OOP_MAX_HEADER_SHORT`);

  usePageTracking(PagesData.accountSummaryExplanation.name, [PageTags.accountSummaryExplanation], true);

  return (
    <Modal
      dataUiSection={trackFeatures.accountSummaryExplanation}
      modalTitle={t(modalTitle, { deductibleLabel: deductibleLabel, oopMaxLabel: oopMaxLabel })}
      tabIndex={0}
    >
      <Feature featureId={trackFeatures.accountSummaryExplanation}>
        <$ExplanationModalBody>
          {customExplanation.map((item, index) => (
            <span key={index}>
              {item.isCustomLabel && <h2>{item.label} </h2>}
              {!item.isCustomLabel && <h2>{t(item.label)} </h2>}
              {item.isCustomDef && <ParagraphWithInnerHTML innerHTML={item.definition} />}
              {!item.isCustomDef && <p>{t(item.definition)}</p>}
            </span>
          ))}
        </$ExplanationModalBody>
      </Feature>
    </Modal>
  );
};

const mapStateToProps = (state: IReduxState): IExplanationStateToProps => ({
  clientConfig: selectClientConfigData(state),
});

export const EIAccountSummaryExplanation = connect(mapStateToProps, { getClientConfig })(
  withLocaleAwareClientConfig(RawEIAccountSummaryExplanation),
);
