import {
  BenefitNetwork,
  IBenefit,
  IBenefitAccumulators,
  IBenefitAmount,
  ICoverageParams,
  IPlanAccumulators,
  IPlanBenefits,
  IPlanVariationParams,
  BenefitPaymentType,
} from 'scripts/api/plans/plans.interfaces';
import {
  CoverageStatus,
  CoverageType,
  CoverageTypeCode,
  RelationshipType,
  ICoverageTimePeriod,
} from 'scripts/api/api.interfaces';
import {
  IProfile,
  IProfileUser,
  IPlanCoverage,
  ProgramType,
  LineOfBusiness,
  IProducts,
  IPrimaryCarePhysician,
  PcpType,
} from 'scripts/api/profile/profile.interfaces';
import { concatPvrc, isCS, isMR, isPolaris } from 'scripts/util/user/user';
import { removeLeadingZeroes } from 'scripts/util/plans/plans-utils';
import { ICoverageSection } from 'scripts/features/modals/id-cards/id-cards.interfaces';
import {
  CoverageStatusActive,
  CoverageStatusEnded,
  CoverageStatusNotStarted,
  CoverageStatusUnknown,
} from 'scripts/util/plans/plans.constants';
import { IConfig } from 'scripts/util/constants/environment.interfaces';
import { getFullName, isPreEffective, isTermed } from '../profile/profile';
import { splitConfig } from 'scripts/util/config/config-utils';

/**
 * Removes duplicates from coverages by coverageType and coverageTypeCode.
 * In some cases, coverages could have the same coverageType but different coverageTypeCodes. (Dental v EhbDental)
 * CoverageTypeCode is nullable so checking on both properties for safety.
 * @param coverages which have been gotten from all users, likely with duplicates.
 */
export function dedupeCoveragesByType(coverages: IPlanCoverage[]): IPlanCoverage[] {
  const uniqueCoverageTypes: { type: CoverageType; typeCode: CoverageTypeCode }[] = [];

  const dedupedCoverages = coverages.filter(cov => {
    if (
      uniqueCoverageTypes.find(
        uniqueCov => uniqueCov.type === cov.coverageType && uniqueCov.typeCode === cov.coverageTypeCode,
      )
    ) {
      return false;
    } else {
      uniqueCoverageTypes.push({ type: cov.coverageType, typeCode: cov.coverageTypeCode });
      return true;
    }
  });

  return dedupedCoverages;
}

export function getActivePcp(
  pcpList: IPrimaryCarePhysician[],
  primaryCareType: PcpType[] = [PcpType.Physician, PcpType.Group, PcpType.Facility],
): string {
  for (const pcp of pcpList) {
    if (pcp.status === CoverageStatus.Active && primaryCareType.includes(pcp.pcpType)) {
      return getFullName(pcp);
    }
  }
}

export function getAllCoveragesFromUsers(currentProfile: IProfileUser, users: IProfileUser[]): IPlanCoverage[] {
  let planCoverages = [];
  for (const user of users) {
    planCoverages = planCoverages.concat(user.planCoverages);
  }
  if (currentProfile.lineOfBusiness === LineOfBusiness.EI) {
    // We want a list of all possible coverages among all users, so here we remove duplicates by coverageType
    planCoverages = dedupeCoveragesByType(planCoverages);
  }
  return planCoverages;
}

/**
 * matches two coverages by {@see IPlanCoverage.coverageType} and {@see IPlanCoverage.coverageTypeCode}.
 * @param coverage An {@see IPlanCoverage} instance.
 * @param otherCoverage The {@see IPlanCoverage} instance to match on.
 * @returns true if the coverages match; otherwise false.
 */
function matchesPlanCoverage(coverage: IPlanCoverage, otherCoverage: IPlanCoverage): boolean {
  return (
    otherCoverage.coverageType === coverage.coverageType && otherCoverage.coverageTypeCode === coverage.coverageTypeCode
  );
}

/**
 * Finds a coverage in the collection of coverages for a user.
 * @param coverage An {@see IPlanCoverage} instance.
 * @param user An {@see IProfileUser} instance.
 * @returns A matching {@see IPlanCoverage} instance, or null if no matching coverage is found in the user.
 */
function getMatchingPlanCoverage(coverage: IPlanCoverage, user: IProfileUser): IPlanCoverage | undefined {
  if (user.planCoverages && user.planCoverages.length > 0) {
    return user.planCoverages.find(c => matchesPlanCoverage(c, coverage));
  } else {
    return undefined;
  }
}

/**
 * Gets a Plan Period for a given coverage for a user.
 * @param coverage An {@see IPlanCoverage} instance.
 * @param user An {@see IProfileUser} instance.
 * @returns An {@see ICoverageTimePeriod} instance for a coverage the user has that matches the coverage argument, or undefined.
 */
export function getMatchingPlanPeriod(coverage: IPlanCoverage, user: IProfileUser): ICoverageTimePeriod {
  const matchingPlanCoverage = getMatchingPlanCoverage(coverage, user);
  if (matchingPlanCoverage !== undefined && matchingPlanCoverage.planPeriod !== undefined) {
    return matchingPlanCoverage.planPeriod;
  } else {
    return undefined;
  }
}

/**
 * Given a coverage and a user, finds the text representation of a coverage status
 * for a coverage the user has matching the passed-in coverge.
 * @param coverage An {@see IPlanCoverage} instance to match.
 * @param user An {@see IProfileUser} instance.
 * @returns A UI-Friendly coverage status.
 */
export function getCoverageStatus(coverage: IPlanCoverage, user: IProfileUser): string {
  const planPeriod = getMatchingPlanPeriod(coverage, user);
  const coverageStatus: CoverageStatus | undefined = planPeriod && planPeriod.status;
  switch (coverageStatus) {
    case CoverageStatus.Active:
      return CoverageStatusActive;
    case CoverageStatus.Future:
      return CoverageStatusNotStarted;
    case CoverageStatus.Termed:
      return CoverageStatusEnded;
    default:
      return CoverageStatusUnknown;
  }
}

export function hasActiveUser(coverage: IPlanCoverage, users: IProfileUser[]): boolean {
  return users
    .map(user => getMatchingPlanPeriod(coverage, user))
    .some(coverageStatus => coverageStatus && coverageStatus.status === CoverageStatus.Active);
}

export function hasActiveOrFutureUser(coverage: IPlanCoverage, users: IProfileUser[]): boolean {
  return users
    .map(user => getMatchingPlanPeriod(coverage, user))
    .some(coverageStatus => coverageStatus && coverageStatus.status !== CoverageStatus.Termed);
}

export function getGroupNumber(coverage: ICoverageSection, currentProfile: IProfileUser): string | undefined {
  const isCS = currentProfile.lineOfBusiness === LineOfBusiness.CS;
  const reportingCodePlanIds = new Set(['0FLDSNP', '0OHDSNP']);
  const showReportingCodeAsGroupId = reportingCodePlanIds.has(currentProfile.userInfo.primaryCustomerId) && isCS;

  if (showReportingCodeAsGroupId) {
    return currentProfile.pvrc ? currentProfile.pvrc.reportingCode : undefined;
  } else {
    return isPolaris(currentProfile) ? removeLeadingZeroes(coverage.groupNumber) : coverage.policyNumber;
  }
}

export function getMembershipCategory(currentProfile: IProfileUser): string | undefined {
  return currentProfile.membershipCategory;
}

export function getLabelFromCoverageType(coverageType: CoverageType, rxLabel: string = 'PRESCRIPTION_DRUG'): string {
  return coverageType === CoverageType.Rx ? rxLabel : coverageType;
}

export function getLabelFromCoverage(coverage: ICoverageSection): string {
  if (coverage.planFeatures.isSupplemental) {
    return coverage.planFeatures.programType === ProgramType.Ship ? 'HEALTH_INSURANCE_CARD' : 'SUPPLEMENTAL_MEDICAL';
  } else {
    return getLabelFromCoverageType(coverage.coverageType);
  }
}

/**
 * Given a C&S user's coverages, separates those coverages between
 * those which should and shouldn't be displayed with an id card.
 * @param coverages An array of coverages {@see IPlanCoverage}.
 * @returns An array of two arrays of coverages -
 * those that should have an id card displayed and those that shouldn't.
 */
export function separateCoveragesByIdCardDisplay(
  planCoverages: IPlanCoverage[],
  showCsDentalPolicies: IConfig['ARCADE_WEB_SHOW_CS_DENTAL_ID_CARD_POLICIES'],
): [IPlanCoverage[], IPlanCoverage[]] {
  // For the most part, id cards should only be displayed for Medical for C&S.
  // The exception is some dental coverages with specific policy numbers (like HIQI).
  const isShowDentalPolicy = (policyNumber: string): boolean =>
    splitConfig(showCsDentalPolicies).includes(policyNumber);

  const displayIdCard = (cov: IPlanCoverage): boolean => {
    return (
      cov.coverageType === CoverageType.Medical ||
      (cov.coverageType === CoverageType.Dental && isShowDentalPolicy(cov.policyNumber))
    );
  };

  const [idCardCoverages, nonIdCardCoverages] = planCoverages.reduce(
    (result, cov) => {
      result[displayIdCard(cov) ? 0 : 1].push(cov);
      return result;
    },
    [[], []],
  );
  return [idCardCoverages, nonIdCardCoverages];
}

export function getOtherCoverageTypesForCs(
  medicalCoverage: IPlanCoverage[],
  nonMedicalCoverage: IPlanCoverage[],
  products?: IProducts,
): CoverageType[] {
  let otherCoverageTypesForCs: CoverageType[];
  // filter out coverage types that are already in the medical coverage's additionalCoverageTypes
  const uniqueNonMedicalCoverageTypes = nonMedicalCoverage
    .filter((cov: { coverageType: any }) => medicalCoverage[0].additionalCoverageTypes.indexOf(cov.coverageType) === -1)
    .map((cov: { coverageType: any }) => cov.coverageType);
  // combine other coverages with additional coverages
  otherCoverageTypesForCs = medicalCoverage[0].additionalCoverageTypes.concat(uniqueNonMedicalCoverageTypes);
  // filter out behavioral health coverage because, for C&S only, we get this from the plan summary response
  otherCoverageTypesForCs = otherCoverageTypesForCs.filter(cov => cov !== CoverageType.BehavioralHealth);
  // add behavioral health coverage if applicable
  if (products && products.liveAndWorkWell) {
    otherCoverageTypesForCs.push(CoverageType.BehavioralHealth);
  }
  otherCoverageTypesForCs.sort();
  return otherCoverageTypesForCs;
}

export function hasCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
  const matchingUserCoverage = getMatchingPlanCoverage(coverage, user);
  if (matchingUserCoverage !== undefined) {
    return matchingUserCoverage.planPeriod !== undefined;
  } else {
    return false;
  }
}

export function getUsersWithCoverage(coverage: ICoverageSection, users: IProfileUser[]): IProfileUser[] {
  return users.filter(user => hasCoverage(coverage, user));
}

function hasCoverageOfStatus(coverage: IPlanCoverage, user: IProfileUser, coverageStatus: CoverageStatus): boolean {
  const matchingUserCoverage = getMatchingPlanCoverage(coverage, user);
  if (matchingUserCoverage !== undefined) {
    return matchingUserCoverage.planPeriod !== undefined && matchingUserCoverage.planPeriod.status === coverageStatus;
  } else {
    return false;
  }
}

export function hasActiveCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
  return hasCoverageOfStatus(coverage, user, CoverageStatus.Active);
}

export function hasPdpPlan(coverages: IPlanCoverage[]): boolean {
  return coverages?.some(
    c => c.coverageTypeCode === CoverageTypeCode.PDP && c.planPeriod.status === CoverageStatus.Active,
  );
}

export function hasTermedCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
  return hasCoverageOfStatus(coverage, user, CoverageStatus.Termed);
}

export function hasTermedUser(coverage: IPlanCoverage, users: IProfileUser[]): boolean {
  return users
    .map(user => getMatchingPlanPeriod(coverage, user))
    .some(coverageStatus => coverageStatus && coverageStatus.status === CoverageStatus.Termed);
}

export function getCoverageParams(currentProfile: IProfileUser, coverageTypeCode: CoverageTypeCode): ICoverageParams {
  for (const coverage of currentProfile.planCoverages) {
    if (coverage.coverageTypeCode === coverageTypeCode && coverage.planPeriod.status === CoverageStatus.Active) {
      return {
        coverageTypeCode: coverage.coverageTypeCode,
        fundingArrangementType: coverage.planFeatures.fundingArrangementType,
        programType: coverage.planFeatures.programType,
        marketType: coverage.additionalCoverageInfo.marketType,
      };
    }
  }
  return {} as ICoverageParams;
}

export function planHasOtherMembers(profile: IProfile, currentProfile: IProfileUser): boolean {
  return currentProfile.relationshipType !== RelationshipType.Subscriber || profile.dependents.length > 0;
}

export const getPlanVariationParams = (profile: IProfile): IPlanVariationParams => ({
  pvrc: concatPvrc(profile.currentUser),
  clientId: profile.clientInfo ? profile.clientInfo.clientId : null,
});

export function shouldHideCards(coverage: ICoverageSection, currentUser: IProfileUser): boolean {
  return (
    currentUser.relationshipType !== RelationshipType.Subscriber &&
    coverage.selectedDepSeqNum !== currentUser.dependentSeqNum &&
    coverage.planFeatures.isIndividualIdCard
  );
}

/**
 * Checks whether or not to display an ID card for a user for a coverage.
 * @param coverage The {@see IPlanCoverage} instance to check.
 * @param user The {@see IProfileUser} instance to check.
 * @param currentUser The currently logged-in {@see IProfileUser}.
 * Returns true if the ui should show an ID card for the user; otherwise false.
 */
export function showIdCard(coverage: IPlanCoverage, user: IProfileUser, currentUser: IProfileUser): boolean {
  const isSubscriber = currentUser.relationshipType === RelationshipType.Subscriber;
  // always show the id if it belongs to the logged in user.  Always show subscribers all cards
  if (currentUser.dependentSeqNum === user.dependentSeqNum || isSubscriber) {
    return true;
  }

  const matchingPlanCoverage = getMatchingPlanCoverage(coverage, user);
  // get plan features for the given user, based on the plan coverage of the current user
  const planFeatures = matchingPlanCoverage && matchingPlanCoverage.planFeatures;
  if (!planFeatures) {
    return false;
  }

  const loggedInUserHasIndividualId = coverage.planFeatures.isIndividualIdCard;
  const userHasIndividualId = planFeatures.isIndividualIdCard;

  // users with individual plans should not see other users' cards
  // users should not see cards for any other user that has an individual plan
  return !loggedInUserHasIndividualId && !userHasIndividualId;
}

/**
 * Checks a set of users and returns true if more than one user matches a coverage.
 * @param coverage The {@see IPlanCoverage} to match to the user's coverages.
 * @param users A set of {@see IProfileUser} instances.
 * @param currentUser The currently logged-in {@see IProfileUser}.
 * @returns true if more than one user matches a plan coverage and should see an ID card; otherwise false.
 */
export function hasMultipleActiveUsersWithIds(
  coverage: IPlanCoverage,
  users: IProfileUser[],
  currentUser: IProfileUser,
): boolean {
  return (
    users.filter(user => {
      const planPeriod = getMatchingPlanPeriod(coverage, user);
      return (
        showIdCard(coverage, user, currentUser) &&
        planPeriod !== undefined &&
        planPeriod.status !== CoverageStatus.Termed
      );
    }).length > 1
  );
}

/**
 * Checks a set coverages and returns true if user has only Ship coverage program types; otherwise false
 * @param coverages The {@see IPlanCoverage[]} to map user's coverages.
 */
export function hasOnlyShipPlans(coverages: IPlanCoverage[]): boolean {
  return coverages.every(cov => cov.planFeatures.programType === ProgramType.Ship);
}

export function isCsPreEffective(user: IProfileUser): boolean {
  return (
    isCS(user) &&
    user.planCoverages.length > 0 &&
    user.planCoverages.every(c => c.planPeriod.status === CoverageStatus.Future)
  );
}

export function isMrShipNotTermed(user: IProfileUser): boolean {
  return (
    isMR(user) &&
    !isTermed(user.planCoverages) &&
    user.planCoverages.some(c => c.planFeatures.programType === ProgramType.Ship)
  );
}

export function isMrPreEffective(user: IProfileUser): boolean {
  return (
    isMR(user) &&
    user.planCoverages.length > 0 &&
    user.planCoverages.every(c => c.planPeriod.status === CoverageStatus.Future)
  );
}

export function hasSomeSspPlan(coverages: IPlanCoverage[]): boolean {
  return coverages?.some(c => c?.coverageTypeCode === CoverageTypeCode.SSP);
}

export function isVisionCoverage(coverage: IPlanCoverage): boolean {
  return coverage.coverageType === CoverageType.Vision;
}

export function isFutureShipCoverage(user: IProfileUser): boolean {
  const futureCoverages = user.planCoverages.filter(c => c.planPeriod.status === CoverageStatus.Future);

  return (
    user.planCoverages.length > 0 &&
    isPreEffective(user.planCoverages) &&
    futureCoverages.some(c => c.planFeatures.programType === ProgramType.Ship)
  );
}

/**
 * Checks coverage to determine if card type is EHBDental.
 * @param coverage The {@see IPlanCoverage} to match to the user's coverages.
 * @returns true if coverage type is Dental and coverage type code is EhbDental (DS); otherwise false.
 */
export function isEhbDental(coverage: IPlanCoverage): boolean {
  return coverage.coverageType === CoverageType.Dental && coverage.coverageTypeCode === CoverageTypeCode.EhbDental;
}

const getBenefit =
  (coverageType: CoverageType) =>
  (planBenefits?: IPlanBenefits): IBenefit | undefined =>
    planBenefits?.benefits.find(benefit => benefit.coverageType === coverageType);

export const getMedicalBenefit = getBenefit(CoverageType.Medical);

const getBenefitAccumulator =
  (coverageType: CoverageType) =>
  (planAccumulators?: IPlanAccumulators): IBenefitAccumulators | undefined =>
    planAccumulators?.benefits.find(benefit => benefit.coverageType === coverageType);

export const getMedicalBenefitAccumulator = getBenefitAccumulator(CoverageType.Medical);

export const hasBenefitType =
  (coverageType: CoverageType, network: BenefitNetwork) =>
  (planBenefits: IPlanBenefits, paymentType: BenefitPaymentType): boolean => {
    let benefitAmount: IBenefitAmount;

    const benefit = getBenefit(coverageType)(planBenefits);
    if (benefit) {
      benefitAmount = benefit.maxes[network]?.find((max: IBenefitAmount) => max.type === paymentType);
    }

    return !!benefitAmount;
  };

/**
 * This method determines which plan payment type (Family or Individual) is closer to being met, and returns
 * the balance for both that payment type's benefit and accumulator.
 */
export const deriveCloserToBeMetAmounts =
  (coverageType: CoverageType, network: BenefitNetwork) =>
  (
    benefits: IPlanBenefits,
    accumulators: IPlanAccumulators,
    familyBenefitPaymentType: BenefitPaymentType,
    individualBenefitPaymentType: BenefitPaymentType,
  ): {
    benefitAmount: IBenefitAmount;
    accumulatorAmount?: IBenefitAmount;
  } | null => {
    const getAmountsClosure = getAmounts(coverageType, network);

    const familyAmounts = getAmountsClosure(benefits, accumulators, familyBenefitPaymentType);
    const { benefitAmount: familyBenefitAmount, accumulatorAmount: familyAccumulatorAmount } = familyAmounts || {};

    const individualAmounts = getAmountsClosure(benefits, accumulators, individualBenefitPaymentType);
    const { benefitAmount: individualBenefitAmount, accumulatorAmount: individualAccumulatorAmount } =
      individualAmounts || {};

    if (!individualAmounts && !familyAmounts) {
      return null;
    }

    if (individualAmounts && !familyAmounts) {
      return individualAmounts;
    }

    if (familyAmounts && !individualAmounts) {
      return familyAmounts;
    }

    const unmetIndividualAmount = getUnmetAmount(individualBenefitAmount, individualAccumulatorAmount);
    const unmetFamilyAmount = getUnmetAmount(familyBenefitAmount, familyAccumulatorAmount);

    const individualDeductibleMet = unmetIndividualAmount <= 0;
    const familyDeductibleMet = unmetFamilyAmount <= 0;

    const bothDeductiblesMet = individualDeductibleMet && familyDeductibleMet;

    // If both amounts are met or unnmet amounts are the same for individual and family, use family.
    if (bothDeductiblesMet || unmetIndividualAmount === unmetFamilyAmount) {
      return familyAmounts;
    }

    return unmetIndividualAmount < unmetFamilyAmount ? individualAmounts : familyAmounts;
  };

export const getAmounts =
  (coverageType: CoverageType, network: BenefitNetwork, isMedsup?: boolean) =>
  (
    benefits: IPlanBenefits,
    accumulators: IPlanAccumulators,
    paymentType: BenefitPaymentType,
  ): {
    benefitAmount: IBenefitAmount;
    accumulatorAmount?: IBenefitAmount;
  } | null => {
    const benefit = getBenefit(coverageType)(benefits);
    const accumulator = getBenefitAccumulator(coverageType)(accumulators);

    const benefitAmount = isMedsup
      ? benefit?.mnrMaxes?.find((benefitAmount: IBenefitAmount) => benefitAmount.type === paymentType)
      : benefit?.maxes[network]?.find((benefitAmount: IBenefitAmount) => benefitAmount.type === paymentType);

    if (!benefitAmount) {
      return null;
    }

    const accumulatorAmount = accumulator?.accumulators[network]?.find(
      (accumulatorAmount: IBenefitAmount) => accumulatorAmount.type === paymentType,
    );

    return {
      benefitAmount,
      accumulatorAmount,
    };
  };

export const getAmountsPerNetwork =
  (coverageType: CoverageType) =>
  (
    benefits: IPlanBenefits,
    accumulators: IPlanAccumulators,
    paymentType: BenefitPaymentType,
    network: BenefitNetwork,
  ): {
    benefitAmount: IBenefitAmount;
    accumulatorAmount?: IBenefitAmount;
  } | null => {
    const benefit = getBenefit(coverageType)(benefits);
    const accumulator = getBenefitAccumulator(coverageType)(accumulators);

    const benefitAmount = benefit?.maxes[network]?.find(
      (benefitAmount: IBenefitAmount) => benefitAmount.type === paymentType,
    );

    if (!benefitAmount) {
      return null;
    }

    const accumulatorAmount = accumulator?.accumulators[network]?.find(
      (accumulatorAmount: IBenefitAmount) => accumulatorAmount.type === paymentType,
    );

    return {
      benefitAmount,
      accumulatorAmount,
    };
  };

export const getUnmetAmount = (benefitAmount?: IBenefitAmount, accumulatorAmount?: IBenefitAmount): number =>
  (benefitAmount?.amount.value || Number.MAX_VALUE) - (accumulatorAmount?.amount.value || 0);

export const isAmountMet = (benefitAmount?: IBenefitAmount, accumulatorAmount?: IBenefitAmount): boolean =>
  benefitAmount ? benefitAmount.amount.value <= (accumulatorAmount?.amount.value || 0) : false;

export function isComplicatedPlanSet(planCoverages: IPlanCoverage[]): boolean {
  const coverageTypeCodes = planCoverages.map(coverage => coverage.coverageTypeCode).filter(e => !!e);
  const toFix = [
    [CoverageTypeCode.MA, CoverageTypeCode.HospitalIndemnity, CoverageTypeCode.PDP],
    [CoverageTypeCode.SSP, CoverageTypeCode.HospitalIndemnity, CoverageTypeCode.PDP],
    [CoverageTypeCode.MedicareSupplement, CoverageTypeCode.HospitalIndemnity, CoverageTypeCode.PDP],
  ];
  return toFix.some(scenario => {
    return scenario.every(code => coverageTypeCodes.includes(code));
  });
}

export function isMedRx(coverage: IPlanCoverage): boolean {
  return coverage.coverageType === CoverageType.Medical || coverage.coverageType === CoverageType.Rx;
}

export const getIsDeductibleMet = (accumulators?: IPlanAccumulators, benefits?: IPlanBenefits): boolean | null => {
  if (!benefits || !accumulators) {
    return null;
  }

  const { benefitAmount, accumulatorAmount } =
    deriveCloserToBeMetAmounts(CoverageType.Medical, BenefitNetwork.InNetwork)(
      benefits,
      accumulators,
      BenefitPaymentType.FamilyDeductible,
      BenefitPaymentType.IndividualDeductible,
    ) || {};
  const unmetDeductible = getUnmetAmount(benefitAmount, accumulatorAmount);

  return unmetDeductible <= 0;
};

/**
 * @param user An {@see IProfileUser} instance
 * @returns True if active medical coverage is found else false
 */

export function hasActiveMedicalCoverage(user: IProfileUser): boolean {
  return !!user.planCoverages?.find(
    coverage => coverage.coverageType === CoverageType.Medical && coverage.planPeriod?.status === CoverageStatus.Active,
  );
}

export function isMedicaid(coverages: Array<IPlanCoverage>): boolean {
  return coverages.some(coverage => coverage?.planFeatures?.programType === ProgramType.MEDICAID);
}
