import { IClaimsInfoPacket } from '@rally/rallypay';
import { IClaim, IClaimTotals, IManageClaimData, IEOB, IClaimNote } from 'scripts/api/claims/claims.interfaces';
import { IHealthcareStatementList } from 'scripts/api/documents/documents.interfaces';
import { IStateData } from './reducer.interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { changeSelectedProfile } from './profile-service-reducer';

export interface IClaimsState {
  eob: IStateData<{ claimMatchIds: Record<string, boolean>; eobs: IEOB[] }>;
  notes: Record<string, IStateData<IClaimNote>>;
  sortedHealthcare: IStateData<IClaim[]>;
  totals: IStateData<IClaimTotals[]>;
  needAttention: IStateData<IClaim[]>;
  healthcareStatementList: IStateData<IHealthcareStatementList>;
  payNowClaim: IStateData<IClaim>;
  payNowClaimsInfoPacket: IStateData<IClaimsInfoPacket>;
  updatedRallyPayData: IStateData<string>;
}

export const initialState: IClaimsState = {
  eob: {
    data: { claimMatchIds: {}, eobs: [] },
    error: undefined,
    loading: false,
  },
  notes: {},
  sortedHealthcare: {
    arcadeDataUpdated: undefined,
    data: undefined,
    error: undefined,
    loading: false,
  },
  totals: {
    arcadeDataUpdated: undefined,
    data: undefined,
    error: undefined,
    loading: false,
  },
  needAttention: {
    arcadeDataUpdated: undefined,
    data: undefined,
    error: undefined,
    loading: false,
  },
  healthcareStatementList: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  payNowClaim: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  payNowClaimsInfoPacket: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  updatedRallyPayData: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const claims = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    getEOBError: (state: IClaimsState): void => {
      state.eob.error = true;
      state.eob.loading = false;
    },
    getEOBLoading: (state: IClaimsState): void => {
      state.eob.loading = true;
    },
    getEOBSuccess: (state: IClaimsState, action: PayloadAction<{ claimMatchIds: string[]; eobs: IEOB[] }>): void => {
      const { claimMatchIds, eobs } = action.payload;
      state.eob.data.claimMatchIds = claimMatchIds.reduce(
        (acc, id) => ({ ...acc, [id]: true }),
        state.eob.data.claimMatchIds,
      );
      state.eob.data.eobs = [...state.eob.data.eobs, ...eobs];
      state.eob.error = false;
      state.eob.loading = false;
    },
    getSortedHealthcareSuccess: (
      state: IClaimsState,
      action: PayloadAction<{ data: IClaim[]; arcadeDataUpdated?: string }>,
    ): void => {
      state.sortedHealthcare.arcadeDataUpdated = action.payload.arcadeDataUpdated;
      state.sortedHealthcare.data = action.payload.data;
      state.sortedHealthcare.error = false;
      state.sortedHealthcare.loading = false;
    },
    getSortedHealthcareLoading: (state: IClaimsState): void => {
      state.sortedHealthcare.loading = true;
    },
    getSortedHealthcareError: (state: IClaimsState): void => {
      state.sortedHealthcare.arcadeDataUpdated = undefined;
      state.sortedHealthcare.data = undefined;
      state.sortedHealthcare.error = true;
      state.sortedHealthcare.loading = false;
    },
    getNeedAttentionSuccess: (
      state: IClaimsState,
      action: PayloadAction<{ value: IClaim[]; arcadeDataUpdated?: string }>,
    ): void => {
      state.needAttention.arcadeDataUpdated = action.payload.arcadeDataUpdated;
      state.needAttention.data = action.payload.value;
      state.needAttention.error = false;
      state.needAttention.loading = false;
    },
    getNeedAttentionLoading: (state: IClaimsState): void => {
      state.needAttention.loading = true;
    },
    getNeedAttentionError: (state: IClaimsState): void => {
      state.needAttention.arcadeDataUpdated = undefined;
      state.needAttention.data = undefined;
      state.needAttention.error = true;
      state.needAttention.loading = false;
    },
    manageClaimSuccess: (state: IClaimsState, action: PayloadAction<IManageClaimData>): void => {
      const updateClaim = ({ manageClaim }: IManageClaimData, currentClaim?: IClaim): void => {
        if (!currentClaim) {
          return;
        }
        currentClaim.claimManagementInfo.markPaid =
          typeof manageClaim.markPaid === 'boolean' ? manageClaim.markPaid : currentClaim.claimManagementInfo.markPaid;
        currentClaim.claimManagementInfo.isSaved =
          typeof manageClaim.isSaved === 'boolean' ? manageClaim.isSaved : currentClaim.claimManagementInfo.isSaved;
        // Optum treats an empty string as claimNote: false.
        currentClaim.claimManagementInfo.claimNote =
          typeof manageClaim.claimNote === 'string'
            ? !!manageClaim.claimNote.length
            : currentClaim.claimManagementInfo.claimNote;
      };

      // Update relevant slice of IClaimManagementInfo in each claim with matching claimKey
      updateClaim(
        action.payload,
        state.sortedHealthcare.data?.find((claim: IClaim) => claim.claimKey === action.payload.claimKey),
      );
      updateClaim(
        action.payload,
        state.needAttention.data?.find((claim: IClaim) => claim.claimKey === action.payload.claimKey),
      );

      // Update the proper claimNote string in notes (if applicable)
      const claimNote = action.payload.manageClaim.claimNote;
      const claimNoteExists = typeof claimNote === 'string';
      if (claimNoteExists) {
        state.notes[action.payload.claimKey] = {
          data: {
            claimKey: action.payload.claimKey,
            note: claimNote,
          },
          loading: false,
          error: false,
        };
      }

      // TODO: When state.financialDetails is implemented, update this function to address it
    },
    rallyPayPaymentSuccess: (state: IClaimsState, action: PayloadAction<IClaim>): void => {
      const updateClaim = (updatedClaim: IClaim, currentClaim: IClaim): void => {
        if (!currentClaim) {
          return;
        }
        if (!updatedClaim.balance.youMayOweAmount?.value) {
          currentClaim.claimManagementInfo.markPaid = true;
        }
        currentClaim.balance = updatedClaim.balance;
        currentClaim.claimPaymentTypes = updatedClaim.claimPaymentTypes;
      };
      const updatedClaim = action.payload;
      updateClaim(
        updatedClaim,
        state.sortedHealthcare.data?.find((claim: IClaim) => claim.claimKey === updatedClaim.claimKey),
      );
      updateClaim(
        updatedClaim,
        state.needAttention.data?.find((claim: IClaim) => claim.claimKey === updatedClaim.claimKey),
      );
    },
    getHealthcareStatementListSuccess: (
      state: IClaimsState,
      action: PayloadAction<{ data: IHealthcareStatementList }>,
    ): void => {
      state.healthcareStatementList.data = action.payload.data;
      state.healthcareStatementList.error = false;
      state.healthcareStatementList.loading = false;
    },
    getHealthcareStatementListLoading: (state: IClaimsState): void => {
      state.healthcareStatementList.error = false;
      state.healthcareStatementList.loading = true;
    },
    getHealthcareStatementListError: (state: IClaimsState): void => {
      state.healthcareStatementList.data = undefined;
      state.healthcareStatementList.error = true;
      state.healthcareStatementList.loading = false;
    },
    getPayNowClaimSuccess: (state: IClaimsState, action: PayloadAction<{ payNowClaim: IClaim }>): void => {
      state.payNowClaim.data = action.payload.payNowClaim;
      state.payNowClaim.error = false;
      state.payNowClaim.loading = false;
    },
    getPayNowClaimLoading: (state: IClaimsState): void => {
      state.payNowClaim.error = false;
      state.payNowClaim.loading = true;
    },
    getPayNowClaimError: (state: IClaimsState): void => {
      state.payNowClaim.data = undefined;
      state.payNowClaim.error = true;
      state.payNowClaim.loading = false;
    },
    getPayNowClaimsInfoPacketSuccess: (
      state: IClaimsState,
      action: PayloadAction<{ payNowClaimsInfoPacket: IClaimsInfoPacket }>,
    ): void => {
      state.payNowClaimsInfoPacket.data = action.payload.payNowClaimsInfoPacket;
      state.payNowClaimsInfoPacket.error = false;
      state.payNowClaimsInfoPacket.loading = false;
    },
    getPayNowClaimsInfoPacketLoading: (state: IClaimsState): void => {
      state.payNowClaimsInfoPacket.error = false;
      state.payNowClaimsInfoPacket.loading = true;
    },
    getPayNowClaimsInfoPacketError: (state: IClaimsState): void => {
      state.payNowClaimsInfoPacket.data = undefined;
      state.payNowClaimsInfoPacket.error = true;
      state.payNowClaimsInfoPacket.loading = false;
    },
    getUpdatedRallyPayDataSuccess: (state: IClaimsState, action: PayloadAction<{ token: string }>): void => {
      state.updatedRallyPayData.data = action.payload.token;
      state.updatedRallyPayData.error = false;
      state.updatedRallyPayData.loading = false;
    },
    getUpdatedRallyPayDataLoading: (state: IClaimsState): void => {
      state.updatedRallyPayData.error = false;
      state.updatedRallyPayData.loading = true;
    },
    getUpdatedRallyPayDataError: (state: IClaimsState): void => {
      state.updatedRallyPayData.data = undefined;
      state.updatedRallyPayData.error = true;
      state.updatedRallyPayData.loading = false;
    },
  },
  extraReducers: {
    [changeSelectedProfile.toString()]: (state: IClaimsState): void => {
      state.sortedHealthcare.data = undefined;
      state.totals.data = undefined;
    },
  },
});

export const {
  getEOBError,
  getEOBLoading,
  getEOBSuccess,
  getSortedHealthcareError,
  getSortedHealthcareLoading,
  getSortedHealthcareSuccess,
  getNeedAttentionError,
  getNeedAttentionLoading,
  getNeedAttentionSuccess,
  manageClaimSuccess,
  rallyPayPaymentSuccess,
  getHealthcareStatementListSuccess,
  getHealthcareStatementListError,
  getHealthcareStatementListLoading,
  getPayNowClaimError,
  getPayNowClaimLoading,
  getPayNowClaimSuccess,
  getPayNowClaimsInfoPacketError,
  getPayNowClaimsInfoPacketLoading,
  getPayNowClaimsInfoPacketSuccess,
  getUpdatedRallyPayDataError,
  getUpdatedRallyPayDataLoading,
  getUpdatedRallyPayDataSuccess,
} = claims.actions;

export default claims.reducer;
