export const inflightRequests = {};

export function inflight<T>(key: string, makeRequest: () => PromiseLike<T>): Promise<T> {
  if (!inflightRequests[key]) {
    inflightRequests[key] = Promise.resolve(makeRequest());
    const cleanUp = (): boolean => delete inflightRequests[key];
    inflightRequests[key].then(cleanUp, cleanUp);
  }
  return inflightRequests[key];
}
