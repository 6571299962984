import { CoverageType, CoverageStatus } from 'scripts/api/api.interfaces';
import { LineOfBusiness, IPlanCoverage } from 'scripts/api/profile/profile.interfaces';
import { LastIndexedCoverageType, PartnerId } from './connect-service.interfaces';

/**
 * The map from the connect services' coverage types to Arcade Coverage Types.
 */
export const CoverageTypeToLastIndexedCoverageType: Map<CoverageType, LastIndexedCoverageType> = new Map([
  [CoverageType.BehavioralHealth, LastIndexedCoverageType.Behavioral],
  [CoverageType.Medical, LastIndexedCoverageType.Medical],
]);

/**
 * The map from Arcade's LineOfBusiness to the connect services' partnerId
 */
export const LineOfBusinessToPartnerId: Map<LineOfBusiness, PartnerId> = new Map([[LineOfBusiness.MR, PartnerId.MR]]);

export const getLastUpdatedCoverageTypes = (coverages: IPlanCoverage[]): LastIndexedCoverageType[] => {
  return coverages
    .filter(c => c.planPeriod.status === CoverageStatus.Active)
    .map(coverage => CoverageTypeToLastIndexedCoverageType.get(coverage.coverageType))
    .filter(Boolean)
    .filter((coverageType, index, coverageTypes) => coverageTypes.indexOf(coverageType) === index);
};
