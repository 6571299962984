import { userUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import HttpClient from '../http-client';
import { IHeartbeatResponse, ITokenResponse } from './user.interfaces';

function getHeartbeatLike(url: string, ignoreCache?: boolean): Promise<IHeartbeatResponse> {
  return HttpClient.get(url, {
    cacheName: CacheName.User,
    ignoreCache,
  });
}

/**
 * Calls the heartbeat endpoint in Arcade User.  Heartbeat extends and introspects the current session and returns information about that
 * session.
 * @param force When true, adds a request configuration flag that ignores cached responses forcing the request to be made.  Note that
 * forcing the request will still trigger an update to the cache.
 */
function getHeartbeat(force?: boolean): Promise<IHeartbeatResponse> {
  const url = userUris.heartbeat();
  return getHeartbeatLike(url, force);
}

/**
 * Calls the info endpoint in Arcade User.  Info introspects the current session and returns information about that session.
 * @param force When true, adds a request configuration flag that ignores cached responses forcing the request to be made.  Note that
 * forcing the request will still trigger an update to the cache.
 */
function getInfo(force?: boolean): Promise<IHeartbeatResponse> {
  const url = userUris.info();
  return getHeartbeatLike(url, force);
}

function getToken(ssoUrl: string): Promise<ITokenResponse> {
  const url = userUris.token(ssoUrl);
  return HttpClient.get(url);
}

function getRallyPayUserToken(): Promise<ITokenResponse> {
  const url = userUris.rallyPayTokenV2();
  return HttpClient.get(url);
}

export const UserApi = {
  getHeartbeat,
  getInfo,
  getToken,
  getRallyPayUserToken,
};
