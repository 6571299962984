// This allows you to use Promise.all without 'fast-failing'
// https://stackoverflow.com/questions/31424561/wait-until-all-es6-promises-complete-even-rejected-promises

export interface IReflectResult<T> {
  error?: Error;
  value?: T;
}

export const reflect = <T>(promise: Promise<T>): Promise<IReflectResult<T>> => {
  return promise.then(
    value => {
      return { value };
    },
    error => {
      return { error };
    },
  );
};
