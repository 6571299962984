import { targetingUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import HttpClient from '../http-client';
import { IArcadeClient, IProfileUser } from '../profile/profile.interfaces';
import {
  ICampaignResponse,
  ICampaigns,
  IClientConfig,
  IClientConfigResponse,
  IRealTimeOfferCount,
  IRealTimeOfferCountResponse,
  ICsCustomClientContentResponse,
  ICsCustomClientContent,
  IApiTacoRecommendationsResponse,
  ITacoRecommendationsResponse,
  IAlertsResponse,
  IAlerts,
} from './targeting.interfaces';
import { concatPvrc } from '../../util/user/user';

function getCampaigns(rallyId: string, placements: string[], dependentSeqNbr?: string): Promise<ICampaigns> {
  const url = targetingUris.campaigns(rallyId, { placements, dependentSeqNbr });
  return HttpClient.get<ICampaignResponse>(url, { cacheName: CacheName.Targeting, localizedCacheKey: true }).then(
    rsp => rsp.data,
  );
}

function getClientConfig(currentUser: IProfileUser, clientInfo?: IArcadeClient): Promise<IClientConfig> {
  const defaultConfig = {
    clientCustomContentId: '',
    clientConfigId: '',
    clientMetaData: {} as any,
    suppressions: {} as any,
    customLabels: {} as any,
    customMessaging: {},
    contentOverrides: {} as any,
  };

  if (clientInfo && clientInfo.hasCustomContent && clientInfo.configId) {
    const policyNumber = currentUser.primaryPolicyNumber;
    const pvrc = concatPvrc(currentUser);

    const url = targetingUris.clientConfig(clientInfo.configId, {
      clientId: clientInfo.clientId,
      policyNumber,
      pvrc,
    });

    return HttpClient.get<IClientConfigResponse>(url, { cacheName: CacheName.Targeting, localizedCacheKey: true })
      .then(rsp => rsp.data)
      .catch(() => Promise.resolve(defaultConfig));
  } else {
    return Promise.resolve(defaultConfig);
  }
}

function getCsFaqCustomizations(primaryPolicyNumber: string): Promise<ICsCustomClientContent> {
  const url = targetingUris.csFaqCustomizations(primaryPolicyNumber);
  return HttpClient.get<ICsCustomClientContentResponse>(url, {
    cacheName: CacheName.Targeting,
    localizedCacheKey: true,
  }).then(rsp => rsp.data);
}

function getRealTimeOfferCount(rallyId: string, lob: string): Promise<IRealTimeOfferCount> {
  const url = targetingUris.realTimeOfferCount(rallyId, { lob });
  // ARC-10300: Removing the caching of RTO Count to prevent caching of count being inaccurate
  return HttpClient.get<IRealTimeOfferCountResponse>(url).then(rsp => rsp.data);
}

function getTacoRecommendations(
  rallyId: string,
  placements: string[],
  dependentSeqNbr?: string,
  maxCampaignsPerPlacement?: number,
): Promise<ITacoRecommendationsResponse> {
  const url = targetingUris.tacoRecommendations(rallyId, { placements, dependentSeqNbr, maxCampaignsPerPlacement });
  return HttpClient.get<IApiTacoRecommendationsResponse>(url, {
    cacheName: CacheName.Targeting,
    localizedCacheKey: true,
  }).then(rsp => rsp.data);
}

function getTacoRealTimeOffers(rallyId: string, placements: string[]): Promise<ITacoRecommendationsResponse> {
  const url = targetingUris.tacoRealTimeOffers(rallyId, { placements });
  return HttpClient.get<IApiTacoRecommendationsResponse>(url, {
    cacheName: CacheName.Targeting,
    localizedCacheKey: true,
  }).then(rsp => rsp.data);
}

async function getAlerts(rallyId: string): Promise<IAlerts> {
  const url = targetingUris.alerts(rallyId);
  const response = await HttpClient.get<IAlertsResponse>(url, {
    cacheName: CacheName.Targeting,
    localizedCacheKey: true,
  });
  return response.data;
}

export const TargetingApi = {
  getAlerts,
  getCampaigns,
  getClientConfig,
  getCsFaqCustomizations,
  getRealTimeOfferCount,
  getTacoRecommendations,
  getTacoRealTimeOffers,
};
