import React, { FunctionComponent, MouseEvent } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectFeatureFlags, selectConfig } from 'scripts/selectors/app-selectors';
import { AnchorWithClickTracking } from './anchor';
import { getSsoUrl } from 'scripts/util/uri/uri';
import { constructParams } from 'scripts/util/uri/construct-params';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { internalSsoRedirect, lyesmithSsoRedirect } from 'scripts/thunks/user-service-thunks';
import { IResourceAnchorProps } from './resource-anchor';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { RallyCaller } from 'scripts/util/constants/rally-caller.constants';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { getResource } from 'scripts/util/resource/resource';

export interface IInternalRedirectAnchorProps
  extends Omit<IResourceAnchorProps, 'resourceValue' | 'population'>,
    IInternalRedirectAnchorDispatchToProps {
  dataTrackId: string;
  dataUiElementName?: string;
  resourceValue?: IResource;
  fullInternalRedirectValue?: string;
  rallyCaller: RallyCaller;
}

interface IInternalRedirectAnchorDispatchToProps {
  internalSsoRedirect: (ssoUrl: string, rallyCaller: RallyCaller, targetProp?: string) => void;
}

export const lyesmithSsoParams = (
  ssoUrl: string,
  authUrl: string,
): { vendorId: string | undefined; targetUrl: string | undefined } => {
  let vendorId: string;
  let targetUrl: string;
  const url = new URL(ssoUrl, location.origin);

  if (url.href.indexOf(`${authUrl}/ssoout`) === 0) {
    vendorId = url.searchParams.get('vendorId');
    targetUrl = url.searchParams.get('targetUrl') || undefined;
  } else if (url.pathname.includes('/sso/v1/vendor/')) {
    vendorId = url.pathname.split('/').pop();
  }
  return { vendorId, targetUrl };
};

export const RawInternalRedirectAnchor: FunctionComponent<IInternalRedirectAnchorProps> = props => {
  const { fullInternalRedirectValue, internalSsoRedirect, onClick, rallyCaller, resourceValue, target, ...rest } =
    props;
  // if a fullInternalRedirectValue is passed that means that the URL contains baseUrl/internal-redirect?deepLink=...
  // this is a rare case, and we want to extract just the ssoUrl to pass to the internalSsoRedirect function below
  const population = useSelector(selectPopulation);
  const ssoUrl = fullInternalRedirectValue
    ? getSsoUrl(fullInternalRedirectValue)
    : getResource(resourceValue, population);
  const href = `/internal-redirect${constructParams({ deepLink: ssoUrl })}`;
  const targetProp = target || LinkTarget.Self;
  const dispatch = useDispatch();
  const isLyesmithSsoFlagOn = useSelector(selectFeatureFlags).ARCADE_FEATURES_LYESMITH_SSO;
  const authUrl = useSelector(selectConfig).ARCADE_WEB_RALLY_AUTH_URL;
  const { vendorId, targetUrl } = isLyesmithSsoFlagOn
    ? lyesmithSsoParams(ssoUrl, authUrl)
    : { vendorId: undefined, targetUrl: undefined };

  const handleInternalRedirectClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    /*
      This control needs a preventDefault here as we are not guaranteed to run preventDefault later.
    */
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
    if (isLyesmithSsoFlagOn && vendorId) {
      dispatch(lyesmithSsoRedirect({ rallyCaller, vendorId, targetUrl }));
    } else {
      internalSsoRedirect(ssoUrl, rallyCaller, targetProp);
    }
  };

  return <AnchorWithClickTracking href={href} onClick={handleInternalRedirectClick} target={targetProp} {...rest} />;
};

export const InternalRedirectAnchor = connect(null, { internalSsoRedirect })(RawInternalRedirectAnchor);
