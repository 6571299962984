import { FunctionComponent, useEffect } from 'react';

export const ACCESSIBLE_OUTLINES_CLASS = 'hide-outlines';

/**
 * Adds and removes the class on the body that shows the accessible outlines around interactive elements on the page. This
 * component should be added once into the root of the application.
 */
export const AccessibleOutlines: FunctionComponent = () => {
  const handleMousedown = (): void => {
    document.body.classList.add(ACCESSIBLE_OUTLINES_CLASS);
  };

  const handleKeydown = (event: KeyboardEvent): void => {
    if (event.key === 'Tab') {
      document.body.classList.remove(ACCESSIBLE_OUTLINES_CLASS);
    }
  };

  useEffect(() => {
    document.body.classList.add(ACCESSIBLE_OUTLINES_CLASS);
    window.addEventListener('mousedown', handleMousedown);
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('mousedown', handleMousedown);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return null;
};
