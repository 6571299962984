import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILastIndexed } from 'scripts/api/connect/connect-service.interfaces';

export interface IConnectState {
  lastIndexed: IStateData<ILastIndexed>;
}

export const initialState: IConnectState = {
  lastIndexed: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const connect = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    getLastIndexedSuccess: (state: IConnectState, action: PayloadAction<ILastIndexed>): void => {
      state.lastIndexed.data = action.payload;
      state.lastIndexed.error = undefined;
      state.lastIndexed.loading = false;
    },
    getLastIndexedLoading: (state: IConnectState): void => {
      state.lastIndexed.loading = true;
    },
    getLastIndexedError: (state: IConnectState): void => {
      state.lastIndexed.data = undefined;
      state.lastIndexed.error = true;
      state.lastIndexed.loading = false;
    },
  },
});

export const { getLastIndexedSuccess, getLastIndexedLoading, getLastIndexedError } = connect.actions;

export default connect.reducer;
