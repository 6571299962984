import { getLocale } from 'scripts/util/locale/locale';
import { LocaleLanguage } from 'scripts/util/locale/locale.interfaces';
import { IBaseUrls, IPopulation } from 'scripts/util/population/population.interfaces';
import { SessionLocaleKey } from 'scripts/util/constants/i18n.constants';
import { IResource, IResourceLinkParameters } from './resource.interfaces';

function getBaseUrlWithLanguage(baseUrls: IBaseUrls, language?: string): string {
  switch (language) {
    case LocaleLanguage.Burmese:
      return baseUrls.myUhcLegacyMyBaseUrl;
    case LocaleLanguage.Spanish:
      return baseUrls.myUhcLegacyEsBaseUrl;
    case LocaleLanguage.LusHmoob:
      return baseUrls.myUhcLegacyHmBaseUrl;
    case LocaleLanguage.Ilocano:
    case 'ilo':
      return baseUrls.myUhcLegacyIlBaseUrl;
    case LocaleLanguage.ChineseTraditional:
      return baseUrls.myUhcLegacyZhBaseUrl;
    case LocaleLanguage.Vietnamese:
      return baseUrls.myUhcLegacyViBaseUrl;
    case LocaleLanguage.Karen:
      return baseUrls.myUhcLegacyKcBaseUrl;
    case LocaleLanguage.Korean:
    case 'kr':
      return baseUrls.myUhcLegacyKoBaseUrl;
    case LocaleLanguage.HaitianCreole:
      return baseUrls.myUhcLegacyHtBaseUrl;
    case LocaleLanguage.Soomaali:
      return baseUrls.myUhcLegacySoBaseUrl;
    case LocaleLanguage.Tagalog:
    case 'tag':
      return baseUrls.myUhcLegacyTlBaseUrl;
    default:
      return baseUrls.myUhcLegacyEnBaseUrl;
  }
}

function getResourceParams({ baseUrls, uri, lineOfBusiness }: IPopulation): IResourceLinkParameters {
  const locale = getLocale();
  /*
    myUhcLegacyBaseUrl needs to match the url where the session originates from.
    `SessionLocaleKey` stores the language, which maps to the legacy url
  */
  const sessionLanguage = localStorage.getItem(SessionLocaleKey)?.toLowerCase();
  const myUhcLegacyBaseUrl = getBaseUrlWithLanguage(baseUrls, sessionLanguage);
  return {
    ...baseUrls,
    ...locale,
    myUhcLegacyBaseUrl,
    populationUri: uri,
    lineOfBusiness: lineOfBusiness,
  };
}

export function getResource(resource: IResource, population: IPopulation): string {
  if (typeof resource === 'object') {
    const lineOfBusiness = population && population.lineOfBusiness;
    resource = resource[lineOfBusiness] ? resource[lineOfBusiness] : resource.default;
  }
  if (typeof resource === 'string') {
    return resource;
  }
  if (typeof resource === 'function') {
    const params = getResourceParams(population);
    return resource(params);
  }
  console.error('Invalid resource:', resource);
  return '';
}
