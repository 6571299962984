import { parse } from 'scripts/util/uri/parse';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { CS_ALLOW_MAP_LINKS, MR_ALLOW_MAP_LINKS } from 'scripts/util/population/population.constants';

export function isSeeYouLaterRequired(
  href: string,
  lineOfBusiness: LineOfBusiness,
  csLinksAllowMap: Record<string, boolean> = CS_ALLOW_MAP_LINKS,
  mrLinksAllowMap: Record<string, boolean> = MR_ALLOW_MAP_LINKS,
): boolean {
  const anchor = parse(href);
  const hostname = anchor.hostname.indexOf('www.') === 0 ? anchor.hostname.substr(4) : anchor.hostname;
  const isSameAsCurrentHost = hostname === window.location.hostname;
  const isCurrentStateSeeYouLater = window.location.pathname.indexOf('see-you-later') !== -1;
  const isHttp = anchor.protocol === 'https:' || anchor.protocol === 'http:';
  const isMrHostnameNotInAllowlist = lineOfBusiness === LineOfBusiness.MR && !mrLinksAllowMap[hostname];
  const isCsHostnameNotInAllowlist = lineOfBusiness === LineOfBusiness.CS && !csLinksAllowMap[hostname];

  const isSeeYouLaterRequired =
    !isSameAsCurrentHost &&
    !isCurrentStateSeeYouLater &&
    isHttp &&
    (isMrHostnameNotInAllowlist || isCsHostnameNotInAllowlist);

  return isSeeYouLaterRequired;
}
