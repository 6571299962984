import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IProfile, IProducts } from 'scripts/api/profile/profile.interfaces';
import { IConfig, IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import { ILastIndexed, LastIndexedCoverageType } from 'scripts/api/connect/connect-service.interfaces';
import { ImageKey } from '@rally/arcade-ui-components';
import { EntryPointProps } from '@rally/arcade-ui-components/lib/entry-point';
import { BaseUrls } from '@rally/arcade-ui-components/lib/configuration/url';
import { Profile, Products, Locale } from '@rally/arcade-ui-components/lib/domain/types';
import {
  ISharedEntryPointActionEventPayload,
  ISharedEntryPointTrackingEventPayload,
  ISharedEntryPointAction,
  IBaseTrackingEvent,
  EventType,
} from './shared-entry-point.interfaces';
import { parse } from 'scripts/util/uri/parse';
import { isLinkToArcade, getBaseUrls } from 'scripts/util/uri/uri';
import { TrackingTriggerType, TrackingClickType } from 'scripts/api/tracking/tracking.interfaces';
import {
  formatCamelCasedTrackingString,
  formatTrackingString,
  getPlacement,
} from 'scripts/util/tracking/tracking-helper';
import { ILocale } from 'scripts/util/locale/locale.interfaces';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { useEffect, useCallback } from 'react';
import { GenericEvent, GenericEventPayload } from '@rally/arcade-ui-components/lib/event/adapter';
import { getConfig } from 'scripts/util/config/config';
import { queueEvent as queueEventReact, postEvents as postEventsReact } from 'scripts/util/tracking/tracking.react';
import { getLocale } from 'scripts/util/locale/locale';
import { getGroupNumber } from 'scripts/util/plans/plans';
import { ICoverageSection } from 'scripts/features/modals/id-cards/id-cards.interfaces';
import { splitConfig } from 'scripts/util/config/config-utils';

export interface IBuildEntryPointProps {
  locale: ILocale['id'];
  population: IPopulation;
  profile: IProfile;
  products: IProducts;
  featureFlags: IFeatureFlags;
  config: IConfig;
  version?: string;
  lastIndexed: ILastIndexed;
  getProducts: () => Promise<void>;
  getLastIndexed: () => Promise<void>;
  changeNavOpen: (navOpen: boolean) => undefined;
  changeLocale: (localeId: string) => undefined;
}

export const useBuildEntryPointProps = (state: IBuildEntryPointProps): EntryPointProps => {
  const { history } = useRouter();
  const {
    population,
    profile,
    products,
    // Set default since locale state is null on initialization
    locale = getLocale().id,
    featureFlags: {
      ARCADE_FEATURES_CS_KS_LANG_SUPPORT_ENABLED,
      ARCADE_FEATURES_CS_KYPR_PREMIUM_PAYMENTS,
      ARCADE_FEATURES_CS_NEW_LANGUAGES_SUPPORT_ENABLED,
      ARCADE_FEATURES_CS_SECURE_MESSAGES,
      ARCADE_FEATURES_MR_PRE_EFFECTIVE_RX_TAB,
      ARCADE_FEATURES_RALLY_SUPPRESS_FOOTER,
      ARCADE_FEATURES_SHOW_PLAN_SELECTOR_LINK,
    },
    config: { ARCADE_WEB_LWR_RP_SUFFIX },
    version,
    lastIndexed,
    getLastIndexed,
    getProducts,
    changeNavOpen,
    changeLocale,
  } = state;

  const rpTokenSuffix = ARCADE_WEB_LWR_RP_SUFFIX?.trim() ? ARCADE_WEB_LWR_RP_SUFFIX.trim() : undefined;
  const userGroupNumbers: string[] = profile?.currentUser?.planCoverages.map(planCoverage =>
    getGroupNumber(planCoverage as ICoverageSection, profile?.currentUser),
  );
  const suppressedGroups: string[] = splitConfig(getConfig().ARCADE_WEB_CS_NY_PHARMACY_SUPPRESSION);
  const isCSNYPharmacySuppressionEnable =
    userGroupNumbers && userGroupNumbers.length > 0
      ? userGroupNumbers.some(groupNumber => suppressedGroups.includes(groupNumber))
      : false;

  const featureConfig = {
    isArcade: true,
    isLoggedInUserSelected: true,
    useA11ySkipToMain: true,
    lastUpdatedMedicalDate: getLastUpdatedDate(lastIndexed, LastIndexedCoverageType.Medical),
    lastUpdatedMentalDate: getLastUpdatedDate(lastIndexed, LastIndexedCoverageType.Behavioral),
    rpTokenSuffix,
  };

  const featureFlags = {
    isCsKsLanguagesSuppOn: ARCADE_FEATURES_CS_KS_LANG_SUPPORT_ENABLED,
    isCsKyprPremiumPaymentsOn: ARCADE_FEATURES_CS_KYPR_PREMIUM_PAYMENTS,
    isCSNewLanguagesSuppOn: ARCADE_FEATURES_CS_NEW_LANGUAGES_SUPPORT_ENABLED,
    isCsSecureMessagesOn: ARCADE_FEATURES_CS_SECURE_MESSAGES,
    isMRRallyClaimsOn: true,
    isMedSuppProviderSearchOn: true,
    isMrPreEffectiveRxTabOn: ARCADE_FEATURES_MR_PRE_EFFECTIVE_RX_TAB,
    isRallyDesignSystemOn: true,
    isViewMyPlansOn: ARCADE_FEATURES_SHOW_PLAN_SELECTOR_LINK,
    isBlueDomainUnificationOn: true,
    isBlueDomainUnificationPhaseTwoOn: true,
    isCSNYPharmacySuppressionOn: isCSNYPharmacySuppressionEnable,
    isRallySupressionOn: ARCADE_FEATURES_RALLY_SUPPRESS_FOOTER,
  };

  const imageUrls = {
    [ImageKey.CDP]: '/images/branding/cdp.svg',
    [ImageKey.ERS]: '/images/branding/ers.svg',
    [ImageKey.IconHelp]: '/images/branding/icon-help.svg',
    [ImageKey.IEX]: '/images/branding/iex.svg',
    [ImageKey.MedicaHealthcare]: '/images/branding/medicahealthcare.svg',
    [ImageKey.Medica]: '/images/branding/medica.svg',
    [ImageKey.OptumRx]: '/images/branding/optum-rx.svg',
    [ImageKey.PreferredCare]: '/images/branding/preferredcare.svg',
    [ImageKey.Rally]: '/images/branding/rally.svg',
    [ImageKey.RallyInverse]: '/images/branding/rally-inverse.svg',
    [ImageKey.UHC]: '/images/branding/uhc.svg',
    [ImageKey.UHCAARP]: '/images/branding/uhc-aarp.svg',
    [ImageKey.UHCCommunity]: '/images/branding/uhc-cs.svg',
    [ImageKey.UHCDualComplete]: '/images/branding/uhc-dual-complete.svg',
    [ImageKey.UHCGlobal]: '/images/branding/uhc-global.svg',
    [ImageKey.UHCMobile]: '/images/branding/uhc-small.svg',
    [ImageKey.UHCOxford]: '/images/branding/uhc-oxford.svg',
  };

  const arcadeUrl = getBaseUrls().web + population.uri;
  const logoutUrl = arcadeUrl + '/logout';
  const planSelectorUrl = getConfig().ARCADE_WEB_PLAN_SELECTOR_URL;

  const baseUrls = {
    arcadeUrl: arcadeUrl,
    logoutUrl: logoutUrl,
    planSelectorUrl,
    ...population.baseUrls,
  };

  useEffect(() => {
    if (!products) {
      getProducts();
    }
  }, [products]);

  useEffect(() => {
    if (!lastIndexed) {
      getLastIndexed();
    }
  }, [lastIndexed]);

  const handleEvent = (event: GenericEvent<GenericEventPayload>): void => {
    if (event?.type === EventType.Action && event.payload) {
      handleActionEvent(event.payload as unknown as ISharedEntryPointActionEventPayload);
    }
    if (event?.type === EventType.Tracking && event.payload) {
      handleTrackingEvent(event.payload as ISharedEntryPointTrackingEventPayload, history);
    }
  };

  const handleActionEvent = (payload: ISharedEntryPointActionEventPayload): void => {
    switch (payload.action) {
      case ISharedEntryPointAction.localeChanged:
        changeLocale(payload.props.locale);
        if (payload.props.isNavOpen === false) {
          changeNavOpen(payload.props.isNavOpen);
        }
        break;
      case ISharedEntryPointAction.toggleNav:
        changeNavOpen(payload.props.isNavOpen);
        break;
    }
  };

  const handleTrackingEvent = (payload: ISharedEntryPointTrackingEventPayload, history): void => {
    const isLink = !!payload.href;
    const url = parse(payload.href);
    const isInternalRedirect = isLink && url.pathname.split('/').pop() === 'internal-redirect';
    const isExternalLink =
      isLink && (isInternalRedirect || url.hostname !== window.location.hostname || !isLinkToArcade(url.href));

    const baseTrackingEvent: IBaseTrackingEvent = {
      trigger: TrackingTriggerType.Click,
      uri: window.location.href,
      serviceVersion: version as unknown as string,
      featureList: payload.features.map(feature => formatCamelCasedTrackingString(feature)),
      actionName: formatTrackingString(payload.track),
      placement: getPlacement(payload.event.target as HTMLElement),
    };

    if (isExternalLink) {
      const externalUrl = isInternalRedirect ? parse(decodeURIComponent(url.href.split('deepLink=').pop())) : url;
      const trackingEvent: IBaseTrackingEvent = {
        ...baseTrackingEvent,
        clickType: TrackingClickType.ExternalLink,
        externalDomain: externalUrl.hostname,
        externalUrl: externalUrl.href.split('?')[0],
      };
      queueEventReact(trackingEvent);
      postEventsReact();
    } else {
      const trackingEvent: IBaseTrackingEvent = {
        ...baseTrackingEvent,
        clickType: isLink ? TrackingClickType.StateChange : TrackingClickType.PageEvent,
      };
      queueEventReact(trackingEvent);

      if (isLink) {
        payload.event.preventDefault();
        history.push(`${url.pathname}${url.search}`);
      }
    }
  };

  const memoedHandleEvent = useCallback(e => handleEvent(e), []);

  return {
    baseUrls: baseUrls as BaseUrls,
    imageUrls: imageUrls,
    locale: locale as unknown as Locale,
    profile: profile as unknown as Profile,
    products: (products || {}) as Products,
    handleEvent: memoedHandleEvent,
    featureConfig,
    featureFlags,
  };
};

const getLastUpdatedDate = (
  lastIndexed: ILastIndexed,
  lastIndexedCoverageType: LastIndexedCoverageType,
): string | undefined => {
  if (lastIndexed?.[lastIndexedCoverageType]) {
    const lastIndexedDate = new Date(lastIndexed[lastIndexedCoverageType]);
    // An invalid date will have NaN for any of the number properties.
    return isNaN(lastIndexedDate.getSeconds()) ? undefined : lastIndexedDate.toISOString();
  }
  return undefined;
};
