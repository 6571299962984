import { tacoUris } from 'scripts/util/uri/uri';
import HttpClient from 'scripts/api/http-client';
import { ITacoResponse, ITacoPlacements, ITacoTrackEventPostData } from './taco.interfaces';
import { CacheName } from '../api.interfaces';

function getTargetedContent(placementType: string[]): Promise<ITacoPlacements> {
  const url = tacoUris.targetedContent({ placementType });
  return HttpClient.get<ITacoResponse>(url, { cacheName: CacheName.Taco, localizedCacheKey: true }).then(
    rsp => rsp.data,
  );
}

function postTacoTrackEvent(data: ITacoTrackEventPostData): Promise<void> {
  const url = tacoUris.trackEvent();
  return HttpClient.post(url, data);
}

function postTacoTrackEventV2(data: ITacoTrackEventPostData): Promise<void> {
  const url = tacoUris.trackEventV2();
  return HttpClient.post(url, data);
}

function postTacoTrackEventsV2(data: ITacoTrackEventPostData[]): Promise<void> {
  const url = tacoUris.trackEventsV2();
  return HttpClient.post(url, data);
}

export const TacoApi = {
  getCampaigns: getTargetedContent,
  postTacoTrackEvent,
  postTacoTrackEventV2,
  postTacoTrackEventsV2,
};
