import React, { FunctionComponent, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { GlobalStyle } from 'scripts/styles/global';
import arcadeStore from 'scripts/store/store';
import { selectConfig, selectFeatureFlags, selectTheme } from './selectors/app-selectors';
import { ThemeProvider } from 'styled-components';
import { setTheme } from './thunks/theme-selection-thunks';
import { BrowserRouter } from 'react-router-dom';
import { initializeApi } from './config/api.config';
/**
 * Imported Components
 */
import { Routes } from 'scripts/config/routes';
import { Population } from './features/population/population';
import { init as initChatterbox } from './util/chatterbox/chatterbox';
import { AccessibleOutlines } from './ui/accessible-outlines/accessible-outlines';
import { FeatureFlagDrawer } from './ui/feature-flag-drawer/feature-flag-drawer';
import { useDataDog } from 'scripts/hooks/use-datadog/use-data-dog';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';
import { useBranchIo } from './hooks/use-branch-io/use-branch-io';
import { Heartbeat } from './features/heartbeat/heartbeat';
import { ChromeContainer } from './features/chrome-container/chrome-container';
import { adobeTargetCustomEvents } from 'scripts/util/tracking/adobe-analytics';
import { selectProfileData } from 'scripts/selectors/profile-service-selectors';
import { isIDCardPath } from 'scripts/util/uri/uri';

export const App: FunctionComponent = () => {
  return (
    <Provider store={arcadeStore}>
      <Configuration>
        <Population>
          <Themed>
            <GlobalStyle />
            <AccessibleOutlines />
            <FeatureFlagDrawer />
            <BrowserRouter>
              <HeartbeatCaller>
                <ChromeContainer>
                  <Routes />
                </ChromeContainer>
              </HeartbeatCaller>
            </BrowserRouter>
          </Themed>
        </Population>
      </Configuration>
    </Provider>
  );
};

const Configuration: FunctionComponent = ({ children }) => {
  const config = useSelector(selectConfig);
  const featureFlags = useSelector(selectFeatureFlags);
  const rallyId = useSelector(selectRallyId);
  const profile = useSelector(selectProfileData);
  const currentPath = window.location.pathname;
  useDataDog();
  useBranchIo();

  useEffect(() => {
    initializeApi({ CONFIG: config, FEATURE_FLAGS: featureFlags }, rallyId);
  }, []);

  useEffect(() => {
    // The isIDCardPath check is to make sure we are not recording the
    // id-card page view twice in case of an id-card page refresh
    if (profile && !isIDCardPath(currentPath)) {
      adobeTargetCustomEvents(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (config) {
      initChatterbox(featureFlags, config);
    }
  }, [featureFlags, config]);

  return <>{children}</>;
};

const Themed: FunctionComponent = ({ children }) => {
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!theme) {
      dispatch(setTheme());
    }
  }, [theme]);
  if (!theme) {
    return null;
  }
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const HeartbeatCaller: FunctionComponent = ({ children }) => {
  const restrictHeartbeat =
    location.pathname.includes('/unauthorized-error') ||
    location.pathname.includes('/internal-error') ||
    location.pathname.includes('/login') ||
    location.pathname.includes('/logout');

  if (restrictHeartbeat) {
    return <>{children}</>;
  }
  return <Heartbeat>{children}</Heartbeat>;
};
