import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActivateOnboarding } from 'scripts/api/activate/activate.interfaces';
import { IStateData } from './reducer.interfaces';

export interface IOnboardingState {
  features: IStateData<IActivateOnboarding>;
}

export const initialState: IOnboardingState = {
  features: {
    data: undefined,
    loading: undefined,
    error: undefined,
  },
};

const activateOnboarding = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    onboardingSuccess: (state: IOnboardingState, action: PayloadAction<IActivateOnboarding>): void => {
      state.features.data = action.payload;
      state.features.loading = false;
      state.features.error = false;
    },
    onboardingLoading: (state: IOnboardingState): void => {
      state.features.loading = true;
      state.features.error = false;
    },
    onboardingError: (state: IOnboardingState): void => {
      state.features.data = undefined;
      state.features.loading = false;
      state.features.error = true;
    },
  },
});

export const { onboardingSuccess, onboardingLoading, onboardingError } = activateOnboarding.actions;

export default activateOnboarding.reducer;
