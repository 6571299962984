import styled, { css } from 'styled-components';
import { desktopOnly, mobileOnly, widescreenOnly } from './breakpoints';
import { fromTheme } from 'scripts/styles/themes/themes.utils';

export const wideScreen = css`
  ${widescreenOnly`
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const container = css`
  display: block;
  float: none;
  margin-left: auto;
  margin-right: auto;
  max-width: ${fromTheme('docMaxWidth')}px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  width: auto;

  ${desktopOnly`
    padding-left: ${fromTheme('desktopContainerSpacing')};
    padding-right: ${fromTheme('desktopContainerSpacing')};
  `}
  ${wideScreen}
  ${mobileOnly`
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const checkBox = css`
  .rds-checkmark {
    border-color: ${fromTheme('clrPrimary')};
    &:hover {
      border-color: ${fromTheme('clrPrimaryDark')};
    }
  }
  .rds-checkbox-text {
    color: ${fromTheme('clrPrimary')};
    font-weight: ${fromTheme('fwBold')};
    &:hover {
      color: ${fromTheme('clrPrimaryDark')};
    }
  }
`;

export const containerSupport = css`
  ${desktopOnly`
    padding: ${fromTheme('spacing24')} ${fromTheme('spacing32')} ${fromTheme('spacing40')};
  `}
  ${wideScreen}
  ${mobileOnly`
    padding: ${fromTheme('spacing16')};
    padding-bottom: ${fromTheme('spacing24')};
  `}
`;

export const iconReward = css`
  background: url('images/icons/icon-rewards.svg') no-repeat top left;
  display: inline-block;
  height: ${fromTheme('spacing24')};
  vertical-align: top;
  width: ${fromTheme('spacing24')};
`;

/*
srOnly(Screen Reader Only) will hide the visuals of the element while still allowing screen readers to read its content
*/
export const srOnly = css`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const $SrOnly = styled.span`
  ${srOnly}
`;

export const $SrOnlyNewTab = styled.span`
  &:before {
    content: '\00a0';
  }
  ${srOnly}
`;

export const title = css`
  color: ${fromTheme('clrGreyDark')};
  ${mobileOnly`
    padding-bottom: ${fromTheme('spacing24')};
    text-align: center;
  `}
`;

export const hideDesktop = css`
  ${desktopOnly`
    display: none !important;
  `}
`;

export const hideMobile = css`
  ${mobileOnly`
    display: none !important;
  `}
`;

export const $HideDesktop = styled.div`
  ${hideDesktop}
`;

export const $HideMobile = styled.div`
  ${hideMobile}
`;

export const padding_bottom_8_mobile = css`
  ${mobileOnly`
    padding-bottom: ${fromTheme('spacing8')};
  `}
`;

export const $SectionHeader = styled.header`
  ${desktopOnly`
    float: left;
    padding-bottom: ${fromTheme('spacing24')};
  `}
  ${mobileOnly`
    border-bottom: 1px solid ${fromTheme('clrNeutral80')};
    margin: ${fromTheme('spacing24')} ${fromTheme('spacing16')} 0 ${fromTheme('spacing16')};
    padding-bottom: ${fromTheme('spacing16')};
  `}
`;

/*
Arrow icon styling adjusted for left side of beta-dashboard components (Claims, Accounts, Resources)
*/
export const leftArrowIcon = css`
  .rds-icon.system-arrow-md.rds-icon-16 span {
    padding-top: 4px;
  }
  ${mobileOnly`
    .rds-icon.system-arrow-md.rds-icon-16 span {
      padding-top: 0;
    }
  `}
`;

export const $AsteriskBefore = styled.span`
  &:before {
    content: '*';
  }
`;

export const paddedSection = css`
  padding: ${fromTheme('spacing48')} 0;
  ${mobileOnly`
    padding: 2em 0;
  `}
`;

export const flex_spaceBetween = css`
  display: flex;
  justify-content: space-between;
`;

export const flex_justify_end = css`
  display: flex;
  justify-content: flex-end;
`;

export const font_13_mobile = css`
  ${mobileOnly` font-size: ${fromTheme('fs13')};`}
`;

export const margin_right_4_mobile = css`
  ${mobileOnly`
    margin-right: 4px;
  `}
`;

export const width_100_percent_mobile = css`
  ${mobileOnly` width: 100%;`}
`;

export const display_block_mobile = css`
  ${mobileOnly` display: block;`}
`;

export const margin_bottom_8_mobile = css`
  ${mobileOnly` margin-bottom: ${fromTheme('spacing8')};`}
`;

export const Fs13_FwsemiBold = css`
  font-size: ${fromTheme('fs13')};
  font-weight: ${fromTheme('fwSemiBold')};
`;

export const Fs14_FwsemiBold = css`
  font-size: ${fromTheme('fs14')};
  font-weight: ${fromTheme('fwSemiBold')};
`;

export const Fs14_FwMedium = css`
  font-size: ${fromTheme('fs14')};
  font-weight: ${fromTheme('fwMedium')};
`;

export const Fs16_FwsemiBold = css`
  font-size: ${fromTheme('fs16')};
  font-weight: ${fromTheme('fwSemiBold')};
`;

export const Fs16_FwBold = css`
  font-size: ${fromTheme('fs16')};
  font-weight: ${fromTheme('fwBold')};
`;

export const fs18_FwMedium = css`
  font-size: ${fromTheme('fs18')};
  font-weight: ${fromTheme('fwMedium')};
`;

export const fs18_fwSemiBold_lh24 = css`
  font-size: ${fromTheme('fs18')};
  font-weight: ${fromTheme('fwSemiBold')};
  line-height: ${fromTheme('lh24')};
`;

export const padding_16_8 = css`
  padding: ${fromTheme('spacing16')} ${fromTheme('spacing8')};
`;

export const text_center_mobile = css`
  ${mobileOnly`
    text-align: center;
  `}
`;

export const padding_right_left_16_mobile = css`
  ${mobileOnly`
    padding-left: ${fromTheme('spacing16')};
    padding-right: ${fromTheme('spacing16')};
  `}
`;

export const padding_0_mobile = css`
  ${mobileOnly` padding: 0;`}
`;

export const backDoNotUseIcon = css`
  background: url('images/icons/icon-back-do-not-use.svg') no-repeat top left;
  border: none;
  height: 60px;
  position: absolute;
  width: 60px;
  &:hover:after {
    background-image: url('images/icons/icon-back-hover-do-not-use.svg');
  }
  &:active:after {
    background-image: url('images/icons/icon-back-active-do-not-use.svg');
  }
  ${mobileOnly`
      height: 44px;
      left: 10px;
      width: 44px;
      &:after{
        height: 44px;
        width: 44px;
      }
  `}
`;

export const sectionHeaderMobileOnly = css`
  ${mobileOnly`
    margin-bottom: 0;
    padding: 0 ${fromTheme('spacing24')};
    text-align: center;
  `}
`;

export const textUnderline = css`
  &&& {
    text-decoration: underline;
  }
`;
