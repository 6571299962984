import { createSelector } from '@reduxjs/toolkit';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { ILocale as ILocaleState } from 'scripts/util/locale/locale.interfaces';

export const selectLocale = (state: IReduxState): ILocaleState => state.locale;

export const selectCountry = createSelector(selectLocale, locale => locale?.country);

export const selectLanguage = createSelector(selectLocale, locale => locale?.language);

/**
 * Returns the Locale ID from the current redux state.
 */
export const selectId = createSelector(selectLocale, locale => locale?.id);
