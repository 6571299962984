import { IAxiosRequestConfig, IRequestShortcutConfig } from './api.interfaces';
import { getFhCookie } from 'scripts/util/session/fhCookie';

function getXsrfToken(fhCookieName: string = 'FH'): string {
  const fhCookie = getFhCookie(fhCookieName);
  const xsrfToken = fhCookie?.sessionId || 'INVALID-TOKEN!';
  return xsrfToken;
}

// This is used by requests through both baseApiService and axios, which have different config parameters. Here, we only
// care about the fields withCredentials and headers, which exist on both objects, so give back the same type as was
// passed in.
/**
 * @deprecated  All outbound requests will feature the XSRF Token automatically so long as those requests are done via Axios or
 * the Angular HTTP service.
 * Adds the XSRF Token to a request.
 * @param config the client config
 */
export function ensureXsrfToken(config?: IRequestShortcutConfig): IRequestShortcutConfig;
/**
 * @deprecated  All outbound requests will feature the XSRF Token automatically so long as those requests are done via Axios or
 * the Angular HTTP service.
 * Adds the XSRF Token to a request.
 * @param config the client config
 */
export function ensureXsrfToken(config?: IAxiosRequestConfig): IAxiosRequestConfig;
/**
 * @deprecated  All outbound requests will feature the XSRF Token automatically so long as those requests are done via Axios or
 * the Angular HTTP service.
 * Adds the XSRF Token to a request.
 * @param config the client config
 */
export function ensureXsrfToken(
  config?: IRequestShortcutConfig | IAxiosRequestConfig,
): IRequestShortcutConfig | IAxiosRequestConfig {
  const newConfig = config ? config : {};
  newConfig.withCredentials = true;
  if (!newConfig.headers) {
    newConfig.headers = {};
  }
  if (!newConfig.headers[XSRF_HEADER_NAME]) {
    newConfig.headers[XSRF_HEADER_NAME] = getXsrfToken();
  }
  return newConfig;
}

export const XSRF_HEADER_NAME = 'XSRF-TOKEN';
