import { CoverageType } from 'scripts/api/api.interfaces';
import { createSelector } from '@reduxjs/toolkit';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IPlansState } from 'scripts/reducers/plans-service-reducer';

const selectPlans = (state: IReduxState): IPlansState => state.plans;

export const selectAccumulators = createSelector(selectPlans, plans => plans.accumulators);

export const selectBenefits = createSelector(selectPlans, plans => plans.benefits);

const selectIdCards = createSelector(selectPlans, plans => plans.idCards);

export const selectIdCardsData = createSelector(selectIdCards, idCards => idCards.data);

export const selectIdCardsLoading = createSelector(selectIdCards, idCards => idCards.loading);

export const selectIdCardsError = createSelector(selectIdCards, idCards => idCards.error);

export const selectAccumulatorsData = createSelector(selectAccumulators, accumulators => accumulators.data);

export const selectAccumulatorsLoading = createSelector(selectAccumulators, accumulators => accumulators.loading);

export const selectAccumulatorsError = createSelector(selectAccumulators, accumulators => accumulators.error);

export const selectBenefitsData = createSelector(selectBenefits, benefits => benefits.data);

export const selectBenefitsLoading = createSelector(selectBenefits, benefits => benefits.loading);

export const selectBenefitsError = createSelector(selectBenefits, benefits => benefits.error);

export const selectMedicalBenefit = createSelector(
  selectBenefitsData,
  benefitData => benefitData && benefitData.benefits.find(benefit => benefit.coverageType === CoverageType.Medical),
);

export const selectRenewActive = createSelector(selectBenefitsData, benefitData =>
  benefitData?.benefits?.some(b => b.renewActive),
);

export const selectMedicalPlanName = createSelector(selectMedicalBenefit, medicalBenefit => medicalBenefit?.planName);

export const selectDentalBenefit = createSelector(
  selectBenefitsData,
  benefitData => benefitData && benefitData.benefits.find(benefit => benefit.coverageType === CoverageType.Dental),
);

export const selectDentalPlanName = createSelector(selectDentalBenefit, dentalBenefit => dentalBenefit?.planName);
