import HttpClient from 'scripts/api/http-client';
import { trackingUris, isModalPath } from 'scripts/util/uri/uri';
import {
  ITrackingEventRequest,
  ITrackingEventRequestFactory,
  TrackingServiceType,
  TrackingTriggerType,
  IVideoTrackingEvent,
} from 'scripts/api/tracking/tracking.interfaces';
import { IPageData } from '../../config/pages-data';
import CONFIG from '../constants/config';
import FEATURE_FLAGS from '../constants/feature-flag';
import { stringifyData } from './tracking-helper';
import {
  queueAAVideoEvent,
  sendAAEvent,
  setAAUserFeedbackPath,
  sendTabLoadedEvent as sendAATabLoadedEvent,
} from 'scripts/util/tracking/adobe-analytics';
import { shouldSendCampaignEvent, storeCampaignSent } from './campaign-tracking-helper';
import { ILocale } from '../locale/locale.interfaces';

// Variables to be mutated
let failedPostEventCount = 0;
let queuedEvents: ITrackingEventRequest[] = [];
let postInterval: NodeJS.Timer;

export function initTracking(): void {
  startPostingQueuedEvents();
}

export function queueEvent(
  event: ITrackingEventRequest | ITrackingEventRequestFactory,
  isImpression: boolean = false,
): void {
  // Don't send campaign "impressions" to Adobe Analytics
  if (!isImpression) {
    sendAAEvent(getEvent(event, TrackingServiceType.Adobe));
  }
  const arcadeEvent = getEvent(event, TrackingServiceType.Arcade);
  if (shouldSendCampaignEvent(arcadeEvent)) {
    stringifyData(arcadeEvent);
    updateQueuedEvents(arcadeEvent);
    // reset the failed post event count when a new event is added to the queue
    failedPostEventCount = 0;
    storeCampaignSent(arcadeEvent);
  }
}

export function queuePageLoadEvent(uri: string, featureList: string[], data: any): void {
  const event = {
    trigger: TrackingTriggerType.PageLoad,
    actionName: 'page-load',
    uri,
    serviceVersion: CONFIG.ARCADE_WEB_VERSION,
    featureList,
    placement: 'page-load',
    data,
  };
  stringifyData(event);
  updateQueuedEvents(event);
}

export function postEvents(): Promise<any> {
  if (!queuedEvents.length) {
    return Promise.resolve();
  }
  try {
    const { url, queuedEventsToSend } = eventCompile();

    HttpClient.post(url, queuedEventsToSend, { ignoreLoadingBar: true }).catch(e => {
      updateQueuedEvents(queuedEventsToSend);
      failedPostEventCount++;
      console.error('Sending events to analytics failed. Count: ', failedPostEventCount, e);
    });
    return Promise.resolve();
  } catch (error) {
    console.warn('Error while posting analytics Event: ', error);
  }
}

export function queueVideoEvent(videoEvent: IVideoTrackingEvent): void {
  queueAAVideoEvent(videoEvent);
}

// getEvent returns event as is or gets it from factory if applicable
export function getEvent(
  event: ITrackingEventRequest | ITrackingEventRequestFactory,
  trackingService: TrackingServiceType,
): ITrackingEventRequest {
  if (typeof event === 'function') {
    return event(trackingService);
  }
  return event;
}

function updateQueuedEvents(eventsOrEventToQueue: ITrackingEventRequest[] | ITrackingEventRequest): void {
  if (Array.isArray(eventsOrEventToQueue)) {
    queuedEvents = queuedEvents.concat(eventsOrEventToQueue);
  } else {
    queuedEvents.push(eventsOrEventToQueue);
  }
}

function getQueuedEvents(): ITrackingEventRequest[] {
  return queuedEvents;
}

function resetQueuedEvents(): void {
  queuedEvents = [];
}

function startPostingQueuedEvents(): void {
  if (postInterval) {
    clearInterval(postInterval);
  }

  postInterval = setInterval(postEvents, CONFIG.ARCADE_WEB_TRACKING_INTERVAL_MS);
}

function eventCompile(): { url: string; queuedEventsToSend: ITrackingEventRequest[] } {
  const url = trackingUris.events();
  let queuedEventsToSend = [] as typeof queuedEvents;

  if (failedPostEventCount <= CONFIG.ARCADE_WEB_TRACKING_MAX_RETRY_COUNT) {
    queuedEventsToSend = getQueuedEvents();
    resetQueuedEvents();
  }

  queuedEventsToSend.forEach(event => {
    logEvent(event);
  });

  return { url, queuedEventsToSend };
}

function logEvent(event: ITrackingEventRequest): void {
  // If logging the analytics events out to the console is turned on, display it.
  if (FEATURE_FLAGS.ARCADE_FEATURES_ANALYTICS_DEBUG) {
    console.warn(`Analytics event triggered:
        \turi: ${event.uri}
        \ttrigger: ${event.trigger}
        \tfeatureList: ${event.featureList}
        \taction: ${event.actionName}
        \tfullName: ${[...event.featureList, event.actionName].join('.')}
        \tplacement: ${event.placement}
        \tdata: ${event.data || 'NONE'}`);
  }
}

function getDataForQueuedPageLoadEvent(toState: IPageData, locale: ILocale): Record<string, any> {
  const data: Record<string, any> = {};
  data.locale = locale.id;
  data.pageTitle = toState.title;
  if (window.location.search) {
    (function buildQueryParams() {
      const queryParams: Record<string, string> = {};
      new URLSearchParams(window.location.search).forEach((value, key) => (queryParams[key] = value));
      data.queryParams = queryParams;
    })();
  }
  return data;
}

function trackRouteChange(locale: ILocale, fromState: IPageData | undefined, toState: IPageData | undefined): void {
  if (!fromState || !toState) {
    console.warn('Route change tracking was called with undefined fromState or toState', fromState, toState);
    return;
  }
  if (fromState.path === toState.path) {
    return;
  }

  if (!isModalPath(fromState.path) && !isModalPath(toState.path)) {
    sendAATabLoadedEvent();
  }

  setTimeout(() => {
    setAAUserFeedbackPath();
  });

  if (isModalPath(fromState.path) || isModalPath(toState.path)) {
    setTimeout(() => {
      // This event is consumed by arcade-ui-chrome
      // eslint-disable-next-line max-len
      // https://github.com/uhc-consumer/arcade-ui-chrome/blob/fa836c177ba612d3c6411a7639c1b3887372b693/packages/components/src/header/nav/nav.tsx#L168
      const customEvent = new CustomEvent('arcadeStateChangeEvent');
      document.dispatchEvent(customEvent);
    });
  }
  const featureList = window.location.pathname.split('/').filter(Boolean);
  queuePageLoadEvent(window.location.href, featureList, getDataForQueuedPageLoadEvent(toState, locale));
}

export { trackRouteChange };
