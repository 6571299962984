import { Footer } from '@rally/arcade-ui-components';
import React, { FunctionComponent } from 'react';
import { SharedEntryPoint } from './shared-entry-point';

export const SharedFooter: FunctionComponent = () => {
  return (
    <SharedEntryPoint>
      <Footer />
    </SharedEntryPoint>
  );
};
