import { IConfig, IFeatureFlags, ArcadeEnvironment } from './environment.interfaces';
import CONFIG from './config';
import { constructParams } from '../uri/construct-params';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    CONFIG: IConfig;
    FEATURE_FLAGS: IFeatureFlags;
  }
}

export const featureFlagOverridesStorageKey = 'arcade.featureflag.overrides';
const featureFlagPrefix = 'ARCADE_FEATURES_';

const getFFQueryParams = (params: URLSearchParams): Partial<Record<keyof IFeatureFlags, boolean>> => {
  const ffParams: Partial<Record<keyof IFeatureFlags, boolean>> = {};
  params.forEach((value, key) => {
    if (key.startsWith(featureFlagPrefix) && (value === 'true' || value === 'false')) {
      ffParams[key] = value === 'true';
    }
  });
  return ffParams;
};

const removeFFQueryParams = (params: URLSearchParams): void => {
  const paramsWithoutFF: Record<string, string[]> = {};
  params.forEach((_, key) => {
    if (!key.startsWith(featureFlagPrefix)) {
      paramsWithoutFF[key] = params.getAll(key);
    }
  });
  const queryString = constructParams(paramsWithoutFF);
  const path = window.location.hash ? window.location.hash.substr(1).split('?')[0] : window.location.pathname;
  const newUrl = queryString ? path + queryString : path;
  window.history.replaceState(null, '', newUrl);
};

export const getOverriddenFeatureFlags = (config: IConfig = CONFIG): Partial<Record<keyof IFeatureFlags, boolean>> => {
  // do not use overridden feature flags in production
  if (config.ARCADE_WEB_ENVIRONMENT_NAME === ArcadeEnvironment.Production) {
    return {};
  }
  const params = new URLSearchParams(window.location.search);
  // do not use overridden feature flags look if nocache param is present as they will be removed from session storage
  if (params.has('nocache')) {
    return {};
  }
  try {
    const queryFeatureFlags = getFFQueryParams(params);
    // remove any feature flags query params if they exist
    if (Object.keys(queryFeatureFlags).length > 0) {
      removeFFQueryParams(params);
    }
    const sessionStorageFlags = sessionStorage.getItem(featureFlagOverridesStorageKey) || '{}';
    const overriddenFeatureFlags = {
      ...JSON.parse(sessionStorageFlags),
      ...queryFeatureFlags,
    };
    sessionStorage.setItem(featureFlagOverridesStorageKey, JSON.stringify(overriddenFeatureFlags));
    return overriddenFeatureFlags;
  } catch (err) {
    console.warn('could not determine overridden feature flags', err);
    return {};
  }
};

const overriddenFeatureFlags = getOverriddenFeatureFlags();
const FEATURE_FLAGS = {
  ...window.FEATURE_FLAGS,
  ...overriddenFeatureFlags,
};

export default FEATURE_FLAGS;
