import { IResponse } from '../api.interfaces';

export enum ActivateStepStatus {
  completed = 'COMPLETED',
  skipped = 'SKIPPED',
  supressed = 'SUPPRESSED',
  unstarted = 'UNSTARTED',
}

export enum ActivateGlobalStatus {
  completed = 'COMPLETED',
  started = 'STARTED',
}

export enum ActivatePlanKey {
  'AZDP' = 'AZDP',
  'CADPN' = 'CADPN',
  'CADPS' = 'CADPS',
  'CDP' = 'CDP',
  'COB' = 'COB',
  'COPD' = 'COPD',
  'DP' = 'DP',
  'EI' = 'E&I',
  'EI_EMPIRE' = 'E&I_EMPIRE',
  'EMAIL_BOUNCE' = 'BOUNCE',
  'GATED' = 'GATED_E&I',
  'NON-GATED-PCP' = 'NON-GATED_PCP_E&I',
  'MR' = 'M&R_FEDERAL',
  'MRMA' = 'MR_MA',
  'MRMAPAY' = 'MR_MA_PAY',
  'MRFUTMA' = 'MR_FUTURE_MA',
  'MRFUTMAPAY' = 'MR_FUTURE_MA_PAY',
  'MRMAPD' = 'MR_MAPD',
  'MRMAPDPAY' = 'MR_MAPD_PAY',
  'MRFUTMAPD' = 'MR_FUTURE_MAPD',
  'MRFUTMAPDPAY' = 'MR_FUTURE_MAPD_PAY',
  'SSN' = 'CONFIRM_SSN',
  'SVHP' = 'SVHP',
}

export interface IActivateOnboardingResponse extends IResponse<IActivateOnboarding> {}
export interface IActivateStepsResponseV7 extends IResponse<IActivatePlans> {}
export interface IActivateStepsResponseV9 extends IResponse<IActivatePlans> {}

export const activateBaseRoute = '/activate';

export enum StepRoute {
  Undefined = 'undefined',
  CardActivation = '/cards',
  Goals = '/goals',
  Overview = '/overview',
  Paperless = '/paperless',
  EmailBounce = '/email-bounce',
  Cob = '/cob',
  ConfirmSsn = '/confirm-ssn',
  PersonalizedEmail = '/personalizedemail',
  TextPreferences = '/smsconsent',
  UseYourPlan = '/useyourplan',
  Welcome = '/welcome',
  SAMBanner = '/sam-banner',
}

export interface IActivateOnboarding {
  features: OnboardingSources[];
}

export enum OnboardingSources {
  BenefitsCard = 'BENEFITSCARD',
  PersonalizationSurvey = 'PERSONALIZATIONSURVEY',
  PlanOverview = 'PLANOVERVIEW',
  PlanOverviewWithVideo = 'PLANOVERVIEW-WITH-VIDEO',
  Paperless = 'PAPERLESS',
  EmailBounce = 'EMAIL-BOUNCE',
  Cob = 'COB',
  ConfirmSsn = 'CONFIRM-SSN',
  PersonalizedEmail = 'PERSONALIZED-EMAIL',
  TextPreferences = 'TEXT-PREFERENCES',
  UseYourPlan = 'USE-YOUR-PLAN',
  Unknown = 'UNKNOWN',
  Welcome = 'WELCOME',
  SAMBanner = 'SAM-BANNER',
}

export interface IActivatePlans {
  plans?: IActivatePlanV7[];
  flows?: IActivatePlanV9[];
}

export interface IActivatePlanV7 {
  planKey: ActivatePlanKey;
  status: ActivateGlobalStatus;
  steps: IActivatePlanStep[];
  planVersion: number;
  autoPrompt: boolean;
  annualReprompt: boolean;
}

export interface IActivatePlanV9 {
  status: ActivateGlobalStatus;
  steps: IActivatePlanStepV9[];
  flowType: ActivatePlanKey;
  flowVersion: number;
  autoPrompt: boolean;
  annualReprompt: boolean;
}

export enum UserFlowData {
  Reminder = 'GP',
  Registration = 'NPS',
}

export interface IActivatePlanStep {
  activateUiRoute?: string;
  sequence: number;
  stepId: string;
  stepName: StepName;
  stepState: ActivateStepStatus;
  bannerPriority?: number;
  userFlowTrackingData?: UserFlowData[];
}

export interface IActivatePlanStepV9 {
  sequence: number;
  stepId: string;
  stepName: StepName;
  stepState: ActivateStepStatus;
  bannerPriority?: number;
  userFlowTrackingData?: UserFlowData[];
  activateUiRoute?: string;
  subSteps: IActivatePlanSubStep[];
  dashboardRoute?: string;
}

export interface IActivatePlanSubStep {
  subStepId: string;
  subStepName: string;
  subStepState: ActivateStepStatus;
  subStepSequence: number;
  dashboardRoute?: string;
}

export interface ICommunicationPreferencesV2Individual {
  depSequenceNum?: string;
  hasAgreedToPaperlessCommunication?: boolean;
  hasAgreedToMediumSensitivityEmailContent?: boolean;
  noTaxIdNumber?: string;
  notifyOptions?: string[];
  taxIdLastFourDigits?: number;
  telephone?: number[];
  paperlessEmailAddress?: string;
  hasOptedIntoSMSPrograms?: boolean;
}

export interface ICommunicationPreferencesV2 {
  data: ICommunicationPreferencesV2Individual[];
}

export enum StepName {
  basicCommunicationPreference = 'basic_communication_preference',
  basicCompletion = 'basic_completion',
  basicEmailConfirmation = 'basic_email_confirmation',
  basicIntroduction = 'basic_introduction',
  basicMiniSurvey = 'basic_mini-survey',
  basicPhoneInfo = 'basic_phone_info',
  basicTaskCollection = 'basic_task_collection',
  basicUcard = 'basic_ucard',
  emailBounce = 'email_bounce',
  cob = 'coordination_of_benefits',
  confirmSsn = '1095_ssn_update',
  healthTopics = 'basic_health_topics',
  onboardingVideo = 'onboarding_video',
  paperless = 'basic_communication_preference',
}

export enum SubStepName {
  basicCommunicationPreference = 'basicCommunicationPreference',
  basicUcard = 'basicUcard',
  basicUcardPreEffective = 'basicUcardPreEffective',
  uCardEligible = 'uCardEligible',
  paperlessEnrollment = 'paperlessEnrollment',
}

export enum TrackingEventName {
  formSubmitEvent = 'FormSubmitEvent',
  formErrorEvent = 'FormErrorEvent',
  pageNavEvent = 'PageNavEvent',
  externamPageNavEvent = 'ExternalPageNavEvent',
}

export interface IPostStepV9PayloadSubSteps {
  subStepId: string;
  subStepStatus: ActivateStepStatus;
}

export interface IPostStepV9Payload {
  stepId?: string;
  isSkipped?: boolean;
  stepState?: ActivateStepStatus;
  subSteps?: IPostStepV9PayloadSubSteps[];
  flowType: string;
  flowVersion: number;
}
