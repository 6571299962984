import { IHeartbeat } from 'scripts/api/user/user.interfaces';
import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserState {
  heartbeat: IStateData<IHeartbeat>;
  info: IStateData<IHeartbeat>;
  rallyPayUserToken: IStateData<string>;
  planToken?: string;
}

export const initialState: IUserState = {
  heartbeat: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  info: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  rallyPayUserToken: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  planToken: undefined,
};

type HeartbeatInfoReducers = {
  getInfoSuccess: (state: IUserState, action: PayloadAction<IHeartbeat>) => void;
  getInfoLoading: (state: IUserState) => void;
  getInfoError: (state: IUserState) => void;
  getHeartbeatSuccess: (state: IUserState, action: PayloadAction<IHeartbeat>) => void;
  getHeartbeatError: (state: IUserState) => void;
  getHeartbeatLoading: (state: IUserState) => void;
};

/**
 * returns heartbeat/info state reducers.  Heartbeat state has a one-way link to Info state such
 * that updating the heartbeat state will trigger the same update to the info state, but the opposite is
 * not true.
 */
function heartbeatInfoStateReducers(): HeartbeatInfoReducers {
  function getInfoSuccess(state: IUserState, action: PayloadAction<IHeartbeat>): void {
    state.info.data = action.payload;
    state.info.error = false;
    state.info.loading = false;
  }
  function getInfoLoading(state: IUserState): void {
    state.info.loading = true;
  }
  function getInfoError(state: IUserState): void {
    state.info.data = undefined;
    state.info.error = true;
    state.info.loading = false;
  }

  function getHeartbeatSuccess(state: IUserState, action: PayloadAction<IHeartbeat>): void {
    state.heartbeat.data = action.payload;
    state.heartbeat.error = false;
    state.heartbeat.loading = false;
    getInfoSuccess(state, action);
  }

  function getHeartbeatLoading(state: IUserState): void {
    state.heartbeat.loading = true;
    getInfoLoading(state);
  }

  function getHeartbeatError(state: IUserState): void {
    state.heartbeat.data = undefined;
    state.heartbeat.error = true;
    state.heartbeat.loading = false;
    getInfoError(state);
  }
  return {
    getInfoSuccess,
    getInfoLoading,
    getInfoError,
    getHeartbeatSuccess,
    getHeartbeatError,
    getHeartbeatLoading,
  };
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    ...heartbeatInfoStateReducers(),
    getRallyPayUserTokenSuccess: (state: IUserState, action: PayloadAction<{ token: string }>): void => {
      state.rallyPayUserToken.data = action.payload.token;
      state.rallyPayUserToken.error = false;
      state.rallyPayUserToken.loading = false;
    },
    getRallyPayUserTokenLoading: (state: IUserState): void => {
      state.rallyPayUserToken.error = false;
      state.rallyPayUserToken.loading = true;
    },
    getRallyPayUserTokenError: (state: IUserState): void => {
      state.rallyPayUserToken.data = undefined;
      state.rallyPayUserToken.error = true;
      state.rallyPayUserToken.loading = false;
    },
    setPlanToken: (state: IUserState, action: PayloadAction<string>): void => {
      state.planToken = action.payload;
    },
  },
});

export const {
  getHeartbeatSuccess,
  getHeartbeatLoading,
  getHeartbeatError,
  getInfoSuccess,
  getInfoLoading,
  getInfoError,
  getRallyPayUserTokenError,
  getRallyPayUserTokenLoading,
  getRallyPayUserTokenSuccess,
  setPlanToken,
} = user.actions;

export default user.reducer;
