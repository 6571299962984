import { useEffect, useState } from 'react';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { selectId } from 'scripts/selectors/locale-selectors';
import { CampaignPlacementType, ITacoRecommendation, ITacos } from 'scripts/api/targeting/targeting.interfaces';
import { getTacoRecommendations } from 'scripts/thunks/targeting-service-thunks';
import {
  selectTacoRecommendationsData,
  selectTacoRecommendationsError,
  selectTacoRecommendationsLoading,
} from 'scripts/selectors/targeting-service-selectors';

export interface IUseTacoState {
  tacos: ITacoRecommendation[];
  loading: boolean;
  failed: boolean;
}

/**
 * A hook for consuming content from Taco (Targeted Content).
 * Tacos can be campaigns (from Campaigns Service), Recommmendations (from Pythia), or Realtime Offers (from Member Actions).
 * Data from Taco can be mocked with Doppelganger selecting Rally as Partner and the Campaigns :: Get Campaigns :: Multiple endpoint.
 * @param placements An array of placements to request from Taco.
 * @param isTacoDirectPlacementOn We are implementing a new usetacoDirect hook for beta-dashboard. This FF is used to switch from
 *                                useTaco and useTacoDirect hooks
 */
export function useTaco(placements: CampaignPlacementType[], isTacoDirectPlacementOn: boolean = false): IUseTacoState {
  const dispatch = useDispatch();
  const [requestedTacos, setRequestedTacos] = useState([] as ITacoRecommendation[]);
  const locale = useSelector<IReduxState, string>(selectId);
  const allTacos = useSelector<IReduxState, ITacos>(state => selectTacoRecommendationsData(state));
  const tacosLoading = useSelector<IReduxState, boolean>(state => selectTacoRecommendationsLoading(state));
  const tacosFailed = useSelector<IReduxState, boolean>(state => selectTacoRecommendationsError(state));

  const setTacosForPlacements = (): void => {
    const tacosForPlacement = placements.reduce((tacosAcc, nextPlacement) => {
      const placementTacos = allTacos[nextPlacement];
      return placementTacos?.length ? [...tacosAcc, ...placementTacos] : tacosAcc;
    }, []);

    if (tacosForPlacement.length) {
      setRequestedTacos(tacosForPlacement);
    }
  };

  useEffect(() => {
    if (placements.length && !tacosFailed) {
      !isTacoDirectPlacementOn && dispatch(getTacoRecommendations(placements));
    }
  }, [locale]);

  useEffect(() => {
    if (allTacos) {
      setTacosForPlacements();
    }
  }, [allTacos]);

  const useTacoState: IUseTacoState = {
    tacos: requestedTacos,
    loading: tacosLoading,
    failed: tacosFailed,
  };

  return useTacoState;
}
