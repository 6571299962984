import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { mobileOnly } from 'scripts/styles/breakpoints';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { $SrOnly } from 'scripts/styles/utilities';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import styled, { keyframes } from 'styled-components';

export function LoadingDots(): ReactElement {
  const { t } = useTranslation(Dictionary.COMMON);
  return (
    <$LoadingDots data-testid="loading-dots">
      <$LoadingDot />
      <$LoadingDot />
      <$LoadingDot />
      <$SrOnly>{t('LOADING')}</$SrOnly>
    </$LoadingDots>
  );
}

const $LoadingDots = styled.div.attrs({
  className: 'loading-dots',
})`
  color: ${fromTheme('clrLoading')};
  display: flex;
  margin: 20px 0;

  ${mobileOnly`
    justify-content: center;
  `}
`;

const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  20% {
    background-color: currentColor;
    opacity: 1;
  }
  40% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
`;

const $LoadingDot = styled.span`
  animation: ${pulse} 2.1s infinite;
  animation-fill-mode: both;
  background-color: ${fromTheme('clrNeutral80')};
  border-radius: 50%;
  &&& {
    font-size: 0;
  }
  height: 16px;
  width: 16px;

  &:first-child {
    animation-delay: 0s;
  }

  &:first-child + & {
    animation-delay: 0.7s;
    margin-left: 10px;
  }

  &:first-child + & + & {
    animation-delay: 1.4s;
    margin-left: 10px;
  }
`;
