import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Created in an effort to side effects more React-y and to solve closure issues
 * https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/
 * @param callback Function with side effects to run
 * @param delay Number in milliseconds defining the amount of time until the callback executes
 * @returns A reference to the timeout itself to allow for programmatic clearing of the timeout
 */
export const useTimeout = (callback: () => void, delay: (() => number) | number | null = 0): MutableRefObject<any> => {
  const timeoutRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = (): void => savedCallback.current();

    if (typeof delay === 'function') {
      timeoutRef.current = setTimeout(tick, delay());
    } else if (delay !== null) {
      timeoutRef.current = setTimeout(tick, delay);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [delay]);

  return timeoutRef;
};
