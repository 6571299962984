import {
  PcpMemberState,
  IProfileUser,
  IPlanFeatures,
  IPlanInformation,
  IPhysician,
} from 'scripts/api/profile/profile.interfaces';
import { IBenefit } from 'scripts/api/plans/plans.interfaces';

export interface IChangePcdInfo {
  rallyId?: IProfileUser['rallyId'];
  dependentSeqNum?: IProfileUser['dependentSeqNum'];
  planName?: IBenefit['planName'];
  planId?: IPlanFeatures['planId'];
  planNetworkId?: IPlanInformation['networkId'];
  providerId?: IPhysician['providerId'];
}

export interface ISelectedUserPrimaryCareProviderInfo {
  activeDocId?: string;
  activeDocName?: string;
  hasFuturePcp?: boolean;
  phoneNumber?: string;
  lockedIn?: boolean;
  memberState?: PcpMemberState;
  npi?: string;
}

export interface ISelectedUserPrimaryCareDentistInfo {
  addressSequenceNumber?: string;
  activeDocId?: string;
  activeDocName?: string;
  providerId?: string;
}

export enum PrimaryCareStatusText {
  Assign = 'ASSIGN',
  AssignmentInProgress = 'ASSIGNMENT_IN_PROGRESS',
  Change = 'CHANGE',
  ChangeInProgress = 'CHANGE_IN_PROGRESS',
  DentistAssignmentInProgress = 'DENTIST_ASSIGNMENT_IN_PROGRESS',
}

export interface IPrimaryCareProviderInfo {
  showPrimaryCareProviderTile: boolean;
  showPrimaryCareDentistTile: boolean;
  showVirtualVisitsTile: boolean;
  showFindProvidersTile: boolean;
  selectedUserPrimaryCareProviderInfo: ISelectedUserPrimaryCareProviderInfo;
  selectedUserPrimaryCareDentistInfo: ISelectedUserPrimaryCareDentistInfo;
  primaryCareProviderStatusText: PrimaryCareStatusText;
  primaryCareDentistStatusText: PrimaryCareStatusText;
}
