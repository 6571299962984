import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICardBalancesV2,
  IEnrollmentStatus,
  IRewardsEligibilty,
  IGphubFullfilmentDetails,
} from 'scripts/api/rewards/rewards.interfaces';
import { IStateData } from 'scripts/reducers/reducer.interfaces';

export interface IRewardsState {
  enrollmentStatus: IStateData<IEnrollmentStatus>;
  cardBalances: IStateData<ICardBalancesV2>;
  rewardsEligibility: IStateData<IRewardsEligibilty>;
  fHubFulfillment: IStateData<IGphubFullfilmentDetails>;
}

/**
 * Returns a new {@see IStateData} instance marked as errored.
 * @param data Optional parameter.  Most cases will clear the current state data on error.
 */
export function ERRORED_STATE<TData>(data: TData | undefined = undefined): IStateData<TData> {
  return {
    data: data,
    error: true,
    loading: false,
  };
}

/**
 * Returns a new {@see IStateData} instance with the loading and error flags set off.
 * @param data The data to set in the state.
 */
export function SUCCESS_STATE<TData>(data: TData): IStateData<TData> {
  return {
    data,
    error: false,
    loading: false,
  };
}

export const initialState: IRewardsState = {
  enrollmentStatus: {
    data: undefined,
    error: false,
    loading: false,
  },
  cardBalances: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  rewardsEligibility: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  fHubFulfillment: {
    data: undefined,
    error: undefined,
    loading: undefined,
  },
};

const rewards = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    getEnrollmentStatusSuccess: (state: IRewardsState, action: PayloadAction<IEnrollmentStatus>): void => {
      state.enrollmentStatus.data = action.payload;
      state.enrollmentStatus.error = false;
      state.enrollmentStatus.loading = false;
    },
    getEnrollmentStatusLoading: (state: IRewardsState): void => {
      state.enrollmentStatus.loading = true;
    },
    getEnrollmentStatusError: (state: IRewardsState): void => {
      state.enrollmentStatus = ERRORED_STATE();
    },
    getCardBalanceSuccess: (state: IRewardsState, action: PayloadAction<ICardBalancesV2>): void => {
      state.cardBalances.data = action.payload;
      state.cardBalances.error = false;
      state.cardBalances.loading = false;
    },
    getCardBalanceLoading: (state: IRewardsState): void => {
      state.cardBalances.loading = true;
    },
    getCardBalanceError: (state: IRewardsState): void => {
      state.cardBalances = ERRORED_STATE();
    },
    postRewardsEligibilitySuccess: (state: IRewardsState, action: PayloadAction<IRewardsEligibilty>): void => {
      state.rewardsEligibility.data = action.payload;
      state.rewardsEligibility.error = false;
      state.rewardsEligibility.loading = false;
    },
    postRewardsEligibilityLoading: (state: IRewardsState): void => {
      state.rewardsEligibility.loading = true;
    },
    postRewardsEligibilityError: (state: IRewardsState): void => {
      state.rewardsEligibility = ERRORED_STATE();
    },
    getFhubFulfillmentSuccess: (state: IRewardsState, action: PayloadAction<any>): void => {
      state.fHubFulfillment.data = action.payload;
      state.fHubFulfillment.error = false;
      state.fHubFulfillment.loading = false;
    },
    getFhubFulfillmentLoading: (state: IRewardsState): void => {
      state.fHubFulfillment.loading = true;
    },
    getFhubFulfillmentError: (state: IRewardsState): void => {
      state.fHubFulfillment = ERRORED_STATE();
      state.fHubFulfillment.loading = false;
    },
  },
});

export const {
  getEnrollmentStatusSuccess,
  getEnrollmentStatusLoading,
  getEnrollmentStatusError,
  getCardBalanceSuccess,
  getCardBalanceLoading,
  getCardBalanceError,
  postRewardsEligibilitySuccess,
  postRewardsEligibilityLoading,
  postRewardsEligibilityError,
  getFhubFulfillmentSuccess,
  getFhubFulfillmentLoading,
  getFhubFulfillmentError,
} = rewards.actions;

export default rewards.reducer;
