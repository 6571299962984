import 'scripts/polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'scripts/i18n';
import { App } from './app';

/* process.env is coming from EnvironmentPlugin (which is basically just DefinePlugin)
  It is evaluated during build time of a bundle.
  https://webpack.js.org/plugins/environment-plugin/
*/
require('@rally/creation-matrix/dist/css/uhc.css');

const div = document.createElement('div');
div.setAttribute('id', 'arcade-ui-app');
if (!document.body) {
  throw new Error('Document has no body');
}
document.body.appendChild(div);
document.body.classList.add('rds-on');
const root = createRoot(div);
document.body.classList.remove('advantage');
root.render(<App />);
