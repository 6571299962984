import { createSelector } from '@reduxjs/toolkit';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { ITargetingState } from 'scripts/reducers/targeting-service-reducer';

const selectTargeting = (state: IReduxState): ITargetingState => state.targeting;

const selectCampaigns = createSelector(selectTargeting, targeting => targeting.campaigns);

export const selectCampaignsData = createSelector(selectCampaigns, campaigns => campaigns.data);

export const selectCampaignsLoading = createSelector(selectCampaigns, campaigns => campaigns.loading);

export const selectCampaignsError = createSelector(selectCampaigns, campaigns => campaigns.error);

export const selectClientConfig = createSelector(selectTargeting, targeting => targeting.clientConfig);

export const selectClientConfigData = createSelector(selectClientConfig, clientConfig => clientConfig.data);

export const selectClientConfigLoading = createSelector(selectClientConfig, clientConfig => clientConfig.loading);

export const selectClientConfigError = createSelector(selectClientConfig, clientConfig => clientConfig.error);

const selectCsFaqCustomizations = createSelector(selectTargeting, targeting => targeting.csFaqCustomizations);

export const selectCsFaqCustomizationsData = createSelector(
  selectCsFaqCustomizations,
  csFaqCustomizations => csFaqCustomizations.data,
);

export const selectCsFaqCustomizationsLoading = createSelector(
  selectCsFaqCustomizations,
  csFaqCustomizations => csFaqCustomizations.loading,
);

export const selectCsFaqCustomizationsError = createSelector(
  selectCsFaqCustomizations,
  csFaqCustomizations => csFaqCustomizations.error,
);

const selectRealTimeOfferCount = createSelector(selectTargeting, targeting => targeting.realTimeOfferCount);

export const selectRealTimeOfferCountData = createSelector(
  selectRealTimeOfferCount,
  realTimeOfferCount => realTimeOfferCount.data,
);

export const selectRealTimeOfferCountLoading = createSelector(
  selectRealTimeOfferCount,
  realTimeOfferCount => realTimeOfferCount.loading,
);

export const selectRealTimeOfferCountError = createSelector(
  selectRealTimeOfferCount,
  realTimeOfferCount => realTimeOfferCount.error,
);

export const selectRecommendations = createSelector(selectTargeting, targeting => targeting.recommendations);

export const selectRealTimeOffers = createSelector(selectRecommendations, recommendations => recommendations.data);

export const selectActiveRecommendations = createSelector(
  selectRealTimeOffers,
  realTimeOffers => realTimeOffers?.filter(r => !r.dismissed) || [],
);

export const selectDismissedRecommendations = createSelector(
  selectRealTimeOffers,
  realTimeOffers => realTimeOffers?.filter(r => r.dismissed) || [],
);

const selectTacoRecommendations = createSelector(selectTargeting, targeting => targeting.tacoRecommendations);

export const selectTacoRecommendationsData = createSelector(
  selectTacoRecommendations,
  tacoRecommendations => tacoRecommendations.data,
);

export const selectTacoRecommendationsLoading = createSelector(
  selectTacoRecommendations,
  tacoRecommendations => tacoRecommendations.loading,
);

export const selectTacoRecommendationsError = createSelector(
  selectTacoRecommendations,
  tacoRecommendations => tacoRecommendations.error,
);

const selectAlerts = createSelector(selectTargeting, targeting => targeting.alerts);

export const selectAlertsError = createSelector(selectAlerts, clientConfig => clientConfig.error);
