import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { $Section } from 'scripts/styles/primitives/section';
import { container } from 'scripts/styles/utilities';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { Feature } from 'scripts/ui/feature/feature';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { mobileOnly } from 'scripts/styles/breakpoints';
import { trackFeatures } from 'scripts/util/constants/track-features';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ResourceAnchorWithClickTracking } from 'scripts/ui/anchor/resource-anchor';
import { getResource } from 'scripts/util/resource/resource';
import { viewMyPlans } from 'scripts/util/resource/resource.constants';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { selectPlanCoverages, selectSelectedProfile } from 'scripts/selectors/profile-service-selectors';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import CircleAlertIcon from 'images/icons/icon-circle-alert.svg';
import { selectConfig } from 'scripts/selectors/app-selectors';
import { splitConfig } from 'scripts/util/config/config-utils';
import { getGroupNumber } from 'scripts/util/plans/plans';
import { ICoverageSection } from 'scripts/features/modals/id-cards/id-cards.interfaces';
import { getConfig } from 'scripts/util/config/config';

export const CsIdCardBanner: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.COMMON);
  const population = useSelector(selectPopulation);
  const currentProfile = useSelector(selectSelectedProfile);
  const coverages = useSelector(selectPlanCoverages);
  const config = useSelector(selectConfig);
  const mnPlanIds = splitConfig(config.ARCADE_WEB_CS_MN_PLAN_IDS);
  const showCsIdCardBanner =
    currentProfile.lineOfBusiness === LineOfBusiness.CS &&
    (coverages.some(c => mnPlanIds.includes(c.planFeatures?.planId)) ||
      mnPlanIds.includes(currentProfile.pvrc?.reportingCode)); // ARC-13813: PVRC corresponds to planID
  const userGroupNumbers = currentProfile.planCoverages.map(planCoverage =>
    getGroupNumber(planCoverage as ICoverageSection, currentProfile),
  );
  const suppressedGroups: string[] = getConfig().ARCADE_WEB_GROUP_IDS_SUPPRESS.split(',');
  const hideBanner =
    userGroupNumbers && userGroupNumbers.length > 0
      ? userGroupNumbers.some(userGroupNumber => suppressedGroups.includes(userGroupNumber))
      : false;
  if (!showCsIdCardBanner || hideBanner) {
    return null;
  }

  return (
    <>
      <Feature featureId={trackFeatures.arcadeDashboardBanner}>
        <$Section
          as="section"
          role="region"
          aria-labelledby="cs-id-card-banner-header"
          aria-describedby="cs-id-card-banner-description"
        >
          <$CsIdCardBanner data-testid="cs-id-card-banner">
            <$BannerContainer>
              <$BannerLeft>
                <$BannerLogo>
                  <$CircleAlertIcon />
                </$BannerLogo>
                <$BannerText>
                  <$BannerHeadline id="cs-id-card-banner-header">{t('CS_ID_CARD_BANNER_HEADLINE')}</$BannerHeadline>
                  <$BannerDescription id="cs-id-card-banner-description">
                    {t('CS_ID_CARD_BANNER_BODY')}
                  </$BannerDescription>
                </$BannerText>
              </$BannerLeft>
              <$BannerRight>
                <$BannerLink>
                  <ResourceAnchorWithClickTracking
                    resourceValue={getResource(viewMyPlans, population)}
                    data-testid="cs-id-card-banner-view-plans"
                    dataTrackId="cs-id-card-banner-view-plans"
                    dataUiElementName="cs-id-card-banner-view-plans"
                    className="rds-primary-button"
                    noNewWindowIcon
                  >
                    {t('VIEW_MY_PLANS')}
                  </ResourceAnchorWithClickTracking>
                </$BannerLink>
              </$BannerRight>
            </$BannerContainer>
          </$CsIdCardBanner>
        </$Section>
      </Feature>
    </>
  );
};

const $CsIdCardBanner = styled.div`
  background-color: ${fromTheme('clrBannerBlue')};
  border-bottom: 6px solid ${fromTheme('clrNeutral80')};
  padding: ${fromTheme('spacing16')} 0;
  ${mobileOnly`
    min-height: 90px;
  `}
`;

const $BannerContainer = styled.div`
  ${container}
  display: flex;
  flex-direction: row;
  ${mobileOnly`
    align-items: center;
    flex-direction: column;
  `}
`;

const $BannerLeft = styled.div`
  display: flex;
  flex-direction: row;
  ${mobileOnly`
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    text-align: center;
  `}
`;
const $BannerLogo = styled.div`
  box-sizing: content-box;
  ${mobileOnly`
    height: ${fromTheme('spacing48')};
    padding-bottom: ${fromTheme('spacing8')};
  `}
`;

const $CircleAlertIcon = styled(CircleAlertIcon)`
  height: ${fromTheme('spacing48')};
  width: ${fromTheme('spacing48')};
`;

const $BannerHeadline = styled.p`
  font-size: 18px;
  font-weight: ${fromTheme('fwBold')};
  &&&& {
    margin-bottom: 0px;
    ${mobileOnly`
      font-size: ${fromTheme('fs16')};
      line-height: ${fromTheme('lh20')};
      margin-bottom: 4px;
    `}
  }
`;

const $BannerDescription = styled.p`
  font-size: 18px;
  ${mobileOnly`
    font-size: ${fromTheme('fs16')};
    line-height: ${fromTheme('lh20')};
  `}
`;

const $BannerText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${fromTheme('fs16')};
  font-weight: ${fromTheme('fwMedium')};
  justify-content: center;
  margin-left: ${fromTheme('spacing16')};
  width: 100%;
  ${mobileOnly`
    margin: 0 ${fromTheme('spacing16')};
    width: calc(100% - 40px);
  `}
`;

const $BannerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: ${fromTheme('spacing24')};
  ${mobileOnly`
    margin: 20px 0 20px;
  `}
`;

const $BannerLink = styled.div`
  a {
    font-size: ${fromTheme('fs14')};
    margin-left: 40px;
    text-align: center;
    text-transform: capitalize;
    .icon-external-link {
      fill: ${fromTheme('clrWhite')};
    }
    ${mobileOnly`
      margin-left: 0;
    `}
  }
`;
