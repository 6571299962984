import { useSelector } from 'react-redux';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { selectConfig } from 'scripts/selectors/app-selectors';
import { IConfig } from 'scripts/util/constants/environment.interfaces';
import { createMapFromAllowlist } from 'scripts/util/population/population.constants';
import { isSeeYouLaterRequired } from 'scripts/util/anchor/anchor';

/*
close over these values in order to prevent the need to recreate the hash every time the hook is invoked.
 */
let csLinksAllowMap: Record<string, boolean>;
let mrLinksAllowMap: Record<string, boolean>;

function getCsLinksAllowMap(config: IConfig): Record<string, boolean> {
  if (!csLinksAllowMap) {
    csLinksAllowMap = createMapFromAllowlist(config.ARCADE_WEB_CS_ALLOWLIST_LINKS);
  }
  return csLinksAllowMap;
}

function getMrLinksAllowMap(config: IConfig): Record<string, boolean> {
  if (!mrLinksAllowMap) {
    mrLinksAllowMap = createMapFromAllowlist(config.ARCADE_WEB_MR_ALLOWLIST_LINKS);
  }
  return mrLinksAllowMap;
}

export interface IUseSeeYouLaterState {
  seeYouLaterRequired: boolean;
  seeYouLaterUri: string;
  seeYouLaterDestinationUri: string;
}
/**
 * Checks if a link href value requires that a see-you-later intermediary page is required; returns an object with
 * a flag that indicates whether see-you-later is required and the URI for the see-you-later page.
 * @param href The link href to check.
 */
export const useSeeYouLater = (href): IUseSeeYouLaterState => {
  const population = useSelector(selectPopulation);
  const config = useSelector(selectConfig);
  const mrLinksAllowMap = getMrLinksAllowMap(config);
  const csLinksAllowMap = getCsLinksAllowMap(config);
  const seeYouLaterRequired = isSeeYouLaterRequired(href, population?.lineOfBusiness, csLinksAllowMap, mrLinksAllowMap);
  const seeYouLaterUri = seeYouLaterRequired ? `${population?.uri || ''}/see-you-later` : href;
  return { seeYouLaterRequired, seeYouLaterUri, seeYouLaterDestinationUri: href };
};

/**
 * Resets the closed values of the useSeeYouLater hook to undefined in order to pick up any configuration changes.
 */
export function resetUseSeeYouLaterAllowMaps(): void {
  csLinksAllowMap = undefined;
  mrLinksAllowMap = undefined;
}
