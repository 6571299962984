import React, { Dispatch, FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Action } from 'redux';
import styled, { css } from 'styled-components';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { trapFocus, getReturnFocusTo, focusFirstFocusableElement } from 'scripts/ui/modal/modal-utils';
import { TrackedModalCloseButton } from './modal-close-button';
import { changeModalOpen } from 'scripts/reducers/app-reducer';
import { TrackingClickType } from 'scripts/api/tracking/tracking.interfaces';
import { ModalContext } from './modal-context';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { flex_justify_end, hideDesktop } from 'scripts/styles/utilities';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { mobileOnly, desktopOnly } from 'scripts/styles/breakpoints';
import { heading2, heading6 } from 'scripts/styles/typography';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { captureEventTrigger, logModalPageClose } from 'scripts/thunks/analytics-thunks';

const getTitle = (title: string | ReactElement, centerModalTitle: boolean): string | ReactElement => {
  if (typeof title === 'string') {
    return (
      <$H1 {...{ centerModalTitle: centerModalTitle }} id="modal-header" data-testid="modal-header">
        {title}
      </$H1>
    );
  }
  return title;
};

interface ICustomBodySize {
  height: string;
  width: string;
}

interface IModalProps extends IModalDispatchToProps, IModalStateProps {
  children: ReactElement | ReactElement[];
  centerModalTitle?: boolean;
  className?: string;
  customCloseModal?: () => void;
  dataUiSection?: string;
  headerComponent?: ReactElement | ReactElement[];
  hideHeader?: boolean;
  id?: string;
  fullScreen?: boolean;
  modalSecondarySubtitle?: string;
  modalSubtitle?: string | ReactElement;
  modalSubtitlePara?: string | ReactElement;
  modalTitle?: string | ReactElement;
  notifications?: ReactElement;
  testIdSuffix?: string;
  tabIndex?: number;
  modalContainerAriaLabeledBy?: string;
  overlayCloseEnabled?: boolean;
  customBodySize?: ICustomBodySize;
  customScrollableModal?: boolean;
  showDashboardButton?: boolean;
  showAllButtons?: boolean;
  bodyModalRole?: string;
  bodyModalAriaLabelledBy?: string;
}

interface IModalStateProps {
  population?: IPopulation;
}

interface IModalDispatchToProps {
  stateOpenModal: () => void;
  stateCloseModal: () => void;
}

const mapStateToProps = (state: IReduxState): IModalStateProps => ({
  population: selectPopulation(state),
});

interface IModalHeaderProps extends Partial<IModalProps> {
  displayTitle: string | React.ReactElement;
}

const ModalHeader: FunctionComponent<IModalHeaderProps> = props => {
  const { fullScreen, children, customScrollableModal, showDashboardButton } = props;
  if (customScrollableModal) {
    if (showDashboardButton) {
      return <$ScrollableModalHeaderWithButton>{children}</$ScrollableModalHeaderWithButton>;
    }
    return <$ScrollableModalHeader>{children}</$ScrollableModalHeader>;
  }

  return fullScreen ? <$ModalHeader>{children}</$ModalHeader> : <$ModalHeader as="div">{children}</$ModalHeader>;
};

export const RawModal: FunctionComponent<IModalProps> = props => {
  const {
    centerModalTitle,
    children,
    className,
    customCloseModal,
    dataUiSection,
    headerComponent,
    hideHeader,
    id,
    fullScreen,
    modalSecondarySubtitle,
    modalSubtitle,
    modalSubtitlePara,
    modalTitle,
    notifications,
    stateOpenModal,
    stateCloseModal,
    testIdSuffix = '',
    tabIndex = 0,
    population,
    customBodySize,
    customScrollableModal,
    showDashboardButton,
    showAllButtons,
    overlayCloseEnabled = true,
    bodyModalRole,
    bodyModalAriaLabelledBy,
  } = props;

  const {
    location: { pathname, search },
    history,
  } = useRouter();
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  const closeModal = (): void => {
    const pathnameWithoutModal = pathname.split('/modal')[0].trim() || '';
    // While we call the field URI, it appears to be used as a path, so we will make that same assumption here.
    const expectedModalBasePathname = population?.uri || '';
    /*
    An empty pathname as a result of trying to return to the dashboard based on the URL that does not include
    the dashboard in the original path.  If this is the case, we want to try and go back.
     */
    // ARC-11144: Add check to test if stripped page url is empty for the case when page loaded directly to the modal
    if (pathnameWithoutModal === expectedModalBasePathname || pathnameWithoutModal === '') {
      history.goBack();
    } else {
      history.push(pathnameWithoutModal + search);
    }
  };

  const onClose = (
    event?: Event | React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
  ): void => {
    event && dispatch(captureEventTrigger(event));
    dispatch(logModalPageClose());

    if (customCloseModal) {
      customCloseModal();
    } else {
      closeModal();
    }
  };

  const hideModal = (e: MouseEvent): void => {
    const modal = modalRef.current;
    const target = e.target;
    if (!fullScreen && target === modal && overlayCloseEnabled) {
      onClose();
    }
  };

  const onKeyPress = (e: KeyboardEvent): void => {
    switch (e.key) {
      case 'Escape':
        onClose(e);
        break;
      case 'Tab':
        trapFocus(e, modalRef);
        break;
    }
  };

  const [returnFocusToElement] = useState(() => getReturnFocusTo(window.document.activeElement));
  const uiViewPage = window.document.getElementById('ui-view-page');
  const displayTitle = getTitle(modalTitle, centerModalTitle);
  const containerTestId = testIdSuffix ? `modal-container-${testIdSuffix}` : 'modal-container';
  const ModalContentType = customScrollableModal ? $ScrollableModalContent : $ModalContent;
  const HeaderType = customScrollableModal ? $ModalHeaderContentScrollable : $ModalHeaderContent;
  const focusFirstFocusableElementInModal = (): void => focusFirstFocusableElement(modalRef);

  useEffect(() => {
    stateOpenModal();
    document.documentElement.classList.add('modal-open');
    uiViewPage?.setAttribute('aria-hidden', 'true');
    window.addEventListener('keydown', onKeyPress);
    document.body.addEventListener('mouseup', hideModal);
    window.addEventListener('focus', focusFirstFocusableElementInModal);
    return () => {
      stateCloseModal();
      document.documentElement.classList.remove('modal-open');
      uiViewPage?.setAttribute('aria-hidden', 'false');
      window.removeEventListener('keydown', onKeyPress);
      document.body.removeEventListener('mouseup', hideModal);
      if (returnFocusToElement) {
        const dataTrackId = returnFocusToElement.getAttribute('data-track-id');
        const focusElement: HTMLElement = document.body.querySelector(`[data-track-id="${dataTrackId}"]`);
        focusElement && focusElement.focus();
      }
      window.removeEventListener('focus', focusFirstFocusableElementInModal);
    };
  }, []);

  return (
    <div className={className} id={id} data-testid="modal-outer-container" data-ui-section={dataUiSection}>
      <$ModalContainer
        ref={modalRef}
        role="dialog"
        aria-modal="true"
        aria-labelledby={displayTitle && 'modal-header'}
        data-testid={containerTestId}
        fullScreen={fullScreen}
        tabIndex={tabIndex}
      >
        <ModalContentType data-testid="modal-content" customBodySize={customBodySize} fullScreen={fullScreen}>
          {headerComponent}
          {!hideHeader && (
            <ModalHeader
              displayTitle={displayTitle}
              fullScreen={fullScreen}
              customScrollableModal={customScrollableModal}
              showDashboardButton={showDashboardButton}
            >
              <HeaderType data-testid="modal-header-content">
                <$HeaderRow>
                  {displayTitle}
                  {customScrollableModal && !showDashboardButton && (
                    <$MobileOnlyCloseButton
                      dataClickType={TrackingClickType.StateChange}
                      dataTrackId="close-modal"
                      onClose={onClose}
                    />
                  )}
                  {showDashboardButton ||
                    (!customScrollableModal && (
                      <TrackedModalCloseButton
                        dataClickType={TrackingClickType.StateChange}
                        dataTrackId="close-modal"
                        onClose={onClose}
                      />
                    ))}
                  {showAllButtons && (
                    <$ModalCloseButtonContainer>
                      <TrackedModalCloseButton
                        dataClickType={TrackingClickType.StateChange}
                        dataTrackId="close-modal"
                        onClose={onClose}
                      />
                    </$ModalCloseButtonContainer>
                  )}
                </$HeaderRow>
                <$SubtitleHeaderRow>
                  {modalSubtitle && (
                    <$SubtitleOne id="modal-subtitle" data-testid="modal-subtitle" centerModalTitle={centerModalTitle}>
                      {modalSubtitle}
                    </$SubtitleOne>
                  )}
                  {modalSubtitlePara && (
                    <$SubtitlePara
                      id="modal-subtitle-para"
                      data-testid="modal-subtitle-para"
                      centerModalTitle={centerModalTitle}
                    >
                      {modalSubtitlePara}
                    </$SubtitlePara>
                  )}
                  {modalSecondarySubtitle && (
                    <$SubtitleOne
                      id="modal-secondary-subtitle"
                      data-testid="modal-secondary-subtitle"
                      centerModalTitle={centerModalTitle}
                    >
                      {modalSecondarySubtitle}
                    </$SubtitleOne>
                  )}
                </$SubtitleHeaderRow>
              </HeaderType>
            </ModalHeader>
          )}
          {notifications}
          {!customScrollableModal && (
            <$ModalBody
              tabIndex={tabIndex}
              customBodySize={customBodySize}
              fullScreen={fullScreen}
              data-testid="modal-body"
              role={bodyModalRole}
              aria-labelledby={bodyModalAriaLabelledBy}
            >
              <ModalContext.Provider value={{ closeModal, onClose }}>{children}</ModalContext.Provider>
            </$ModalBody>
          )}
          {customScrollableModal && (
            <$ModalBodyScrollable
              tabIndex={tabIndex}
              customBodySize={customBodySize}
              fullScreen={fullScreen}
              data-testid="modal-body"
              role={bodyModalRole}
              aria-labelledby={bodyModalAriaLabelledBy}
            >
              <ModalContext.Provider value={{ closeModal, onClose }}>{children}</ModalContext.Provider>
            </$ModalBodyScrollable>
          )}
        </ModalContentType>
      </$ModalContainer>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action<string>>): IModalDispatchToProps => {
  return {
    stateOpenModal: () => dispatch(changeModalOpen(true)),
    stateCloseModal: () => dispatch(changeModalOpen(false)),
  };
};

export const $ModalCloseButtonContainer = styled.div`
  ${flex_justify_end}
  margin: 0px;
  padding: 0px;
  width: 100%;
`;

export const $MobileOnlyCloseButton = styled(TrackedModalCloseButton)`
  ${hideDesktop}
`;

export const Modal = connect(mapStateToProps, mapDispatchToProps)(RawModal);

export const $ModalContainer = styled.div<{ fullScreen?: boolean; tabIndex?: number | never }>`
  align-items: ${props => (props.fullScreen ? 'stretch' : 'center')};
  background: ${props => (props.fullScreen ? fromTheme('clrWhite') : 'rgba(0,0,0,0.6)')};
  display: flex;
  height: 100vh;
  justify-content: ${props => (props.fullScreen ? 'flex-start' : 'center')};
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1001;
  ${mobileOnly`
    background: ${fromTheme('clrWhite')};
    -webkit-overflow-scrolling: touch;
  `}
`;

const ModalContentFullScreen = `
  display: flex;
  flex-direction: column;
  height: 100%;
  top: 0;
  width: 100%;
`;

export const $ModalContent = styled.div<{ customBodySize: ICustomBodySize; fullScreen: boolean }>`
  ${props => {
    if (props.customBodySize) {
      return `
        width: ${props.customBodySize.width};
      `;
    }
    if (props.fullScreen) {
      return ModalContentFullScreen;
    }
    return `
      width: 768px;
    `;
  }}
  background-color: ${fromTheme('clrWhite')};
  max-width: 100vw;
  padding: 0;
  ${mobileOnly`
    ${ModalContentFullScreen}
  `}
`;

const $ScrollableModalContent = styled($ModalContent)`
  padding-bottom: ${fromTheme('spacing24')};
`;

export const $ModalHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const $ScrollableModalHeader = styled($ModalHeader)`
  ${desktopOnly`
    margin-bottom: ${fromTheme('spacing16')};
    height: 0px;
    overflow: hidden;
  `}
`;

export const $ScrollableModalHeaderWithButton = styled($ScrollableModalHeader)`
  ${desktopOnly`
    overflow: visible;
  `}
`;

export const $ModalHeaderContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${fromTheme('docMaxWidth')}px;
  padding: ${fromTheme('spacing32')};
  width: 100%;
  ${mobileOnly`
    padding: ${fromTheme('spacing16')};
  `}
`;

export const $ModalHeaderContentScrollable = styled($ModalHeaderContent)`
  background: transparent;
  border-bottom: none;
  justify-content: flex-end;
`;

const $H1 = styled.h1<{ centerModalTitle: boolean }>`
  ${heading2}
  color: ${fromTheme('clrGreyDark')};
  flex: 1;
  justify-content: center;
  ${props =>
    props.centerModalTitle &&
    css`
      margin-left: ${fromTheme('spacing40')};
      text-align: center;
    `}
  ${mobileOnly`
    padding-top: ${fromTheme('spacing16')};
    text-align: center;
  `}
  ${desktopOnly`
    margin-top: 4px;
  `}
`;

const $SubtitleOne = styled.h2<{ centerModalTitle: boolean }>`
  ${heading6}
  ${props =>
    props.centerModalTitle &&
    css`
      text-align: center;
      ${desktopOnly`
        margin-left: ${fromTheme('spacing40')};
      `}
    `}
  color: ${fromTheme('clrGreyDark')};
  font-size: ${fromTheme('fs16')};
  justify-content: center;
  margin-top: ${fromTheme('spacing16')};
`;

const $SubtitlePara = styled.p<{ centerModalTitle: boolean }>`
  ${heading6}
  ${props =>
    props.centerModalTitle &&
    css`
      text-align: center;
      ${desktopOnly`
        margin-left: ${fromTheme('spacing40')};
    `}
    `}
color: ${fromTheme('clrGreyDark')};
  font-size: ${fromTheme('fs16')};
  justify-content: center;
  margin-top: ${fromTheme('spacing16')};
`;

const $HeaderRow = styled.div`
  display: flex;
  width: 100%;
  ${mobileOnly`
    align-items: flex-end;
    flex-flow: wrap-reverse;
  `}
`;

const $SubtitleHeaderRow = styled.div`
  display: block;
  width: 100%;
`;

export const $ModalBody = styled.div<{ customBodySize: ICustomBodySize; fullScreen: boolean }>`
  ${props => {
    if (props.customBodySize) {
      return `
        max-height: ${props.customBodySize.height};
      `;
    }
    if (props.fullScreen) {
      return `
        max-height: 100%;
      `;
    }
    return `
      max-height: 436px;
    `;
  }}
  align-self: center;
  flex: 2;
  max-width: ${fromTheme('docMaxWidth')}px;
  overflow: auto;
  padding: 0 ${fromTheme('spacing32')} ${fromTheme('spacing32')};
  width: 100%;
  &:focus {
    outline: 4px solid ${fromTheme('clrOlympicBlue')};
  }
  ${mobileOnly`
    flex: 1;
    margin: 0;
    // leave enough room for the bottom toolbar in mobile safari
    padding: 0 ${fromTheme('spacing16')} 110px;
    max-height: 100%;
  `}
`;

export const $ModalBodyScrollable = styled($ModalBody)`
  && {
    ${desktopOnly`
      max-height: 80vh;
      padding-bottom: 0;
    `}
  }
`;
