import { ILocale } from 'scripts/util/locale/locale.interfaces';
import { CoverageStatus } from '../api.interfaces';
import { LineOfBusiness, MembershipCategory } from '../profile/profile.interfaces';
import { ClaimStatus } from '../claims/claims.interfaces';

export enum TrackingTriggerType {
  Click = 'CLICK',
  PageLoad = 'PAGE_LOAD',
  SectionFailed = 'SECTION_FAILED',
  SectionLoad = 'SECTION_LOAD',
  View = 'VIEW',
}

export enum TrackingClickType {
  ExternalLink = 'EXTERNAL_LINK',
  PageEvent = 'PAGE_EVENT',
  StateChange = 'STATE_CHANGE',
}

export enum TrackingServiceType {
  Adobe = 'Adobe',
  Arcade = 'Arcade',
}

// Duplicated from AmpPageTags, which has been deprecated along with the rest of amplitude.
export const PageTags = {
  accountSummaryExplanation: 'account-summary-explanation',
  behaviouralHealth: 'behavioural-health',
  claims: 'claims',
  combinedNetwork: 'combined-network',
  compareDoctorCare: 'compare-doctor-care',
  compareImmediateCare: 'compare-immediate-care',
  compareServices: 'compare-services',
  coronavirus: 'coronavirus',
  costInfo: 'cost-info',
  dashboard: 'dashboard',
  dental: 'dental',
  drugTiers: 'drug-tiers',
  dentalClaimsAvailable: 'dental-claims-available',
  editClaimNote: 'edit-claim-note-modal',
  error: 'error',
  facilities: 'facilities',
  fsa: 'fsa',
  healthResources: 'health-resources',
  healthTopicsModal: 'health-topics',
  help: 'help',
  hra: 'hra',
  hsa: 'hsa',
  inactive: 'inactive',
  idCards: 'id-cards',
  idCardspaperlessPreferences: 'id-cards-preferences',
  idCardAccessibilityModal: 'member-id-cards',
  infoModal: 'info-modal',
  inNetwork: 'in-network',
  insulinDrugs: 'insulin-drugs',
  markAsPaidExplanation: 'mark-as-paid-explanation',
  medical: 'medical',
  outNetwork: 'out-network',
  payNow: 'pay-now-modal',
  payments: 'payments',
  pcp: 'pcp',
  pcpChangeUnavailable: 'pcp-change-unavailable',
  pcpReferral: 'pcp-referral',
  planBalances: 'plan-balances',
  providers: 'providers',
  rx: 'rx',
  mrNudge: 'mr-nudge',
  mrRecommendations: 'mr-recommendations',
  splitHra: 'split-hra',
  tipsToSave: 'tips-to-save',
  urgentCare: 'urgent-care',
  waysToSave: 'ways-to-save',
  healthplan: 'healthplan',
};

export interface IArcadeStateData {
  name?: string;
  tags?: string[];
  trackingKeys?: string | string[];
  title: string | string[];
  useSelectedProfile?: boolean;
  uuid: string;
}

export const adobeMcParamKey = 'adobe_mc';
export const adobeMcTimestampKey = '|TS';

export type ITrackingEventRequestFactory = (serviceType: TrackingServiceType) => ITrackingEventRequest;

export interface ITrackingEventBase {
  featureList: string[];
  actionName: string;
}

export interface ITrackingEventRequest<T = Record<string, unknown>> extends ITrackingEventBase {
  uri: string;
  trigger: TrackingTriggerType;
  clickType?: TrackingClickType;
  serviceVersion: string;
  placement: string;
  data?: any;
  campaignTrackingInfo?: ICampaignTrackingInfo;
  additionalProperties?: T;
  // for external link clicks only:
  externalDomain?: string;
  externalUrl?: string;
}

export interface IVideoData {
  videoName: string;
  videoSource?: string;
  videoProgress?: number;
  playStartTriggered?: boolean;
}

export interface IVideoTrackingEvent extends ITrackingEventRequest {
  videoData: IVideoData;
}

export interface ICampaignTrackingInfo {
  campaignId: string;
  placementType: string;
}

export interface IAASatellite {
  track(event?: string, options?: Record<string, unknown>): void;
  pageBottom(): void;
}

export interface IAAEvent {
  uri: string;
  featureList: string[];
  action: string;
  eventType: TrackingTriggerType;
  fullName: string;
}

export interface IAAContent {
  pageName: string;
  pageVersionName?: string;
  siteSectionL1?: string;
  siteSectionL2?: string;
  siteErrorType?: string;
  locale?: ILocale['id'];
  mostRecentEvent?: IAAEvent;
  claimStatusType?: ClaimStatus | string;
  videoAvailable?: string;
  pcpAssignStatus?: string;
  impressionTitles?: string;
}

export interface IAAPostPageData {
  content: IAAContent;
}

export interface IAADataLayer {
  content: IAAContent;
  rallyId: string;
  loginStatus: string;
  lineOfBusiness: LineOfBusiness;
  membershipCategory?: MembershipCategory;
  groupId?: string;
  externalId?: string;
  dependentSeqNum?: string;
  memberStatus?: CoverageStatus | string;
  zipcode: string;
  clientId: string;
  clientName: string;
  memberPlansInfo: IAAMemberPlan[];
  userFeedback: IAAUserFeedback;
}

export interface IAAMemberPlan {
  aco: string;
  coverageTypeCode: string;
  fundingArrangementCode: string;
  govtProgramTypeCode: string;
  memberId: string;
  policyNumber: string;
  status: string;
}

export interface IAACampaignsData {
  campaigns: IAACampaigns;
}

export interface IAACampaigns {
  id: string;
  placement: string;
  title: string;
  offerCode?: string;
}

export interface IAAUserFeedback {
  clientId: string;
  depSeqNumber: string;
  lob: LineOfBusiness;
  memberId: string; // this is actually externalId from heartbeat
  memberType: MembershipCategory;
  path: string;
  policyNumber: string;
  rallyId: string;
  status: CoverageStatus | string;
  age: number;
  gender: string;
}
