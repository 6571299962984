import { IReduxState, IStateData } from 'scripts/reducers/reducer.interfaces';
import { IPrimaryCareProviderInfo } from 'scripts/features/beta-dashboard/pcp/pcp-strip.interfaces';
import { createSelector } from '@reduxjs/toolkit';

export const selectPrimaryCareProviderInfoState = (state: IReduxState): IStateData<IPrimaryCareProviderInfo> =>
  state.primaryCareProvider.primaryCareProviderInfo;

/**
 * Gets an {IPrimaryCareProviderInfo} instance from the current {IRedux} state.
 */
export const selectPrimaryCareProviderInfo = createSelector(selectPrimaryCareProviderInfoState, state => state.data);

/**
 * Selector to get whether or not a request to retrieve PCP information is in progress.
 */
export const selectPrimaryCareProviderInfoLoading = createSelector(
  selectPrimaryCareProviderInfoState,
  state => state.loading,
);

/**
 * Selector to get whether or not an error has occurred retrieving PCP information
 */
export const selectPrimaryCareProviderInfoError = createSelector(
  selectPrimaryCareProviderInfoState,
  state => state.error,
);

/**
 * Selector to get the count of tiles the PCP strip is expected to display.
 */
export const selectPcpStripTileCount = createSelector(selectPrimaryCareProviderInfo, primaryCareProviderInfo => {
  const { showPrimaryCareProviderTile, showPrimaryCareDentistTile, showFindProvidersTile, showVirtualVisitsTile } =
    primaryCareProviderInfo || ({} as IPrimaryCareProviderInfo);
  return [showPrimaryCareProviderTile, showPrimaryCareDentistTile, showFindProvidersTile, showVirtualVisitsTile].reduce(
    (count, tileEnabled) => (tileEnabled ? count + 1 : count),
    0,
  );
});
