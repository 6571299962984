import {
  getAccumulatorsError,
  getAccumulatorsLoading,
  getAccumulatorsSuccess,
  getBenefitsError,
  getBenefitsLoading,
  getBenefitsSuccess,
  getIdCardsError,
  getIdCardsLoading,
  getIdCardsSuccess,
  getBenefitsSuccessMR,
} from 'scripts/reducers/plans-service-reducer';
import {
  IPlanAccumulatorsResponse,
  IPlanBenefitsResponse,
  IPlanAccumulators,
  IBenefit,
  IIdCard,
} from 'scripts/api/plans/plans.interfaces';
import { selectedUser as selectedUserSelector, selectProfileData } from 'scripts/selectors/profile-service-selectors';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { CoverageTypeCode } from 'scripts/api/api.interfaces';
import { IPlanCoverage, IProfileUser, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { PlansApi } from 'scripts/api/plans/plans-api';
import { reflect } from 'scripts/util/promises/promise-reflect';
import { formatError } from 'scripts/util/error/error';
import { max } from 'scripts/util/date/date';

// We also filter for these on the BE
const approvedCoverageTypeCodesAccumulators = [
  CoverageTypeCode.MA,
  CoverageTypeCode.MAPD,
  CoverageTypeCode.PDP,
  CoverageTypeCode.MedicareSupplement,
];

const getApprovedCoverageTypeCodes = (
  profile: IProfileUser,
  approvedCodeList: CoverageTypeCode[],
): CoverageTypeCode[] =>
  profile.planCoverages
    .map(({ coverageTypeCode }) => coverageTypeCode)
    .filter(coverageTypeCode => approvedCodeList.indexOf(coverageTypeCode) !== -1);

type IdCardResult = { idCards?: IIdCard[]; error?: any };

/**
 * Gets the set of ID cards for a given {@link IProfileUser} for a given {@link IPlanCoverage}.  If the response does not appear to
 * parse, this function will return an empty set.  This function should return an {@link IdCardResult}, with the
 * {@link IdCardResult.idCards} populated with the ID card response or {@link IdCardResult.error} field set to an exception
 * encountered during retrieval or parsing.
 * @param selectedProfile The {@link IProfileUser} instance to fetch ID cards for.
 * @param coverage The {@link IPlanCoverage} instance indicating the coverage type to fetch ID cards for.
 */
async function getIdCardsForCoverage(selectedProfile: IProfileUser, coverage: IPlanCoverage): Promise<IdCardResult> {
  try {
    const response = await (coverage.planFeatures.isIndividualIdCard
      ? PlansApi.getIdCards(selectedProfile, coverage, selectedProfile.dependentSeqNum)
      : PlansApi.getIdCards(selectedProfile, coverage));
    const idCards = response?.data;
    // This does not ensure that the response model structure matches, but it should filter out
    // non-json responses hopefully.
    if (response?.status === 200 && !!idCards && typeof idCards === 'object') {
      const result = {
        idCards: idCards.map(idCard => {
          return {
            ...idCard,
            coverageTypeCode: coverage.coverageTypeCode,
          };
        }),
      };
      return result;
    } else {
      // TODO https://jira.rallyhealth.com/browse/ARC-11733 we currently ignore errors on the UI side
      const error = {
        message: `Could not interpret response as an ID Card response: status: ${response.status} ${response.statusText}`,
        response: response,
      };
      console.error(error.message);
      return { error };
    }
  } catch (error) {
    console.error(`Error while trying to fetch ID Card for ${coverage.coverageType}: ${formatError(error)}`);
    return { error };
  }
}

/**
 * Gets all ID cards for all coverages from the Plans API
 * @param selectedProfile The {@link IProfileUser} instance to fetch plans for.
 */
export function getIdCards(selectedProfile: IProfileUser): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      dispatch(getIdCardsLoading());
      const planCoverages = selectedProfile.planCoverages;
      const coverages = planCoverages.filter(planCoverage => planCoverage.planFeatures.hasIdCard);
      if (coverages.length > 0) {
        const idCardsPromises: Promise<IdCardResult>[] = coverages.map(coverage =>
          getIdCardsForCoverage(selectedProfile, coverage),
        );
        const idCardsResults = await Promise.all(idCardsPromises);
        if (idCardsResults.every(idCardResult => !!idCardResult?.error)) {
          dispatch(getIdCardsError());
        } else {
          // TODO should we do something with the captured errors? A larger discussion around this is needed so this does not have
          // a ticket filed yet.
          const idCards = idCardsResults
            .filter(idCardResult => !!idCardResult.idCards)
            .map(idCardResult => idCardResult.idCards);
          dispatch(getIdCardsSuccess(idCards));
        }
      } else {
        dispatch(getIdCardsSuccess(undefined));
      }
    } catch (error) {
      dispatch(getIdCardsError());
    }
  };
}

export function getAccumulators(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getAccumulatorsLoading());
      const profile = selectProfileData(getState());
      const selectedProfile = selectedUserSelector.selectProfile(getState());
      const lineOfBusiness = selectedUserSelector.selectLineOfBusiness(getState());
      if (lineOfBusiness === LineOfBusiness.EI) {
        const { data, arcadeDataUpdated } = await PlansApi.getAccumulators(
          profile,
          selectedProfile,
          selectedProfile.dependentSeqNum,
        );
        dispatch(getAccumulatorsSuccess({ accumulators: data, arcadeDataUpdated }));
      }
      if (lineOfBusiness === LineOfBusiness.MR) {
        const planAccumulatorsPromises: Promise<IPlanAccumulatorsResponse>[] = getApprovedCoverageTypeCodes(
          selectedProfile,
          approvedCoverageTypeCodesAccumulators,
        ).map(coverageTypeCode => PlansApi.getAccumulators(profile, selectedProfile, null, coverageTypeCode));

        const planAccumulatorsResults = await Promise.all(planAccumulatorsPromises.map(reflect));
        if (planAccumulatorsResults.every(planAccumulatorResult => !!planAccumulatorResult.error)) {
          dispatch(getAccumulatorsError());
        } else {
          const accumulators = planAccumulatorsResults
            .flatMap(planAccumulatorResult => {
              const { value } = planAccumulatorResult;
              return value && value.data && value.data.benefits && value.data.benefits;
            })
            .filter(val => !!val);
          const accumulatorsUpdated = planAccumulatorsResults
            .map(planAccumulatorResult => {
              const { value } = planAccumulatorResult;
              return value && value.arcadeDataUpdated;
            })
            .filter(val => !!val);
          const arcadeDataUpdated = accumulatorsUpdated.length ? max(accumulatorsUpdated).toISOString() : undefined;
          const planAccumulators: IPlanAccumulators = { benefits: accumulators };
          dispatch(getAccumulatorsSuccess({ accumulators: planAccumulators, arcadeDataUpdated }));
        }
      }
    } catch (error) {
      dispatch(getAccumulatorsError());
    }
  };
}

/**
 * Simple interface that wraps an {IPlanBenefitsResponse} instance with the {CoverageTypeCode} that the
 * original benefits request was made with.
 */
interface IBenefitsResultWithOriginalCoverageTypeCode {
  coverageTypeCode: CoverageTypeCode;
  benefitsResponse: IPlanBenefitsResponse;
}

export function getBenefits(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getBenefitsLoading());
      const profile = selectProfileData(getState());
      const selectedProfile = selectedUserSelector.selectProfile(getState());
      const lineOfBusiness = selectedUserSelector.selectLineOfBusiness(getState());

      if (lineOfBusiness === LineOfBusiness.MR) {
        // PAY-658: getBenefits returns an IPlanBenefitsResponse, which contains a benefits array of IBenefit objects.
        // We need to call getBenefits for each coverage, pull out the IBenefit, and reconstruct IPlanBenefit to store in Redux store.
        const planBenefitsPromises: Promise<IBenefitsResultWithOriginalCoverageTypeCode>[] =
          selectedProfile.planCoverages
            .map(coverage => coverage.coverageTypeCode)
            .map(coverageTypeCode =>
              PlansApi.getBenefits(profile, selectedProfile, null, coverageTypeCode).then(benefitsResponse => ({
                coverageTypeCode,
                benefitsResponse,
              })),
            );

        /*
         reflect here doesn't actually do reflection but rather wraps the promise so that if the promise fails,
         the chain of promises in the Promise.all call does not immediately bomb out. This is done because apparently
         having some benefits calls fail is expected.
         */
        const planBenefitsResults = await Promise.all(planBenefitsPromises.map(reflect));
        if (planBenefitsResults.every(planBenefitResult => !!planBenefitResult.error)) {
          dispatch(getBenefitsError());
        } else {
          /*
          What's happening here is we need to know what coverage type code a set of plan benefits was called with.
          Because the coverage type code from the original call may not match the coverage type code in the call,
          consumers cannot select a benefits set by that coverageTypeCode, which drug benefits needs to do.
          In order to address that, the M&R response will construct a map keyed on the original CoverageTypeCode.

          The dispatched reducer will still fill in the benefits state data in addition to a new piece of state
          data in order to maintain backwards compatibility.
           */
          const benefitsByCoverageTypeCode = planBenefitsResults
            .map(reflectedResult => {
              const { value } = reflectedResult;
              const { coverageTypeCode, benefitsResponse } = (value ||
                {}) as IBenefitsResultWithOriginalCoverageTypeCode;
              const benefits = benefitsResponse?.data?.benefits;
              return coverageTypeCode && benefits && { coverageTypeCode, benefits };
            })
            .reduce((map, benefitsTuple) => {
              if (benefitsTuple && benefitsTuple.benefits && benefitsTuple.coverageTypeCode) {
                const { coverageTypeCode, benefits } = benefitsTuple;
                map[coverageTypeCode] = benefits;
              }
              return map;
            }, {} as { [key: string]: IBenefit[] });
          dispatch(getBenefitsSuccessMR(benefitsByCoverageTypeCode));
        }
      } else {
        const { data } = await PlansApi.getBenefits(profile, selectedProfile, selectedProfile.dependentSeqNum);
        dispatch(getBenefitsSuccess(data));
      }
    } catch (error) {
      dispatch(getBenefitsError());
    }
  };
}
