import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePopulation } from 'scripts/reducers/population-reducer';
import { selectLineOfBusiness, selectPopulation } from 'scripts/selectors/population-selectors';
import { getPopulationByUri } from 'scripts/util/population/population';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { useRouter } from '../use-router/use-router';

export const usePopulationByPath = (): IPopulation => {
  const population = useSelector(selectPopulation);
  const lineOfBusiness = useSelector(selectLineOfBusiness);
  const { params, history, location } = useRouter<{ path: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    let currentPopulation: IPopulation | undefined = population;
    if (!currentPopulation) {
      currentPopulation = getPopulationByUri(params.path, lineOfBusiness);
      dispatch(changePopulation(currentPopulation));
    }

    if (params.path && `/${params.path}` !== currentPopulation.uri) {
      history.replace(location.pathname.replace(`/${params.path}`, currentPopulation.uri) + location.search);
    } else if (!params.path && currentPopulation.uri) {
      history.replace(currentPopulation.uri + location.pathname + location.search);
    }
  }, [population, lineOfBusiness]);

  return population;
};
