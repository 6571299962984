import { useLocation as useLocationHook } from 'react-router-dom';
import { Location, LocationState } from 'history';

// only including properties that we have implemented here
export type IUseLocationState = Location<LocationState>;

// creating a wrapper around useLocation so that it is easier to mock for tests
export const useLocation = (): IUseLocationState => {
  const location = useLocationHook();
  return location;
};
