import { CoverageType } from 'scripts/api/api.interfaces';
import { IMemberInfo, ISegmentationData } from 'scripts/api/live-person/live-person.interfaces';
import { IProfileUser, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import {
  ITrackingEventRequest,
  TrackingClickType,
  TrackingServiceType,
  TrackingTriggerType,
} from 'scripts/api/tracking/tracking.interfaces';
import { ICoverageSection } from 'scripts/features/modals/id-cards/id-cards.interfaces';
import { getGroupNumber } from 'scripts/util/plans/plans';
import { getCoverage } from 'scripts/util/profile/profile';
import { formatTrackingString, createEventFactory } from 'scripts/util/tracking/tracking-helper';
import { queueEvent } from 'scripts/util/tracking/tracking.react';
import CONFIG from 'scripts/util/constants/config';

export const parseMemberInfo = (userProfile: IProfileUser, isEfsEligibleFF?: boolean): IMemberInfo => {
  const medicalCoverage = getCoverage(CoverageType.Medical, userProfile.planCoverages);

  if (!medicalCoverage) {
    console.warn('Parsing member info for Live Person Chat failed. User does not have medical coverage.');
    return null;
  }
  const isEfsEligible = isEfsEligibleFF && userProfile.primaryPolicyNumber === '0168504';
  const userData = !isEfsEligible
    ? {
        firstName: userProfile.userInfo.firstName,
        lastName: userProfile.userInfo.lastName,
        dob: userProfile.userInfo.dob,
        memberId: medicalCoverage.memberId?.id,
        groupId: getGroupNumber(medicalCoverage as ICoverageSection, userProfile),
        // EI param does not require planId
        planId: userProfile.lineOfBusiness === LineOfBusiness.CS ? medicalCoverage.planFeatures?.planId : undefined,
      }
    : {
        firstName: userProfile.userInfo.firstName,
        lastName: userProfile.userInfo.lastName,
        dob: userProfile.userInfo.dob,
        memberId: medicalCoverage.memberId?.id,
        groupId: getGroupNumber(medicalCoverage as ICoverageSection, userProfile),
        planId: undefined,
        ENT_FromAddress: userProfile.userInfo?.mailingAddress,
        ENT_ContactPhoneNbr: userProfile.userInfo?.primaryPhoneNumber,
        ENT_ContactConstituentID: '',
        ENT_ContactConstituentSourceID: '',
        ENT_ContactStreet: userProfile.userInfo?.address1,
        ENT_ContactCityNm: userProfile.userInfo?.city,
        ENT_ContactStateCD: userProfile.userInfo?.state,
        ENT_ContactZIP: userProfile.userInfo?.zipCode,
        ENT_Client: userProfile.planCoverages[0]?.additionalCoverageInfo?.customerName,
      };

  return userData;
};

export const startChatForEi = async (segmentationData: ISegmentationData): Promise<void> => {
  if (!segmentationData) {
    throw new Error('Attempted to start live person chat without segmentation data.');
  }

  if (!window?.startLiveperson) {
    throw new Error('Window.startLiveperson is undefined.');
  }

  await window.startLiveperson(segmentationData);
  initializeClickTracking();
};

export const startChatForCs = async (userProfile: IProfileUser): Promise<void> => {
  if (!userProfile) {
    throw new Error('Attempted to start live person chat without user data.');
  }

  const memberInfo = parseMemberInfo(userProfile);
  if (!memberInfo) {
    throw new Error('Attempted to start live person chat without member info.');
  }

  if (!window?.startLiveperson) {
    throw new Error('Window.startLiveperson is undefined.');
  }

  await window.startLiveperson(memberInfo);
  initializeClickTracking();
};

export const initializeClickTracking = (): string => {
  const trackChatButtonClick = async (): Promise<void> => {
    try {
      const defaultEvent: ITrackingEventRequest = {
        featureList: ['live-person-message-us'],
        actionName: formatTrackingString('Live Person Chat Window Clicked'),
        placement: 'live-person-message-us',
        uri: window.location.pathname,
        trigger: TrackingTriggerType.Click,
        clickType: TrackingClickType.PageEvent,
        serviceVersion: CONFIG.ARCADE_WEB_VERSION,
      };

      const adobeEvent: ITrackingEventRequest = {
        ...defaultEvent,
        actionName: 'live-person-message-us',
      };

      await queueEvent(createEventFactory(defaultEvent, { [TrackingServiceType.Adobe]: adobeEvent }));
    } catch (e) {
      console.warn('Chat Button Tracking Failed: ');
    }
  };

  return window.lpTag?.events?.bind?.('*', 'OFFER_CLICK', trackChatButtonClick);
};
