import { useLocation } from 'scripts/hooks/use-location/use-location';

export const useQueryParams = <T>(): T => {
  try {
    const location = useLocation();
    const queryParams = {} as { [key: string]: any | any[] };
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.forEach((value, key) => {
      const values = urlSearchParams.getAll(key);
      if (values.length > 1) {
        queryParams[key] = values;
      } else {
        queryParams[key] = value;
      }
    });
    return queryParams as T;
  } catch (err) {
    console.warn('Unable to parse query params', err);
    return {} as T;
  }
};
