import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { changePopulation } from 'scripts/reducers/population-reducer';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { selectCurrentUser } from 'scripts/selectors/profile-service-selectors';
import { doesPopulationMatch, getPopulationByProfile, getPopulationByUri } from 'scripts/util/population/population';
import { IEmptyComponent } from '../general.interfaces';

const Population: FC = ({ children }) => {
  const population = useSelector(selectPopulation);
  const dispatch = useDispatch();

  useEffect(() => {
    const populationByUri = getPopulationByUri(window.location.pathname);
    dispatch(changePopulation(populationByUri));
  }, []);

  if (!population) {
    return null;
  }

  return <>{children}</>;
};

const DeterminePopulationByProfile: FC<IEmptyComponent> = (): null => {
  const { history, location, params } = useRouter<{ path: string }>();
  const population = useSelector(selectPopulation);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      const populationByProfile = getPopulationByProfile(currentUser);
      if (!doesPopulationMatch(population, populationByProfile)) {
        dispatch(changePopulation(populationByProfile));
      }

      if (params.path && `/${params.path}` !== populationByProfile.uri) {
        history.replace(location.pathname.replace(`/${params.path}`, populationByProfile.uri) + location.search);
      } else if (!params.path && populationByProfile.uri) {
        history.replace(populationByProfile.uri + location.pathname + location.search);
      }
    }
  }, [currentUser, population, params?.path]);

  return null;
};

export { Population, DeterminePopulationByProfile };
