export enum RallyCaller {
  Banner = 'myuhc_web.uhc_banner',
  BenefitsOverview = 'myuhc_web.uhc_benefits_overview',
  CareRecs = 'myuhc_web.uhc_carerecs',
  CareRecs_Taco = 'myuhc_web.uhc_carerecs.taco',
  CommonService = 'myuhc_web.uhc_commonservice',
  CovidResource = 'myuhc_web.uhc_covidresource',
  Dashboard = 'myuhc_web.dashboard',
  FindCare = 'myuhc_web.uhc_findcare',
  Genoa = 'myuhc_web.genoasso',
  Header = 'myuhc_web.uhc_header',
  HealthPromos = 'myuhc_web.uhc_healthpromos',
  HealthPromos_Taco = 'myuhc_web.uhc_healthpromos.taco',
  Help = 'myuhc_web.uhc_help',
  MR_FPC = 'myuhc_web.uhc_mnrfpc',
  Providers = 'myuhc_web.uhc_providers',
  Recommendations = 'myuhc_web.uhc_recommendations',
  Resources = 'myuhc_web.uhc_healthresources.taco',
  Rewards = 'myuhc_web.uhc_rewards',
  VirtualCare = 'myuhc_web.virtualcare',
  WaysToSave = 'myuhc_web.waystosave',
}
