import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStateData } from 'scripts/reducers/reducer.interfaces';
import {
  ContentInteraction,
  IUhcReward,
  IRallyReward,
  IRewardContentId,
  RewardType,
} from 'scripts/util/query/reward-query';

export interface IEIRewardsState {
  rewardType: IStateData<RewardType>;
  rewardContentId: IStateData<string>;
  ContentInteraction: IStateData<ContentInteraction>;
  rallyReward: IStateData<IRallyReward>;
  uhcReward: IStateData<IUhcReward>;
}

/**
 * Returns a new {@see IStateData} instance marked as errored.
 * @param data Optional parameter.  Most cases will clear the current state data on error.
 */
export function ERRORED_STATE<TData>(data: TData | undefined = undefined): IStateData<TData> {
  return {
    data: data,
    error: true,
    loading: false,
  };
}
/**
 * Returns a new {@see IStateData} instance with the loading and error flags set off.
 * @param data The data to set in the state.
 */
export function SUCCESS_STATE<TData>(data: TData): IStateData<TData> {
  return {
    data,
    error: false,
    loading: false,
  };
}

export const initialState: IEIRewardsState = {
  rewardType: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  rewardContentId: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  ContentInteraction: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  rallyReward: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  uhcReward: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const eiRewards = createSlice({
  name: 'eiRewards',
  initialState,
  reducers: {
    getRewardTypeSuccess: (state: IEIRewardsState, action: PayloadAction<RewardType>): void => {
      state.rewardType.data = action.payload;
      state.rewardType.error = false;
      state.rewardType.loading = false;
    },
    getRewardTypeLoading: (state: IEIRewardsState): void => {
      state.rewardType.loading = true;
      state.rewardType.error = false;
      state.rewardType.data = null;
    },
    getRewardTypeError: (state: IEIRewardsState): void => {
      state.rewardType = ERRORED_STATE();
    },
    getRewardContentIdSuccess: (state: IEIRewardsState, action: PayloadAction<IRewardContentId>): void => {
      state.rewardContentId.data = action.payload;
      state.rewardContentId.error = false;
      state.rewardContentId.loading = false;
    },
    getRewardContentIdLoading: (state: IEIRewardsState): void => {
      state.rewardContentId.loading = true;
    },
    getRewardContentIdError: (state: IEIRewardsState): void => {
      state.rewardContentId = ERRORED_STATE();
    },
    getContentInteractionSuccess: (state: IEIRewardsState, action: PayloadAction<ContentInteraction>): void => {
      state.ContentInteraction.data = action.payload;
      state.ContentInteraction.error = false;
      state.ContentInteraction.loading = false;
    },
    getContentInteractionLoading: (state: IEIRewardsState): void => {
      state.ContentInteraction.loading = true;
    },
    getContentInteractionError: (state: IEIRewardsState): void => {
      state.ContentInteraction = ERRORED_STATE();
    },
    getRallyRewardSuccess: (state: IEIRewardsState, action: PayloadAction<IRallyReward>): void => {
      state.rallyReward.data = action.payload;
      state.rallyReward.error = false;
      state.rallyReward.loading = false;
    },
    getRallyRewardLoading: (state: IEIRewardsState): void => {
      state.rallyReward.loading = true;
    },
    getRallyRewardError: (state: IEIRewardsState): void => {
      state.rallyReward = ERRORED_STATE();
    },
    getUhcRewardSuccess: (state: IEIRewardsState, action: PayloadAction<IUhcReward>): void => {
      state.uhcReward.data = action.payload;
      state.uhcReward.error = false;
      state.uhcReward.loading = false;
    },
    getUhcRewardLoading: (state: IEIRewardsState): void => {
      state.uhcReward.loading = true;
    },
    getUhcRewardError: (state: IEIRewardsState): void => {
      state.uhcReward = ERRORED_STATE();
    },
  },
});

export const {
  getRewardTypeSuccess,
  getRewardTypeLoading,
  getRewardTypeError,
  getRewardContentIdSuccess,
  getRewardContentIdLoading,
  getRewardContentIdError,
  getContentInteractionSuccess,
  getContentInteractionLoading,
  getContentInteractionError,
  getRallyRewardSuccess,
  getRallyRewardLoading,
  getRallyRewardError,
  getUhcRewardSuccess,
  getUhcRewardLoading,
  getUhcRewardError,
} = eiRewards.actions;

export default eiRewards.reducer;
