/**
 * Injects a script tag with a source and explicit id
 * Used to comply with CSP standards.
 * @param src - script source url
 * @param id - id for script tag
 * @param async - script async behavior
 */
export function include(src: string, id: string, async?: boolean): void {
  const head = document.getElementsByTagName('head')[0];
  const scriptTag = document.createElement('script');
  scriptTag.src = src;
  scriptTag.type = 'text/javascript';
  scriptTag.id = id;
  scriptTag.nonce = 'NGINX_NONCE';
  scriptTag.async = async;
  head.appendChild(scriptTag);
}
