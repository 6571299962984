import React, { ReactElement, ReactNode, Component, ErrorInfo } from 'react';
import { ErrorMessage } from './error-message';

interface IErrorBoundaryProps {
  children: ReactNode;
  componentId?: string;
}

interface IErrorBoundaryState {
  error?: Error | null | undefined;
  errorInfo?: ErrorInfo | null | undefined;
  hasError: boolean;
}

/* ARC-11152: We want to use ErrorBoundary on all dashboard redesign components
 * to ensure we have appropriate error handling and have resilient dashboard rendering
 * Ex. Component having unhandled exception such as undefined value of a field without optional chain check
 * will prevent dashboard from rendering completely.
 * Error Boundaries can catch JS error in the child component tree, log those errors, and display a fallback UI.
 * Do not use: inside event handlers, asynchronous code, server side rendering
 * Error Boundary provide two life cycle methods to be used within a class: static getDerivedStateFromError()
 * and componentDidCatch() that are activated when error occurs in the error boundary's child component.
 * It's encouraged to use JS error reporting services to report unhandled exceptions and fix them
 * componentDidCatch provides access to errors and can be used to print componentStack error trace using errorInfo object,
 * errors can be logged and saved or be a part of UI fallback, ex. this.state.errorInfo.componentStack.toString()
 * This allows to integrate with analytics ex. in componentDidCatch: newrelic.noticeError(error object $error, [object $customAttributes])
 * componentDidCatch(error: Error | null, errorInfo: ErrorInfo | null): void {
 *   this.setState({ error: error || new Error('Unknown error'), errorInfo: errorInfo });
 *   console.error("Uncaught error:", error, errorInfo);
 * }
 */

export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
  }

  public state: IErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true };
  }

  public render(): ReactElement | ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorMessage {...this.props} />;
    }

    return children;
  }
}
