import React, { FunctionComponent } from 'react';
import { SuperUserBanner } from '../header/super-user-banner';
import { SharedHeader } from '../shared-header-footer/shared-header';
import { NotificationComponent } from '../header/notification';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { isDesktop } from 'scripts/styles/themes/themes.utils';
import { TacoArcadeDashboardBanner } from '../beta-dashboard/arcade-dashboard-banner/taco-arcade-dashboard-banner';
import { CsIdCardBanner } from '../beta-dashboard/arcade-dashboard-banner/cs-id-card-banner';
import { useProfileLoaded } from 'scripts/hooks/use-profile-loaded/use-profile-loaded';
import useGlobalNav from 'scripts/hooks/use-global-nav/use-global-nav';
import GlobalHeader from '../global-nav-header-footer/global-header';
import { IGlobalNavContainer } from '../chrome-container/chrome-container';

export interface IAllArcadeBannersProps {
  hasProfileLoaded: boolean;
}

const AllBanners: FunctionComponent<IAllArcadeBannersProps> = ({ hasProfileLoaded }) => {
  const { pageName, location } = useRouter();
  const nonBannerPageNames = ['CoronavirusResources', 'OptumVirtualCare'];
  const isOnNonBannerPage = nonBannerPageNames.includes(pageName);
  const nonBannerPathNames = ['mobile-responsive-modal'];
  const isOnNonBannerPathName = nonBannerPathNames.includes(location.pathname.split('/').pop());
  const suppressBanner = isOnNonBannerPage || isOnNonBannerPathName;
  const isGlobalNavEnabled = useGlobalNav();
  return (
    <>
      {!isGlobalNavEnabled && <SuperUserBanner />}
      {hasProfileLoaded && (
        <>
          {!suppressBanner && <TacoArcadeDashboardBanner />}
          {!suppressBanner && <CsIdCardBanner />}
        </>
      )}
    </>
  );
};

export const HeaderContainer: FunctionComponent<IGlobalNavContainer> = props => {
  const { readyToDisplay, isDashboard } = props;
  const hasProfileLoaded = useProfileLoaded();
  const shouldDisplay = readyToDisplay || !isDashboard;

  return (
    <>
      {shouldDisplay && <NotificationComponent />}
      {/* Banners above Header in Desktop mode */}
      {shouldDisplay && isDesktop() && <AllBanners hasProfileLoaded={hasProfileLoaded} />}
      {(useGlobalNav() && <GlobalHeader />) || (shouldDisplay && <SharedHeader />)}
      {/* Banners below Header in Mobile mode */}
      {shouldDisplay && !isDesktop() && <AllBanners hasProfileLoaded={hasProfileLoaded} />}
    </>
  );
};
