import { useSelector } from 'react-redux';
import { selectConfig, selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { useEffect } from 'react';
import { isMobile } from 'scripts/util/browser/browser';
import { isCS, isDsnp, isEI, isEmpire, isExpatriate, isMR } from 'scripts/util/user/user';
import { hasPdpPlan, hasSomeSspPlan } from 'scripts/util/plans/plans';
import { MembershipCategory } from '../../api/profile/profile.interfaces';
import { splitConfig } from 'scripts/util/config/config-utils';
import { getConfig } from 'scripts/util/config/config';

export const useBranchIo = (): void => {
  const config = useSelector(selectConfig);
  const featureFlags = useSelector(selectFeatureFlags);
  const userProfile = useSelector(currentUser.selectProfile);
  const planCoverages = userProfile?.planCoverages;
  const hasTexasERS = userProfile?.membershipCategory === MembershipCategory.TEXAS_ERS;

  const branchKey = config.ARCADE_WEB_BRANCH_IO_SDK_KEY;
  const branchFF = featureFlags.ARCADE_FEATURES_BRANCH_IO_BANNER;
  const clientPolicyNumbers = splitConfig(getConfig().ARCADE_WEB_BRANCH_BANNER_FILTER_POLICY_NUMBERS);
  const hasClientPolicyNumbers = clientPolicyNumbers.some(policyNum => policyNum === userProfile?.primaryPolicyNumber);
  const isEiEligible = isEI(userProfile) && !isExpatriate(userProfile) && !isEmpire(userProfile);
  const isMrEligible =
    isMR(userProfile) &&
    !hasPdpPlan(planCoverages) &&
    !hasSomeSspPlan(planCoverages) &&
    !hasClientPolicyNumbers &&
    !hasTexasERS;
  const isCsEligible = isCS(userProfile) && isDsnp(userProfile);

  useEffect(() => {
    if (isMobile() && branchFF && branchKey?.length && (isEiEligible || isMrEligible || isCsEligible)) {
      try {
        init(branchKey);
      } catch (e) {
        console.error('Attempt to start Branch SDK failed', e);
      }
    }
  }, [isMobile, featureFlags, config, userProfile]);
};

function init(branchKey: string): void {
  const id = 'branch-sdk';
  if (document.getElementById(id)) {
    return;
  }

  const script = document.createElement('script');
  const head = document.querySelector('head');
  script.id = id;
  script.type = 'text/javascript';
  script.nonce = 'NGINX_NONCE';
  script.async = true;
  script.innerHTML =
    // eslint-disable-next-line max-len
    '(function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode setRequestMetaData setAPIUrl getAPIUrl setDMAParamsForEEA".split(" "), 0);';

  script.insertAdjacentHTML('beforeend', 'branch.setAPIUrl("https://protected-api.branch.io");');
  script.insertAdjacentHTML('beforeend', `branch.init("${branchKey}");`);

  head.appendChild(script);
}
