import { IResponse } from '../api.interfaces';

/**
 * The collection of all known supported Coverage Types used by the Connect-Service's lastIndexed
 * endpoint.
 */
export enum LastIndexedCoverageType {
  Medical = 'medical',
  Behavioral = 'behavioral',
}

/**
 * The collection of valid Connect partner IDs.  This corresponds to Arcade's Line Of Business, though perhaps not
 * directly.
 */
export enum PartnerId {
  MR = 'uhc.mnr',
}

export interface ILastIndexed {
  [LastIndexedCoverageType.Medical]?: number;
  [LastIndexedCoverageType.Behavioral]?: number;
}

/**
 * The LastIndex response type.
 */
export interface ILastIndexedResponse extends IResponse<{ lastIndexed: ILastIndexed }> {}
