import { CacheName, CoverageTypeCode } from 'scripts/api/api.interfaces';
import { IProfile, IProfileUser, IPlanCoverage, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { stringToFloat } from 'scripts/util/conversion/conversion';
import { getCoverageParams, planHasOtherMembers, getPlanVariationParams, isEhbDental } from 'scripts/util/plans/plans';
import { planUris } from 'scripts/util/uri/uri';
import HttpClient from '../http-client';
import {
  ICoverageParams,
  IMRBenefitParams,
  IPlanAccumulatorsResponse,
  IPlanBenefitsResponse,
  IIdCardsResponse,
  IIdCardMRBenefitParams,
} from './plans.interfaces';
import { EhbDental } from 'scripts/util/plans/plans.constants';

const buildParams = (
  profile: IProfile,
  selectedProfile: IProfileUser,
  dependentOffset?: string,
  coverageTypeCode?: CoverageTypeCode,
): IMRBenefitParams => {
  const coverageParams = coverageTypeCode
    ? getCoverageParams(selectedProfile, coverageTypeCode)
    : ({} as ICoverageParams);
  return {
    ...coverageParams,
    ...getPlanVariationParams(profile),
    d: dependentOffset || profile.currentUser.dependentSeqNum,
    showFamily: planHasOtherMembers(profile, selectedProfile),
  };
};

function getIdCards(
  selectedProfile: IProfileUser,
  coverage: IPlanCoverage,
  dependentOffset?: string,
): Promise<IIdCardsResponse> {
  const queryParams = dependentOffset ? { d: dependentOffset, getImage: true } : {};
  const coverageTypeParam = isEhbDental(coverage) ? EhbDental : coverage.coverageType;

  let url: string;
  if (selectedProfile.lineOfBusiness === LineOfBusiness.MR) {
    const medicareQueryParams: IIdCardMRBenefitParams = {
      marketType: coverage.additionalCoverageInfo.marketType,
      fundingArrangementType: coverage.planFeatures.fundingArrangementType,
      programType: coverage.planFeatures.programType,
      coverageTypeCode: coverage.coverageTypeCode,
      getImage: true,
    };
    url = planUris.idCards(selectedProfile.rallyId, coverageTypeParam, medicareQueryParams);
  } else {
    url = planUris.idCards(selectedProfile.rallyId, coverageTypeParam, queryParams);
  }
  return HttpClient.get<IIdCardsResponse>(url, { cacheName: CacheName.Plans });
}

function getBenefitsFromCacheOrApi(
  url: string,
  isAccumulator?: boolean,
  dependents: string[] = [],
): Promise<IPlanBenefitsResponse | IPlanAccumulatorsResponse> {
  return HttpClient.get<IPlanBenefitsResponse | IPlanAccumulatorsResponse>(url, { cacheName: CacheName.Plans }).then(
    rsp => {
      if (isAccumulator) {
        stringToFloat(rsp, 'data', 'benefits', 'accumulators', 'inNetwork', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'accumulators', 'outOfNetwork', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'accumulators', 'combined', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'accumulators', 'tier1', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'predeductibleAccumulators', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'lifetimeMaxAccumulator', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'mnrRxAccumulator', 'ytdAnnualDeductibleAmount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'mnrRxAccumulator', 'ytdOopCost', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'mnrRxAccumulator', 'ytdTotalDrugCost', 'value');
        for (const d of dependents) {
          stringToFloat(rsp, 'data', d, 'benefits', 'accumulators', 'inNetwork', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'accumulators', 'outOfNetwork', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'accumulators', 'combined', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'accumulators', 'tier1', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'predeductibleAccumulators', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'lifetimeMaxAccumulator', 'amount', 'value');
        }
      } else {
        stringToFloat(rsp, 'data', 'benefits', 'maxes', 'inNetwork', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'services', 'inNetwork', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'maxes', 'outOfNetwork', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'services', 'outOfNetwork', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'maxes', 'combined', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'services', 'combined', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'maxes', 'tier1', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'services', 'tier1', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'maxes', 'predeductibleMax', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'maxes', 'lifetimeMax', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'services', 'type1', 'amount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'mnrRxBenefits', 'maxAnnualDeductibleAmount', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'mnrRxBenefits', 'maxOopCost', 'value');
        stringToFloat(rsp, 'data', 'benefits', 'mnrRxBenefits', 'maxTotalDrugCost', 'value');
        for (const d of dependents) {
          stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'inNetwork', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'services', 'inNetwork', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'outOfNetwork', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'services', 'outOfNetwork', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'combined', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'services', 'combined', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'tier1', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'services', 'tier1', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'predeductibleMax', 'amount', 'value');
          stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'lifetimeMax', 'amount', 'value');
        }
      }
      return rsp;
    },
  );
}

/**
 * ARC-1120: for getBenefits and getAccumulators, note that
 * 1. showFamily is not passed as an argument but is instead generated based on profile and currentProfile responses
 * 2. dependentSeqNum defaults to that of the logged in user if it is not passed
 */
function getAccumulators(
  profile: IProfile,
  selectedProfile: IProfileUser,
  dependentOffset?: string,
  coverageTypeCode?: CoverageTypeCode,
): Promise<IPlanAccumulatorsResponse> {
  const params = buildParams(profile, selectedProfile, dependentOffset, coverageTypeCode);
  const url = planUris.accumulator(profile.rallyId, params);
  return getBenefitsFromCacheOrApi(url, true) as Promise<IPlanAccumulatorsResponse>;
}

function getBenefits(
  profile: IProfile,
  selectedProfile: IProfileUser,
  dependentOffset?: string,
  coverageTypeCode?: CoverageTypeCode,
): Promise<IPlanBenefitsResponse> {
  const params = buildParams(profile, selectedProfile, dependentOffset, coverageTypeCode);
  const url = planUris.benefits(profile.rallyId, params);
  return getBenefitsFromCacheOrApi(url) as Promise<IPlanBenefitsResponse>;
}

export const PlansApi = {
  getAccumulators,
  getBenefits,
  getIdCards,
};
