import { TacoApi } from 'scripts/api/taco/taco-api';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';
import { ITacoRecommendation } from 'scripts/api/targeting/targeting.interfaces';
import {
  ITacoTrackEventV2,
  TacoTrackEventType,
  ITacoTrackEventPostData,
  TacoTrackEventTypeV2,
} from 'scripts/api/taco/taco.interfaces';
import { formatError } from 'scripts/util/error/error';
import { getTacosError, getTacosLoading, getTacosSuccess } from 'scripts/reducers/taco-service-reducer';

type TacoServicePayloadThunk<TPayload> = (payload: TPayload) => ArcadeThunkAction<Promise<void>>;

type TacoTrackingThunk = (
  recommendation: ITacoRecommendation,
  eventType: TacoTrackEventType | TacoTrackEventTypeV2,
) => ArcadeThunkAction<Promise<void>>;

type TacoTrackingEventsV2Thunk = (contents: ITacoTrackEventV2[]) => ArcadeThunkAction<Promise<void>>;

/**
 * Get targeted contents directly from taco.
 * @param placementType A list of placement types
 * @returns A map of campaigns
 */
export const getTargetedContent: TacoServicePayloadThunk<string[]> = (placementType: string[]) => async dispatch => {
  dispatch(getTacosLoading());
  try {
    const result = await TacoApi.getCampaigns(placementType);
    dispatch(getTacosSuccess(result));
  } catch (_) {
    dispatch(getTacosError());
  }
};

/**
 *
 * @param recommendation Information regarding the single recommendation itself {@see ITacoRecommendation}
 * @param eventType The event that is triggering the request {@see TacoTrackEventType}
 *
 * postTackTrackEvent currently tracks click events and impressions and has no effect on the current state, dispatches nothing to the UI
 */
export const postTacoTrackEvent: TacoTrackingThunk = (recommendation, eventType) => async (_, getState) => {
  const rallyId = selectRallyId(getState());

  const trackEventData: ITacoTrackEventPostData = {
    rallyId,
    sessionId: 'arcade-dashboard',
    campaignId: recommendation?.campaignId,
    eventType,
    placementType: recommendation?.placement?.placementType,
    timestamp: Date.now(),
    origContent: recommendation,
  };

  try {
    await TacoApi.postTacoTrackEvent(trackEventData);
  } catch (error) {
    console.warn(`failed to send event tracking (${eventType}) for care recommendation: ${formatError(error)}`);
  }
};

/**
 * @param recommendation Information regarding the single recommendation itself {@see ITacoRecommendation}
 * @param eventType The event that is triggering the request {@see TacoTrackEventType}
 */
export const postTacoTrackEventV2: TacoTrackingThunk = (recommendation, eventType) => async (_, getState) => {
  const rallyId = selectRallyId(getState());

  const trackEventData: ITacoTrackEventPostData = {
    rallyId,
    sessionId: 'arcade-dashboard',
    campaignId: recommendation?.campaignId,
    eventType,
    placementType: recommendation?.placement?.placementType,
    timestamp: Date.now(),
    origContent: recommendation,
  };

  try {
    await TacoApi.postTacoTrackEventV2(trackEventData);
  } catch (error) {
    console.warn(`failed to send event tracking (${eventType}) for targeted content: ${formatError(error)}`);
  }
};

export const postTacoTrackEventsV2: TacoTrackingEventsV2Thunk = contentEvents => async (_, getState) => {
  const rallyId = selectRallyId(getState());

  const data = contentEvents.map(e => {
    const event: ITacoTrackEventPostData = {
      rallyId,
      sessionId: 'arcade-dashboard',
      campaignId: e.recommendation?.campaignId,
      eventType: e.eventType,
      placementType: e.recommendation?.placement?.placementType,
      timestamp: Date.now(),
      origContent: e.recommendation,
    };
    return event;
  });

  try {
    await TacoApi.postTacoTrackEventsV2(data);
  } catch (error) {
    console.warn(
      `failed to send events tracking (${contentEvents.map(e => e).join(' ')}) for targeted content: ${formatError(
        error,
      )}`,
    );
  }
};
