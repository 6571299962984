import {
  getInfoError,
  getInfoSuccess,
  getInfoLoading,
  getHeartbeatError,
  getHeartbeatLoading,
  getHeartbeatSuccess,
  getRallyPayUserTokenError,
  getRallyPayUserTokenLoading,
  getRallyPayUserTokenSuccess,
  setPlanToken as setPlanTokenReducer,
} from 'scripts/reducers/user-service-reducer';
import HttpClient from 'scripts/api/http-client';
import { UserApi } from 'scripts/api/user/user-api';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectRallyId, selectSessionIdp } from 'scripts/selectors/user-service-selectors';
import { Idp, IHeartbeatResponse } from 'scripts/api/user/user.interfaces';
import { constructParams } from 'scripts/util/uri/construct-params';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { addLoadingBarRequest, removeLoadingBarRequest } from './loading-bar-thunks';
import { addAdobeTrackingParam } from 'scripts/util/tracking/adobe-analytics';
import { RallyCaller } from 'scripts/util/constants/rally-caller.constants';
import { ssoUris } from 'scripts/util/uri/uri';
import { selectConfig } from 'scripts/selectors/app-selectors';
import { ArcadeAuthType } from 'scripts/util/constants/environment.interfaces';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { IAnnexJWTResponse, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { IPopulation as IPopulationState } from 'scripts/util/population/population.interfaces';
import { getBaseUrls } from 'scripts/util/population/population';
import { postEvents } from 'scripts/util/tracking/tracking.react';
import { selectId } from 'scripts/selectors/locale-selectors';
import { getLocale } from 'scripts/util/locale/locale';
import { PagesData } from 'scripts/config/pages-data';

export function getHeartbeat(
  heartbeatRequestId?: string,
  setHeartbeatRequestId?: React.Dispatch<React.SetStateAction<string>>,
  force: boolean = false,
): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(addLoadingBarRequest('user_heartbeat'));
      dispatch(getHeartbeatLoading());
      const prevRallyId = selectRallyId(getState());
      const response: IHeartbeatResponse = await UserApi.getHeartbeat(force);
      dispatch(getHeartbeatSuccess(response.data));
      if (setHeartbeatRequestId && response.config.$$id !== heartbeatRequestId) {
        dispatch(extendOptumSession());
        setHeartbeatRequestId(response.config.$$id);
      }
      // If previous rallyId and new rallyId are different that means different users
      if (prevRallyId && response.data.rallyId !== prevRallyId) {
        const population = selectPopulation(getState());
        const hostName = population?.baseUrls?.arcadeUrl ?? '';
        window.location.replace(`${hostName}${PagesData.logout.path}`);
      }
    } catch (error) {
      dispatch(getHeartbeatError());
      dispatch(handleAuthenticationError(error));
    } finally {
      dispatch(removeLoadingBarRequest('user_heartbeat'));
    }
  };
}

export function getInfo(force: boolean = false): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      dispatch(addLoadingBarRequest('user_info'));
      dispatch(getInfoLoading());
      const { data } = await UserApi.getInfo(force);
      dispatch(getInfoSuccess(data));
    } catch (error) {
      dispatch(getInfoError());
      dispatch(handleAuthenticationError(error));
    } finally {
      dispatch(removeLoadingBarRequest('user_info'));
    }
  };
}

interface ILyesmithSsoRedirectArgs {
  rallyCaller: RallyCaller;
  vendorId: string;
  targetUrl?: string;
  additionalAttributes?: {};
}

export function lyesmithSsoRedirect(args: ILyesmithSsoRedirectArgs): ArcadeThunkAction<void> {
  const { rallyCaller, vendorId, targetUrl, additionalAttributes = {} } = args;
  return async (_, getState) => {
    try {
      await postEvents();
      const authUrl = selectConfig(getState()).ARCADE_WEB_RALLY_AUTH_URL;
      const annexResponse = await HttpClient.post<IAnnexJWTResponse>(ssoUris.annexToken(), {
        ...additionalAttributes,
      });
      const annex = annexResponse.data.data.annex;

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `${authUrl}/ssoout`;

      const vendorIdInput = document.createElement('input');
      vendorIdInput.type = 'hidden';
      vendorIdInput.name = 'vendorId';
      vendorIdInput.value = vendorId;

      const rallyCallerInput = document.createElement('input');
      rallyCallerInput.type = 'hidden';
      rallyCallerInput.name = 'rally-caller';
      rallyCallerInput.value = rallyCaller;

      const annexInput = document.createElement('input');
      annexInput.type = 'hidden';
      annexInput.name = 'annex';
      annexInput.value = annex;

      if (targetUrl) {
        const targetUrlInput = document.createElement('input');
        targetUrlInput.type = 'hidden';
        targetUrlInput.name = 'targetUrl';
        targetUrlInput.value = targetUrl;
        form.appendChild(targetUrlInput);
      }
      form.appendChild(vendorIdInput);
      form.appendChild(rallyCallerInput);
      form.appendChild(annexInput);
      document.body.appendChild(form);

      form.submit();
    } catch (error) {
      console.warn('Error while requesting Lyesmith SSO redirect: ', error);
    }
  };
}

export function internalSsoRedirect(
  ssoUrl: string,
  rallyCaller: RallyCaller,
  target?: string,
): ArcadeThunkAction<void> {
  return async (_, getState) => {
    try {
      await postEvents();
      const sessionIdp = selectSessionIdp(getState());
      const isOfflineWeb = sessionIdp === Idp.OfflineWeb;
      const directUrl = new URL(ssoUrl);
      if (window.location.hostname === directUrl.hostname || isOfflineWeb) {
        window.location.href = directUrl.href;
      } else {
        const urlWithAdobeTracking = addAdobeTrackingParam(directUrl.href);

        const {
          data: { token: ssoToken },
        } = await UserApi.getToken(urlWithAdobeTracking);

        const authUrl = `${
          selectConfig(getState()).ARCADE_WEB_RALLY_AUTH_URL
        }/sso/v1/direct/uhcDigital?rally-caller=${rallyCaller}`;
        const form = document.createElement('form');
        const tokenInput = document.createElement('input');

        form.method = 'POST';
        form.action = authUrl;
        form.target = target || LinkTarget.Self;
        tokenInput.type = 'hidden';
        tokenInput.name = 'sso-token';
        tokenInput.value = ssoToken;
        form.appendChild(tokenInput);
        document.body.appendChild(form);
        form.submit();
      }
    } catch (error) {
      // if the response was a 403, redirect them to the unauthorized page, otherwise go to the internal error page
      if (error.status === 403) {
        const queryParams = {
          errorUID: error.data?.correlationId,
          errorReason: error.data?.code,
        };
        const unauthorizedError = `/unauthorized-error${constructParams(queryParams, false)}`;
        if (target === LinkTarget.Blank) {
          window.open(unauthorizedError);
        } else {
          window.location.href = unauthorizedError;
        }
      } else {
        const queryParams = {
          errorUID: error.data?.correlationId,
        };
        const internalErrorUrl = `/internal-error${constructParams(queryParams, false)}`;
        if (target === LinkTarget.Blank) {
          window.open(internalErrorUrl);
        } else {
          window.location.href = internalErrorUrl;
        }
      }
    }
  };
}

export function setPlanToken(planToken?: string): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    dispatch(setPlanTokenReducer(planToken));
  };
}

export function getRallyPayUserToken(): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      dispatch(getRallyPayUserTokenLoading());
      const { data } = await UserApi.getRallyPayUserToken();
      dispatch(getRallyPayUserTokenSuccess(data));
    } catch (error) {
      dispatch(getRallyPayUserTokenError());
    }
  };
}

export function extendOptumSession(): ArcadeThunkAction<void> {
  return (_, getState) => {
    try {
      const config = selectConfig(getState());
      if (config.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
        const pop = selectPopulation(getState()) || ({} as IPopulationState);
        if (pop.lineOfBusiness !== LineOfBusiness.MR) {
          const url =
            getBaseUrls(pop.lineOfBusiness, pop.membershipCategory).myUhcNoDuBaseUrl +
            config.ARCADE_WEB_MYUHC_EXTEND_SESSION_PATH;
          const hiddenImage = document.createElement('img');
          hiddenImage.setAttribute('src', url);
          hiddenImage.style.display = 'none';
          document.body.appendChild(hiddenImage);
        }
      }
    } catch (error) {
      console.warn('Could not extend Optum Session: ', error);
    }
  };
}

export function handleAuthenticationError(error: any): ArcadeThunkAction<void> {
  return (_, getState) => {
    const population = selectPopulation(getState());
    const hostName = population?.baseUrls?.arcadeUrl ?? '';

    if (error.status === 401) {
      const locale = getLocale(selectId(getState())).id;
      const params = {
        redirect: location.href,
        lob: population?.lineOfBusiness,
        membershipCategory: population?.membershipCategory,
        locale,
      };
      window.location.replace(`${hostName}/login${constructParams(params)}`);
    } else if (error.status === 403) {
      const params = {
        errorUID: error.data?.correlationId,
        errorReason: error.data?.code,
      };
      window.location.replace(`${hostName}/unauthorized-error${constructParams(params)}`);
    } else if (error.status > 399) {
      const params = {
        errorUID: error.data?.correlationId,
      };
      window.location.replace(`${hostName}/internal-error${constructParams(params)}`);
    }
  };
}
