import React, { FunctionComponent, HTMLAttributes, createRef, useEffect, ElementType } from 'react';
import DOMPurify from 'dompurify';
import { handleClickTracking } from 'scripts/hoc/with-click-tracking/with-click-tracking';

export interface IWithInnerHTMLProps {
  innerHTML: string;
}

export function withInnerHTML<T extends HTMLElement>(
  Component: ElementType,
): FunctionComponent<HTMLAttributes<T> & IWithInnerHTMLProps> {
  return ({ innerHTML, ...rest }) => {
    const onAnchorClick = (e: MouseEvent): boolean => {
      handleClickTracking(e as unknown as React.MouseEvent<HTMLAnchorElement>);
      return true;
    };
    const ref = createRef<T>();
    const sanitizedHTML = DOMPurify.sanitize(innerHTML, { ADD_ATTR: ['target'] });

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const anchorElements = ref.current.querySelectorAll('a');
      if (!anchorElements) {
        return;
      }

      const anchors = Array.from(ref.current.querySelectorAll('a'));
      anchors.forEach((anchorEl: HTMLAnchorElement) => {
        anchorEl.addEventListener('click', onAnchorClick);
        anchorEl.setAttribute('data-track-id', anchorEl.textContent);
      });

      return () => {
        anchors.forEach((anchorEl: HTMLAnchorElement) => {
          anchorEl.removeEventListener('click', onAnchorClick);
        });
      };
    }, []);

    return <Component dangerouslySetInnerHTML={{ __html: sanitizedHTML }} ref={ref} {...rest} />;
  };
}
