import { mobileOnly } from 'scripts/styles/breakpoints';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectConfig, selectFeatureFlags } from '../../selectors/app-selectors';
import { Button } from '../button/button';
import { Checkbox } from '../checkbox/checkbox';
import { ArcadeEnvironment, IFeatureFlags } from '../../util/constants/environment.interfaces';
import { featureFlagOverridesStorageKey } from '../../util/constants/feature-flag';
import { changeFeatureFlag_DEBUG_ONLY } from 'scripts/thunks/feature-flag-debug-thunk';
import { flex_spaceBetween } from 'scripts/styles/utilities';

const Portal: FunctionComponent = ({ children }) => createPortal(children, document.body);

export const FeatureFlagDrawer: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [keysPressed, setKeysPressed] = useState<string[]>([]);
  const featureFlags = useSelector(selectFeatureFlags);
  const featureFlagListMemo = useMemo(() => Object.entries(featureFlags), [featureFlags]);
  const [search, setSearch] = useState('');
  const config = useSelector(selectConfig);
  const dispatch = useDispatch();
  const drawerRef = useRef<HTMLDivElement>(null);
  // do not allow overridden feature flags in production
  const isProduction = config.ARCADE_WEB_ENVIRONMENT_NAME === ArcadeEnvironment.Production;

  useEffect(() => {
    if (keysPressed.join('') === '/ff') {
      setOpen(true);
      setKeysPressed([]);
    }
  }, [keysPressed]);

  useEffect(() => {
    if (isProduction) {
      return;
    }
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [config]);

  const handleKeydown = (event: KeyboardEvent): void => {
    setKeysPressed(keys => [...keys.slice(-2), event.key]);
    // close the drawer when the escape key is pressed
    if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const handleMouseUp = (event: MouseEvent): void => {
    // close the drawer when the user clicks outside the drawer
    if (drawerRef.current && !drawerRef.current.contains(event.target as HTMLElement)) {
      setOpen(false);
    }
  };

  const handleFlagChange =
    (featureFlag: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      // update the FF values in session storage
      const sessionStorageFlags = sessionStorage.getItem(featureFlagOverridesStorageKey) || '{}';
      const updatedFeatureFlags = {
        ...JSON.parse(sessionStorageFlags),
        [featureFlag]: event.target.checked,
      };
      sessionStorage.setItem(featureFlagOverridesStorageKey, JSON.stringify(updatedFeatureFlags));
      // update the FF values in state
      dispatch(changeFeatureFlag_DEBUG_ONLY(featureFlag as keyof IFeatureFlags));
      // reloads the page to apply the changes
      window.location.reload();
    };

  if (!open || isProduction) {
    return null;
  }

  const filteredFF = search
    ? featureFlagListMemo.filter(([key]) => key.toLowerCase().includes(search))
    : featureFlagListMemo;

  return (
    <Portal>
      <$Container ref={drawerRef} data-testid="feature-flag-drawer">
        <$Header>
          <$Input
            type="search"
            placeholder="Search"
            onChange={e => setSearch(e.target.value.toLowerCase())}
            value={search}
          />
          <Button onClick={() => setOpen(false)} variant="primary">
            <img src="images/icons/icon-close-white.svg" aria-hidden="true" />
          </Button>
        </$Header>
        <$CheckboxesContainer>
          {filteredFF
            .sort(([a], [b]) => a.localeCompare(b))
            .map(([ffKey, value]) => (
              <$CheckboxContainer key={ffKey}>
                <Checkbox
                  onChange={handleFlagChange(ffKey)}
                  label={ffKey.replace('ARCADE_FEATURES_', '')}
                  name={ffKey}
                  checked={value}
                  data-testid={value}
                />
              </$CheckboxContainer>
            ))}
        </$CheckboxesContainer>
      </$Container>
    </Portal>
  );
};

const $Container = styled.div`
  background: ${fromTheme('clrWhite')};
  box-shadow: ${fromTheme('spacing8')} 0 ${fromTheme('spacing48')} ${fromTheme('clrNeutral30')};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 450px;
  z-index: 5;
  ${mobileOnly`
    width: 100vw;
  `}
`;

const $Header = styled.div`
  border-bottom: 1px solid #ddd;
  ${flex_spaceBetween}
`;

const $CheckboxesContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const $CheckboxContainer = styled.div`
  margin: ${fromTheme('spacing8')} ${fromTheme('spacing48')} ${fromTheme('spacing8')} ${fromTheme('spacing8')};
`;

const $Input = styled.input`
  font-size: ${fromTheme('fs20')};
  padding-left: ${fromTheme('spacing8')};
  width: 50%;
`;
