import React from 'react';
import styled from 'styled-components';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { isMR } from 'scripts/util/user/user';
import { IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import {
  ITrackingEventRequest,
  TrackingClickType,
  TrackingServiceType,
  TrackingTriggerType,
} from 'scripts/api/tracking/tracking.interfaces';
import { formatTrackingString, createEventFactory } from 'scripts/util/tracking/tracking-helper';
import { queueEvent } from 'scripts/util/tracking/tracking.react';
import CONFIG from 'scripts/util/constants/config';

// JSX complains about uhc-pcp-schedule-now not existing in JSX.IntrinsicElements
declare global {
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface IntrinsicElements {
      'uhc-pcp-schedule-now': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

interface IPcpSchedulingWidgetProps {
  npi: string;
}

// Container for the PCP scheduling widget that provides styling
export const PcpSchedulingWidget = (props: IPcpSchedulingWidgetProps): JSX.Element => {
  const { npi } = props;
  return (
    <$PcpSchedulingContainer>
      <uhc-pcp-schedule-now
        data-testid="pcp-scheduling-widget-button"
        cookie-name="pcp"
        partner-code="PCP_MnR"
        data-npi={npi}
        onClick={trackPcpWidgetClick}
      ></uhc-pcp-schedule-now>
    </$PcpSchedulingContainer>
  );
};

const $PcpSchedulingContainer = styled.div`
  margin-left: 72px;

  &&&& #uhcScheduleNowButton {
    margin-bottom: ${fromTheme('spacing8')};
    align-items: center;
    background: ${fromTheme('clrWhite')};
    border: 1px solid ${fromTheme('clrBrand')};
    border-radius: ${fromTheme('br20')};
    color: ${fromTheme('clrBrand')};
    display: flex;
    flex-direction: column;
    font-weight: ${fromTheme('fwBold')};
    height: ${fromTheme('spacing24')};
    justify-content: center;
  }

  &&&& #uhcScheduleNowButton:hover:not([disabled]) {
    border: 1px solid ${fromTheme('clrPrimary')};
    color: ${fromTheme('clrPrimary')};
  }

  &&&& #uhcScheduleNowButton:disabled {
    display: none;
  }
`;

export const shouldSeePcpSchedulingWidget = (featureFlags: IFeatureFlags, currentUser: IProfileUser): boolean => {
  return featureFlags.ARCADE_FEATURES_PCP_SCHEDULING_WIDGET_ENABLED && isMR(currentUser);
};

const trackPcpWidgetClick = async (): Promise<void> => {
  try {
    const defaultEvent: ITrackingEventRequest = {
      featureList: ['pcp-scheduling-widget'],
      actionName: formatTrackingString('PCP Scheduling Widget Clicked'),
      placement: 'pcp-scheduling-widget',
      uri: window.location.pathname,
      trigger: TrackingTriggerType.Click,
      clickType: TrackingClickType.PageEvent,
      serviceVersion: CONFIG.ARCADE_WEB_VERSION,
    };

    const adobeEvent: ITrackingEventRequest = {
      ...defaultEvent,
      actionName: 'pcp-scheduling-widget',
    };

    queueEvent(createEventFactory(defaultEvent, { [TrackingServiceType.Adobe]: adobeEvent }));
  } catch (e) {
    console.warn('PCP Widget Tracking Failed: ');
  }
};
