export function constructParams(queryParams: object, encodeParams: boolean = true, url: string = null): string {
  const encodeValue = encodeParams ? encodeURIComponent : String;
  const stringValues = Object.keys(queryParams || {})
    .filter(key => !!queryParams[key])
    .map(key => {
      if (Array.isArray(queryParams[key])) {
        return queryParams[key]
          .filter(Boolean)
          .map(val => `${key}=${encodeValue(val)}`)
          .join('&');
      } else {
        return `${key}=${encodeValue(queryParams[key])}`;
      }
    })
    .filter(Boolean);
  if (stringValues.length === 0) {
    return '';
  } else if (url) {
    return url.includes('?') ? `${url}&${stringValues.join('&')}` : `${url}?${stringValues.join('&')}`;
  } else {
    return `?${stringValues.join('&')}`;
  }
}
