import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPaperlessPreference, IPaperlessPreferenceRequest } from 'scripts/api/preferences/preferences.interfaces';
import { IStateData } from './reducer.interfaces';

export interface IEpmpServiceState {
  paperlessPreference: IStateData<IPaperlessPreference>;
}

export const initialState: IEpmpServiceState = {
  paperlessPreference: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const epmpService = createSlice({
  name: 'epmpService',
  initialState,
  reducers: {
    getPaperlessPreferenceSuccess: (state: IEpmpServiceState, action: PayloadAction<IPaperlessPreference>): void => {
      state.paperlessPreference.data = action.payload;
      state.paperlessPreference.error = false;
      state.paperlessPreference.loading = false;
    },
    getPaperlessPreferenceError: (state: IEpmpServiceState): void => {
      state.paperlessPreference.data = undefined;
      state.paperlessPreference.error = true;
      state.paperlessPreference.loading = false;
    },
    getPaperlessPreferenceLoading: (state: IEpmpServiceState): void => {
      state.paperlessPreference.loading = true;
    },
    updatePaperlessPreferenceSuccess: (
      state: IEpmpServiceState,
      action: PayloadAction<IPaperlessPreferenceRequest>,
    ): void => {
      if (state.paperlessPreference.data) {
        state.paperlessPreference.data.idCardCode = action.payload.payload.selection;
      }
      state.paperlessPreference.error = false;
      state.paperlessPreference.loading = false;
    },
    updatePaperlessPreferenceError: (state: IEpmpServiceState): void => {
      state.paperlessPreference.error = true;
      state.paperlessPreference.loading = false;
    },
    updatePaperlessPreferenceLoading: (state: IEpmpServiceState): void => {
      state.paperlessPreference.loading = true;
    },
  },
});

export const {
  getPaperlessPreferenceSuccess,
  getPaperlessPreferenceError,
  getPaperlessPreferenceLoading,
  updatePaperlessPreferenceError,
  updatePaperlessPreferenceLoading,
  updatePaperlessPreferenceSuccess,
} = epmpService.actions;

export default epmpService.reducer;
