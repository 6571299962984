import { useSelector } from 'react-redux';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { selectCurrentUser } from 'scripts/selectors/profile-service-selectors';

export default function useGlobalNav(): boolean {
  const currentUser = useSelector(selectCurrentUser);
  const featureFlags = useSelector(selectFeatureFlags);

  return (
    currentUser &&
    ((currentUser.lineOfBusiness === LineOfBusiness.EI && featureFlags.ARCADE_FEATURES_GLOBAL_NAV_ENI_SDK_ENABLED) ||
      (currentUser.lineOfBusiness === LineOfBusiness.MR && featureFlags.ARCADE_FEATURES_GLOBAL_NAV_MNR_SDK_ENABLED) ||
      (currentUser.lineOfBusiness === LineOfBusiness.CS && featureFlags.ARCADE_FEATURES_GLOBAL_NAV_CNS_SDK_ENABLED))
  );
}
