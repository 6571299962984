export const isEdge = (): boolean => {
  return window.navigator.userAgent.indexOf('Edge') > -1;
};

export const isiOSSafari = (): boolean => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};

// searching for the string 'Mobi' within the user agent is the approach recommended by MDN
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const isMobile = (): boolean => {
  return /Mobi/i.test(navigator.userAgent) && screen.width <= 480;
};

export const scrollToTop = (duration: number = 250): Promise<void> => {
  const interval = 25;
  const numberOfScrolls = duration / interval;
  const amountToScroll = numberOfScrolls > 1 ? Math.ceil(window.pageYOffset / numberOfScrolls) : pageYOffset;
  return new Promise(resolve => {
    const scrollInterval = setInterval(() => {
      const newTop = Math.max(window.pageYOffset - amountToScroll, 0);
      window.scrollTo(0, newTop);
      if (newTop === 0) {
        clearInterval(scrollInterval);
        resolve();
      }
    }, interval);
  });
};

// Back Button
export const goBack = (): void => {
  window.history.back();
};

export const showBackButton = (): boolean => {
  return window.history.length > 1;
};

// From https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
export const checkSupportsPassive = (): boolean => {
  let supportsPassive = false;
  try {
    const opts = Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get() {
        supportsPassive = true;
      },
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {
    // Intentionally empty
  }

  return supportsPassive;
};

export function resetFocus(): void {
  // insert a new hidden element, focus on it, then remove it so the next tab will start from top of page
  const hiddenSpan = document.createElement('span');
  hiddenSpan.setAttribute('tabindex', '0');
  document.body.insertBefore(hiddenSpan, document.body.firstChild);
  hiddenSpan.focus();
  hiddenSpan.remove();
}
