import { applyMiddleware, createStore, Store, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducers from '../reducers';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { adobeAnalyticsMiddleware } from 'scripts/middleware/adobe-analytics-middleware';
import FEATURE_FLAGS from 'scripts/util/constants/feature-flag';

const middlewares = [thunk, adobeAnalyticsMiddleware];
if (FEATURE_FLAGS.ARCADE_FEATURES_REDUX_LOGGING && process.env.NODE_ENV !== 'test') {
  middlewares.push(
    createLogger({
      collapsed: true,
      duration: true,
    }),
  );
}

export const configureStore = (preloadedState = {}, enhancer?: typeof compose): Store<IReduxState> => {
  if (enhancer) {
    return createStore(rootReducers, preloadedState, enhancer);
  }
  return createStore(rootReducers, preloadedState, applyMiddleware(...middlewares));
};

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'arcade-ui app',
      })
    : compose;
const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  // other store enhancers if any
);
const store = configureStore(undefined, enhancer);

export default store;
