import React, { FunctionComponent } from 'react';
import { SharedFooter } from '../shared-header-footer/shared-footer';
import useGlobalNav from 'scripts/hooks/use-global-nav/use-global-nav';
import GlobalFooter from '../global-nav-header-footer/global-footer';
import { IGlobalNavContainer } from '../chrome-container/chrome-container';

export const FooterContainer: FunctionComponent<IGlobalNavContainer> = props => {
  const { readyToDisplay, isDashboard } = props;
  return (useGlobalNav() && <GlobalFooter />) || ((readyToDisplay || !isDashboard) && <SharedFooter />);
};
