import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFeatureFlags, selectConfig } from 'scripts/selectors/app-selectors';
import { selectCurrentUser } from 'scripts/selectors/profile-service-selectors';
import { selectPrimaryCareProviderInfoState } from 'scripts/selectors/primary-care-provider-selectors';
import { useConditionalDataLoader } from 'beta-dashboard/dashboard/beta-dashboard-utils';
import { getPrimaryCareProviderInfo } from 'scripts/thunks/primary-care-provider-thunks';
import { ProfileApi } from 'scripts/api/profile/profile-api';
import { shouldSeePcpSchedulingWidget } from 'scripts/features/pcp-scheduling-widget/pcp-scheduling-widget';

export function usePcpScheduling(): void {
  const featureFlags = useSelector(selectFeatureFlags);
  const currentUser = useSelector(selectCurrentUser);
  const config = useSelector(selectConfig);
  const [hasLoadedScript, setHasLoadedScript] = useState(false);
  const [hasAuthenticated, setHasAuthenticated] = useState(false);

  const {
    data: primaryCareProviderInfo,
    loading: primaryCareProviderInfoLoading,
    error: primaryCareProviderInfoError,
  } = useSelector(selectPrimaryCareProviderInfoState);
  useConditionalDataLoader(
    primaryCareProviderInfo,
    primaryCareProviderInfoLoading,
    primaryCareProviderInfoError,
    getPrimaryCareProviderInfo,
    !!currentUser,
  );

  useEffect(() => {
    const authenticateWidget = async (npi): Promise<void> => {
      await ProfileApi.setPcpSchedulingAuth({ issuer: 'arcade-ui', expiresIn: 36000, npiId: npi });
      setHasAuthenticated(true);
    };

    // only attempt to auth and load PCP script if FF is on and user is MR.
    if (shouldSeePcpSchedulingWidget(featureFlags, currentUser)) {
      // Waits until primaryCareProviderInfo is available
      if (!hasAuthenticated && primaryCareProviderInfo) {
        const npi = primaryCareProviderInfo.selectedUserPrimaryCareProviderInfo?.npi;
        if (npi) {
          authenticateWidget(npi);
        }
      }

      // only load script after the PCP scheduling auth call has been made
      if (hasAuthenticated && !hasLoadedScript) {
        const pcpSchedulingScript = document.createElement('script');
        pcpSchedulingScript.src = config.ARCADE_WEB_PCP_SCHEDULING_WIDGET_URL;
        pcpSchedulingScript.setAttribute('data-testid', 'pcp-scheduling-widget-script');
        pcpSchedulingScript.nonce = 'NGINX_NONCE';
        document.head.appendChild(pcpSchedulingScript);
        setHasLoadedScript(true);
      }
    }
  }, [primaryCareProviderInfo, hasLoadedScript, hasAuthenticated]);
}
