import { createSelector } from '@reduxjs/toolkit';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IAppState } from 'scripts/reducers/app-reducer';
import { ArcadeWebTheme } from 'scripts/util/constants/environment.interfaces';

const selectApp = (state: IReduxState): IAppState => state.app;

export const selectConfig = createSelector(selectApp, app => app?.config);

export const selectRedesignEnabledThemes = createSelector(
  selectConfig,
  config =>
    config.ARCADE_WEB_REDESIGN_THEMES?.split(',')
      .map(theme => theme.trim())
      .map(theme => theme as ArcadeWebTheme)
      .reduce((enabledThemes, theme) => {
        enabledThemes[theme] = true;
        return enabledThemes;
      }, {}) || {},
);

export const selectTheme = createSelector(selectApp, app => app?.theme);

export const selectThemeName = createSelector(selectConfig, config => config.ARCADE_WEB_THEME);

export const selectFeatureFlags = createSelector(selectApp, app => app?.featureFlags);

export const selectLoadingBarRequests = createSelector(selectApp, app => app?.loadingBar.requests);

export const selectAnalyticsInitialization = createSelector(selectApp, app => app?.analyticsInitialization);

export const selectCurrentPageAnalyticsData = createSelector(selectApp, app => app?.currentPageAnalyticsData);

export const selectPreviousPageAnalyticsData = createSelector(selectApp, app => app?.previousPageAnalyticsData);

export const selectDashboardReadyToDisplay = createSelector(selectApp, app => app?.dashboardReadyToDisplay);

/**
 * A selector for checking whether to log event to rally analytics.
 * (should be removed/reworked when redundant as part of GEN-195)
 */
export const selectShouldLogToRallyAnalytics = createSelector(
  selectFeatureFlags,
  flags => flags.ARCADE_FEATURES_RALLY_ANALYTICS,
);
