import { MutableRefObject } from 'react';
import { TrackingTriggerType } from 'scripts/api/tracking/tracking.interfaces';
import {
  getFeatureList,
  getCampaignTrackingInfo,
  getTrackingEventRequest,
} from 'scripts/util/tracking/tracking-helper';
import { ICampaign } from 'scripts/api/targeting/targeting.interfaces';
import { queueEvent } from './tracking.react';

export function sendSectionLoad(
  trackLabel: string,
  trackData: any,
  featureList: string[],
  ref: MutableRefObject<HTMLElement>,
  isError?: boolean,
  startTime?: number,
): void {
  if (trackLabel) {
    const fullFeatureList = getFeatureList(ref.current).concat(featureList);
    if (fullFeatureList.length > 0) {
      trackData.rspTime = startTime ? new Date().getTime() - startTime : 0;
      const trigger = isError ? TrackingTriggerType.SectionFailed : TrackingTriggerType.SectionLoad;
      const event = getTrackingEventRequest(trackLabel, trigger, ref.current, fullFeatureList, trackData);
      queueEvent(event, false);
    }
  }
}

export function sendImpressionTracking(
  trackLabel: string,
  featureList: string[],
  ref: MutableRefObject<HTMLElement>,
  campaign?: ICampaign,
): void {
  const fullFeatureList = getFeatureList(ref.current).concat(featureList);
  const trigger = TrackingTriggerType.View;

  let campaignInfo;
  if (campaign && campaign.campaignId) {
    campaignInfo = getCampaignTrackingInfo(campaign);
  }

  const trackData = campaignInfo;
  const campaignTrackingInfo = campaignInfo;

  const event = getTrackingEventRequest(
    trackLabel,
    trigger,
    ref.current,
    fullFeatureList,
    trackData,
    campaignTrackingInfo,
  );
  queueEvent(event, true);
}
