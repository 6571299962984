import {
  ITrackingEventRequest,
  ICampaignTrackingInfo,
  TrackingServiceType,
  ITrackingEventRequestFactory,
  TrackingTriggerType,
} from 'scripts/api/tracking/tracking.interfaces';
import { trackFeatures } from '../constants/track-features';
import { ICampaign } from 'scripts/api/targeting/targeting.interfaces';
import CONFIG from '../constants/config';

export function formatCamelCasedTrackingString(str: string): string {
  return !str ? 'undefined' : str.replace(/([A-Z])/g, match => `-${match[0].toLowerCase()}`);
}

export function formatTrackingString(str: string): string {
  return !str ? 'undefined' : str.toLowerCase().split(' ').join('-').split('_').join('-');
}

export function getCampaignTrackingInfo(campaign: ICampaign): ICampaignTrackingInfo {
  return {
    campaignId: campaign.campaignId,
    placementType: campaign.placementType,
  };
}

export function getPlacement(element: HTMLElement): string {
  const body: HTMLElement = window.document.body;
  const html: HTMLElement = window.document.documentElement;
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  const offset = (element?.getBoundingClientRect().top || 0) + window.pageYOffset;
  return Math.round((100 * offset) / height) + '%';
}

function getParentsWithTrackFeature(elem: HTMLElement): HTMLElement[] {
  const originalElem = elem;
  const parentElementsWithTrackFeature = [];

  while (elem) {
    if (elem !== originalElem && elem.hasAttribute('track-feature')) {
      parentElementsWithTrackFeature.push(elem);
    }
    elem = elem.parentElement;
  }

  return parentElementsWithTrackFeature;
}

function getTrackFeatureValue(trackFeatureKey: string): string {
  return trackFeatures[trackFeatureKey];
}

function getTrackFeatureKey(trackFeatureAttribute: string): string {
  return trackFeatureAttribute.substring('$track.features.'.length);
}

// Evaluates function or variable with its accompanying scope
function getDynamicTrackFeatureValue(trackFeatureAttr: string, $scope: ng.IScope): string {
  return $scope.$eval(trackFeatureAttr);
}

function getTrackFeature(trackFeatureAttr: string, $scope?: ng.IScope): string {
  const trackFeatureKey = getTrackFeatureKey(trackFeatureAttr);
  const trackFeatureValue = getTrackFeatureValue(trackFeatureKey);

  // if undefined, either the key is not in feature.values
  // or it's a dynamic value which needs to be interpreted in the scope of its controller
  if (trackFeatureValue === undefined && $scope !== undefined) {
    const dynamicTrackFeatureValue = getDynamicTrackFeatureValue(trackFeatureAttr, $scope);
    const dynamicTrackFeature = getTrackFeatureValue(dynamicTrackFeatureValue);
    return dynamicTrackFeature;
  }

  return trackFeatureValue;
}

export function getFeatureList(element: HTMLElement, $scope?: ng.IScope): string[] {
  const featureList = [];
  const parentsWithTrackFeature = getParentsWithTrackFeature(element);

  parentsWithTrackFeature.forEach(elem => {
    const trackFeatureAttr = elem.getAttribute('track-feature');
    const trackFeature = getTrackFeature(trackFeatureAttr, $scope);
    featureList.push(formatTrackingString(trackFeature));
  });

  return featureList.reverse();
}

export function stringifyData(event: ITrackingEventRequest): void {
  // Currently, data needs to be string for the service to accept the request.
  if (event.data !== undefined && typeof event.data !== 'string') {
    event.data = JSON.stringify(event.data);
  }
}

export function getTrackingEventRequest(
  trackLabel: string,
  trigger: TrackingTriggerType,
  element: HTMLElement,
  featureList?: string[],
  trackData?: any,
  campaignTrackingInfo?: ICampaignTrackingInfo,
): ITrackingEventRequest {
  const event = {
    trigger,
    uri: window.location.toString(),
    serviceVersion: CONFIG.ARCADE_WEB_VERSION,
    featureList: featureList || getFeatureList(element),
    actionName: formatTrackingString(trackLabel),
    placement: getPlacement(element),
    data: trackData || undefined,
    campaignTrackingInfo: campaignTrackingInfo || undefined,
  } as ITrackingEventRequest;

  return event;
}

export function createScriptTag(
  src: string,
  id: string,
  parentNode: HTMLElement = document.getElementsByTagName('head')[0],
): void {
  if (document.getElementById(id)) {
    return;
  }

  const scriptTag = document.createElement('script');
  scriptTag.src = src;
  scriptTag.type = 'text/javascript';
  scriptTag.id = id;
  scriptTag.async = true;
  scriptTag.nonce = 'NGINX_NONCE';
  parentNode.appendChild(scriptTag);
}

export function createEventFactory(
  defaultEvent: ITrackingEventRequest,
  customEvents?: Partial<Record<TrackingServiceType, ITrackingEventRequest>>,
): ITrackingEventRequestFactory {
  return (serviceType: TrackingServiceType) => (customEvents && customEvents[serviceType]) || defaultEvent;
}
