import { connectServiceUris } from 'scripts/util/uri/uri';
import HttpClient from '../http-client';
import { PartnerId, LastIndexedCoverageType, ILastIndexedResponse } from './connect-service.interfaces';

function getLastIndexed(partnerId: PartnerId, coverageTypes: LastIndexedCoverageType[]): Promise<ILastIndexedResponse> {
  const url = connectServiceUris.lastIndexed(partnerId, coverageTypes);
  return HttpClient.get<ILastIndexedResponse>(url);
}

export const ConnectApi = {
  getLastIndexed,
};
