/* eslint-disable max-classes-per-file */
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { IAxiosRequestConfig } from 'scripts/api/api.interfaces';
import { getCache, getCacheKey } from 'scripts/api/cache';
import { LocaleKey } from '../util/constants/i18n.constants';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { getFhCookie } from 'scripts/util/session/fhCookie';
import { XSRF_HEADER_NAME } from 'scripts/api/xsrf';
import {
  clearArcadeCacheSessionStorageItems,
  clearArcadeSessionStorageItems,
} from 'scripts/util/session/clear-session-storage';
import { PLAN_TOKEN_KEY } from 'scripts/util/constants/cookies.constant';
import Cookies from 'js-cookie';
const NO_CACHE_KEY = 'nocache';

function getNoCacheValue(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(NO_CACHE_KEY) && urlParams.get(NO_CACHE_KEY) === 'true';
}

function removeNoCacheValue(): void {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(NO_CACHE_KEY)) {
    urlParams.delete(NO_CACHE_KEY);
    const queryString = urlParams.toString();
    // get path without hash ('#/path/more-path?query=param&another' becomes '/path/more-path')
    const path = window.location.hash ? window.location.hash.substr(1).split('?')[0] : window.location.pathname;
    const newUrl = queryString ? path + '?' + queryString : path;
    window.history.replaceState(null, '', newUrl);
  }
}

export function hasRpTokenSuffix(Environment: IEnvironmentConstants): boolean {
  const length = Environment.CONFIG.ARCADE_WEB_LWR_RP_SUFFIX?.trim().length || 0;
  return length > 0;
}

export function getRpTokenSuffix(Environment: IEnvironmentConstants): string {
  const suffix = Environment.CONFIG.ARCADE_WEB_LWR_RP_SUFFIX?.trim();
  if (!suffix) {
    console.warn(
      'ARCADE_WEB_LWR_RP_SUFFIX is unset but getRpTokenSuffix was invoked to get the suffix, indicating an intention' +
        'to add the suffix as a header to the request.',
    );
  }
  return suffix || '';
}

export const axiosRequestInterceptor = (Environment: IEnvironmentConstants) => (config: IAxiosRequestConfig) => {
  const { headers, method, cacheName, localizedCacheKey, url, ignoreCache } = config;
  const hasRpSuffix = hasRpTokenSuffix(Environment);
  headers['Arcade-Locale'] = localStorage.getItem(LocaleKey);
  if (hasRpTokenSuffix(Environment)) {
    headers['RP-Token-Suffix'] = getRpTokenSuffix(Environment);
  }

  if (Environment.FEATURE_FLAGS.ARCADE_FEATURES_LWR_XSRF_HEADER_ENABLED) {
    // XSRF-Token is the SessionId (first part) of the FH cookie
    let fhCookieName = 'FH';

    if (hasRpSuffix) {
      fhCookieName += `_${getRpTokenSuffix(Environment)}`;
    }

    const sessionId = getFhCookie(fhCookieName)?.sessionId;

    if (sessionId !== undefined || sessionId !== '') {
      config.headers[XSRF_HEADER_NAME] = sessionId;
    } else {
      console.warn(`${fhCookieName} cookie was not found, so XSRF-Token header was not set.`);
    }
  }

  if (method === 'get' && cacheName && !ignoreCache) {
    const cache = getCache(cacheName);
    const planToken = Cookies.get(PLAN_TOKEN_KEY);
    const cacheKey = getCacheKey(url, localizedCacheKey, planToken);
    const cachedResponse = cache && cache.get(cacheKey);
    if (cachedResponse) {
      config.adapter = () => Promise.resolve({ ...cachedResponse, config }) as AxiosPromise;
    }
  }

  return config;
};

export function axiosResponseInterceptor(response: AxiosResponse): AxiosResponse {
  const { config }: { config: IAxiosRequestConfig } = response;
  const { method, cacheName, maxAge, url, localizedCacheKey } = config;
  if (method === 'get' && response.status < 300 && cacheName) {
    const cache = getCache(cacheName);
    if (cache) {
      const planToken = Cookies.get(PLAN_TOKEN_KEY);
      const cacheKey = getCacheKey(url, localizedCacheKey, planToken);
      if (maxAge) {
        cache.put(cacheKey, response, { maxAge });
      } else {
        cache.put(cacheKey, response);
      }
    }
  }
  return response;
}

export const initializeApi = (Environment: IEnvironmentConstants, rallyId: string): void => {
  const noCache = getNoCacheValue();
  axios.defaults.xsrfHeaderName = 'Arcade-XSRF-Token';
  axios.defaults.xsrfCookieName = 'Arcade-XSRF-Token';
  axios.defaults.withCredentials = true;
  axios.defaults.withXSRFToken = true;
  axios.defaults.headers.Accept = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
  axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';
  if (noCache) {
    axios.defaults.headers['Cache-Control'] = 'no-cache';
    clearArcadeSessionStorageItems(rallyId);
    clearArcadeCacheSessionStorageItems();
  }
  axios.interceptors.request.use(axiosRequestInterceptor(Environment));
  axios.interceptors.response.use(axiosResponseInterceptor);

  removeNoCacheValue();
};
