import { MutableRefObject, useEffect } from 'react';

interface IUseMutationObserverProps {
  callback: MutationCallback;
  options: MutationObserverInit;
  ref: MutableRefObject<HTMLElement | null>;
}

export const useMutationObserver = (props: IUseMutationObserverProps): void => {
  const { callback, options, ref } = props;
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [callback, options]);
};
