import { createSelector } from '@reduxjs/toolkit';
import { ILivePersonState } from 'scripts/reducers/live-person-reducer';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';

const selectLivePerson = (state: IReduxState): ILivePersonState => state.livePerson;

export const selectLivePersonSegmentationInfo = createSelector(
  selectLivePerson,
  livePerson => livePerson.segmentationInfo,
);

export const selectLivePersonChatStarted = createSelector(
  selectLivePerson,
  livePerson => livePerson.livePersonChatStarted,
);
