import { css } from 'styled-components';
import {
  SupportedInterpolations,
  ThemedPropsToFlattenInterpolation,
  ThemedPropsToString,
} from 'scripts/styles/themes/themes.interfaces';
import { fromTheme } from 'scripts/styles/themes/themes.utils';

type BreakpointFunctionGenerator = (
  getMediaPropString: ThemedPropsToString,
) => (
  args: TemplateStringsArray,
  ...interpolations: Array<SupportedInterpolations>
) => ThemedPropsToFlattenInterpolation;

/**
 * Helper function to make creating breakpoint function easier. Accepts a function
 * that returns a string that will be used in the '@media' query.
 */
const breakpointFunctionGenerator: BreakpointFunctionGenerator = getMediaPropString => {
  return (args, ...interpolations) => {
    return props => {
      const mediaProps = getMediaPropString(props);
      const evaluatedInterpolations = interpolations.map(interpolation =>
        typeof interpolation === 'function' ? interpolation(props) : interpolation,
      );
      return css`
        @media ${mediaProps} {
          ${css(args, ...evaluatedInterpolations)}
        }
      `;
    };
  };
};

/* BREAKPOINTS
  Some quick notes about our breakpoints. The views as validated from Figma should be...

  widescreen-desktop: 1335+ | grid columns are 2fr 1fr
  desktop: 1334-1048 | grid columns are 2fr 1fr (the last column has a size of 33%)
  tablet-landscape: 1047-768 | grid columns are 2fr 1fr. Mobile view starts here
  tablet-portrait: 767-540 | grid columns SHRINKS to 1fr 1fr.
  mobile: 539-320 | grid columns SHRINKS to 1fr

  Our default css should be appied to the Desktop view so we *should* never need a desktop only media query hence it is excluded.
  Please only use the marked media queries for the beta-dashboard.
  Instead of using a breakpoint such as not-phone set the style at the top level and add a phone-only query and unset that css prop.
*/

// Active when the width is 1336px <= x to accommodate widescreen desktop views and a 2fr 1fr grid
export const widescreenOnly = breakpointFunctionGenerator(({ theme }) => {
  const widescreenMinWidth = fromTheme('widescreenMinWidth', theme);
  return `(min-width: ${widescreenMinWidth}px)`;
});

// No 'Desktop Only' View

// Active when the width is x <= 1047x to accommodate mobile views (ALL tablet AND phone views), a 2fr 1fr, and a 1fr 1fr grid
export const mobileOnly = breakpointFunctionGenerator(({ theme }) => {
  const tabletMaxWidth = fromTheme('tabletMaxWidth', theme);
  return `(max-width: ${tabletMaxWidth}px)`;
});

// Active when the width is between 1047px >= x >= 768px to accommodate tablet landscape views and a 2fr 1fr grid
export const tabletLandscapeOnly = breakpointFunctionGenerator(({ theme }) => {
  const tabletLandscapeMinWidth = fromTheme('tabletLandscapeMinWidth', theme);
  const tabletLandscapeMaxWidth = fromTheme('tabletLandscapeMaxWidth', theme);
  return `(min-width: ${tabletLandscapeMinWidth}px) and (max-width: ${tabletLandscapeMaxWidth}px)`;
});

// Active when the width is x <= 767px to accommodate mobile views (tablet AND phone views) a 1fr 1fr grid
export const phoneAndTabletPortraitOnly = breakpointFunctionGenerator(({ theme }) => {
  const tabletPortraitMaxWidth = fromTheme('tabletPortraitMaxWidth', theme);
  return `(max-width: ${tabletPortraitMaxWidth}px)`;
});

// Active when the width is between 767px >= x >= 540px to accommodate tablet potrait views and a 1fr 1fr grid
export const tabletPortraitOnly = breakpointFunctionGenerator(({ theme }) => {
  const tabletPortraitMinWidth = fromTheme('tabletPortraitMinWidth', theme);
  const tabletPortraitMaxWidth = fromTheme('tabletPortraitMaxWidth', theme);
  return `(min-width: ${tabletPortraitMinWidth}px) and (max-width: ${tabletPortraitMaxWidth}px)`;
});

// Active when the width is x <= 539px to accommodate phone views only and a 1fr grid
export const phoneOnly = breakpointFunctionGenerator(({ theme }) => {
  const phoneMaxWidth = fromTheme('phoneMaxWidth', theme);
  return `(max-width: ${phoneMaxWidth}px)`;
});

// Active when the width is x <= 320px to accomodate small phone view only and a 1fr grid
export const smallPhoneOnly = breakpointFunctionGenerator(({ theme }) => {
  const smallPhoneMaxWidth = fromTheme('smallPhoneMaxWidth', theme);
  return `(max-width: ${smallPhoneMaxWidth}px)`;
});

/* ============ Old Breakpoints - AVOID USING IN BETA-DASHBOARD ============ */

export const tabletOnly = breakpointFunctionGenerator(({ theme }) => {
  const tabletMinWidth = fromTheme('tabletPortraitMinWidth', theme);
  const tabletMaxWidth = fromTheme('tabletLandscapeMaxWidth', theme);
  return `(min-width: ${tabletMinWidth}px) and (max-width: ${tabletMaxWidth}px)`;
});

export const desktopOnly = breakpointFunctionGenerator(({ theme }) => {
  const desktopMinWidth = fromTheme('desktopMinWidth', theme);
  return `(min-width: ${desktopMinWidth})`;
});

export const printOnly = breakpointFunctionGenerator(() => {
  return 'print';
});
