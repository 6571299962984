import React, { FunctionComponent } from 'react';
import { FeatureContext } from 'scripts/contexts/feature-context/feature-context';

export interface IFeatureProps {
  featureId: string;
  children: React.ReactNode;
}
// Consumes the featureList from parent providers and renders a new provider with
// the value of featureId appended to the array. If there are no parent providers,
// featureList will be [] as specified by the arg passed to createContext above.
export const Feature: FunctionComponent<IFeatureProps> = ({ featureId, children }) => (
  <FeatureContext.Consumer>
    {value => (
      <FeatureContext.Provider value={{ featureList: value.featureList.concat([featureId]) }}>
        {children}
      </FeatureContext.Provider>
    )}
  </FeatureContext.Consumer>
);
