import { IIdCard, IIdCardsResponse } from '../../../api/plans/plans.interfaces';
import { IPlanCoverage } from '../../../api/profile/profile.interfaces';

export interface ICoverageSection extends IPlanCoverage {
  cards: IIdCard[];
  flipped: boolean;
  idCardsRequest: Promise<IIdCardsResponse>;
  planName: string;
  planType: string;
  template: string;
  selectedDepSeqNum?: string;
  showGroupNumber?: boolean;
  showCarveOutLink?: boolean;
}

export const IPlanTypes = {
  MEDICAL: 'MEDICAL_PLAN',
  VISION: 'VISION_PLAN',
  DENTAL: 'DENTAL_PLAN',
  BEHAVIORAL_HEALTH: 'BH_PLAN',
  RX: 'DRUG_PLAN',
};

export enum CardSide {
  Front = 'view-front',
  Back = 'view-back',
}

export type TIdCardHeaderId = string;

export const YEAR_2023 = 2023;
