import { createSelector } from '@reduxjs/toolkit';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { ITacoState } from 'scripts/reducers/taco-service-reducer';

const selectTaco = (state: IReduxState): ITacoState => state.taco;

/**
 * Campaigns
 */
const selectTacos = createSelector(selectTaco, taco => taco.tacos);

export const selectTacosData = createSelector(selectTacos, tacos => tacos.data);

export const selectTacosLoading = createSelector(selectTacos, tacos => tacos.loading);

export const selectTacosError = createSelector(selectTacos, tacos => tacos.error);
