import React, {
  AnchorHTMLAttributes,
  forwardRef,
  ForwardRefExoticComponent,
  FunctionComponent,
  PropsWithoutRef,
  Ref,
  RefAttributes,
} from 'react';
import { useSelector } from 'react-redux';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { parse } from 'scripts/util/uri/parse';
import withClickTracking, { handleClickTracking } from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { IWhitelabelable } from '../theme/whitelabel.interfaces';
import { RdsLink } from '@rally/energon-react';
import { LinkProps as RdsLinkProps } from '@rally/energon-react/dist/esm/Link/Link.interface';
import { useSeeYouLater } from 'scripts/hooks/use-see-you-later/use-see-you-later';
import { SEE_YOU_LATER_DESINATION_LOCAL_STORAGE_KEY } from 'scripts/features/modals/see-you-later/see-you-later';
/**
 * The set of properties used by our Anchor component.
 */
export interface IAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement>, IWhitelabelable, RdsLinkProps {
  /**
   * a forwarded reference to the HTML element in the link.
   */
  forwardedRef?: Ref<HTMLAnchorElement>;
  /**
   * A flag that when set to true, will suppress the New Window icon, or in the case of an RDS-enabled anchor, will not default to the
   * EXTERNAL_INLINE variant.
   */
  noNewWindowIcon?: boolean;
  /**
   * A flag that when true, suppresses the addition of the rel property to the link.
   */
  noRel?: boolean;
  /**
   * Dynamic onboarding ID, for the dashboard tour to highlight
   */
  dynamiconboardingid?: string;

  /** A flag when set to true, the plan token is included in a href */
  includePlanToken?: string | undefined;
}

/**
 * Takes a component and wraps that component with handling for the See You Later page.
 * @param WrappedComponent The {@link RawAnchor}-derived component to wrap.
 * @param seeYouLaterUri The URI to use during the see-you-later action.
 * @param seeYouLaterDestinationUri The URI to store in localStorage/that the see-you-later page will ultimately navigate to
 * @param hasClickTracking A flag that when true, tells the See You Later click handler to account for click tracking.
 */
function withSeeYouLaterEnabled<T extends IAnchorProps>(
  WrappedComponent: React.ComponentType<T>,
  seeYouLaterUri: string,
  seeYouLaterDestinationUri: string,
  hasClickTracking: boolean,
  originalOnClick?: (event: React.MouseEvent) => void,
): React.ComponentType<T> {
  return props => {
    const handleSeeYouLaterClick = (event: React.MouseEvent): void => {
      // preventDefault so that the link does not open in a new tab and we link out to /see-you-later in a new tab below
      event.preventDefault();

      // fire the original onClick since we'll swap out that parameter with this handleSeeYouLaterClick function
      if (originalOnClick && typeof originalOnClick === 'function') {
        originalOnClick(event);
      }

      // setting the see you later destination in local storage to access on the /see-you-later route
      localStorage.setItem(SEE_YOU_LATER_DESINATION_LOCAL_STORAGE_KEY, seeYouLaterDestinationUri);
      window.open(seeYouLaterUri);
    };
    const propsWithSeeYouLater = {
      ...props,
      target: LinkTarget.Blank,
      onClick: hasClickTracking
        ? event => handleClickTracking(event, event => handleSeeYouLaterClick(event))
        : event => handleSeeYouLaterClick(event),
    };
    return <WrappedComponent {...propsWithSeeYouLater}></WrappedComponent>;
  };
}

/**
 * Renders an Anchor component.
 * uses {@link RdsLink}.
 * @param props The {@link IAnchorProps} for the component
 * @constructor
 */
export const RawAnchor: FunctionComponent<IAnchorProps> = props => {
  const {
    children,
    forwardedRef,
    noNewWindowIcon,
    noRel,
    target,
    className,
    variant,
    typographySize,
    role,
    includePlanToken,
    ...rest
  } = props;
  /**
   * if the seeYouLater intermediary page is required we always open in a new tab, setting the link target to blank so that click tracking
   * explicity recognizes this and does not hit the condition where linkTarget is undefined and does not equal blank which leads to
   * window.location.href being set to the anchor href
   *
   * otherwise, the link target will be the passed target and either undefined or the explicit passed target
   */
  const isBlank = target === LinkTarget.Blank;
  const externalLink = !noNewWindowIcon && isBlank;
  const rel = !noRel && isBlank ? 'noopener noreferrer' : undefined;
  const commonLinkProps = {
    ref: forwardedRef,
    rel,
    target,
    ...rest,
  };

  // make link to have a button-like behavior to navigate on pressed Space key when it has button role
  const navigateOnSpaceKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>): void => {
    if (event.key === ' ') {
      // Prevent scrolling when spacebar is used on a link with role="button"
      event.preventDefault();
      event.currentTarget.click();
    }
  };

  const handleKeyDown = role === 'button' ? navigateOnSpaceKeyDown : undefined;

  const sendPlanTokenFF = useSelector(selectFeatureFlags)?.ARCADE_FEATURES_SEND_PLAN_TOKEN;

  if (includePlanToken && sendPlanTokenFF) {
    const hrefURL = parse(commonLinkProps.href);
    const urlParams = new URLSearchParams(hrefURL.search);

    urlParams.append('plan-token', includePlanToken);
    commonLinkProps.href = `${hrefURL.pathname}?${urlParams.toString()}`;
  }

  // Use the EXTERNAL variant if a variant is not explicitly specified.
  const rdsVariant = variant || (externalLink ? 'external-inline' : undefined);
  return (
    <RdsLink
      variant={rdsVariant}
      className={className}
      typographySize={typographySize}
      onKeyDown={handleKeyDown}
      role={role}
      {...commonLinkProps}
    >
      {children}
    </RdsLink>
  );
};

/**
 * Wraps an anchor component with a check for whether or not the anchor should use a "See you later page"
 * @param WrappedComponent The component that may be wrapped using {@link withSeeYouLaterEnabled}
 * @param isClickTrackingEnabled a boolean flag that when set, signals that the seeYouLater click event should account for click tracking.
 */
function maybeWithSeeYouLater<T extends IAnchorProps>(
  WrappedComponent: React.ComponentType<T>,
  isClickTrackingEnabled: boolean,
) {
  return props => {
    const { href, onClick } = props;
    const { seeYouLaterRequired, seeYouLaterUri } = useSeeYouLater(href);
    if (seeYouLaterRequired) {
      const SeeYouLaterEnabled = withSeeYouLaterEnabled(
        WrappedComponent,
        seeYouLaterUri,
        href,
        isClickTrackingEnabled,
        onClick,
      );
      return <SeeYouLaterEnabled {...props} />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };
}

/**
 * Type alias for the anchorWithForwardRef return type, makes the {@link anchorWithForwardRef} funciton definition fit onto
 * a single line.
 */
type AnchorWithForwardRef<T> = ForwardRefExoticComponent<PropsWithoutRef<T> & RefAttributes<HTMLAnchorElement>>;
/**
 * Common anchor with forwardRef, used by Anchor and AnchorWithClickTracking
 */
function anchorWithForwardRef<T>(
  Anchor: React.ComponentType<T>,
  isClickTrackingEnabled: boolean,
): AnchorWithForwardRef<T> {
  return forwardRef((props: T, ref: Ref<HTMLAnchorElement>) => {
    const AnchorWithForwardRef = maybeWithSeeYouLater(Anchor, isClickTrackingEnabled);
    return <AnchorWithForwardRef forwardedRef={ref} {...props}></AnchorWithForwardRef>;
  });
}

/**
 * A plain Anchor, for cases when tracking for a link is not desired. In most cases {@link AnchorWithClickTracking} should be used
 * @param props
 * @constructor
 */
export const Anchor = anchorWithForwardRef(RawAnchor, false);

/**
 * An anchor component enhanced with click-tracking, which sends data to the tracking service.
 * @param props The component properties
 * @constructor
 */
export const AnchorWithClickTracking = anchorWithForwardRef(withClickTracking(RawAnchor), true);
