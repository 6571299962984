import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as AppReducer from 'scripts/reducers/app-reducer';
import {
  incrementLoadingBarRequests,
  decrementLoadingBarRequests,
  ILoadingBarRequest,
} from 'scripts/reducers/app-reducer';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectLoadingBarRequests } from 'scripts/selectors/app-selectors';

export function changeNavOpen(navOpen: boolean): ArcadeThunkAction<void> {
  return dispatch => {
    if (navOpen) {
      document.body.classList.add('nav-open');
    } else {
      document.body.classList.remove('nav-open');
    }
    dispatch(AppReducer.changeNavOpen(navOpen));
  };
}

export function addLoadingBarRequest(reqName: string): ArcadeThunkAction<void> {
  return (dispatch, getState) => {
    dispatch(incrementLoadingBarRequests(reqName));
    const requests = selectLoadingBarRequests(getState());

    // dispatch showLoading when the first request begins to load and the round of requests has not yet loaded once.
    // i.e. we do not want to show the loading bar if a request is loading for a second time.
    if (!roundComplete(requests) && numLoading(requests) === 1) {
      dispatch(showLoading());
    }
  };
}

export function removeLoadingBarRequest(reqName: string): ArcadeThunkAction<void> {
  return (dispatch, getState) => {
    // set 1000ms timeout so that the loading bar loads for at least 1000ms (any less looks bad)
    setTimeout(() => {
      dispatch(decrementLoadingBarRequests(reqName));
      const requests = selectLoadingBarRequests(getState());
      if (roundComplete(requests)) {
        dispatch(hideLoading());
      }
    }, 1000);
  };
}

// A round of requests if all requests have completed at least once
function roundComplete(requests: Record<string, ILoadingBarRequest>): boolean {
  return Object.values(requests).every(r => r.completed > 0);
}

// num requests loading
function numLoading(requests: Record<string, ILoadingBarRequest>): number {
  return Object.values(requests).reduce((s, r) => s + (r.started - r.completed), 0);
}
