import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { Anchor, IAnchorProps } from './anchor';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getResource } from 'scripts/util/resource/resource';

export interface IResourceAnchorProps extends Omit<IAnchorProps, 'href'> {
  population: IPopulation;
  resourceAddendum?: string;
  resourceValue: IResource;
}

export const RawResourceAnchor: FunctionComponent<IResourceAnchorProps> = props => {
  /*
   * Population is being destructed to remove from ...rest to avoid passing onto the Anchor below.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { population, resourceAddendum, resourceValue, ...rest } = props;

  const href = `${getResource(resourceValue, population)}${resourceAddendum || ''}`;
  return <Anchor href={href} {...rest} />;
};

export const ResourceAnchor = connect(
  (state: IReduxState) => ({
    population: state.population,
  }),
  {},
)(RawResourceAnchor);

export const ResourceAnchorWithClickTracking = withClickTracking(ResourceAnchor);
