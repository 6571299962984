export enum LocaleCountry {
  Haiti = 'HT',
  Philippines = 'PH',
  Taiwan = 'TW',
  UnitedStates = 'US',
  Vietnam = 'VN',
  Somalia = 'SO',
  Burmese = 'MM',
}

export enum LocaleLanguage {
  ChineseTraditional = 'zh',
  English = 'en',
  HaitianCreole = 'ht',
  Ilocano = 'il',
  Karen = 'kc',
  Korean = 'ko',
  Spanish = 'es',
  Tagalog = 'tl',
  Vietnamese = 'vi',
  Soomaali = 'so',
  LusHmoob = 'hm',
  Burmese = 'my',
}

export interface ILocale {
  id: string;
  language: LocaleLanguage;
  country: LocaleCountry | null;
}
