import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAlerts,
  IClientConfig,
  ICampaigns,
  IRealTimeOfferCount,
  IStateRecommendations,
  IRecommendation,
  IApiRecommendations,
  ISessionRecommendations,
  ICsCustomClientContent,
  ITacoRecommendationsResponse,
  ITacos,
  ICampaign,
} from 'scripts/api/targeting/targeting.interfaces';
import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { changeLocale } from './locale-reducer';

export interface ITargetingState {
  alerts: IStateData<IAlerts>;
  arcadeDashboardBannerCampaign: IStateData<ICampaign>;
  campaigns: IStateData<ICampaigns>;
  clientConfig: IStateData<IClientConfig>;
  csFaqCustomizations: IStateData<ICsCustomClientContent>;
  realTimeOfferCount: IStateData<IRealTimeOfferCount>;
  recommendations: IStateData<IRecommendation[]>;
  statusUpdate?: Promise<any>;
  _stateRecommendations: IStateRecommendations;
  tacoRecommendations: IStateData<ITacos>;
}

/**
 * The key to use when persisting recommendations to the session store.
 */
export const RecommendationsStorageKey = 'arcade.recommendations';

/**
 * Returns a new {@see IStateData} instance in a default state.
 */
export function DEFAULT_STATE<TData>(): IStateData<TData> {
  return {
    data: undefined,
    error: undefined,
    loading: false,
  };
}

/**
 * Returns a new {@see IStateData} instance marked as errored.
 * @param data Optional parameter.  Most cases will clear the current state data on error.
 */
export function ERRORED_STATE<TData>(data: TData | undefined = undefined): IStateData<TData> {
  return {
    data: data,
    error: true,
    loading: false,
  };
}

/**
 * Returns a new {@see IStateData} instance with the loading and error flags set off.
 * @param data The data to set in the state.
 */
export function SUCCESS_STATE<TData>(data: TData): IStateData<TData> {
  return {
    data,
    error: false,
    loading: false,
  };
}

/**
 * returns a set of default values to use for values of type {@see IStateRecommendations} in the Redux state.
 */
export function DEFAULT_STATE_RECOMMENDATIONS(): IStateRecommendations {
  return {
    recommendations: {},
    order: [],
  };
}

export const initialState: ITargetingState = {
  alerts: DEFAULT_STATE(),
  arcadeDashboardBannerCampaign: DEFAULT_STATE(),
  campaigns: DEFAULT_STATE(),
  clientConfig: DEFAULT_STATE(),
  csFaqCustomizations: DEFAULT_STATE(),
  realTimeOfferCount: DEFAULT_STATE(),
  recommendations: DEFAULT_STATE(),
  _stateRecommendations: DEFAULT_STATE_RECOMMENDATIONS(),
  statusUpdate: undefined,
  tacoRecommendations: DEFAULT_STATE(),
};

/**
 * Initializes the _stateRecommendations property using a collection of {@see IRecommendation} items.  if the
 * recommendation is already present in the collection loaded in the current state, the new value is ignored.  Otherwise,
 * the recommendation is inserted into the dictionary of {@see IRecommendation} by recommendation key
 * ({@see IStateRecommendations.getKey}). New entries are also added to a collection that's used to represent ordering
 * of the recommendations, since the backend is responsible for setting the sort order.
 * @param state The current {@see ITargetingState} from Redux.
 * @param recommendations The collection of {@see IRecommendation} items to load.
 */
function loadedRecommendations(state: ITargetingState, recommendations: IRecommendation[]): void {
  recommendations.forEach(r => {
    const key = r.offerKey;
    if (!state._stateRecommendations.recommendations[key]) {
      state._stateRecommendations.recommendations[key] = r;
      state._stateRecommendations.order.push(key);
    }
  });
}

/**
 * Initializes the redux state using the result of the an API call.  {@see IRecommendationCampaign} items do not have
 * fields for dismissed, loading, and showError, which are all used by the application but have no meaning to the
 * backend.
 * @param state The {@see ITargetingState} from Redux.
 * @param payload An {@see IApiRecommendations} response from the Targeting API.
 */
function loadedRecommendationsFromApi(state: ITargetingState, payload: IApiRecommendations): void {
  loadedRecommendations(
    state,
    payload.realTimeOffers.map(r => {
      return {
        ...r,
        dismissed: false,
        loading: false,
        showError: false,
      };
    }),
  );
}

/**
 * Initializes the redux state using the result of the an API call.  {@see IRecommendationCampaign} items do not have
 * fields for dismissed, loading, and showError, which are all used by the application but have no meaning to the
 * backend.
 * @param state The {@see ITargetingState} from Redux.
 * @param payload The {@see ISessionRecommendations} stored on the current session.
 */
function loadedRecommendationsFromSession(state: ITargetingState, payload: ISessionRecommendations): void {
  loadedRecommendations(state, payload.recommendations);
}

/**
 * Updates a single recommendation in the current Redux state.  Individual recommendations may get updated
 * when a user clicks on the CTA link or when the user dismisses a recommendation.
 * @param state The {@see ITargetingState} from Redux.
 * @param recommendation A {@see IRecommendation} item to update.
 */
function updatedRecommendation(state: ITargetingState, recommendation: IRecommendation): void {
  const key = recommendation.offerKey;
  if (state._stateRecommendations.recommendations[key]) {
    state._stateRecommendations.recommendations[key] = recommendation;
  }
}

/**
 * Creates a new instance of {@see ISessionRecommendations} from the current {@see IStateRecommendations} instance.
 * The {@see IStateRecommendations} is designed in such a way that concurrent updates are allowed against different
 * {@see IRecommendation} items, with the idea being that the
 *
 * @param state The current {@see ITargetingState} instance from Redux.
 * @param localeId The Locale ID of the current session.
 *
 * @todo Since we listen to the @{see changeLocale} event, we may not need to store locale in the session recommendations.
 */
function aggregateRecommendations(state: ITargetingState, localeId: string): ISessionRecommendations {
  const recommendations = state._stateRecommendations.order.map(
    key => state._stateRecommendations.recommendations[key],
  );
  const result = {
    numActive: recommendations.reduce((ac, r) => (r.dismissed ? ac : ac + 1), 0),
    localeId: localeId,
    recommendations: recommendations,
  };
  return result;
}

/**
 * Aggregates the current set of {@see IStateRecommendations} into a set of {@see IRecommendation} instances and
 * updates the session store.
 * @param state The current {@see ITargetingState} instance from Redux.
 * @param localeId The Locale ID of the current session.
 * @returns an {@see IStateData} instance with the set of {@see IRecommendation} instances and with loading/error set
 * to false.
 */
function aggregateAndUpdateRecommendations(state: ITargetingState, localeId: string): IStateData<IRecommendation[]> {
  const sessionRecommendations = aggregateRecommendations(state, localeId);
  sessionStorage.setItem(RecommendationsStorageKey, JSON.stringify(sessionRecommendations));
  return SUCCESS_STATE(sessionRecommendations.recommendations);
}

const targeting = createSlice({
  name: 'targeting',
  initialState,
  reducers: {
    getAlertsSuccess: (state: ITargetingState, action: PayloadAction<IAlerts>): void => {
      state.alerts = SUCCESS_STATE(action.payload);
    },
    getAlertsLoading: (state: ITargetingState): void => {
      state.alerts.loading = true;
    },
    getAlertsError: (state: ITargetingState): void => {
      state.alerts = ERRORED_STATE();
    },
    getClientConfigSuccess: (state: ITargetingState, action: PayloadAction<IClientConfig>): void => {
      state.clientConfig = SUCCESS_STATE(action.payload);
    },
    getClientConfigLoading: (state: ITargetingState): void => {
      state.clientConfig.loading = true;
    },
    getClientConfigError: (state: ITargetingState): void => {
      state.clientConfig = ERRORED_STATE();
    },
    getCampaignsSuccess: (state: ITargetingState, action: PayloadAction<ICampaigns>): void => {
      // ARC-10158
      // campaigns may be called several times with a different CampaignPlacementType, we want the campaign placements from a previous call
      // to persist in state when a call with a different CampaignPlacementType is made instead of overriding the campaign placements.
      if (state.campaigns.data?.placements) {
        state.campaigns.data = {
          placements: {
            ...state.campaigns.data.placements,
            ...action.payload.placements,
          },
        };
      } else {
        state.campaigns.data = action.payload;
      }
      state.campaigns.error = false;
      state.campaigns.loading = false;
    },
    getCampaignsLoading: (state: ITargetingState): void => {
      state.campaigns.loading = true;
    },
    getCampaignsError: (state: ITargetingState): void => {
      state.campaigns = ERRORED_STATE();
    },
    getTacoRecommendationsSuccess: (
      state: ITargetingState,
      action: PayloadAction<ITacoRecommendationsResponse>,
    ): void => {
      // Taco recommendations may be called several times with different placement types, all of which we might need.
      // So when we get new recs we add them to the existing recs by placement type.
      if (state.tacoRecommendations.data) {
        state.tacoRecommendations.data = {
          ...state.tacoRecommendations.data,
          ...action.payload.recommendations,
        };
      } else {
        state.tacoRecommendations.data = action.payload.recommendations;
      }
      state.tacoRecommendations.error = false;
      state.tacoRecommendations.loading = false;
    },
    getTacoRecommendationsLoading: (state: ITargetingState): void => {
      state.tacoRecommendations.loading = true;
    },
    getTacoRecommendationsError: (state: ITargetingState): void => {
      state.tacoRecommendations = ERRORED_STATE();
    },
    getCsFaqCustomizationsSuccess: (state: ITargetingState, action: PayloadAction<ICsCustomClientContent>): void => {
      state.csFaqCustomizations = SUCCESS_STATE(action.payload);
    },
    getCsFaqCustomizationsLoading: (state: ITargetingState): void => {
      state.csFaqCustomizations.loading = true;
    },
    getCsFaqCustomizationsError: (state: ITargetingState): void => {
      state.csFaqCustomizations = ERRORED_STATE();
    },
    getRealTimeOfferCountSuccess: (state: ITargetingState, action: PayloadAction<IRealTimeOfferCount>): void => {
      state.realTimeOfferCount = SUCCESS_STATE(action.payload);
    },
    getRealTimeOfferCountLoading: (state: ITargetingState): void => {
      state.realTimeOfferCount.loading = true;
    },
    getRealTimeOfferCountError: (state: ITargetingState): void => {
      state.realTimeOfferCount = ERRORED_STATE();
    },
    getRecommendationsSuccess: (state: ITargetingState, action: PayloadAction<string>): void => {
      state.recommendations = aggregateAndUpdateRecommendations(state, action.payload);
    },
    getRecommendationsLoading: (state: ITargetingState): void => {
      state.recommendations.loading = true;
    },
    getRecommendationsError: (state: ITargetingState): void => {
      state.recommendations = ERRORED_STATE();
    },
    recommendationsLoadedFromSession: (
      state: ITargetingState,
      action: PayloadAction<ISessionRecommendations>,
    ): void => {
      loadedRecommendationsFromSession(state, action.payload);
    },
    recommendationsLoadedFromApi: (state: ITargetingState, action: PayloadAction<IApiRecommendations>): void => {
      loadedRecommendationsFromApi(state, action.payload);
    },
    recommendationUpdated: (state: ITargetingState, action: PayloadAction<IRecommendation>): void => {
      updatedRecommendation(state, action.payload);
    },
    updatingRecommendationStatuses: (state: ITargetingState, action: PayloadAction<Promise<any>>): void => {
      state.statusUpdate = action.payload;
    },
  },
  extraReducers: {
    [changeLocale.toString()]: (state: ITargetingState): void => {
      state.campaigns = DEFAULT_STATE();
      // When locales are changed, we want to reload recommendations for the new locale, so we need to clear the current
      // state and session stores.
      state.recommendations = DEFAULT_STATE();
      state._stateRecommendations = DEFAULT_STATE_RECOMMENDATIONS();
      state.csFaqCustomizations = DEFAULT_STATE();
      sessionStorage.setItem(RecommendationsStorageKey, null);
    },
  },
});

export const {
  getAlertsSuccess,
  getAlertsLoading,
  getAlertsError,
  getCampaignsError,
  getCampaignsLoading,
  getCampaignsSuccess,
  getClientConfigError,
  getClientConfigLoading,
  getClientConfigSuccess,
  getCsFaqCustomizationsError,
  getCsFaqCustomizationsLoading,
  getCsFaqCustomizationsSuccess,
  getRealTimeOfferCountError,
  getRealTimeOfferCountLoading,
  getRealTimeOfferCountSuccess,
  getRecommendationsLoading,
  getRecommendationsSuccess,
  getRecommendationsError,
  getTacoRecommendationsLoading,
  getTacoRecommendationsSuccess,
  getTacoRecommendationsError,
  recommendationsLoadedFromApi,
  recommendationsLoadedFromSession,
  recommendationUpdated,
  updatingRecommendationStatuses,
} = targeting.actions;

export default targeting.reducer;
