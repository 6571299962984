import Backend from 'i18next-xhr-backend';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import CONFIG from 'scripts/util/constants/config';
import { resources } from './resources';

const options = {
  loadPath: '../../i18n/{{lng}}/{{ns}}.json',
  queryStringParams: { v: CONFIG.ARCADE_WEB_VERSION },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: options,
    defaultNS: Dictionary.COMMON,
    fallbackLng: 'en-US',
    fallbackNS: Dictionary.COMMON,
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    load: 'currentOnly',
    ns: Dictionary.COMMON,
    react: {
      useSuspense: false,
    },
  });

// preload the resources for the english so we don't get a flash of un-translated text
resources.forEach(([dictionary, content]) => {
  i18n.addResources('en-US', dictionary, content);
});

export default i18n;
