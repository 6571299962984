import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStateData } from './reducer.interfaces';
import {
  IActivatePlans,
  IActivatePlanStepV9,
  IActivatePlanSubStep,
  IActivatePlanV9,
} from 'scripts/api/activate/activate.interfaces';
import cloneDeep from 'lodash.clonedeep';

const updateCurrentStepInFlows = (
  flows: IActivatePlanV9[],
  action: PayloadAction<IActivatePlanStepV9>,
): IActivatePlanV9[] => {
  flows.find((flow, flowIndex) => {
    flow.steps.find((step, stepIndex) => {
      if (action.payload.stepId === step.stepId) {
        flows[flowIndex].steps[stepIndex] = { ...action.payload };
      }
      return action.payload.stepId === step.stepId;
    });
  });

  return flows;
};

const updateCurrentSubStepInFlows = (
  flows: IActivatePlanV9[],
  action: PayloadAction<IActivatePlanSubStep>,
): IActivatePlanV9[] => {
  flows.find((flow, flowIndex) => {
    flow.steps.find((step, stepIndex) => {
      step.subSteps.find((subStep, subStepIndex) => {
        if (action.payload.subStepId === subStep.subStepId) {
          flows[flowIndex].steps[stepIndex].subSteps[subStepIndex] = {
            ...action.payload,
          };
        }
        return action.payload.subStepId === subStep.subStepId;
      });
    });
  });

  return flows;
};

export interface IActivateState {
  steps: IStateData<IActivatePlans>;
}

export const initialState: IActivateState = {
  steps: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const activate = createSlice({
  name: 'activate',
  initialState,
  reducers: {
    getStepsSuccess: (state: IActivateState, action: PayloadAction<IActivatePlans>): void => {
      state.steps.data = action.payload;
      state.steps.error = false;
      state.steps.loading = false;
    },
    stepsLoading: (state: IActivateState): void => {
      state.steps.loading = true;
    },
    stepsError: (state: IActivateState, action?: PayloadAction<IActivatePlanStepV9>): void => {
      state.steps.error = true;
      state.steps.loading = false;

      if (action?.payload) {
        const { flows } = state.steps.data;
        const clonedFlows = cloneDeep(flows);
        state.steps.data.flows = updateCurrentStepInFlows(clonedFlows, action);
      }
    },
    updateStep: (state: IActivateState, action: PayloadAction<IActivatePlanStepV9>): void => {
      const { flows } = state.steps.data;
      const clonedFlows = cloneDeep(flows);

      state.steps.data.flows = updateCurrentStepInFlows(clonedFlows, action);
      state.steps.error = false;
      state.steps.loading = false;
    },
    subStepError: (state: IActivateState, action?: PayloadAction<IActivatePlanSubStep>): void => {
      state.steps.error = true;
      state.steps.loading = false;

      if (action?.payload) {
        const { flows } = state.steps.data;
        const clonedFlows = cloneDeep(flows);

        state.steps.data.flows = updateCurrentSubStepInFlows(clonedFlows, action);
      }
    },
    updateSubStep: (state: IActivateState, action: PayloadAction<IActivatePlanSubStep>): void => {
      const { flows } = state.steps.data;
      const clonedFlows = cloneDeep(flows);

      state.steps.data.flows = updateCurrentSubStepInFlows(clonedFlows, action);
      state.steps.error = false;
      state.steps.loading = false;
    },
  },
});

export const { stepsLoading, getStepsSuccess, stepsError, updateStep, subStepError, updateSubStep } = activate.actions;

export default activate.reducer;
