import React, { MutableRefObject, ReactElement } from 'react';

export const addFuncToReactChildren = (
  children: ReactElement | ReactElement[],
  func: () => void,
  funcName: string,
): ReactElement | ReactElement[] => {
  return React.Children.map(children, child => {
    // We need this isValidElement check in order to make typescript happy
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { [funcName]: func });
    }
  });
};

export const filterVisibleElements = (elements: HTMLElement[]): HTMLElement[] => {
  const visibleElements = [];
  elements.forEach(element => {
    if (element.offsetWidth > 0 || element.offsetHeight > 0) {
      visibleElements.push(element);
    }
  });
  return visibleElements;
};

export const getTabElements = (parent?: HTMLElement, isShiftKey?: boolean): HTMLElement[] => {
  parent = parent || document.body;
  const possibilities =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), ' +
    'textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
  const elements = parent.querySelectorAll(possibilities);
  const tabElements = [];
  elements.forEach(element => {
    if (element.getAttribute('tabindex') !== '-1') {
      tabElements.push(element);
    }
  });
  return filterRadioButton(tabElements, isShiftKey);
};

const filterRadioButton = (tabElements: HTMLElement[], isShiftKey: boolean): HTMLElement[] => {
  const radioButtonGroupDict = {};
  const filteredTabElements = [];
  (isShiftKey ? tabElements.reverse() : tabElements).forEach(element => {
    if (element instanceof HTMLInputElement && element.type === 'radio') {
      if (radioButtonGroupDict[element.name] === undefined) {
        radioButtonGroupDict[element.name] = filteredTabElements.length;
        filteredTabElements.push(element);
      } else if (element.checked) {
        filteredTabElements[radioButtonGroupDict[element.name]] = element;
      }
    } else {
      filteredTabElements.push(element);
    }
  });
  return isShiftKey ? filteredTabElements.reverse() : filteredTabElements;
};
export const handleFocusAssignment = (
  event: KeyboardEvent,
  tabElements: HTMLElement[],
  focusInModal: boolean,
): void => {
  const firstTab = tabElements[0];
  const lastTab = tabElements[tabElements.length - 1];
  if (event.shiftKey && event.target === firstTab) {
    lastTab.focus();
    event.preventDefault();
  } else if ((!event.shiftKey && event.target === lastTab) || !focusInModal) {
    firstTab.focus();
    event.preventDefault();
  }
};

export const trapFocus = (event: KeyboardEvent, modalRef: MutableRefObject<HTMLElement>): void => {
  const modal = modalRef.current;
  const tabElements = filterVisibleElements(getTabElements(modal, event.shiftKey));
  const focusInModal = modalRef.current.contains(document.activeElement);
  handleFocusAssignment(event, tabElements, focusInModal);
};

export const getReturnFocusTo = (previouslyFocusedElement?: Element): HTMLElement | null => {
  if (previouslyFocusedElement && previouslyFocusedElement instanceof HTMLElement) {
    // If the previously focused element was a dropdown list item, instead return the dropdown's toggle.
    const dropdown = previouslyFocusedElement.closest('[data-testid="dropdown"]');
    if (dropdown) {
      const dropdownToggle = dropdown.querySelector('[data-testid="toggle-button"]');
      return dropdownToggle instanceof HTMLElement && dropdownToggle;
    } else {
      return previouslyFocusedElement;
    }
  }
  return null;
};

export const focusFirstFocusableElement = (modalRef: MutableRefObject<HTMLElement>): void => {
  const tabElements = getTabElements(modalRef.current);
  tabElements.length && tabElements[0].focus();
};
