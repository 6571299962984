import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { small, body3 } from 'scripts/styles/typography';
import { css } from 'styled-components';
import { mobileOnly } from './breakpoints';

const button = css`
  align-items: center;
  border-radius: ${fromTheme('spacing24')};
  display: flex;
  height: ${fromTheme('spacing40')};
  justify-content: center;
  text-decoration: none;
  transition: color 0.4s, border-color 0.4s;
  &:focus {
    outline: 4px solid ${fromTheme('clrOlympicBlue')};
  }
  &:hover {
    text-decoration: none;
  }
`;

export const primaryButton = css`
  ${body3}
  ${button}
  background-color: ${fromTheme('clrBrand')};
  border: 1px solid ${fromTheme('clrBrand')};
  color: ${fromTheme('clrWhite')};
  &:hover {
    background-color: ${fromTheme('clrPrimary')};
    border-color: ${fromTheme('clrPrimary')};
    color: ${fromTheme('clrWhite')};
  }
  &:active {
    background-color: ${fromTheme('clrWhite')};
    border-color: ${fromTheme('clrPrimary')};
    color: ${fromTheme('clrPrimary')};
  }
  &:visited {
    color: ${fromTheme('clrWhite')};
    &:hover {
      background-color: ${fromTheme('clrPrimary')};
      border-color: ${fromTheme('clrPrimary')};
      color: ${fromTheme('clrWhite')};
    }
  }
`;

export const secondaryButton = css`
  ${body3}
  ${button}
  background-color: ${fromTheme('clrWhite')};
  border: 1px solid ${fromTheme('clrBrand')};
  color: ${fromTheme('clrBrand')};
  &:hover {
    background-color: ${fromTheme('clrWhite')};
    border-color: ${fromTheme('clrPrimary')};
    color: ${fromTheme('clrPrimary')};
  }
  &:active {
    background-color: ${fromTheme('clrPrimary')};
    border-color: ${fromTheme('clrPrimary')};
    color: ${fromTheme('clrWhite')};
  }
  &:focus {
    background-color: ${fromTheme('clrWhite')};
    border-color: ${fromTheme('clrBrand')};
    color: ${fromTheme('clrBrand')};
  }
  &:visited {
    color: ${fromTheme('clrBrand')};
    &:hover {
      background-color: ${fromTheme('clrWhite')};
      border-color: ${fromTheme('clrPrimary')};
      color: ${fromTheme('clrPrimary')};
    }
  }
`;

export const smallButton = css`
  ${small}
  height: ${fromTheme('spacing24')};
`;

export interface IButtonStyleProps {
  $variant?: 'primary' | 'secondary';
  $size?: 'small';
}

const smallPayNowButton = css`
  ${smallButton}
  & {
    min-width: 95px;
  }
`;

export const PayNowFakeButton = css<IButtonStyleProps>`
  ${({ $variant }) => ($variant === 'secondary' ? secondaryButton : primaryButton)}
  ${({ $size }) => ($size === 'small' ? smallPayNowButton : undefined)}
  font-weight: ${fromTheme('fwBold')};
  ${mobileOnly`
    font-weight: ${fromTheme('fwBold')};
  `} // overrides body3 styles from secondaryButton
  /* place the button on top of the clickable .claim-view-link */
  position: relative;
  min-width: 136px;
`;
