export function injectTransperfectScript(src: string): void {
  if (document.getElementById('translations-script')) {
    return;
  }
  const script = document.createElement('script');
  script.id = 'translations-script';
  script.type = 'text/javascript';
  script.setAttribute('data-oljs', 'P962C-9031-0D50-22FD');
  script.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');
  script.src = src;
  document.head.appendChild(script);
}
