import i18n from 'scripts/i18n';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectId } from 'scripts/selectors/locale-selectors';
import { getLocale } from 'scripts/util/locale/locale';
import * as LocaleReducer from 'scripts/reducers/locale-reducer';
import { LocaleKey } from 'scripts/util/constants/i18n.constants';
import { setLocale } from 'scripts/util/tracking/rally-analytics';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { selectedUser as selectedUserSelector } from 'scripts/selectors/profile-service-selectors';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';

export function changeLocale(localeId: string): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    const currentLocaleId = selectId(getState());
    const newLocaleId = getLocale(localeId).id;
    const enableTransperfect = selectFeatureFlags(getState()).ARCADE_FEATURES_TRANSPERFECT_TRANSLATION_ENABLED;
    const enableCnsTransperfect = selectFeatureFlags(getState()).ARCADE_FEATURES_TRANSPERFECT_TRANSLATION_CNS_ENABLED;
    const lineOfBusiness = selectedUserSelector.selectLineOfBusiness(getState());
    if (newLocaleId !== currentLocaleId) {
      const newLocale = getLocale(newLocaleId);
      dispatch(LocaleReducer.changeLocale(newLocale));
      !(
        (lineOfBusiness === LineOfBusiness.MR && enableTransperfect) ||
        (lineOfBusiness === LineOfBusiness.CS && enableCnsTransperfect)
      ) && i18n.changeLanguage(newLocaleId);
      // Needed to persist Locale through refreshes and navigation to other Applications
      localStorage.setItem(LocaleKey, newLocale.id);
      setLocale(newLocale);
    }
  };
}
