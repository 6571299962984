import { isLinkToClaimsUi } from 'scripts/util/uri/uri';
import { parse } from 'scripts/util/uri/parse';
import { formatTrackingString, getFeatureList, getPlacement } from 'scripts/util/tracking/tracking-helper';
import {
  ITrackingEventRequest,
  TrackingClickType,
  TrackingTriggerType,
} from 'scripts/api/tracking/tracking.interfaces';
import CONFIG from 'scripts/util/constants/config';
import { FeatureContext } from 'scripts/contexts/feature-context/feature-context';
import { LinkTarget } from 'scripts/api/api.interfaces';
import React from 'react';
import { postEvents } from 'scripts/util/tracking/tracking.react';
import { useDispatch, useSelector } from 'react-redux';
import {
  captureEventTrigger,
  logExternalPageNav,
  logModalPageNav,
  logPageInteraction,
} from 'scripts/thunks/analytics-thunks';
import { ArcadeThunkDispatch } from 'scripts/reducers/reducer.interfaces';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';

export function getClickType(element: HTMLElement): TrackingClickType {
  const clickType = element.getAttribute('data-click-type');
  if (clickType) {
    return clickType as TrackingClickType;
  }
  const href = element.getAttribute('href');
  return href ? TrackingClickType.StateChange : TrackingClickType.PageEvent;
}

function callOriginalOnClick(
  event: React.MouseEvent<HTMLElement>,
  originalOnClick?: (event: React.MouseEvent<HTMLElement>) => void,
): void {
  if (originalOnClick && typeof originalOnClick === 'function') {
    originalOnClick(event);
  }
}

export function handleClickTracking(
  event: React.MouseEvent<HTMLElement>,
  originalOnClick?: (event: React.MouseEvent<HTMLElement>) => void,
  dispatch?: ArcadeThunkDispatch,
  isRallyAnalyticsOn?: boolean,
  skipPageInteractionEvent: boolean = false,
): void {
  const currentTarget = event && event.currentTarget;
  const href = currentTarget && currentTarget.getAttribute('href');
  const linkTarget = currentTarget && currentTarget.getAttribute('target');
  const clickType = currentTarget && getClickType(currentTarget);
  const featureListString = currentTarget.getAttribute('data-feature-list');
  const featureList = getFeatureList(currentTarget).concat(featureListString ? featureListString.split(',') : []);
  const placement = currentTarget ? getPlacement(currentTarget) : '';

  const clickEvent: ITrackingEventRequest = {
    actionName: formatTrackingString(currentTarget.getAttribute('data-track-id')),
    featureList,
    placement,
    serviceVersion: CONFIG.ARCADE_WEB_VERSION,
    trigger: TrackingTriggerType.Click,
    uri: window.location.pathname,
    additionalProperties: {},
  };

  const additionalProperties = currentTarget.getAttribute('data-additional-properties');
  const additionalPropertiesObj = additionalProperties ? JSON.parse(additionalProperties) : {};
  if (additionalProperties) {
    clickEvent.additionalProperties = {
      ...clickEvent.additionalProperties,
      ...JSON.parse(additionalProperties),
    };
  }

  const anchor = parse(href);
  const isOutsideDomain = anchor.hostname !== window.location.hostname;
  const isInternalRedirect = anchor.pathname.split('/').pop() === 'internal-redirect';
  const isExternalLink =
    isInternalRedirect ||
    linkTarget === LinkTarget.Blank ||
    isOutsideDomain ||
    clickType === TrackingClickType.ExternalLink ||
    isLinkToClaimsUi(href);
  const isModalLink = anchor.pathname.split('/').includes('modal');

  if (href) {
    if (isExternalLink) {
      clickEvent.clickType = TrackingClickType.ExternalLink;
      if (isInternalRedirect) {
        const decodedUrl = href.split('deepLink=').pop();
        const redirectUrlAnchor = document.createElement('a');
        redirectUrlAnchor.href = decodedUrl;
        clickEvent.externalDomain = redirectUrlAnchor.hostname;
        clickEvent.externalUrl = decodedUrl.split('?')[0];
      } else {
        clickEvent.externalDomain = anchor.hostname;
        clickEvent.externalUrl = anchor.href.split('?')[0];
      }
    } else {
      clickEvent.clickType = clickType || TrackingClickType.StateChange;
    }
  } else {
    clickEvent.clickType = clickType || TrackingClickType.PageEvent;
  }

  if (isRallyAnalyticsOn) {
    dispatch(captureEventTrigger(event));

    /**
     * Rally analytics has a different definition of "external".
     * For us, we just want to know if it's outside of our app. Rally Analytics wants to know if we're leaving our domain.
     * If we are leaving the member.uhc.com domain, send external page nav event. Otherwise, just log as page interaction.
     */
    if (isExternalLink) {
      if (isOutsideDomain) {
        dispatch(logExternalPageNav(href));
      } else {
        dispatch(logPageInteraction({ URL: href }));
      }
    }
  }

  // Eventually, use-page-tracking on the destination page will handle these events.
  // But until CE is implemented sitewide,
  // we'll send a custom version of the page nav event for Beta Dashboard here.
  if (isRallyAnalyticsOn && href && !isExternalLink) {
    if (isModalLink) {
      dispatch(logModalPageNav());
    } else {
      dispatch(logPageInteraction());
    }
  }

  // Handle click events on internal links
  // or non-links that trigger some activity on the page
  if (!skipPageInteractionEvent && isRallyAnalyticsOn && (!href || window.location.pathname === anchor.pathname)) {
    dispatch(logPageInteraction(additionalPropertiesObj));
  }

  if (isExternalLink && linkTarget !== LinkTarget.Blank) {
    event.persist?.();
    event.preventDefault();

    postEvents()
      .then(() => {
        callOriginalOnClick(event, originalOnClick);
        if (!isInternalRedirect) {
          window.location.href = href;
        }
      })
      .catch(error => {
        console.error(error);
        callOriginalOnClick(event, originalOnClick);
        if (!isInternalRedirect) {
          window.location.href = href;
        }
      });
  } else {
    callOriginalOnClick(event, originalOnClick);
  }
}

export interface ITrackedProps {
  dataClickType?: TrackingClickType;
  dataTrackId: string;
  dataAdditionalProperties?: Record<string, unknown>;
  dataUiElementName?: string;
  href?: string;
  target?: string;
  onClick?: () => void;
  onKeyPress?: () => void;
  alreadyCapturesEvent?: boolean;
  skipPageInteractionEvent?: boolean;
}

function enhanceComponentWithClickTracking<T extends ITrackedProps>(
  WrappedComponent: React.ComponentType,
): React.ComponentType<T> {
  const WithClickTrackingComponent: React.FunctionComponent<T> = ({
    dataClickType,
    dataTrackId,
    dataAdditionalProperties,
    dataUiElementName,
    onClick,
    skipPageInteractionEvent = false,
    ...props
  }) => {
    const dispatch = useDispatch();
    const isRallyAnalyticsOn = useSelector(selectFeatureFlags).ARCADE_FEATURES_RALLY_ANALYTICS;
    const handleClick = (e?: React.MouseEvent<HTMLElement>): void => {
      handleClickTracking(e, onClick, dispatch, isRallyAnalyticsOn, skipPageInteractionEvent);
    };
    return (
      <FeatureContext.Consumer>
        {value => (
          <WrappedComponent
            data-click-type={dataClickType}
            data-feature-list={value.featureList}
            data-track-id={dataTrackId}
            data-ui-element-name={dataUiElementName}
            data-additional-properties={dataAdditionalProperties && JSON.stringify(dataAdditionalProperties)}
            onClick={handleClick}
            {...props}
          />
        )}
      </FeatureContext.Consumer>
    );
  };

  return WithClickTrackingComponent;
}

export interface IClickTrackingProps {
  dataTrackId: string;
  dataClickType?: TrackingClickType;
  dataAdditionalProperties?: Record<string, unknown>;
  dataUiElementName?: string;
  skipPageInteractionEvent?: boolean;
}

export default function withClickTracking<T>(WrappedComponent: React.ComponentType<T>): React.ComponentType<
  T & {
    dataTrackId: string;
    dataClickType?: TrackingClickType;
    dataAdditionalProperties?: Record<string, unknown>;
    dataUiElementName?: string;
    skipPageInteractionEvent?: boolean;
  }
> {
  const TrackedComponent = enhanceComponentWithClickTracking(WrappedComponent);
  return props => {
    return <TrackedComponent {...props} />;
  };
}
