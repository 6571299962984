import 'core-js/stable';
import 'regenerator-runtime/runtime';
// Using mdn-polyfills for polyfills not covered by core-js (e.g. Element)
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/Node.prototype.remove';
import {
  applyPolyfills,
  defineCustomElements as defineCustomIncentiveElements,
} from '@rally/incentives-dashboard/dist/loader';

applyPolyfills().then(() => {
  defineCustomIncentiveElements(window);
});

// Polyfills for @rally/arcade-ui-web-components
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js';

import 'custom-event-polyfill';

// prevents Safari from showing cached content when history.back()/history.forward() or the Back/Forward browser buttons are used;
// fixes ARC-12023, where new translations are not displaying on pages in Safari's history stack after the locale is changed
if (/apple/i.test(navigator.vendor)) {
  window.addEventListener('pageshow', e => {
    if (e.persisted) location.reload();
  });
}
