import styled from 'styled-components';
import { body2 } from 'scripts/styles/typography';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { mobileOnly } from 'scripts/styles/breakpoints';

export const $ExplanationModalBody = styled.div`
  margin-bottom: -${fromTheme('spacing16')};
  h2 {
    ${body2}
    border-bottom: 1px solid ${fromTheme('clrNeutral80')};
    color: ${fromTheme('clrGreyDark')};
    font-weight: ${fromTheme('fwSemiBold')};
    line-height: ${fromTheme('lh32')};
    margin: 0;
    padding: 0 0 ${fromTheme('spacing8')};
    ${mobileOnly`
      line-height: ${fromTheme('lh24')};
      padding: 0;
    `}
  }
  p {
    ${body2}
    color: ${fromTheme('clrGreyDark')};
    margin: 0;
    padding: ${fromTheme('spacing8')} 0 ${fromTheme('spacing16')};
  }
`;
