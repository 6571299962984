import Cookies from 'js-cookie';
import { CAMPAIGN_TRACK_EVENTS_SENT } from 'scripts/util/constants/cookies.constant';
import { ITrackingEventRequest, TrackingTriggerType } from 'scripts/api/tracking/tracking.interfaces';

/**
 * Checks Campaign track events cookie to see if the given event
 * has already been sent. Should always return true for non campaign events.
 * @param arcadeEvent A Tracking event (Not necessarily a campaign event)
 */
export function shouldSendCampaignEvent(arcadeEvent: ITrackingEventRequest): boolean {
  const seenCampaignEvents = Cookies.get(CAMPAIGN_TRACK_EVENTS_SENT);
  const actionId = arcadeEvent.actionName + arcadeEvent.trigger;
  return !seenCampaignEvents || !seenCampaignEvents.includes(actionId);
}

/**
 * Add Campaign impression or click to cookie so track event not
 * sent again after the user has already seen/clicked on a campaign.
 * This cookie should be removed when the session ends.
 * @param arcadeEvent A Tracking event (Not necessarily a campaign event)
 */
export function storeCampaignSent(arcadeEvent: ITrackingEventRequest): void {
  if (
    arcadeEvent.campaignTrackingInfo &&
    (arcadeEvent.trigger === TrackingTriggerType.Click || arcadeEvent.trigger === TrackingTriggerType.View)
  ) {
    const seenCampaignEvents = Cookies.get(CAMPAIGN_TRACK_EVENTS_SENT);
    const actionId = arcadeEvent.actionName + arcadeEvent.trigger;

    const dayInMs = 86_400_000;
    Cookies.set(CAMPAIGN_TRACK_EVENTS_SENT, seenCampaignEvents + actionId, {
      expires: new Date(Date.now() + dayInMs),
    });
  }
}
