import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISpeakEasyChatAvailability, ISpeakEasyChatEligibility } from 'scripts/api/speak-easy/speak-easy.interfaces';

export interface ISpeakEasyState {
  availability: IStateData<ISpeakEasyChatAvailability>;
  eligibility: IStateData<ISpeakEasyChatEligibility>;
}

export const initialState: ISpeakEasyState = {
  availability: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  eligibility: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const speakEasy = createSlice({
  name: 'speakEasy',
  initialState,
  reducers: {
    getSpeakEasyChatAvailabilitySuccess: (
      state: ISpeakEasyState,
      action: PayloadAction<ISpeakEasyChatAvailability>,
    ): void => {
      state.availability.data = action.payload;
      state.availability.error = false;
      state.availability.loading = false;
    },
    getSpeakEasyChatAvailabilityLoading: (state: ISpeakEasyState): void => {
      state.availability.loading = true;
    },
    getSpeakEasyChatAvailabilityError: (state: ISpeakEasyState): void => {
      state.availability.data = undefined;
      state.availability.error = true;
      state.availability.loading = false;
    },
    getSpeakEasyChatEligibilitySuccess: (
      state: ISpeakEasyState,
      action: PayloadAction<ISpeakEasyChatEligibility>,
    ): void => {
      state.eligibility.data = action.payload;
      state.eligibility.error = false;
      state.eligibility.loading = false;
    },
    getSpeakEasyChatEligibilityLoading: (state: ISpeakEasyState): void => {
      state.eligibility.loading = true;
    },
    getSpeakEasyChatEligibilityError: (state: ISpeakEasyState): void => {
      state.eligibility.data = undefined;
      state.eligibility.error = true;
      state.eligibility.loading = false;
    },
  },
});

export const {
  getSpeakEasyChatAvailabilitySuccess,
  getSpeakEasyChatAvailabilityLoading,
  getSpeakEasyChatAvailabilityError,
  getSpeakEasyChatEligibilitySuccess,
  getSpeakEasyChatEligibilityLoading,
  getSpeakEasyChatEligibilityError,
} = speakEasy.actions;

export default speakEasy.reducer;
