import { Aco, Acos, IProfileUser, LineOfBusiness, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import CONFIG from '../constants/config';
import {
  BRANDS,
  DEFAULT_LINE_OF_BUSINESS,
  DEFAULT_MEMBERSHIP_CATEGORY,
  POPULATION_PATHS,
} from './population.constants';
import { IBaseUrls, IBrand, IPopulation } from './population.interfaces';
import { IConfig } from '../constants/environment.interfaces';

/**
 * This object should return the URLs used when Domain Unification is enabled for the Blue Experience
 * @param lob
 * @param membershipCategory
 * @param config
 */
export function getBaseUrls(
  lob?: LineOfBusiness,
  membershipCategory?: MembershipCategory,
  config: IConfig = CONFIG,
): IBaseUrls {
  const baseUrls: IBaseUrls = {
    accountsUrl: config.ARCADE_WEB_RALLY_AUTH_URL,
    advantageUrl: config.ARCADE_WEB_RALLY_ADVANTAGE_URL,
    claimsUrl: getClaimsUrl(lob, membershipCategory, config),
    connectUrl: getConnectUrl(config.ARCADE_WEB_MYUHC_DOMAIN_UNIFICATION_BASE_URL),
    engageUrl: config.ARCADE_WEB_RALLY_ENGAGE_URL,
    healthRecordUrl: getHealthRecordUrl(config.ARCADE_WEB_MYUHC_DOMAIN_UNIFICATION_BASE_URL),
    myUhcBaseUrl: config.ARCADE_WEB_MYUHC_DOMAIN_UNIFICATION_BASE_URL,
    myUhcLegacyEnBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_BASE_URL,
    myUhcLegacyEsBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_ES_BASE_URL,
    myUhcLegacyHmBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_HM_BASE_URL,
    myUhcLegacyIlBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_ILO_BASE_URL,
    myUhcLegacyZhBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_ZH_BASE_URL,
    myUhcLegacyViBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_VI_BASE_URL,
    myUhcLegacyKcBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_KC_BASE_URL,
    myUhcLegacyKoBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_KR_BASE_URL,
    myUhcLegacyHtBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_HT_BASE_URL,
    myUhcLegacyMyBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_MY_BASE_URL,
    myUhcLegacySoBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_SO_BASE_URL,
    myUhcLegacyTlBaseUrl: config.ARCADE_WEB_MYUHC_LEGACY_TAG_BASE_URL,
    myUhcNoDuBaseUrl: config.ARCADE_WEB_MYUHC_BASE_URL,
    planSelectorUrl: config.ARCADE_WEB_PLAN_SELECTOR_URL,
    rallyRewardsUrl: config.ARCADE_WEB_RALLY_REWARDS_URL,
    rewardsUrl: getRewardsUrl(config.ARCADE_WEB_MYUHC_DOMAIN_UNIFICATION_BASE_URL),
    rxUrl: config.ARCADE_WEB_RALLY_RX_URL,
    virtualCareUrl: getVirtualCareUrl(config.ARCADE_WEB_MYUHC_DOMAIN_UNIFICATION_BASE_URL),
    optumVirtualCareUrl: config.ARCADE_WEB_OPTUM_VIRTUAL_BASE_URL,
  };
  if (lob === LineOfBusiness.MR) {
    baseUrls.myUhcNoDuBaseUrl = getBrandedBaseUrl(membershipCategory, baseUrls.myUhcBaseUrl, config);
  } else if (lob === LineOfBusiness.CS) {
    baseUrls.myUhcNoDuBaseUrl = config.ARCADE_WEB_MYUHC_COMMUNITY_BASE_URL || baseUrls.myUhcBaseUrl;
  }

  return baseUrls;
}

function getBrandedBaseUrl(membershipCategory: MembershipCategory, myUhcBaseUrl: string, config: IConfig): string {
  switch (membershipCategory) {
    case MembershipCategory.AARP:
      return config.ARCADE_WEB_MYUHC_AARP_BASE_URL || myUhcBaseUrl;
    case MembershipCategory.MEDICA:
      return config.ARCADE_WEB_MYUHC_MEDICA_BASE_URL || myUhcBaseUrl;
    case MembershipCategory.PCP:
      return config.ARCADE_WEB_MYUHC_PCP_BASE_URL || myUhcBaseUrl;
    case MembershipCategory.RETIREE:
      return config.ARCADE_WEB_MYUHC_RETIREE_BASE_URL || myUhcBaseUrl;
    default:
      return config.ARCADE_WEB_MYUHC_MEDICARE_BASE_URL || myUhcBaseUrl;
  }
}

function getBrand(lob: LineOfBusiness, mc: MembershipCategory, acos?: Acos): IBrand {
  const aco = acos && acos.indexOf(Aco.CDP) >= 0 && Aco.CDP;
  return BRANDS[lob][aco] || BRANDS[lob][mc] || BRANDS[lob].DEFAULT;
}

function isValidType(type: any, instance: any): boolean {
  return Object.keys(type).filter(key => type[key] === instance).length > 0;
}

export function getPopulationByUri(uri: string = '', lob?: string): IPopulation {
  const paths = POPULATION_PATHS;
  let lineOfBusiness = lob !== undefined && lob === LineOfBusiness.CS ? LineOfBusiness.CS : DEFAULT_LINE_OF_BUSINESS;
  let membershipCategory = DEFAULT_MEMBERSHIP_CATEGORY;
  for (const lob of Object.keys(paths)) {
    for (const category of Object.keys(paths[lob]).sort(mc => (mc === 'DEFAULT' ? 1 : -1))) {
      const foundPath = paths[lob][category].trim();
      if (foundPath && uri.toLowerCase().trim().startsWith(foundPath)) {
        lineOfBusiness = lob as LineOfBusiness;
        membershipCategory = category as MembershipCategory;
      }
    }
  }
  return {
    lineOfBusiness,
    membershipCategory,
    uri: POPULATION_PATHS[lineOfBusiness][membershipCategory].trim(),
    brand: getBrand(lineOfBusiness, membershipCategory),
    baseUrls: getBaseUrls(lineOfBusiness, membershipCategory),
  };
}

export function getPopulationByProfile(profileUser: IProfileUser): IPopulation {
  const { lineOfBusiness, membershipCategory, acos } = profileUser;
  const lob = isValidType(LineOfBusiness, lineOfBusiness) ? lineOfBusiness : DEFAULT_LINE_OF_BUSINESS;
  let category = isValidType(MembershipCategory, membershipCategory) ? membershipCategory : DEFAULT_MEMBERSHIP_CATEGORY;
  if (typeof POPULATION_PATHS[lob][category] === 'undefined') {
    category = DEFAULT_MEMBERSHIP_CATEGORY;
  }

  return {
    lineOfBusiness: lob,
    membershipCategory: category,
    uri: POPULATION_PATHS[lob][category].trim(),
    brand: getBrand(lob, category, acos),
    baseUrls: getBaseUrls(lob, category),
  };
}

const getClaimsUrl = (
  lineOfBusiness: LineOfBusiness = DEFAULT_LINE_OF_BUSINESS,
  membershipCategory: MembershipCategory = DEFAULT_MEMBERSHIP_CATEGORY,
  config: IConfig = CONFIG,
): string => {
  const populationPath = POPULATION_PATHS?.[lineOfBusiness]?.[membershipCategory] || '';
  const claimsAndAccountsPath = '/claims-and-accounts';
  return `${config.ARCADE_WEB_RALLY_CLAIMS_URL}${populationPath}${claimsAndAccountsPath}`;
};

const getVirtualCareUrl = (myUhcBaseUrl: string): string => {
  return `${myUhcBaseUrl}/virtualcare`;
};

const getHealthRecordUrl = (myUhcBaseUrl: string): string => {
  return `${myUhcBaseUrl}/ihr`;
};

const getConnectUrl = (myUhcBaseUrl): string => {
  return `${myUhcBaseUrl}/care`;
};

const doesPopulationMatch = (pop1: IPopulation, pop2: IPopulation): boolean => {
  return pop1?.lineOfBusiness === pop2?.lineOfBusiness && pop1?.membershipCategory === pop2?.membershipCategory;
};

const getRewardsUrl = (myUhcBaseUrl: string): string => {
  return `${myUhcBaseUrl}/rewards`;
};

export { doesPopulationMatch };
