import React, { FC, useCallback, useMemo, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAnalyticsInitialization } from 'scripts/selectors/app-selectors';
import { AnalyticsInitialization } from 'scripts/reducers/app-reducer';
import { getPageDataForPathname } from 'scripts/util/uri/uri';
import { PagesData } from './pages-data';
import { trackRouteChange } from 'scripts/util/tracking/tracking.react';
import { selectLocale } from 'scripts/selectors/locale-selectors';

/**
  Track each route change as a Top-Level Wrapper
 */
export const RouteTracking: FC = ({ children }) => {
  const { pathname } = useLocation<{ pathname: string }>();
  const previousPathnameRef = useRef(pathname || '');
  const previousPathname = previousPathnameRef.current;
  const locale = useSelector(selectLocale);

  const analyticsInitialization = useSelector(selectAnalyticsInitialization);
  const analyticsReady = useMemo(
    () => analyticsInitialization === AnalyticsInitialization.Successful,
    [analyticsInitialization],
  );

  const isDashboardPath = useMemo(() => getPageDataForPathname(pathname) === PagesData.dashboard, [pathname]);

  const isIdCardsModalPath = useMemo(() => getPageDataForPathname(pathname) === PagesData.idCards, [pathname]);

  const isRecommendationsPath = useMemo(
    () => getPageDataForPathname(pathname) === PagesData.recommendations,
    [pathname],
  );

  const doTracking = useCallback((): void => {
    try {
      const currentPageData = getPageDataForPathname(pathname);
      // Update this value for next render
      previousPathnameRef.current = pathname;
      if (previousPathname === pathname) {
        /*
          for initial render, pass undefined to trackRouteChange's `fromState` parameter.
        */
        trackRouteChange(locale, undefined, currentPageData);
      } else {
        const previousPageData = getPageDataForPathname(previousPathname);
        trackRouteChange(locale, previousPageData, currentPageData);
      }
    } catch (err) {
      console.warn('Error while tracking State Change Analytics: ', err);
    }
  }, [pathname]);

  // If a route is not for Dashboard then no additional props are needed for Amp
  useEffect(() => {
    if (analyticsReady && !isDashboardPath && !isIdCardsModalPath && !isRecommendationsPath) {
      doTracking();
    }
  }, [analyticsReady, pathname]);

  // If route is for Dashboard then we need to pass additional props to Amp
  useEffect(() => {
    if (analyticsReady && isDashboardPath) {
      doTracking();
    }
  }, [analyticsReady, pathname]);

  // If route is for id-cards modal then we need to pass additional props to Amp
  useEffect(() => {
    if (analyticsReady && isIdCardsModalPath) {
      doTracking();
    }
  }, [analyticsReady, pathname]);

  // If route is for recommendations then we need to pass additional props to Amp
  useEffect(() => {
    if (analyticsReady && isRecommendationsPath) {
      doTracking();
    }
  }, [analyticsReady, isRecommendationsPath]);

  return <>{children}</>;
};
