import { Aco, LineOfBusiness, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import CONFIG from '../constants/config';
import { ILineOfBusiness, ILineOfBusinessBrands } from './population.interfaces';
import { splitConfig } from 'scripts/util/config/config-utils';

export interface IPopulationPaths {
  [lineOfBusiness: string]: ILineOfBusiness;
}

export interface IBrands {
  [lineOfBusiness: string]: ILineOfBusinessBrands;
}

export const DEFAULT_LINE_OF_BUSINESS: LineOfBusiness = LineOfBusiness.EI;
export const DEFAULT_MEMBERSHIP_CATEGORY: MembershipCategory = 'DEFAULT' as MembershipCategory;
export const DEFAULT_BRAND = {
  LOGO_URL: '/images/branding/unitedhealth.svg',
  LOGO_ALT: 'United Healthcare',
};

export const POPULATION_PATHS: IPopulationPaths = {
  [LineOfBusiness.EI]: {
    DEFAULT: '',
    [MembershipCategory.EMPIRE]: '',
    [MembershipCategory.EXCHANGE]: '',
    [MembershipCategory.PUBLIC_EXCHANGE]: '',
    [MembershipCategory.EXPATRIATE]: '',
    [MembershipCategory.JPMC]: '',
    [MembershipCategory.OXFORD]: '',
    [MembershipCategory.SMALL_BUSINESS]: '',
    [MembershipCategory.UHC_MEDICA]: '',
    [MembershipCategory.UHC_WEST]: '',
    [MembershipCategory.HEALTH_PLAN]: '/healthplan',
  },
  [LineOfBusiness.MR]: {
    DEFAULT: '/medicare',
    [MembershipCategory.AARP]: '/aarp',
    [MembershipCategory.MEDICA]: '/pcn',
    [MembershipCategory.PCP]: '/pcp',
    [MembershipCategory.RETIREE]: '/retiree',
    [MembershipCategory.TEXAS_ERS]: '/medicare',
    [MembershipCategory.UHC]: '/medicare',
  },
  [LineOfBusiness.CS]: {
    DEFAULT: '/community',
  },
};

export const BRANDS: IBrands = {
  [LineOfBusiness.EI]: {
    DEFAULT: DEFAULT_BRAND,
    [MembershipCategory.OXFORD]: {
      LOGO_URL: '/images/branding/uhc-oxford-logo.svg',
      LOGO_ALT: 'United Healthcare Oxford',
    },
    [MembershipCategory.EXPATRIATE]: {
      LOGO_URL: '/images/branding/uhc-global.svg',
      LOGO_ALT: 'United Healthcare Global',
    },
    [MembershipCategory.UHC_MEDICA]: {
      ...DEFAULT_BRAND,
      MOBILE_LOGO_URL: '/images/branding/unitedhealth-small.svg',
      SECONDARY_LOGO_URL: '/images/branding/medica.svg',
      SECONDARY_LOGO_ALT: 'Medica',
    },
    [Aco.CDP]: {
      ...DEFAULT_BRAND,
      MOBILE_LOGO_URL: '/images/branding/unitedhealth-small.svg',
      SECONDARY_LOGO_URL: '/images/branding/cdp.svg',
      SECONDARY_LOGO_ALT: 'Centura Health',
    },
  },
  [LineOfBusiness.MR]: {
    DEFAULT: DEFAULT_BRAND,
    [MembershipCategory.AARP]: {
      LOGO_URL: '/images/branding/aarp.svg',
      LOGO_ALT: 'AARP: Medicare Plans from United Healthcare',
    },
    [MembershipCategory.MEDICA]: {
      LOGO_URL: '/images/branding/medicahealthcare.svg',
      LOGO_ALT: 'Medica Healthcare',
    },
    [MembershipCategory.PCP]: {
      LOGO_URL: '/images/branding/preferredcare.svg',
      LOGO_ALT: 'Preferred Care Partners',
    },
    [MembershipCategory.TEXAS_ERS]: {
      LOGO_URL: '/images/branding/ers.svg',
      LOGO_ALT: 'Health Select Medicare RX of Texas',
      LOGO_HREF: 'http://ers.texas.gov',
    },
  },
  [LineOfBusiness.CS]: {
    DEFAULT: {
      LOGO_URL: '/images/branding/uhc-cs.svg',
      LOGO_ALT: 'United Healthcare Community Plan',
    },
  },
};

export function createMapFromAllowlist(allowlist: string): { [key: string]: boolean } {
  const allowMap = {};
  if (allowlist) {
    splitConfig(allowlist).forEach(hostname => {
      allowMap[hostname] = true;
    });
  }
  return allowMap;
}

export const SAME_TAB_PROMO_LINKS = createMapFromAllowlist(CONFIG.ARCADE_WEB_SAME_TAB_PROMO_LINKS);
export const NEW_TAB_PROMO_LINKS = createMapFromAllowlist(CONFIG.ARCADE_WEB_NEW_TAB_PROMO_LINKS);
export const MR_ALLOW_MAP_LINKS = createMapFromAllowlist(CONFIG.ARCADE_WEB_MR_ALLOWLIST_LINKS);
export const CS_ALLOW_MAP_LINKS = createMapFromAllowlist(CONFIG.ARCADE_WEB_CS_ALLOWLIST_LINKS);
