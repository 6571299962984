import { IProfileUser, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { include } from 'scripts/config/analytics.config';
import { ArcadeEnvironment, IConfig } from '../constants/environment.interfaces';

export function initGlobalNav(currentUser: IProfileUser, config: IConfig): void {
  const src =
    currentUser.lineOfBusiness === LineOfBusiness.MR
      ? config.ARCADE_WEB_GLOBAL_NAV_MR_URL
      : currentUser.lineOfBusiness === LineOfBusiness.EI
      ? config.ARCADE_WEB_GLOBAL_NAV_EI_URL
      : config.ARCADE_WEB_GLOBAL_NAV_CS_URL;
  const currentEnvironment = config.ARCADE_WEB_ENVIRONMENT_NAME;
  if (
    currentEnvironment !== ArcadeEnvironment.Integration &&
    currentEnvironment !== ArcadeEnvironment.Bluesteel &&
    currentEnvironment !== ArcadeEnvironment.Production
  ) {
    window.CUSTOM_UHC_MEMBER_ROOT = window.location.origin;
  }
  include(src, 'global-nav-script', true);
}
