import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPrimaryCareProviderInfo } from 'scripts/features/beta-dashboard/pcp/pcp-strip.interfaces';
import { changeSelectedProfile } from './profile-service-reducer';

export interface IPrimaryCareProviderState {
  primaryCareProviderInfo: IStateData<IPrimaryCareProviderInfo>;
}

export const initialState: IPrimaryCareProviderState = {
  primaryCareProviderInfo: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const primaryCareProvider = createSlice({
  name: 'primaryCareProvider',
  initialState,
  reducers: {
    getPrimaryCareProviderInfoSuccess: (
      state: IPrimaryCareProviderState,
      action: PayloadAction<IPrimaryCareProviderInfo>,
    ): void => {
      state.primaryCareProviderInfo.loading = false;
      state.primaryCareProviderInfo.error = false;
      state.primaryCareProviderInfo.data = action.payload;
    },
    getPrimaryCareProviderInfoLoading: (state: IPrimaryCareProviderState): void => {
      state.primaryCareProviderInfo.loading = true;
      state.primaryCareProviderInfo.error = false;
    },
    getPrimaryCareProviderInfoError: (state: IPrimaryCareProviderState): void => {
      state.primaryCareProviderInfo.data = undefined;
      state.primaryCareProviderInfo.loading = false;
      state.primaryCareProviderInfo.error = true;
    },
  },
  extraReducers: {
    [changeSelectedProfile.toString()]: (state: IPrimaryCareProviderState): void => {
      state.primaryCareProviderInfo.error = undefined;
      state.primaryCareProviderInfo.data = undefined;
      state.primaryCareProviderInfo.loading = false;
    },
  },
});

export const { getPrimaryCareProviderInfoSuccess, getPrimaryCareProviderInfoLoading, getPrimaryCareProviderInfoError } =
  primaryCareProvider.actions;

export default primaryCareProvider.reducer;
