import { CoverageType, CoverageTypeCode, ICurrencyAmount, IResponse } from '../api.interfaces';

export enum BenefitPaymentType {
  FamilyCopayMax = 'FAMILY_COPAY_MAX',
  FamilyDeductible = 'FAMILY_DEDUCTIBLE',
  FamilyOop = 'FAMILY_OOP',
  FamilyOopMax2 = 'FAMILY_OOP_MAX_2',
  IndividualAnnualMax = 'INDIVIDUAL_ANNUAL_MAX',
  IndividualCopayMax = 'INDIVIDUAL_COPAY_MAX',
  IndividualDeductible = 'INDIVIDUAL_DEDUCTIBLE',
  IndividualLifetimeMax = 'INDIVIDUAL_LIFETIME_MAX',
  IndividualOop = 'INDIVIDUAL_OOP',
  IndividualOopMax2 = 'INDIVIDUAL_OOP_MAX_2',
  PlanLifetimeMax = 'PLAN_LIFETIME_MAX',
  PreDeductibleAllowanceCombined = 'PREDEDUCTIBLE_ALLOWANCE_COMBINED',
  PreDeductibleAllowanceEmergencyServices = 'PREDEDUCTIBLE_ALLOWANCE_EMERGENCY_SERVICES',
  PreDeductibleAllowanceRoutineServices = 'PREDEDUCTIBLE_ALLOWANCE_ROUTINE_SERVICES',
  IndividualOoPrimary = 'INDIVIDUAL_OOP_PRIMARY',
  IndividualOopTotal = 'INDIVIDUAL_OOP_TOTAL',
}

export enum CopayOrder {
  After = 'AFTER',
  Before = 'BEFORE',
}

export enum BenefitServiceType {
  CoinsurancePercent = 'COINSURANCE_PERCENT',
  CopayAmount = 'COPAY_AMOUNT',
  DeductibleAmount = 'DEDUCTIBLE_AMOUNT',
}

export enum BenefitNetwork {
  Combined = 'combined',
  InNetwork = 'inNetwork',
  OutOfNetwork = 'outOfNetwork',
  Tier1 = 'tier1',
  Tier2 = 'tier2',
  Type1 = 'type1',
  Type2 = 'type2',
}

export enum Tier1Feature {
  Hospitals = 'TIER1_HOSPITALS',
  PCP = 'TIER1_OOP_PCP',
  Specialist = 'TIER1_OOP_SPECIALIST',
}

export interface IBenefitsSpendingList {
  inNetwork: IBenefitAmount[];
  outOfNetwork?: IBenefitAmount[];
  combined?: IBenefitAmount[];
  tier1?: IBenefitAmount[];
  predeductibleMax?: IBenefitAmount;
  lifetimeMax?: IBenefitAmount;
}

export interface IBenefitAccumulators {
  coverageType: CoverageType;
  accumulators: IBenefitsSpendingList;
  predeductibleAccumulators?: IBenefitAmount[];
  lifetimeMaxAccumulator?: IBenefitAmount;
  prorateReasonCode?: ProrateReasonCode;
  mnrRxAccumulator?: IMRRXAccumulators;
}

export interface IBenefitAmount {
  type: BenefitPaymentType;
  amount: ICurrencyAmount;
}

export interface IBenefitAmountService {
  type: BenefitServiceType;
  amount: string;
  referralRequired?: boolean;
  copayOrder?: CopayOrder;
}

export interface IBenefitServiceCode {
  type: string;
  code: string;
  friendlyCode: string;
}

export interface IBenefitService {
  serviceCode: IBenefitServiceCode;
  inNetwork: IBenefitAmountService[];
  outOfNetwork: IBenefitAmountService[];
  tier1: IBenefitAmountService[];
  tier2: IBenefitAmountService[];
  type1: IBenefitAmountService[];
  type2: IBenefitAmountService[];
}

export interface IPlanFeatures {
  isTieredPlan: boolean;
  isCspGspPlan: boolean;
  knownFeatures: Tier1Feature[];
  featureCodes: string[];
}

export interface IBenefit {
  coverageType: CoverageType;
  coverageTypeCode: CoverageTypeCode;
  shortPlanName: string;
  planName: string;
  maxes: IBenefitsSpendingList;
  mnrMaxes?: IBenefitAmount[];
  services: IBenefitService[];
  planFeatures: IPlanFeatures;
  showRxCarveoutClaims: boolean;
  ancillaryBenefits: AncillaryBenefit[];
  hideUHPD: boolean;
  drugBenefits?: IDrugBenefits;
  navigateCompassIndicator?: string;
  mnrRxBenefits: IMRRXBenefits;
  renewActive?: boolean;
}

export interface IPlanAccumulators {
  planKey?: string;
  policyNumber?: string;
  benefits: IBenefitAccumulators[];
}

export interface IPlanBenefits {
  planKey?: string;
  groupName?: string;
  groupNumber?: string;
  benefits: IBenefit[];
}

export interface IPlanBenefitsResponse extends IResponse<IPlanBenefits> {}

export interface IPlanAccumulatorsResponse extends IResponse<IPlanAccumulators> {}

export interface IMRRXBenefits {
  maxAnnualDeductibleAmount?: ICurrencyAmount;
  maxOopCost?: ICurrencyAmount;
  maxTotalDrugCost?: ICurrencyAmount;
  splitTierPlanIndicator?: boolean;
}
export interface IMRRXAccumulators {
  drugStage?: DrugStage;
  ytdAnnualDeductibleAmount?: ICurrencyAmount;
  ytdOopCost?: ICurrencyAmount;
  ytdTotalDrugCost?: ICurrencyAmount;
}

export interface IIdCard {
  cardType: string;
  front: IIdCardSide;
  back: IIdCardSide;
  generatedImage: boolean;
  coverageTypeCode?: CoverageTypeCode;
}

export interface IIdCardSide {
  mimeType: string;
  length: number;
  stream: string;
}

export interface IIdCardsResponse extends IResponse<IIdCard[]> {}

export interface IBenefitExplanation {
  label: string;
  isCustomLabel: boolean;
  isCustomDef: boolean;
  definition: string;
}

export interface IPlanVariationParams {
  pvrc?: string;
  clientId?: string;
}

export interface ICoverageParams {
  coverageTypeCode: CoverageTypeCode;
  fundingArrangementType: string;
  programType: string;
  marketType: string;
}

export interface IMRBenefitParams extends IPlanVariationParams, ICoverageParams {
  d?: string;
  showFamily?: boolean;
}

export interface IIdCardMRBenefitParams extends ICoverageParams {
  getImage: boolean;
}

export interface IDrugBenefits {
  TIER_ONE?: IDrugBenefit;
  TIER_TWO?: IDrugBenefit;
  TIER_THREE?: IDrugBenefit;
  TIER_THREE_INSULIN?: IDrugBenefit;
  TIER_FOUR?: IDrugBenefit;
  TIER_FIVE?: IDrugBenefit;
  GENERIC_DRUGS?: IDrugBenefit;
  OTHER_DRUGS?: IDrugBenefit;
}

export interface IDrugBenefit {
  drugCoverageState: DrugCoverageState;
  drugTypeInformations: IDrugInformation[];
}

export interface IDrugInformation {
  drugCoverageType: DrugCoverageType;
  drugCopay?: string;
  drugCoinsurance?: string;
}

export enum DrugCoverageState {
  Catastrophic = 'CATASTROPHIC',
  CoverageGap = 'COVERAGE_GAP',
  Initial = 'INITIAL',
}

export enum DrugCoverageType {
  Preferred = 'PREFERRED',
  PreferredMailOrder = 'PREFERRED_MAIL_ORDER',
  Standard = 'STANDARD',
  StandardMailOrder = 'STANDARD_MAIL_ORDER',
}

export enum DrugStage {
  Deductible = 'DEDUCTIBLE',
  InitialCoverage = 'INITIAL_COVERAGE',
  CatastrophicCoverage = 'CATASTROPHIC_COVERAGE',
  CoverageGap = 'COVERAGE_GAP',
  Unknown = 'UNKNOWN',
}

export enum ProrateReasonCode {
  BenefitChange = 'BENEFIT_CHANGE',
  MidYear = 'MID_YEAR',
  MidYearAndBenefitChange = 'MID_YEAR_AND_BENEFIT_CHANGE',
}

export enum AncillaryBenefit {
  Chiropractic = 'ANCILLARY_CHIROPRACTIC',
  Dental = 'ANCILLARY_DENTAL',
  Hearing = 'ANCILLARY_HEARING',
  Vision = 'ANCILLARY_VISION',
}
