/**
 * UI-Friendly string representation of a coverage status that has ended {@see CoverageStatus.Termed}.
 */
export const CoverageStatusEnded = 'COVERAGE_STATUS_ENDED';
/**
 * UI-Friendly string representation of a coverage that has yet to begin {@see CoverageStatus.Future}.
 */
export const CoverageStatusNotStarted = 'COVERAGE_STATUS_NOT_STARTED';
/**
 * UI-Friendly string representation of a coverage that is active {@see CoverageStatus.Active}.
 */
export const CoverageStatusActive = 'COVERAGE_STATUS_ACTIVE';
/**
 * UI-Friendly string for when a coverage cannot get matched to a profile.
 */
export const CoverageStatusUnknown = 'COVERAGE_STATUS_UNKNOWN';
/**
 * EHB Dental card type parameter.
 */
export const EhbDental = 'EHBDENTAL';
