import { IConfig } from 'scripts/util/constants/environment.interfaces';
import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import { selectConfig } from 'scripts/selectors/app-selectors';
import { useEffect } from 'react';
import { selectProfileData } from 'scripts/selectors/profile-service-selectors';
import { getPopulationByProfile } from 'scripts/util/population/population';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IProfile } from 'scripts/api/profile/profile.interfaces';
import {
  getCoverageTypeCodesFromProfileUser,
  getGroupNumbersFromProfileUser,
  getProgramTypesFromProfileUser,
} from 'scripts/util/profile/profile';
import Cookies from 'js-cookie';
import { CANARY_STATUS } from 'scripts/util/constants/cookies.constant';
const canaryStatus = (): string => {
  const canaryCookie = Cookies.get(CANARY_STATUS);
  if (canaryCookie === 'always') return 'canary';
  else if (canaryCookie === 'never') return 'stable';
  else return 'n/a';
};

export const useDataDog = (): void => {
  const config = useSelector(selectConfig);
  const profile = useSelector(selectProfileData);
  const psWidget = document.cookie.match(/PS_Widget=([^;]*)/)?.[1];
  const hsid = JSON.parse(atob(psWidget || '') || null)?.accountId;

  useEffect(() => {
    if (config) {
      init(config);
    }
  }, [config]);

  useEffect(() => {
    if (profile && config) {
      setUserData(config, getPopulationByProfile(profile.currentUser), profile, hsid);
    }
  }, [profile, config]);
};

const setUserData = (config: IConfig, population: IPopulation, profile: IProfile, hsid: string): void => {
  if (config.ARCADE_WEB_DATADOG_RUM_APPLICATION_ID && config.ARCADE_WEB_DATADOG_RUM_CLIENT_TOKEN) {
    datadogRum.setGlobalContext({
      lineOfBusiness: population.lineOfBusiness.replace(/&/g, 'n'),
      membershipCategory: population.membershipCategory,
      coverageType: getCoverageTypeCodesFromProfileUser(profile.currentUser)[0],
      businessType: getProgramTypesFromProfileUser(profile.currentUser)[0],
      groupId: getGroupNumbersFromProfileUser(profile.currentUser)[0],
      hsid: hsid,
      canary_status: canaryStatus(),
    });
  }
};

function init(config: IConfig): void {
  if (config.ARCADE_WEB_DATADOG_RUM_APPLICATION_ID && config.ARCADE_WEB_DATADOG_RUM_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: config.ARCADE_WEB_DATADOG_RUM_APPLICATION_ID,
      clientToken: config.ARCADE_WEB_DATADOG_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'arcade-ui',
      env: config.ARCADE_WEB_DATADOG_ENV,
      version: config.ARCADE_WEB_VERSION,
      allowedTracingOrigins: [config.ARCADE_WEB_BASE_URL, config.ARCADE_WEB_BASE_API_URL],
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask',
    });
    datadogRum.startSessionReplayRecording();
  }
}
