import React, { FunctionComponent, useEffect, ComponentType } from 'react';
import { connect } from 'react-redux';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { LocaleLanguage } from 'scripts/util/locale/locale.interfaces';
import { selectClientConfigData } from 'scripts/selectors/targeting-service-selectors';
import { selectLanguage } from 'scripts/selectors/locale-selectors';
import { getClientConfig } from 'scripts/thunks/targeting-service-thunks';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { usePrevious } from 'scripts/hooks/use-previous/use-previous';

interface ILocaleAwareClientConfigStateToProps {
  clientConfig?: IClientConfig;
  language: LocaleLanguage;
}

interface ILocaleAwareClientConfigDispatchToProps {
  getClientConfig: () => void;
}

interface IRawLocaleAwareClientConfigProps
  extends ILocaleAwareClientConfigStateToProps,
    ILocaleAwareClientConfigDispatchToProps {}

// Re-fetch client config after a language change
export const RawLocaleAwareClientConfig: FunctionComponent<IRawLocaleAwareClientConfigProps> = ({
  language,
  clientConfig,
  getClientConfig,
  children,
}) => {
  const prevLanguage = usePrevious(language);
  useEffect(() => {
    if (prevLanguage === language) {
      return;
    }
    getClientConfig();
  }, [clientConfig, language, prevLanguage]);

  return <>{children}</>;
};

const mapStateToProps = (state: IReduxState): ILocaleAwareClientConfigStateToProps => ({
  clientConfig: selectClientConfigData(state),
  language: selectLanguage(state),
});

const LocaleAwareClientConfig = connect(mapStateToProps, { getClientConfig })(RawLocaleAwareClientConfig);

export function withLocaleAwareClientConfig<GenericProps>(
  WrappedComponent: ComponentType<GenericProps>,
): FunctionComponent<GenericProps> {
  return (props: GenericProps) => (
    <LocaleAwareClientConfig>
      <WrappedComponent {...props} />
    </LocaleAwareClientConfig>
  );
}
