import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function useConditionalDataLoader<T, U = Promise<void>>(
  data: T,
  loading: boolean,
  error: boolean,
  thunkFn: () => U,
  loadCondition: boolean,
): void {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data && !error && !loading && loadCondition) {
      dispatch(thunkFn());
    }
  }, [data, error, loading, loadCondition]);
}

export function useDataLoader<T, U = Promise<void>>(data: T, error: boolean, loading: boolean, thunkFn: () => U): void {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data && !error && !loading) {
      dispatch(thunkFn());
    }
  }, [data, error, loading]);
}

export const getShippingAddress = (address: any): string => {
  const lineOne = address?.lineOne || '';
  const lineTwo = address?.lineTwo || '';
  const town = address?.town || '';
  const stateProvinceCode = address?.stateProvinceCode || '';
  const postalCode = address?.postalCode || '';
  const countryCode = address?.countryCode || '';
  const shippingAddress = `${lineOne} ${lineTwo} ${town} ${stateProvinceCode} ${countryCode}, ${postalCode}`;
  return shippingAddress;
};

export const formatMonthDate = (date: Date): string => {
  const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const yyyy = date.getFullYear();
  const dd = String(date.getDate()).padStart(2, '0');
  return months[date.getMonth()] + ' ' + dd + ', ' + yyyy;
};
