import { changeTheme } from 'scripts/reducers/app-reducer';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { getDefaultTheme } from 'scripts/styles/themes/default';

export function setTheme(): ArcadeThunkAction<void> {
  return dispatch => {
    dispatch(
      changeTheme({
        ...getDefaultTheme(),
      }),
    );
  };
}
