import { History, Location } from 'history';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getPageDataForPathname } from 'scripts/util/uri/uri';
import { useQueryParams } from 'scripts/hooks/use-query-params/use-query-params';

export interface IUseRouterState<T> {
  location: Pick<Location, 'pathname' | 'search'>; // only including properties that we have implemented here
  history: Pick<History, 'length' | 'push' | 'replace' | 'goBack' | 'go'>; // only including functions that we have implemented here
  params: T;
  pageName: string;
}

export const useRouter = <T = void>(): IUseRouterState<T> => {
  const location = useLocation();
  const history = useHistory();
  const pathParams = useParams<T>();
  const queryParams = useQueryParams<T>();
  const pageName = getPageDataForPathname(location.pathname)?.name;

  return {
    location,
    history,
    params: { ...pathParams, ...queryParams },
    pageName,
  };
};
