import { IFeatureFlags, IConfig } from 'scripts/util/constants/environment.interfaces';
import FEATURE_FLAGS from 'scripts/util/constants/feature-flag';
import CONFIG from 'scripts/util/constants/config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITheme } from 'scripts/styles/themes/themes.interfaces';
import { KeyValueMap, PageTags } from '@rally/analytics/dist/models/events';

export interface ILoadingBarRequest {
  started: number;
  completed: number;
}

export interface ILoadingBarState {
  requests: Record<string, ILoadingBarRequest>;
}

export enum AnalyticsInitialization {
  Started = 'STARTED',
  Successful = 'SUCCESSFUL',
  Error = 'ERROR',
}

export interface IPageAnalyticsData {
  pageName: string;
  pageTags?: PageTags;
  pageArgs?: KeyValueMap;
  additionalData?: KeyValueMap;
  isModal?: boolean;
}

export interface IAppState {
  analyticsInitialization?: AnalyticsInitialization;
  currentPageAnalyticsData: IPageAnalyticsData;
  previousPageAnalyticsData: IPageAnalyticsData[];
  config: IConfig;
  featureFlags: IFeatureFlags;
  modalOpen: boolean;
  loadingBar: ILoadingBarState;
  showActivateDynamicOnboardingPaperless: boolean;
  healthTopicsModalEnabled: boolean;
  theme: ITheme;
  navOpen: boolean;
  dashboardReadyToDisplay: boolean;
  isUserActive: boolean;
}

export const initialState: IAppState = {
  analyticsInitialization: undefined,
  currentPageAnalyticsData: undefined,
  previousPageAnalyticsData: [],
  config: { ...CONFIG },
  featureFlags: { ...FEATURE_FLAGS },
  modalOpen: undefined,
  loadingBar: {
    requests: {},
  },
  healthTopicsModalEnabled: undefined,
  showActivateDynamicOnboardingPaperless: undefined,
  theme: undefined,
  navOpen: undefined,
  dashboardReadyToDisplay: undefined,
  isUserActive: false,
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeAnalyticsInitialization: (state: IAppState, action: PayloadAction<AnalyticsInitialization>): void => {
      state.analyticsInitialization = action.payload;
    },
    setCurrentPageAnalyticsData: (state: IAppState, action: PayloadAction<IPageAnalyticsData>): void => {
      if (state.currentPageAnalyticsData) {
        state.previousPageAnalyticsData.unshift(state.currentPageAnalyticsData);
      }
      state.currentPageAnalyticsData = action.payload;
    },
    changeModalOpen: (state: IAppState, action: PayloadAction<boolean>): void => {
      state.modalOpen = action.payload;
    },
    changeTheme: (state: IAppState, action: PayloadAction<ITheme>): void => {
      state.theme = action.payload;
    },
    changeNavOpen: (state: IAppState, action: PayloadAction<boolean>): void => {
      state.navOpen = action.payload;
    },
    setShowActivateDynamicOnboardingPaperless: (state: IAppState, action: PayloadAction<boolean>): void => {
      state.showActivateDynamicOnboardingPaperless = action.payload;
    },
    setShowHealthTopicsModal: (state: IAppState, action: PayloadAction<boolean>): void => {
      state.healthTopicsModalEnabled = action.payload;
    },
    incrementLoadingBarRequests: (state: IAppState, action: PayloadAction<string>): void => {
      let req = state.loadingBar.requests[action.payload];
      if (!req) {
        req = state.loadingBar.requests[action.payload] = {
          started: 0,
          completed: 0,
        };
      }
      req.started += 1;
    },
    decrementLoadingBarRequests: (state: IAppState, action: PayloadAction<string>): void => {
      const req = state.loadingBar.requests[action.payload];
      if (req) {
        req.completed += 1;
      }
    },
    setDashboardReadyToDisplay: (state: IAppState, action: PayloadAction<boolean>): void => {
      state.dashboardReadyToDisplay = action.payload;
    },
    setIsUserActive: (state: IAppState, action: PayloadAction<boolean>): void => {
      state.isUserActive = action.payload;
    },
    changeFeatureFlag_DEBUG_ONLY: (state: IAppState, action: PayloadAction<keyof IFeatureFlags>): void => {
      state.featureFlags[action.payload] = !state.featureFlags[action.payload];
    },
  },
});

export const {
  changeAnalyticsInitialization,
  setCurrentPageAnalyticsData,
  changeModalOpen,
  changeTheme,
  changeFeatureFlag_DEBUG_ONLY,
  changeNavOpen,
  // Do not use this action creator directly. Use the loading bar thunks instead.
  incrementLoadingBarRequests,
  // Do not use this action creator directly. Use the loading bar thunks instead.
  decrementLoadingBarRequests,
  setShowActivateDynamicOnboardingPaperless,
  setShowHealthTopicsModal,
  setDashboardReadyToDisplay,
  setIsUserActive,
} = app.actions;

export default app.reducer;
