import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMobileHoops } from 'scripts/api/live-person/live-person.interfaces';
import { IStateData } from './reducer.interfaces';

export interface IMobileHoopsInfo {
  mobileHoops?: IMobileHoops;
}

export interface IMobileHoopsState {
  mobileHoopsInfo: IStateData<IMobileHoopsInfo>;
}

export const initialState: IMobileHoopsState = {
  mobileHoopsInfo: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const mobileHoops = createSlice({
  name: 'mobileHoops',
  initialState,
  reducers: {
    getMobileHoopsSuccess: (state: IMobileHoopsState, action: PayloadAction<IMobileHoopsInfo>): void => {
      state.mobileHoopsInfo.data = action.payload;
      state.mobileHoopsInfo.error = false;
      state.mobileHoopsInfo.loading = false;
    },
    getMobileHoopsError: (state: IMobileHoopsState): void => {
      state.mobileHoopsInfo.data = undefined;
      state.mobileHoopsInfo.error = true;
      state.mobileHoopsInfo.loading = false;
    },
  },
});

export const { getMobileHoopsSuccess, getMobileHoopsError } = mobileHoops.actions;

export default mobileHoops.reducer;
