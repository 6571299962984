import React, { FunctionComponent, Ref } from 'react';
import { LinkProps, Link as ReactRouterLink } from 'react-router-dom';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { Anchor, IAnchorProps } from '../anchor/anchor';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { connect } from 'react-redux';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { LocationDescriptor } from 'history';
import { addModalPath, addPopulationPath } from 'scripts/util/uri/uri';

export interface ILinkStateToProps {
  population: IPopulation;
}

export interface ILinkProps extends LinkProps, IAnchorProps {
  noNewWindowIcon?: boolean;
  forwardedRef?: Ref<HTMLAnchorElement>;
  to: LocationDescriptor; // we do not support 'to' as a function yet, added in react-router-dom": "^5.1.8" upgrade
}

export const RawLink: FunctionComponent<ILinkProps & ILinkStateToProps> = props => {
  const { onClick, population, target, to, ...rest } = props;
  const toWithModal = addModalPath(to);
  const toWithModalAndPopulationUri = addPopulationPath(toWithModal, population.uri);
  // Link component is currently only used for INTERNAL links, so RR will be enough
  const RRLinkWithTo: FunctionComponent = props => <ReactRouterLink to={toWithModalAndPopulationUri} {...props} />;
  return <Anchor as={RRLinkWithTo} onClick={onClick} target={target || LinkTarget.Self} {...rest} />;
};

const mapStateToProps = (state: IReduxState): ILinkStateToProps => ({
  population: selectPopulation(state),
});

export const Link = connect(mapStateToProps, {})(RawLink);

export const LinkWithClickTracking = withClickTracking(Link);
