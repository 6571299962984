import { useEffect, useRef } from 'react';

/**
 * Created in an effort to side effects more React-y and to solve closure issues
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback Function with side effects to run
 * @param delay Number in milliseconds defining the interval at which the callback runs
 */
export const useInterval = (callback: () => void, delay: number | null = 0): void => {
  const savedCallback = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = (): void => savedCallback.current();
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
