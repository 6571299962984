import React, { FunctionComponent, Ref } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled, { css } from 'styled-components';
import { CheckboxProps, RdsCheckbox } from '@rally/energon-react';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { fromTheme } from 'scripts/styles/themes/themes.utils';

export interface ICheckboxProps extends Omit<CheckboxProps, 'isChecked'> {
  checked?: boolean;
  error?: boolean;
  label: string;
  forwardedRef?: Ref<HTMLInputElement>;
}

export const Checkbox: FunctionComponent<ICheckboxProps> = props => {
  const { checked, error, forwardedRef, id = uuidv4(), label, ...rest } = props;

  return (
    <$StyledRdsCheckbox error={error} id={id} isChecked={checked} ref={forwardedRef} {...rest}>
      {label}
    </$StyledRdsCheckbox>
  );
};

export const CheckboxWithClickTracking = withClickTracking(Checkbox);

const $StyledRdsCheckbox = styled(RdsCheckbox)<{ error?: boolean }>`
  ${({ error }) =>
    error &&
    css`
      .rds-checkmark {
        border-color: ${fromTheme('clrRed')};
        &:hover {
          border-color: ${fromTheme('clrRedDark')};
        }
      }
    `}
`;
