import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISegmentationData } from 'scripts/api/live-person/live-person.interfaces';
import { IStateData } from './reducer.interfaces';

export interface ISegmentationInfo {
  eligible: boolean;
  segmentationData?: ISegmentationData;
}

export interface ILivePersonState {
  segmentationInfo: IStateData<ISegmentationInfo>;
  livePersonChatStarted: boolean;
}

export const initialState: ILivePersonState = {
  segmentationInfo: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  livePersonChatStarted: false,
};

const livePerson = createSlice({
  name: 'livePerson',
  initialState,
  reducers: {
    getLivePersonEligibilitySuccess: (state: ILivePersonState, action: PayloadAction<ISegmentationInfo>): void => {
      state.segmentationInfo.data = action.payload;
      state.segmentationInfo.error = false;
      state.segmentationInfo.loading = false;
    },
    getLivePersonEligibilityLoading: (state: ILivePersonState): void => {
      state.segmentationInfo.loading = true;
    },
    getLivePersonEligibilityError: (state: ILivePersonState): void => {
      state.segmentationInfo.data = undefined;
      state.segmentationInfo.error = true;
      state.segmentationInfo.loading = false;
    },
    setLivePersonChatStarted: (state: ILivePersonState, action: PayloadAction<boolean>): void => {
      state.livePersonChatStarted = action.payload;
    },
  },
});

export const {
  getLivePersonEligibilitySuccess,
  getLivePersonEligibilityLoading,
  getLivePersonEligibilityError,
  setLivePersonChatStarted,
} = livePerson.actions;

export default livePerson.reducer;
