import { useEffect, useState } from 'react';
import { ArcadeThunkAction, IReduxState } from 'scripts/reducers/reducer.interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { selectId } from 'scripts/selectors/locale-selectors';
import { ITacoPlacements } from 'scripts/api/taco/taco.interfaces';
import { CampaignPlacementType, ITacoRecommendation } from 'scripts/api/targeting/targeting.interfaces';
import { getTargetedContent } from 'scripts/thunks/taco-service-thunks';
import { selectTacosData, selectTacosError, selectTacosLoading } from 'scripts/selectors/taco-service-selectors';

export interface IUseTacoDirectState {
  tacos: ITacoRecommendation[];
  loading: boolean;
  error: boolean;
  retry: () => void;
}

const defaultTacoDirectValue: IUseTacoDirectState = {
  tacos: undefined,
  loading: false,
  error: false,
  retry: undefined,
};

/**
 * A hook for consuming content from Taco directly (Targeted Content).
 * To avoid confusion, any kind of Taco response directly from Taco is considered "campaigns".
 * Corresponding BE model: https://github.com/AudaxHealthInc/taco/blob/main/service/src/main/scala/com/rallyhealth/
 * taco/service/TargetedContentAggregationService.scala#L60
 * Unlike Tacos responses from arcade-tageting which can be:
 * ** campaigns (from Campaigns Service),
 * ** Recommmendations (from Pythia), or
 * ** Realtime Offers (from Member Actions).
 * Data from Taco can be mocked with Kamaji. Documentation: https://wiki.audaxhealth.com/display/ENG/Kamaji+Payloads
 * @param placementType An array of placements to request from Taco.
 *                      Yes, the name is not phural even though it's a list, see the query param at Taco's route here:
 *                      https://github.com/AudaxHealthInc/taco/blob/main/web/conf/routes#L15
 * @param isTacoDirectPlacementOn Currently, we are still using useTaco hook in prod for some components such as
 *                                arcade dashboard banner. This boolean will stop the call to taco directly in case
 *                                the component is getting the campaigns from targeting service (useTaco hook)
 * @param getTacoReadOnly A flag that tells whether the useTacoDirect hook is called for readOnly purpose or
 *                        dispatch get request with the placementType
 */
export function useTacoDirect(
  placementType: CampaignPlacementType[],
  isTacoDirectPlacementOn: boolean = true,
  getTacoReadOnly: boolean = true,
): IUseTacoDirectState {
  const dispatch = useDispatch();
  const [requestedCampaigns, setRequestedCampaigns] = useState([] as ITacoRecommendation[]);
  const locale = useSelector<IReduxState, string>(selectId);

  const allTacos = useSelector<IReduxState, ITacoPlacements>(state => selectTacosData(state));
  const tacosLoading = useSelector<IReduxState, boolean>(state => selectTacosLoading(state));
  const tacosFailed = useSelector<IReduxState, boolean>(state => selectTacosError(state));

  const getTacoData = isTacoDirectPlacementOn
    ? (): ArcadeThunkAction<void> => dispatch(getTargetedContent(placementType))
    : () => defaultTacoDirectValue;

  const setTacosForPlacements = (): void => {
    const tacosForPlacement = placementType.reduce((tacosAcc, nextPlacement) => {
      const tacos = allTacos[nextPlacement];
      return tacos?.length ? [...tacosAcc, ...tacos] : tacosAcc;
    }, []);
    if (tacosForPlacement.length) {
      setRequestedCampaigns(tacosForPlacement);
    }
  };
  useEffect(() => {
    if (placementType.length && !tacosFailed && !getTacoReadOnly) {
      getTacoData();
    }
  }, [locale]);

  useEffect(() => {
    if (allTacos) {
      setTacosForPlacements();
    }
  }, [allTacos]);

  const useTacoDirectState: IUseTacoDirectState = {
    tacos: requestedCampaigns,
    loading: tacosLoading,
    error: tacosFailed,
    retry: getTacoData,
  };

  return useTacoDirectState;
}
