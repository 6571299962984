import { IPlanAccumulators, IPlanBenefits, IIdCard, IBenefit } from 'scripts/api/plans/plans.interfaces';
import { IStateData } from './reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changeSelectedProfile } from './profile-service-reducer';

export interface IPlansState {
  accumulators: IStateData<IPlanAccumulators>;
  benefits: IStateData<IPlanBenefits>;
  idCards: IStateData<IIdCard[][]>;
}

export const initialState: IPlansState = {
  accumulators: {
    arcadeDataUpdated: undefined,
    data: undefined,
    error: undefined,
    loading: false,
  },
  benefits: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  idCards: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const plans = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    getAccumulatorsSuccess: (
      state: IPlansState,
      action: PayloadAction<{ accumulators: IPlanAccumulators; arcadeDataUpdated?: string }>,
    ): void => {
      state.accumulators.arcadeDataUpdated = action.payload.arcadeDataUpdated;
      state.accumulators.data = action.payload.accumulators;
      state.accumulators.error = false;
      state.accumulators.loading = false;
    },
    getAccumulatorsLoading: (state: IPlansState): void => {
      state.accumulators.loading = true;
    },
    getAccumulatorsError: (state: IPlansState): void => {
      state.accumulators.arcadeDataUpdated = undefined;
      state.accumulators.data = undefined;
      state.accumulators.error = true;
      state.accumulators.loading = false;
    },
    getBenefitsSuccess: (state: IPlansState, action: PayloadAction<IPlanBenefits>): void => {
      state.benefits.data = action.payload;
      state.benefits.error = false;
      state.benefits.loading = false;
    },
    getBenefitsSuccessMR: (state: IPlansState, action: PayloadAction<{ [key: string]: IBenefit[] }>): void => {
      const benefitByCalledCoverageTypeMap = action.payload || {};
      const planBenefits: IPlanBenefits = {
        benefits: Object.values(benefitByCalledCoverageTypeMap).flatMap(benefit => benefit) || [],
      };
      state.benefits.data = planBenefits;
      state.benefits.loading = false;
      state.benefits.error = false;
    },
    getBenefitsLoading: (state: IPlansState): void => {
      state.benefits.loading = true;
    },
    getBenefitsError: (state: IPlansState): void => {
      state.benefits.data = undefined;
      state.benefits.error = true;
      state.benefits.loading = false;
    },
    getIdCardsSuccess: (state: IPlansState, action: PayloadAction<IIdCard[][]>): void => {
      state.idCards.data = action.payload;
      state.idCards.error = false;
      state.idCards.loading = false;
    },
    getIdCardsLoading: (state: IPlansState): void => {
      state.idCards.loading = true;
    },
    getIdCardsError: (state: IPlansState): void => {
      state.idCards.data = undefined;
      state.idCards.error = true;
      state.idCards.loading = false;
    },
  },
  extraReducers: {
    [changeSelectedProfile.toString()]: (state: IPlansState): void => {
      state.accumulators.data = undefined;
      state.benefits.data = undefined;
      state.idCards.data = undefined;
    },
  },
});

export const {
  getAccumulatorsError,
  getAccumulatorsLoading,
  getAccumulatorsSuccess,
  getBenefitsError,
  getBenefitsLoading,
  getBenefitsSuccess,
  getBenefitsSuccessMR,
  getIdCardsError,
  getIdCardsLoading,
  getIdCardsSuccess,
} = plans.actions;

export default plans.reducer;
