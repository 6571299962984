import React, { ButtonHTMLAttributes, FunctionComponent } from 'react';
import classNames from 'classnames/dedupe';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { IWhitelabelable } from '../theme/whitelabel.interfaces';
import { RdsButton } from '@rally/energon-react';
import { ButtonProps } from '@rally/energon-react/dist/esm/Button/Button.interface';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, IWhitelabelable, ButtonProps {}

export const Button: FunctionComponent<IButtonProps> = props => {
  const { className, ...rest } = props;
  return <RdsButton {...rest} className={classNames(className)} />;
};

export const ButtonWithClickTracking = withClickTracking(Button);
