import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITacoPlacements } from 'scripts/api/taco/taco.interfaces';
import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { changeLocale } from './locale-reducer';

export interface ITacoState {
  tacos: IStateData<ITacoPlacements>;
}

/**
 * Returns a new {@see IStateData} instance in a default state.
 */
export function DEFAULT_STATE<TData>(): IStateData<TData> {
  return {
    data: undefined,
    error: undefined,
    loading: false,
  };
}

/**
 * Returns a new {@see IStateData} instance marked as errored.
 * @param data Optional parameter.  Most cases will clear the current state data on error.
 */
export function ERRORED_STATE<TData>(data: TData | undefined = undefined): IStateData<TData> {
  return {
    data: data,
    error: true,
    loading: false,
  };
}

/**
 * Returns a new {@see IStateData} instance with the loading and error flags set off.
 * @param data The data to set in the state.
 */
export function SUCCESS_STATE<TData>(data: TData): IStateData<TData> {
  return {
    data,
    error: false,
    loading: false,
  };
}

export const initialState: ITacoState = {
  tacos: DEFAULT_STATE(),
};

const taco = createSlice({
  name: 'taco',
  initialState,
  reducers: {
    /**
     * Taco placements
     */
    getTacosSuccess: (state: ITacoState, action: PayloadAction<ITacoPlacements>): void => {
      if (state.tacos.data) {
        state.tacos.data = {
          ...state.tacos.data,
          ...action.payload,
        };
      } else {
        state.tacos.data = action.payload;
      }
      state.tacos.error = false;
      state.tacos.loading = false;
    },
    getTacosLoading: (state: ITacoState): void => {
      state.tacos.loading = true;
    },
    getTacosError: (state: ITacoState): void => {
      state.tacos = ERRORED_STATE();
    },
  },
  extraReducers: {
    [changeLocale.toString()]: (state: ITacoState): void => {
      state.tacos = DEFAULT_STATE();
    },
  },
});

export const { getTacosError, getTacosLoading, getTacosSuccess } = taco.actions;

export default taco.reducer;
