// matching the order from arcade-ui-chrome ButtonAction:
// eslint-disable-next-line max-len

import { TrackingTriggerType, TrackingClickType } from 'scripts/api/tracking/tracking.interfaces';
import { formatTrackingString, getPlacement } from '../../util/tracking/tracking-helper';

// eslint-disable-next-line max-len
// https://github.com/uhc-consumer/arcade-ui-chrome/blob/af0754b81569d84475ca88175d578d49657e975b/packages/components/src/footer/links/link-data.ts#L24
export enum ISharedEntryPointAction {
  localeChanged = 1,
  toggleNav,
}

export enum EventType {
  Tracking = 'tracking',
  Action = 'action',
}

export interface ISharedEntryPointActionEventProps {
  locale?: string;
  event?: MouseEvent;
  isNavOpen?: boolean;
}

export interface ISharedEntryPointActionEventPayload {
  action: ISharedEntryPointAction;
  props: ISharedEntryPointActionEventProps;
}

export interface ISharedEntryPointTrackingEventPayload {
  event: MouseEvent;
  features: string[];
  track: string;
  href?: string;
}

export interface IBaseTrackingEvent {
  trigger: TrackingTriggerType;
  uri: string;
  serviceVersion: string;
  featureList: ISharedEntryPointTrackingEventPayload['features'];
  actionName: ReturnType<typeof formatTrackingString>;
  placement: ReturnType<typeof getPlacement>;
  clickType?: TrackingClickType;
  externalDomain?: string;
  externalUrl?: string;
}
