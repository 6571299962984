import { changeLocale } from 'scripts/reducers/locale-reducer';
import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IIncentivesState {
  /**
   * The state data used for programOverviewData in the incentives-dashboard (AKA the rewards component).
   * See https://github.com/AudaxHealthInc/incentives-dashboard/blob/master/src/components/dashboard/readme.md
   * for more details.
   */
  incentives: IStateData<any>;
  /*
   * Eligibility check for program overview
   */
  programOverviewEligibility: IStateData<any>;
}

export const initialState: IIncentivesState = {
  incentives: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  programOverviewEligibility: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const incentives = createSlice({
  name: 'incentives',
  initialState,
  reducers: {
    getIncentivesSuccess: (state: IIncentivesState, action: PayloadAction<any>): void => {
      state.incentives.data = action.payload;
      state.incentives.error = false;
      state.incentives.loading = false;
    },
    getIncentivesLoading: (state: IIncentivesState): void => {
      state.incentives.loading = true;
    },
    getIncentivesError: (state: IIncentivesState): void => {
      state.incentives.data = undefined;
      state.incentives.error = true;
      state.incentives.loading = false;
    },
    getProgramOverviewEligibilitySuccess: (state: IIncentivesState, action: PayloadAction<any>): void => {
      state.programOverviewEligibility.data = action.payload;
      state.programOverviewEligibility.error = false;
      state.programOverviewEligibility.loading = false;
    },
    getProgramOverviewEligibilityLoading: (state: IIncentivesState): void => {
      state.programOverviewEligibility.loading = true;
    },
    getProgramOverviewEligibilityError: (state: IIncentivesState): void => {
      state.programOverviewEligibility.data = undefined;
      state.programOverviewEligibility.error = true;
      state.programOverviewEligibility.loading = false;
    },
  },
  extraReducers: {
    [changeLocale.toString()]: (state: IIncentivesState): void => {
      state.incentives.loading = false;
      state.incentives.error = undefined;
      state.incentives.data = undefined;
    },
  },
});

export const {
  getIncentivesSuccess,
  getIncentivesLoading,
  getIncentivesError,
  getProgramOverviewEligibilitySuccess,
  getProgramOverviewEligibilityLoading,
  getProgramOverviewEligibilityError,
} = incentives.actions;
export default incentives.reducer;
