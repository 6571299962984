import { ITheme, ThemedPropsToThemeValue } from './themes.interfaces';
import { getDefaultTheme } from './default';

/**
 * Helper function to get a property from the theme and fallback to the default theme if
 * the property does not exist on the theme. This overloaded function will return the value
 * of the property if given a theme. Otherwise, it will return a getter function to the value
 * of the property. The getter is for use in our styled components.
 */
export function fromTheme<T extends keyof ITheme>(property: T): ThemedPropsToThemeValue<T>;
export function fromTheme<T extends keyof ITheme>(property: T, theme: ITheme): ITheme[T];
export function fromTheme<T extends keyof ITheme>(property: T, theme?: ITheme): ThemedPropsToThemeValue<T> | ITheme[T] {
  const defaultTheme = getDefaultTheme();
  if (theme) {
    return theme?.[property] || defaultTheme[property];
  }
  return ({ theme }) => theme?.[property] || defaultTheme[property];
}

export const isDesktop =  (theme?: ITheme): boolean => {
  const defaultTheme = getDefaultTheme();
  const currentTheme = theme || defaultTheme;
  const mediaQueryString = `(min-width:${fromTheme('desktopMinWidth', currentTheme)})`;
  return window.matchMedia(mediaQueryString).matches;
} 


