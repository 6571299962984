import { LocaleKey } from '../../util/constants/i18n.constants';
import { ILocale, LocaleCountry, LocaleLanguage } from './locale.interfaces';

export const defaultLocale: ILocale = {
  id: `${LocaleLanguage.English}-${LocaleCountry.UnitedStates}`,
  language: LocaleLanguage.English,
  country: LocaleCountry.UnitedStates,
};

function getSanitizedLocale(locale: string = ''): string {
  const separator = locale.charAt(2);

  if (separator === '-' || separator === '_') {
    const localeArr = locale.split(separator);
    if (
      checkIfExistsInEnum(LocaleLanguage, localeArr[0].toLowerCase()) &&
      checkIfExistsInEnum(LocaleCountry, localeArr[1].toUpperCase())
    ) {
      localeArr[0] = localeArr[0].toLowerCase();
      localeArr[1] = localeArr[1].toUpperCase();

      return localeArr.join('-');
    } else {
      return defaultLocale.id;
    }
  }
  if (checkIfExistsInEnum(LocaleLanguage, locale)) {
    return locale;
  }
  return defaultLocale.id;
}

function checkIfExistsInEnum(e, locale): boolean {
  return Object.values<string>(e).includes(locale);
}

function getLanguage(sanitizedLocale: string): LocaleLanguage {
  return sanitizedLocale.split('-')[0] as LocaleLanguage;
}

function getCountry(sanitizedLocale: string): LocaleCountry | null {
  return sanitizedLocale.includes('-') ? (sanitizedLocale.split('-')[1] as LocaleCountry) : null;
}

export function getLocale(locale?: string): ILocale {
  const localeResult = locale || localStorage.getItem(LocaleKey) || defaultLocale.id;
  const sanitizedLocaleResult = getSanitizedLocale(localeResult);
  return {
    id: sanitizedLocaleResult,
    language: getLanguage(sanitizedLocaleResult),
    country: getCountry(sanitizedLocaleResult),
  };
}
