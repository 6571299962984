import { IResponse } from '../api.interfaces';
import { ITacoRecommendation } from '../targeting/targeting.interfaces';

export enum TacoTrackEventType {
  Click = 'click',
  Impression = 'impression',
}

// Snooze option is not supported by V1 of the Taco API.
// We use snooze instead of "dismiss" for dismissing as Pythia has requested.
export enum TacoTrackEventTypeV2 {
  Click = 'click',
  Impression = 'impression',
  Snooze = 'snooze',
}
export interface ITacoPlacements {
  [campaignPlacementType: string]: ITacoRecommendation[];
}

export interface ITacoTrackEventV2 {
  recommendation: ITacoRecommendation;
  eventType: TacoTrackEventType | TacoTrackEventTypeV2;
}

export interface ITacoResponse extends IResponse<ITacoPlacements> {}

export interface ITacoTrackEventPostData {
  rallyId: string;
  sessionId: string;
  campaignId: string;
  eventType: string;
  placementType: string;
  timestamp: number;
  origContent: ITacoRecommendation;
}
