import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArcadeThunkAction, IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectAnalyticsInitialization } from 'scripts/selectors/app-selectors';
import {
  selectAccumulatorsData,
  selectAccumulatorsError,
  selectAccumulatorsLoading,
  selectBenefitsData,
  selectBenefitsError,
  selectBenefitsLoading,
} from 'scripts/selectors/plans-service-selectors';
import { initializeAdobeForUnauth, initializeAllAnalytics } from 'scripts/thunks/analytics-thunks';
import { getAccumulators, getBenefits } from 'scripts/thunks/plans-service-thunks';
import { IEmptyComponent } from 'scripts/features/general.interfaces';
import {
  selectRealTimeOfferCountData,
  selectRealTimeOfferCountError,
  selectRealTimeOfferCountLoading,
} from 'scripts/selectors/targeting-service-selectors';
import { getRealTimeOfferCount } from 'scripts/thunks/targeting-service-thunks';
import {
  selectCurrentUser,
  selectProductsData,
  selectProductsError,
  selectProductsLoading,
} from 'scripts/selectors/profile-service-selectors';
import { getProducts } from 'scripts/thunks/profile-service-thunks';
import { isCS } from 'scripts/util/user/user';

export interface IAnalyticsCheck<T> {
  selectData: (state: IReduxState) => T;
  selectError: (state: IReduxState) => boolean;
  selectLoading: (state: IReduxState) => boolean;
  getDataThunk: () => ArcadeThunkAction<Promise<void>>;
  shouldAttemptFetch?: boolean;
}

const AnalyticsDataCheck = <T,>(props: PropsWithChildren<IAnalyticsCheck<T>>): JSX.Element | null => {
  const { selectData, selectError, selectLoading, getDataThunk, shouldAttemptFetch = true, children } = props;
  const selectedData = useSelector(selectData);
  const selectedError = useSelector(selectError);
  const selectedLoading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedData && !selectedError && !selectedLoading && shouldAttemptFetch) {
      dispatch(getDataThunk());
    }
  }, [selectedData, selectedError, selectedLoading]);

  return !selectedData && !selectedError && shouldAttemptFetch ? null : <>{children}</>;
};

const RawAnalytics: FC<IEmptyComponent> = (): null => {
  const analyticsInitialization = useSelector(selectAnalyticsInitialization);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!analyticsInitialization) {
      dispatch(initializeAllAnalytics());
    }
  }, [analyticsInitialization]);

  return null;
};

const UnauthAnalytics: FC<IEmptyComponent> = (): null => {
  const analyticsInitialization = useSelector(selectAnalyticsInitialization);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!analyticsInitialization) {
      dispatch(initializeAdobeForUnauth());
    }
  }, [analyticsInitialization]);

  return null;
};

const AnalyticsWithData: FC<IEmptyComponent> = (): JSX.Element => {
  const currentUser = useSelector(selectCurrentUser);
  // ARC-13034 Should not fetch Accumulators for CS users
  const isCSUser = isCS(currentUser);

  return (
    /* This check is for initializing Adobe Analytics and Amplitude */
    <AnalyticsDataCheck
      selectData={selectBenefitsData}
      selectError={selectBenefitsError}
      selectLoading={selectBenefitsLoading}
      getDataThunk={getBenefits}
    >
      {/* This check is for initializing Amplitude */}
      <AnalyticsDataCheck
        selectData={selectAccumulatorsData}
        selectError={selectAccumulatorsError}
        selectLoading={selectAccumulatorsLoading}
        getDataThunk={getAccumulators}
        shouldAttemptFetch={!isCSUser}
      >
        {/* This check is for setting amplitudeWaitForValues */}
        <AnalyticsDataCheck
          selectData={selectRealTimeOfferCountData}
          selectError={selectRealTimeOfferCountError}
          selectLoading={selectRealTimeOfferCountLoading}
          getDataThunk={getRealTimeOfferCount}
          shouldAttemptFetch={false}
        >
          {/* This check is for setting amplitudeWaitForValues */}
          <AnalyticsDataCheck
            selectData={selectProductsData}
            selectError={selectProductsError}
            selectLoading={selectProductsLoading}
            getDataThunk={getProducts}
          >
            <RawAnalytics />
          </AnalyticsDataCheck>
        </AnalyticsDataCheck>
      </AnalyticsDataCheck>
    </AnalyticsDataCheck>
  );
};

export { AnalyticsWithData, AnalyticsDataCheck, RawAnalytics, UnauthAnalytics };
