import { CoverageTypeCode } from '../api.interfaces';
import { FundingType, MrSnpType, ProgramType } from '../profile/profile.interfaces';

/**
 * ChatVendorType 'NotLivePerson' is not an expected value but it doesn't matter.
 * We only care if the value is 'LivePerson' and don't know any other potential values.
 */
export enum ChatVendorType {
  LivePerson = 'LivePerson',
  NotLivePerson = 'NotLivePerson',
  NoChat = 'No Chat',
  Genesys = 'Genesys',
}

/**
 * There will be additional unknown properties in getSegmentationDataResponse.
 * But they will all be pass-thru to and from Live-Person.
 */
export interface ISegmentationData {
  chatVendor: ChatVendorType;
}

export interface IMemberInfo {
  firstName?: string;
  lastName?: string;
  dob?: string;
  memberId?: string;
  groupId?: string;
  planId?: string;
}

export interface IMrMemberInfo {
  coverageTypeCode?: CoverageTypeCode;
  fundingArrangement?: FundingType;
  governmentProgramTypeCode?: ProgramType;
  snpType?: MrSnpType;
  planName?: string;
  groupNumber?: string;
}

export interface IMrChatPackage {
  init: (
    getId: () => string,
    getEnvironment: () => string,
    setLpTag: () => void,
    secSection: () => void,
    issuerUrl: string,
    getMemberInfo: () => IMrMemberInfo[],
    collectUnauthenticatedSDEData: () => void,
  ) => void;
  setLpTag: () => void;
}

export interface IMrChatClaimsPackage {
  collectUnauthenticatedSDEData: () => void;
  setSection: () => void;
}

export interface IMrChatLpTag {
  section: [string];
  events: {
    bind: (
      appName: string,
      eventName: string,
      callback: (data: Record<any, any>, eventInfo: { appName: string; eventName: string }) => void,
    ) => string;
  };
}

export interface IMobileHoops {
  startTime?: string;
  endTime?: string;
  frequency?: string;
  timeZone?: string;
  isTwentyFourSeven?: boolean;
}
