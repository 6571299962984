import { ILocale } from 'scripts/util/locale/locale.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: ILocale | null = null;

const locale = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    changeLocale: (state: ILocale, action: PayloadAction<ILocale>): ILocale => {
      return action.payload;
    },
  },
});

export const { changeLocale } = locale.actions;

export default locale.reducer;
