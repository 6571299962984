import styled, { css } from 'styled-components';
import { desktopOnly, mobileOnly } from './breakpoints';
import { fromTheme } from './themes/themes.utils';

export const sansFont = css`
  font-family: ${fromTheme('ffSans')};
`;

// this is only used for H1 and H2
export const serifFont = css`
  font-family: ${fromTheme('ffSerif')};
`;

export const heading1 = css`
  ${serifFont}
  font-size: ${fromTheme('fs36')};
  font-weight: ${fromTheme('fwHeading1')};
  line-height: ${fromTheme('lh44')};
  ${mobileOnly`
    font-size: ${fromTheme('fs29')};
    line-height: ${fromTheme('lh36')};
  `}
`;

export const heading2 = css`
  ${serifFont}
  font-size: ${fromTheme('fs32')};
  font-weight: ${fromTheme('fwHeading2')};
  line-height: ${fromTheme('lh40')};
  ${mobileOnly`
    font-size: ${fromTheme('fs26')};
    line-height: ${fromTheme('lh32')};
  `}
`;

export const heading3 = css`
  ${sansFont}
  font-size: ${fromTheme('fs29')};
  font-weight: ${fromTheme('fwHeading3')};
  line-height: ${fromTheme('lh36')};
  ${mobileOnly`
    font-size: ${fromTheme('fs23')};
    line-height: ${fromTheme('lh28')};
  `}
`;

export const heading4 = css`
  ${sansFont}
  font-size: ${fromTheme('fs26')};
  font-weight: ${fromTheme('fwHeading4')};
  line-height: ${fromTheme('lh32')};
  ${mobileOnly`
    font-size: ${fromTheme('fs20')};
    line-height: ${fromTheme('lh24')};
  `}
`;

export const heading5 = css`
  ${sansFont}
  font-size: ${fromTheme('fs23')};
  font-weight: ${fromTheme('fwHeading5')};
  line-height: ${fromTheme('lh32')};
  ${mobileOnly`
    font-size: ${fromTheme('fs18')};
    line-height: ${fromTheme('lh24')};
  `}
`;

export const heading6 = css`
  ${sansFont}
  font-size: ${fromTheme('fs20')};
  font-weight: ${fromTheme('fwHeading6')};
  line-height: ${fromTheme('lh24')};
  ${mobileOnly`
    font-size: ${fromTheme('fs16')};
    line-height: ${fromTheme('lh20')};
  `}
`;

export const sectionHeaderH2 = css`
  ${heading6}
  ${desktopOnly`
    font-weight: ${fromTheme('fwMedium')};
  `}
  ${mobileOnly`
    font-weight: ${fromTheme('fwSemiBold')};
 `}
`;

export const body1 = css`
  ${sansFont}
  font-size: ${fromTheme('fs18')};
  font-weight: ${fromTheme('fwBody1')};
  line-height: ${fromTheme('lh24')};
  ${mobileOnly`
    font-size: ${fromTheme('fs14')};
    line-height: ${fromTheme('lh20')};
  `}
`;

export const body2 = css`
  ${sansFont}
  font-size: ${fromTheme('fs16')};
  font-weight: ${fromTheme('fwBody2')};
  line-height: ${fromTheme('lh20')};
  ${mobileOnly`
    font-size: ${fromTheme('fs14')};
  `}
`;

export const body3 = css`
  ${sansFont}
  font-size: ${fromTheme('fs14')};
  font-weight: ${fromTheme('fwBody3')};
  line-height: ${fromTheme('lh20')};
  ${mobileOnly`
    font-weight: ${fromTheme('fwMedium')};
  `}
`;

export const small = css`
  ${sansFont}
  font-size: ${fromTheme('fs13')};
  font-weight: ${fromTheme('fwSmall')};
  line-height: ${fromTheme('lh16')};
`;

export const micro = css`
  ${sansFont}
  font-size: ${fromTheme('fs11')};
  font-weight: ${fromTheme('fwMicro')};
  line-height: ${fromTheme('lh16')};
`;

export const $H1 = styled.h1`
  ${heading1}
`;
