import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { desktopOnly } from 'scripts/styles/breakpoints';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import styled from 'styled-components';
import { Feature } from '../feature/feature';
import { LinkWithClickTracking } from '../link/link';

export const ErrorMessage: FunctionComponent<{ componentId?: string }> = props => {
  const { t } = useTranslation(Dictionary.ERRORS);
  const { componentId } = props;
  const dataTestId = 'error-message-contact-us';
  return (
    <Feature featureId="error-boundary-message">
      <$ErrorBoundaryMessage data-testid="error-boundary-message">
        <$ErrorMessageContainer>
          <$H3>{t('WE_ARE_SORRY')}</$H3>
          <$P>{t('WE_ARE_SORRY_ERROR_MESSAGE')}</$P>
          <$ErrorMessageLink
            doNotWhiteLabel
            data-testid={dataTestId}
            dataUiElementName={dataTestId}
            dataTrackId={dataTestId}
            to="/help"
          >
            {t('ERR_CONTACT_US')}
          </$ErrorMessageLink>
          {componentId && (
            <$P>
              {t('DASHBOARD_COMPONENT_ID_CAPTION')}: {componentId}
            </$P>
          )}
        </$ErrorMessageContainer>
      </$ErrorBoundaryMessage>
    </Feature>
  );
};

const $ErrorBoundaryMessage = styled.div`
  font-size: ${fromTheme('fs16')};
  ${desktopOnly`
    display: flex;
    flex-direction: column;
    height: 380px;
    justify-content: center;
`}
`;

const $ErrorMessageContainer = styled.div`
  background: ${fromTheme('clrWhite')};
  font-weight: ${fromTheme('fwMedium')};
  line-height: ${fromTheme('lh20')};
  text-align: center;
  ${desktopOnly`
    padding:${fromTheme('spacing48')}
  `}
`;

const $ErrorMessageLink = styled(LinkWithClickTracking)`
  color: ${fromTheme('clrPrimary')};
  font-weight: ${fromTheme('fwSemiBold')};
`;

const $H3 = styled.h3`
  color: ${fromTheme('clrNeutral30')};
`;

const $P = styled.p`
  color: ${fromTheme('clrNeutral50')};
  padding-bottom: ${fromTheme('spacing24')};
  padding-top: ${fromTheme('spacing16')};
`;
