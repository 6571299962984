import { IStateData } from 'scripts/reducers/reducer.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ITelemedicineEligibility,
  ITelemedicineEligibilityV4,
} from 'scripts/api/telemedicine-eligibility/telemedicine-eligibility.interfaces';

export interface ITelemedicineEligibilityState {
  eligibility: IStateData<ITelemedicineEligibility>;
  eligibilityV4: IStateData<ITelemedicineEligibilityV4>;
}

export const initialState: ITelemedicineEligibilityState = {
  eligibility: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  eligibilityV4: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const telemedicineEligibility = createSlice({
  name: 'telemedicineEligibility',
  initialState,
  reducers: {
    getTelemedicineEligibilitySuccess: (
      state: ITelemedicineEligibilityState,
      action: PayloadAction<ITelemedicineEligibility>,
    ): void => {
      state.eligibility.data = action.payload;
      state.eligibility.error = false;
      state.eligibility.loading = false;
    },
    getTelemedicineEligibilityLoading: (state: ITelemedicineEligibilityState): void => {
      state.eligibility.loading = true;
    },
    getTelemedicineEligibilityError: (state: ITelemedicineEligibilityState): void => {
      state.eligibility.data = undefined;
      state.eligibility.error = true;
      state.eligibility.loading = false;
    },
    getTelemedicineEligibilityV4Success: (
      state: ITelemedicineEligibilityState,
      action: PayloadAction<ITelemedicineEligibilityV4>,
    ): void => {
      state.eligibilityV4.data = action.payload;
      state.eligibilityV4.error = false;
      state.eligibilityV4.loading = false;
    },
    getTelemedicineEligibilityV4Loading: (state: ITelemedicineEligibilityState): void => {
      state.eligibilityV4.loading = true;
      state.eligibilityV4.error = false;
    },
    getTelemedicineEligibilityV4Error: (state: ITelemedicineEligibilityState): void => {
      state.eligibilityV4.data = undefined;
      state.eligibilityV4.error = true;
      state.eligibilityV4.loading = false;
    },
  },
});

export const {
  getTelemedicineEligibilitySuccess,
  getTelemedicineEligibilityLoading,
  getTelemedicineEligibilityError,
  getTelemedicineEligibilityV4Success,
  getTelemedicineEligibilityV4Loading,
  getTelemedicineEligibilityV4Error,
} = telemedicineEligibility.actions;

export default telemedicineEligibility.reducer;
