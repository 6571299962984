import { IConfig, IFeatureFlags } from './environment.interfaces';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    CONFIG: IConfig;
    FEATURE_FLAGS: IFeatureFlags;
    CUSTOM_UHC_MEMBER_ROOT?: string;
  }
}

const CONFIG: IConfig = {
  ...window.CONFIG,
  // TODO: When we get around to running Arcade-UI in Docker locally, we can pull this code.
  ARCADE_WEB_BASE_URL: (window.CONFIG && window.CONFIG.ARCADE_WEB_BASE_URL) || window.location.origin,
};
export default CONFIG;
