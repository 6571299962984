import { useContext, useEffect, useRef, MutableRefObject } from 'react';
import { FeatureContext } from 'scripts/contexts/feature-context/feature-context';
import {
  sendImpressionTracking as sendImpressionTrackingReact,
  sendSectionLoad as sendSectionLoadReact,
} from 'scripts/util/tracking/shared-tracking-events';

export interface IUseRenderTrackingState<T> {
  ref: MutableRefObject<T>;
}

export interface IUseRenderTrackingProps {
  campaign?: any;
  isImpression?: boolean;
  trackData?: any;
  trackLabel?: string;
}

export function useRenderTracking<T extends HTMLElement>(props: IUseRenderTrackingProps): IUseRenderTrackingState<T> {
  const { campaign, isImpression, trackData = {}, trackLabel } = props;
  const { featureList } = useContext(FeatureContext);
  const ref = useRef<T>();

  useEffect(() => {
    if (isImpression) {
      sendImpressionTrackingReact(trackLabel, featureList, ref, campaign);
    } else {
      sendSectionLoadReact(trackLabel, trackData, featureList, ref);
    }
  }, []);

  return {
    ref,
  };
}
