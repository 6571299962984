import { IFhCookie } from 'scripts/api/api.interfaces';
import Cookies from 'js-cookie';

/**
 * This method converts the raw FH Cookie value into an FH Cookie model
 * @param fhCookieValue - the string value of the FH Cookie
 */
export function getFhCookieFromString(fhCookieValue: string): IFhCookie {
  const fhCookieArray = fhCookieValue?.split('!') || [];

  const [sessionIdOrEmpty, lastUpdated, timeToIdleSeconds] = fhCookieArray;

  const sessionId = sessionIdOrEmpty?.trim() === '' ? undefined : sessionIdOrEmpty;

  if (sessionId === undefined || lastUpdated === undefined || timeToIdleSeconds === undefined) {
    console.debug(
      `FH Cookie is malformed. Received a value of ${fhCookieValue}.` + 'FH functionality may not operate.',
    );
  }

  return {
    sessionId,
    lastUpdated,
    timeToIdleSeconds,
  };
}

/**
 * This method obtains the FH Cookie value given the name of the FH Cookie
 * @param fhCookieName - the name of the FH Cookie
 */
export function getFhCookie(fhCookieName: string): IFhCookie | undefined {
  const cookie = Cookies.get(fhCookieName);
  return getFhCookieFromString(cookie);
}
