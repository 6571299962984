import { Cache, CacheFactory, CacheOptions } from 'cachefactory';
import CONFIG from 'scripts/util/constants/config';
import { getLocale } from 'scripts/util/locale/locale';
import { CacheName } from './api.interfaces';

export const cacheFactory = new CacheFactory();

const cacheOptions: CacheOptions = {
  cacheFlushInterval: CONFIG.ARCADE_WEB_CACHE_TTL_MS * 4,
  maxAge: CONFIG.ARCADE_WEB_CACHE_TTL_MS,
  deleteOnExpire: 'aggressive',
  storageMode: 'sessionStorage',
  storagePrefix: 'arcade.cache.',
};

export function getCache(name: CacheName): Cache {
  if (cacheFactory.exists(name)) {
    return cacheFactory.get(name);
  }
  const cache = cacheFactory.createCache(name, cacheOptions);
  if (cache?.put) {
    // Datadog is attaching a property (_datadog_xhr) to all requests that has a circular reference in it
    // this causes an error when the cache serializes the value. So we will filter out the _datadog_xhr property from being cached.
    const originalPut = cache.put;
    cache.put = (name: string | number, value: any, ...rest) => {
      try {
        const filteredValue = value?.request ? { ...value, request: { ...value.request, _datadog_xhr: null } } : value;
        return originalPut.apply(cache, [name, filteredValue, ...rest]);
      } catch (err) {
        console.warn(`Error putting into ${name} cache`, err);
      }
    };
  }
  return cache;
}

/**
 * The cache stored in this app is also used in claims-ui and activate-ui, so keeping the key consistent between those apps is important.
 * @param url The url of the request.
 * @param localizedCacheKey True if the request is for a resource that is unique for a language. E.g. targeting responses that contains
 * language specific verbiage.
 * @param planToken The plan token that comes from cookies ("plan-token"). It is unique to the plan so that any existing cache will not
 * be used when the user switches plans.
 * @returns A unique cache key used to store a response in the cache.
 */
export function getCacheKey(url: string, localizedCacheKey: boolean = false, planToken?: string): string {
  return url + (localizedCacheKey ? `__${getLocale().id}` : '') + (planToken ? `__${planToken}` : '');
}

export function destroyAll(): void {
  cacheFactory.destroyAll();
}
