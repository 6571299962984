import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { addLoadingBarRequest, removeLoadingBarRequest } from './loading-bar-thunks';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { getLastUpdatedCoverageTypes, LineOfBusinessToPartnerId } from 'scripts/api/connect/connect-api.utils';
import { selectLineOfBusiness } from 'scripts/selectors/population-selectors';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { hasFindCare } from 'scripts/util/profile/profile';
import {
  getLastIndexedLoading,
  getLastIndexedSuccess,
  getLastIndexedError,
} from 'scripts/reducers/connect-service-reducer';
import { ConnectApi } from 'scripts/api/connect/connect-api';

export function getLastIndexed(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(addLoadingBarRequest('connect_lastIndexed'));
      dispatch(getLastIndexedLoading());
      const profile = currentUser.selectProfile(getState());
      const coverageTypes = getLastUpdatedCoverageTypes(profile.planCoverages);
      const lineOfBusiness = selectLineOfBusiness(getState());
      if (lineOfBusiness === LineOfBusiness.MR && coverageTypes.length && hasFindCare(profile)) {
        const partnerId = LineOfBusinessToPartnerId.get(lineOfBusiness);
        const { data } = await ConnectApi.getLastIndexed(partnerId, coverageTypes);
        dispatch(getLastIndexedSuccess(data.lastIndexed));
      } else {
        dispatch(getLastIndexedSuccess({}));
      }
    } catch (error) {
      dispatch(getLastIndexedError());
    } finally {
      dispatch(removeLoadingBarRequest('connect_lastIndexed'));
    }
  };
}
