import { sendCampaignsLoadedEvent } from 'scripts/util/tracking/adobe-analytics';
import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import {
  recommendationsLoadedFromApi,
  recommendationsLoadedFromSession,
} from 'scripts/reducers/targeting-service-reducer';
import { formatError } from 'scripts/util/error/error';

function recommendationsLoaded(api: MiddlewareAPI): void {
  try {
    const targetingState = api.getState();
    const recommendationsState = targetingState && targetingState.recommendations;
    const recommendations = recommendationsState && recommendationsState.data;
    if (recommendationsState && !recommendationsState.error && recommendations !== undefined) {
      sendCampaignsLoadedEvent({ recommendations });
    }
  } catch (error) {
    console.warn(`failed to send recommendations loaded event to Adobe Analytics: ${formatError(error)}`);
  }
}

const ADOBE_ANALYTICS_EVENTS: { [key: string]: (api: MiddlewareAPI) => void } = {
  [recommendationsLoadedFromApi.toString()]: recommendationsLoaded,
  [recommendationsLoadedFromSession.toString()]: recommendationsLoaded,
};

export const adobeAnalyticsMiddleware: Middleware =
  (api: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  <A extends Action>(action: A) => {
    const result = next(action);
    const handler = ADOBE_ANALYTICS_EVENTS[action.type];
    handler && handler(api);
    return result;
  };
