import { EntryPoint } from '@rally/arcade-ui-components';
import React, { FunctionComponent, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { IProducts, IProfile } from 'scripts/api/profile/profile.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectId } from 'scripts/selectors/locale-selectors';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { selectProductsData, selectProfileData } from 'scripts/selectors/profile-service-selectors';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getProducts } from 'scripts/thunks/profile-service-thunks';
import { selectConfig, selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { IConfig, IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import { selectLastIndexedData } from 'scripts/selectors/connect-service-selectors';
import { changeNavOpen } from 'scripts/thunks/loading-bar-thunks';
import { getLastIndexed } from 'scripts/thunks/connect-service-thunks';
import { ILastIndexed } from 'scripts/api/connect/connect-service.interfaces';
import { changeLocale } from 'scripts/thunks/locale-thunks';
import { useMutationObserver } from 'scripts/hooks/use-mutation-observer/use-mutation-observer';
import { useBuildEntryPointProps } from './use-build-entry-point-props';

export interface ISharedEntryPointProps extends ISharedEntryPointStateToProps, ISharedEntryPointDispatchToProps {}

interface ISharedEntryPointStateToProps {
  locale: string;
  population: IPopulation;
  profile: IProfile;
  products: IProducts;
  featureFlags: IFeatureFlags;
  config: IConfig;
  version?: string;
  lastIndexed: ILastIndexed;
}

interface ISharedEntryPointDispatchToProps {
  getLastIndexed: () => Promise<void>;
  getProducts: () => Promise<void>;
  changeNavOpen: (navOpen: boolean) => undefined;
  changeLocale: (localeId: string) => undefined;
}

export const RawSharedEntryPoint: FunctionComponent<ISharedEntryPointProps> = props => {
  const { children, ...additionalProps } = props;
  const ref = useRef<HTMLDivElement>(null);

  const entryPointProps = useBuildEntryPointProps(additionalProps);

  const mutationObserverOptions = {
    attributes: true,
    attributeFilter: ['target'],
    subtree: true,
    childList: true,
  };

  const callback = useCallback(() => ref.current, [ref.current]);

  useMutationObserver({ callback, options: mutationObserverOptions, ref });

  return (
    <div ref={ref} data-testid="shared-header-footer-entry-point">
      <EntryPoint {...entryPointProps}>{children}</EntryPoint>
    </div>
  );
};

const mapStateToProps = (state: IReduxState): ISharedEntryPointStateToProps => ({
  locale: selectId(state),
  population: selectPopulation(state),
  profile: selectProfileData(state),
  products: selectProductsData(state),
  featureFlags: selectFeatureFlags(state),
  config: selectConfig(state),
  version: selectConfig(state).ARCADE_WEB_VERSION,
  lastIndexed: selectLastIndexedData(state),
});

const mapDispatchToProps = {
  getProducts,
  getLastIndexed,
  changeNavOpen,
  changeLocale,
};

export const SharedEntryPoint = connect(mapStateToProps, mapDispatchToProps)(RawSharedEntryPoint);
