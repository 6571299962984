import { Header } from '@rally/arcade-ui-components';
import React, { FunctionComponent } from 'react';
import { mobileOnly } from 'scripts/styles/breakpoints';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import styled from 'styled-components';
import { SharedEntryPoint } from './shared-entry-point';

export const SharedHeader: FunctionComponent = () => {
  return (
    <SharedEntryPoint>
      <$SharedHeaderContainer>
        <Header />
      </$SharedHeaderContainer>
    </SharedEntryPoint>
  );
};

// padding-top set to 48px due to header overlay (only on C&S dashboard)
const $SharedHeaderContainer = styled.div`
  ${mobileOnly`
    padding-top: ${fromTheme('spacing48')};
  `};
`;
