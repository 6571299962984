import React, { FunctionComponent, useEffect } from 'react';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import arcadeStore from 'scripts/store/store';
import { connect, Provider } from 'react-redux';
import RRLoadingBar from 'react-redux-loading-bar';
import { addLoadingBarRequest, removeLoadingBarRequest } from 'scripts/thunks/loading-bar-thunks';
import { ILoadingBarRequest } from 'scripts/reducers/app-reducer';
import { selectLoadingBarRequests } from 'scripts/selectors/app-selectors';
import styled from 'styled-components';
import { fromTheme } from 'scripts/styles/themes/themes.utils';

export interface ILoadingBarProps extends ILoadingBarStateToProps, ILoadingBarDispatchToProps {}

interface ILoadingBarStateToProps {
  loadingBarRequests: Record<string, ILoadingBarRequest>;
}

interface ILoadingBarDispatchToProps {
  addLoadingBarRequest: (s: string) => void;
  removeLoadingBarRequest: (s: string) => void;
}

export const RawLoadingBar: FunctionComponent<ILoadingBarProps> = props => {
  useEffect(() => {
    if (Object.keys(props.loadingBarRequests).length === 0) {
      return;
    }
    // if there are any requests that have requested a load prior to component render,
    // we will issue an artificial request to ensure that the loading bar loads for at least one full loading cycle.
    props.addLoadingBarRequest('initial_render');
    props.removeLoadingBarRequest('initial_render');
  }, []);

  return (
    <div>
      <Provider store={arcadeStore}>
        <$RRLoadingBar progressIncrease={10} />
      </Provider>
    </div>
  );
};

const $RRLoadingBar = styled(RRLoadingBar)`
  background: ${fromTheme('clrOlympicBlue')};
  height: ${fromTheme('spacing8')};
  left: 0;
  z-index: 1;
`;

const mapStateToProps = (state: IReduxState): ILoadingBarStateToProps => ({
  loadingBarRequests: selectLoadingBarRequests(state),
});

export const LoadingBar = connect(mapStateToProps, { addLoadingBarRequest, removeLoadingBarRequest })(RawLoadingBar);
