import React, { useState } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { connect } from 'react-redux';
import { Modal } from 'scripts/ui/modal/modal';
import { useTranslation } from 'react-i18next';
import { selectConfig } from 'scripts/selectors/app-selectors';
import styled from 'styled-components';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { mobileOnly } from 'scripts/styles/breakpoints';
import { primaryButton, secondaryButton } from 'scripts/styles/buttons';
import { body3, heading4 } from 'scripts/styles/typography';
import { millisecondsToSeconds, secondsToMilliseconds } from 'date-fns';
import { PagesData } from 'scripts/config/pages-data';
import { useInterval } from 'scripts/hooks/use-interval/use-interval';
import { usePageTracking } from 'scripts/hooks/use-page-tracking/use-page-tracking';
import { PageTags } from 'scripts/api/tracking/tracking.interfaces';
import { ResourceAnchorWithClickTracking } from 'scripts/ui/anchor/resource-anchor';

interface IInactiveProps extends IInactiveStateToProps {
  handleContinueSession: () => void;
}

interface IInactiveStateToProps {
  heartbeatBuffer: number;
}

// classNames / data-testids
export const timerName = 'countdown-timer';
export const inactiveControlName = 'inactive-controls';
export const continueButtonName = 'cta-btn';
export const logoutLinkIdName = 'logout-link';
export const logoutLinkName = 'logout';
export const logoutLinkClassNames = 'inverse-btn black';

export const RawInactiveModal: React.FunctionComponent<IInactiveProps> = props => {
  const { heartbeatBuffer, handleContinueSession } = props;
  const { t } = useTranslation(Dictionary.INACTIVE);
  const [timeLeft, setTimeLeft] = useState(() => millisecondsToSeconds(heartbeatBuffer));

  useInterval(
    () => {
      setTimeLeft(time => time - 1);
    },
    timeLeft > 0 ? secondsToMilliseconds(1) : null,
  );

  usePageTracking(PagesData.inactive.name, [PageTags.inactive], true);

  return (
    <Modal
      dataUiSection="inactive"
      customCloseModal={handleContinueSession}
      fullScreen={false}
      modalTitle={t(`${Dictionary.INACTIVE}:INACTIVE_HEADER`)}
    >
      <$Timer data-testid={timerName}>{timeLeft}</$Timer>
      <$Message>{t('INACTIVE_MESSAGE')}</$Message>
      <$InactiveControls data-testid={inactiveControlName}>
        <$LinkWithClickTracking
          className="rds-secondary-button"
          data-testid={logoutLinkIdName}
          dataTrackId={logoutLinkName}
          dataUiElementName={logoutLinkName}
          resourceValue="/logout"
        >
          {t('LOGOUT')}
        </$LinkWithClickTracking>

        <$ControlButton data-testid={continueButtonName} onClick={handleContinueSession}>
          {t('CONTINUE_SESSION')}
        </$ControlButton>
      </$InactiveControls>
    </Modal>
  );
};

const $Timer = styled.div`
  border: 3px solid ${fromTheme('clrGreyDark')};
  border-radius: 50%;
  box-sizing: border-box;
  color: ${fromTheme('clrGreyDark')};
  font-size: 6em;
  font-weight: ${fromTheme('fwLight')};
  height: 175px;
  line-height: 175px;
  margin: 0 auto;
  text-align: center;
  width: 175px;
`;

const $Message = styled.p`
  ${heading4}
  margin: 35px auto;
  max-width: 400px;
  text-align: center;
`;

const $InactiveControls = styled.div`
  display: flex;
  justify-content: center;
  ${mobileOnly`
    flex-direction: column;
    align-items: center;
  `}
`;

const $ControlButton = styled.button<{ isSecondary?: boolean }>`
  ${props => (props.isSecondary ? secondaryButton : primaryButton)};
  margin: 0 ${fromTheme('spacing16')} 0;
  width: 200px;
  ${mobileOnly`
    margin-bottom: ${fromTheme('spacing24')};
  `}
`;

const $LinkWithClickTracking = styled(ResourceAnchorWithClickTracking)`
  ${body3}
  border-radius: ${fromTheme('br20')};
  height: auto;
  margin-left: ${fromTheme('spacing8')};
  ${mobileOnly`
    margin-left: 4px;
  `}
  padding-bottom: ${fromTheme('spacing8')};
  padding-top: ${fromTheme('spacing8')};
  text-align: center;
  width: 200px;
`;

const mapStateToProps = (state: IReduxState): IInactiveStateToProps => {
  return {
    heartbeatBuffer: selectConfig(state).ARCADE_WEB_HEARTBEAT_BUFFER_MS,
  };
};

export const InactiveModal = connect(mapStateToProps)(RawInactiveModal);
