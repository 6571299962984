import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IStateData } from './reducer.interfaces';
import { IIDCardTranslate } from 'scripts/api/id-card-accessibility/id-card-accessibility-interfaces';

export interface IIDCardAccessibilityState {
  idCardTranslation: IStateData<IIDCardTranslate>;
}

export const initialState: IIDCardAccessibilityState = {
  idCardTranslation: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

const idCardAccessibility = createSlice({
  name: 'idCardAccessibility',
  initialState,
  reducers: {
    postTranslateSuccess: (state: IIDCardAccessibilityState, action: PayloadAction<IIDCardTranslate>): void => {
      state.idCardTranslation.data = action.payload;
      state.idCardTranslation.error = false;
      state.idCardTranslation.loading = false;
    },
    postTranslateError: (state: IIDCardAccessibilityState): void => {
      state.idCardTranslation.data = undefined;
      state.idCardTranslation.error = true;
      state.idCardTranslation.loading = false;
    },
    postTranslateLoading: (state: IIDCardAccessibilityState): void => {
      state.idCardTranslation.loading = true;
    },
  },
});

export const { postTranslateSuccess, postTranslateError, postTranslateLoading } = idCardAccessibility.actions;

export default idCardAccessibility.reducer;
