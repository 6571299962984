import { ITheme } from './themes.interfaces';
import * as designSystemUhc from '@rally/ui-themes/dist/web/uhc/tokens.es6';

const getColors = () => ({
  // colors
  clrBannerBlue: '#BFE8FF',
  clrBannerGreen: '#E6F5E5',
  clrBlack: designSystemUhc.RDS_COLOR_BLACK,
  clrBrand: designSystemUhc.RDS_COLOR_BRAND,
  clrFocus: designSystemUhc.RDS_FOCUS_OUTLINE_COLOR,
  clrGrey: designSystemUhc.RDS_COLOR_SYSTEM_UHC_GREY,
  clrGreyDark: designSystemUhc.RDS_COLOR_SYSTEM_UHC_GREY_DARK,
  clrGrey3: '#EEEEEE',
  clrGrey4: '#E5E5E6',
  clrGrey5: '#6E7072',
  clrGreen: designSystemUhc.RDS_COLOR_SYSTEM_KELLY_GREEN,
  clrGreenLight: designSystemUhc.RDS_COLOR_SYSTEM_KELLY_GREEN_LIGHT,
  clrGreenDark: designSystemUhc.RDS_COLOR_SYSTEM_KELLY_GREEN_DARK,
  clrInteractive: designSystemUhc.RDS_COLOR_BRAND,
  clrNeutral20: designSystemUhc.RDS_COLOR_NEUTRAL_20,
  clrNeutral30: designSystemUhc.RDS_COLOR_NEUTRAL_30,
  clrNeutral50: designSystemUhc.RDS_COLOR_NEUTRAL_50,
  clrNeutral80: designSystemUhc.RDS_COLOR_NEUTRAL_80,
  clrNeutral90: designSystemUhc.RDS_COLOR_NEUTRAL_90,
  clrNeutral95: designSystemUhc.RDS_COLOR_NEUTRAL_95,
  clrNeutral98: designSystemUhc.RDS_COLOR_NEUTRAL_98,
  clrOlympicBlue: designSystemUhc.RDS_COLOR_SYSTEM_OLYMPIC_BLUE,
  clrOlympicBlueDark: designSystemUhc.RDS_COLOR_SYSTEM_OLYMPIC_BLUE_DARK,
  clrOlympicBlueLight: designSystemUhc.RDS_COLOR_SYSTEM_OLYMPIC_BLUE_LIGHT,
  clrOrange: designSystemUhc.RDS_COLOR_SYSTEM_AMBER_ORANGE,
  clrOrangeLight: designSystemUhc.RDS_COLOR_SYSTEM_AMBER_ORANGE_LIGHT,
  clrOrangeDark: designSystemUhc.RDS_COLOR_SYSTEM_AMBER_ORANGE_DARK,
  clrPrimary: designSystemUhc.RDS_COLOR_SYSTEM_UHC_BLUE,
  clrPrimaryBrightBlue: '#CCF2F7',
  clrPrimaryDark: designSystemUhc.RDS_COLOR_SYSTEM_UHC_BLUE_DARK,
  clrRed: designSystemUhc.RDS_COLOR_SYSTEM_RUBY_RED,
  clrRedDark: designSystemUhc.RDS_COLOR_SYSTEM_RUBY_RED_DARK,
  clrRedGuardsman: '#c40000',
  clrTopElementBackground: '#FAFCFF',
  clrWarning: designSystemUhc.RDS_COLOR_SYSTEM_RUBY_RED_DARK,
  clrWarningDarkBanner: '#F5B700',
  clrWarningLight: designSystemUhc.RDS_COLOR_SYSTEM_RUBY_RED_LIGHT,
  clrWarningLightBanner: '#FEF9EA',
  clrWhite: designSystemUhc.RDS_COLOR_WHITE,
  clrData1: designSystemUhc.RDS_COLOR_SYSTEM_OLYMPIC_BLUE_DARK,
  clrData2: designSystemUhc.RDS_COLOR_SYSTEM_OLYMPIC_BLUE,
  clrData3: designSystemUhc.RDS_COLOR_SYSTEM_KELLY_GREEN_DARK,
  clrData4: designSystemUhc.RDS_COLOR_SYSTEM_RUBY_RED_DARK,
  clrData5: designSystemUhc.RDS_COLOR_SYSTEM_KELLY_GREEN,
  clrClaimIcon: '#19ace3',
  clrLoading: '#00BCD6',
  clrAdBgGreen: '#49c57e',
  clrBgGreen: '#007000',
  clrAdBgHover: '#52c884',
  clrBgHover: '#008400',
  clrGreenCheck: '#a2cb4c',
  clrWarningIcon: '#C24E14',

  // box shadows
  bsSpread4: designSystemUhc.RDS_BOX_SHADOW_RADIUS_4,
  bsSpread6: designSystemUhc.RDS_BOX_SHADOW_NEUTRAL_WITH_SPREAD_6,
  bsSpread10: designSystemUhc.RDS_BOX_SHADOW_NEUTRAL_WITH_SPREAD_10,
  bsSpreadBottom4: '0px 1px 4px rgba(25, 25, 26, 0.16)',
});

const getStructure = () => {
  return {
    // breakpoints
    desktopMaxWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_WIDESCREEN) - 1,
    desktopMinWidth: designSystemUhc.RDS_BREAKPOINTS_DESKTOP,
    docMaxWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_MAX_WIDTH),
    mainNavHeight: designSystemUhc.RDS_SPACING_SIZE_48,
    phoneMaxWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_TABLET_PORTRAIT) - 1,
    smallPhoneMaxWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_MOBILE),
    subNavHeight: designSystemUhc.RDS_SPACING_SIZE_40,
    tabletMaxWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_DESKTOP) - 1,
    tabletMinWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_TABLET_LANDSCAPE),
    tabletLandscapeMaxWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_DESKTOP) - 1,
    tabletLandscapeMinWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_TABLET_LANDSCAPE),
    tabletPortraitMaxWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_TABLET_LANDSCAPE) - 1,
    tabletPortraitMinWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_TABLET_PORTRAIT),
    widescreenMinWidth: parseInt(designSystemUhc.RDS_BREAKPOINTS_WIDESCREEN),
    // spacing
    careProfileSectionHeight: '160px',
    desktopContainerSpacing: designSystemUhc.RDS_SPACING_SIZE_32,
    spacing8: designSystemUhc.RDS_SPACING_SIZE_8,
    spacing16: designSystemUhc.RDS_SPACING_SIZE_16,
    spacing24: designSystemUhc.RDS_SPACING_SIZE_24,
    spacing32: designSystemUhc.RDS_SPACING_SIZE_32,
    spacing40: designSystemUhc.RDS_SPACING_SIZE_40,
    spacing48: designSystemUhc.RDS_SPACING_SIZE_48,
    spacing64: designSystemUhc.RDS_SPACING_SIZE_64,
    spacing72: '72px',
    spacing80: '80px',
    spacing320: '320px',
    // border radius
    br0: designSystemUhc.RDS_BORDER_RADIUS_0,
    br4: designSystemUhc.RDS_BORDER_RADIUS_4,
    br8: designSystemUhc.RDS_BORDER_RADIUS_8,
    br12: designSystemUhc.RDS_BORDER_RADIUS_12,
    br20: designSystemUhc.RDS_BORDER_RADIUS_20,
    brCircle: designSystemUhc.RDS_BORDER_RADIUS_CIRCLE,
    brRounded: designSystemUhc.RDS_BORDER_RADIUS_ROUNDED,
  };
};

const getBaseFontWeights = () => ({
  // Stylus values mapped to new values, here for reference when migrating
  // Stylus   = Old value = Styled Components
  // fwThin   = 100       = N/A
  // fwLight  = 200       = N/A
  // fwNormal = 300       = fwLight
  // fwMedium = 400       = fwRegular
  // fwBold   = 500       = fwMedium
  // fwThick  = 600       = fwSemiBold
  // N/A      = 700       = fwBold
  fwLight: designSystemUhc.RDS_FONT_WEIGHT_LIGHT,
  fwRegular: designSystemUhc.RDS_FONT_WEIGHT_REGULAR,
  fwMedium: designSystemUhc.RDS_FONT_WEIGHT_MEDIUM,
  fwSemiBold: designSystemUhc.RDS_FONT_WEIGHT_SEMIBOLD,
  fwBold: designSystemUhc.RDS_FONT_WEIGHT_BOLD,
});

const getFonts = () => {
  const baseFontWeights = getBaseFontWeights();
  return {
    ...baseFontWeights,
    fsBody: designSystemUhc.RDS_BASE_FONT_SIZE,
    lhBody: 1.3,
    ffSans: designSystemUhc.RDS_FONT_FAMILY,
    ffSerif: designSystemUhc.RDS_FONT_FAMILY_SERIF,
    fs11: designSystemUhc.RDS_FONT_SIZE_11,
    fs13: designSystemUhc.RDS_FONT_SIZE_13,
    fs14: designSystemUhc.RDS_FONT_SIZE_14,
    fs16: designSystemUhc.RDS_FONT_SIZE_16,
    fs18: designSystemUhc.RDS_FONT_SIZE_18,
    fs20: designSystemUhc.RDS_FONT_SIZE_20,
    fs23: designSystemUhc.RDS_FONT_SIZE_23,
    fs26: designSystemUhc.RDS_FONT_SIZE_26,
    fs29: designSystemUhc.RDS_FONT_SIZE_29,
    fs32: designSystemUhc.RDS_FONT_SIZE_32,
    fs36: designSystemUhc.RDS_FONT_SIZE_36,
    fwHeading1: baseFontWeights.fwSemiBold,
    fwHeading2: baseFontWeights.fwSemiBold,
    fwHeading3: baseFontWeights.fwMedium,
    fwHeading4: baseFontWeights.fwMedium,
    fwHeading5: baseFontWeights.fwMedium,
    fwHeading6: baseFontWeights.fwMedium,
    fwBody1: baseFontWeights.fwMedium,
    fwBody2: baseFontWeights.fwMedium,
    fwBody3: baseFontWeights.fwSemiBold,
    fwSmall: baseFontWeights.fwSemiBold,
    fwMicro: baseFontWeights.fwSemiBold,
    lh16: designSystemUhc.RDS_FONT_LINE_HEIGHT_16,
    lh20: designSystemUhc.RDS_FONT_LINE_HEIGHT_20,
    lh24: designSystemUhc.RDS_FONT_LINE_HEIGHT_24,
    lh28: designSystemUhc.RDS_FONT_LINE_HEIGHT_28,
    lh32: designSystemUhc.RDS_FONT_LINE_HEIGHT_32,
    lh36: designSystemUhc.RDS_FONT_LINE_HEIGHT_36,
    lh40: designSystemUhc.RDS_FONT_LINE_HEIGHT_40,
    lh44: designSystemUhc.RDS_FONT_LINE_HEIGHT_44,
  };
};

export const getDefaultTheme = (): ITheme => ({
  ...getColors(),
  ...getStructure(),
  ...getFonts(),
});
