/**
 * Convert comma separated string into an array of string.
 *
 * @param config string
 * @param delimitor string; default is comma.
 * @returns an arry of string
 */
export function splitConfig(config: string | undefined, delimitor: string = ','): string[] {
  return (config || '')
    .trim()
    .split(delimitor)
    .map(element => element.trim());
}

export function deleteLeadingZero(groupNumber: string): string {
  if (groupNumber?.charAt(0) === '0') {
    return groupNumber?.substring(1);
  }
  return groupNumber;
}
