import { createGlobalStyle } from 'styled-components';
import { fromTheme } from './themes/themes.utils';

export const GlobalStyle = createGlobalStyle`
    body.rds-on {
        .rds-link-inline {
            text-decoration: none;
        }
        .rds-primary-button {
            font-weight: ${fromTheme('fwBold')};
            transition: none;
            &:visited {
                color: ${fromTheme('clrWhite')};
            }
            &:active {
                color: ${fromTheme('clrPrimary')};
            };
        }
        .rds-secondary-button {
            font-weight: ${fromTheme('fwBold')};
            transition: none;
            &:visited {
                color: ${fromTheme('clrBrand')};
            }
            &:active {
                color: ${fromTheme('clrWhite')};
            };
        }
    }
    body.advantage.rds-on {
        .column {
            padding: 0 !important;
        }
        display: flex;
        flex-direction: column;
        height: 100%;
        #dashboard section:not(:first-child) {
            border-bottom: none;
        }
        padding-top: 0 !important;
    }
    // hide the scroll bar when the modal is open
    html.modal-open {
        overflow-y: hidden;
    }
    body.hide-outlines {
        *,
        *:focus {
            outline: 0 !important;
        }
    }
    *:focus {
        outline: 3px solid ${fromTheme('clrFocus')};
    }

    .fade-in {
        animation: fade-in 1s;
        animation-delay: .5s;
        animation-fill-mode: both;
    }
    .hide {
        display: none !important
    }
    .first-main-item {
        position: absolute;
        top: -100%;
    }
    #ui-view-page {
        background-color: ${fromTheme('clrWhite')};
        position: relative;
        z-index: 0;
    }
    #ui-view-modal {
        position: relative;
        z-index: 1;
    }
    #arcade-ui-app {
        position: relative;
    }
    fieldset {
        outline: 0;
    }
    svg {
        display: inline;
        &:not(:root) {
            overflow: hidden;
        }
    }
    .external-link {
        text-decoration: underline;
        &::after {
            content: ''/"opens in a new window";
            mask: url('images/icons/icon-external-link.svg') no-repeat 50% 50%;
            mask-size: cover;
            background-color: ${fromTheme('clrPrimary')};
            display: inline-table;
            font-size: 0.7em;
            height: 1em;
            margin-left: 0.3em;
            vertical-align: middle;
            white-space: nowrap;
            width: 1em;
        }
    }

    // over dashboard body, this global value set padding equal to the height of header in responsive
    @media (max-width:  ${fromTheme('tabletMaxWidth')}px) {
        body.rds-on {
            background-color: ${fromTheme('clrWhite')};
            left: 0vw;
            position: absolute;
            transition: left 0.4s, padding-top 0.4s;
            width: 100%;
            .container {
                padding-left: ${fromTheme('spacing16')};
                padding-right: ${fromTheme('spacing16')};
            }
            &.nav-open {
                left: -209vh;
            }
            &.header-hidden {
                padding-top: 0px;
            }
            .hide-mobile { display: none !important; }
            #ui-view-page,
            #ui-view-modal {
              position: static;
            }
        }
    }
    @media (min-width: ${fromTheme('desktopMinWidth')}) {
        body.rds-on {
            .hide-desktop { display: none !important; }
        }
    }

    @media print {
        * {
            overflow: visible !important;
        }
        // These are some minor improvements to make the pages look not terrible when printed
        body {
            padding-top: 0 !important;
        }
        header {
            position: relative !important;
        }
        // Changing the margin  and statements padding so that page titles will be visible and not covered by the sub-nav.
        // Temporary fix until the sub-nav and main nav are suppressed on advantage-ui.
        .advantage #ui-view-page {
            margin-top: 100px;
        }
        // Modals may be printed
        html.modal-open,
        body.modal-open {
            #ui-view-page {
            display: none !important;
            }
        }
        // Ensure all items in the dd are displayed
        dd {
            height: auto !important;
        }

        // 'print-content' is the id of the print modal container element appended as first child to document.body
        // This needs to be done using the insertBefore DOM method,
        // so the css has been added here in global.ts as styled compnonents do not return type HTMLElement (reqd for insertBefore method)
        // and therefore, to avoid using other css-in-js libraries for this solitary case
        #print-content {
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            * {
                display: block;
            }
            & ~ * {
                display: none !important;
            }
        }
    }
    @media screen {
        #print-content {
            display: none;
        }
    }

    @keyframes fade-in {
        0% { opacity: 0 }
        100% { opacity: 1 }
    }

    @keyframes fade-out {
        0% { opacity: 1 }
        100% { opacity: 0 }
    }
`;
