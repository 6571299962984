export enum ArcadeAuthType {
  Arachne = 'Arachne',
  Optum = 'Optum',
  Rally = 'Rally',
  LwR = 'LwR',
}

export enum ArcadeWebTheme {
  UHC = 'unitedhealth',
}

export enum ArcadeEnvironment {
  Bluesteel = 'Bluesteel',
  Development = 'Development',
  Integration = 'Integration',
  Loadtest = 'Loadtest',
  Local = 'Local',
  Production = 'Production',
}

export interface IConfig {
  ARCADE_WEB_TIME_MACHINE_DATE: string;
  ARCADE_WEB_ACTIVATE_DYNAMIC_ONBOARDING_PLANS: string;
  ARCADE_WEB_ACTIVATE_LOB_STEPS_V7: string;
  ARCADE_WEB_ACTIVATE_LOB_STEPS_V9: string;
  ARCADE_WEB_ACTIVITY_TIMEOUT_MS: number;
  ARCADE_WEB_ADOBE_ANALYTICS_URL: string;
  ARCADE_WEB_ADVANTAGE_BASE_DOMAIN: string;
  ARCADE_WEB_ALTERNATIVE_BASE_URLS: string;
  ARCADE_WEB_ARACHNE_SESSION_BASE_URL: string;
  ARCADE_WEB_BASE_API_URL: string;
  ARCADE_WEB_BASE_URL: string;
  ARCADE_WEB_PSX_ELIGIBLE_POLICIES: string;
  ARCADE_WEB_BETA_DASHBOARD_PILOT_SUPPRESSED_PRODUCT_CODES: string;
  ARCADE_WEB_BETA_DASHBOARD_PILOT_PRODUCT_CODES: string;
  ARCADE_WEB_BRANCH_IO_SDK_KEY: string;
  ARCADE_WEB_BRANCH_BANNER_FILTER_POLICY_NUMBERS: string;
  ARCADE_WEB_CACHE_TTL_MS: number;
  ARCADE_WEB_CANOPY_HEALTH_DASHBOARD_URL: string;
  ARCADE_WEB_CANOPY_HEALTH_SUB_PROVIDER_ID: string;
  ARCADE_WEB_CANOPY_HEALTH_VIRTUAL_VISITS_URL: string;
  ARCADE_WEB_CAREMARK_URL: string;
  ARCADE_WEB_CATERPILLAR_OOP2_PVRC: string;
  ARCADE_WEB_CLAIMS_SEARCH_MONTHS_AGO: string;
  ARCADE_WEB_CS_ALLOWLIST_LINKS: string;
  ARCADE_WEB_CS_CUSTOM_FAQ_POLICIES: string;
  ARCADE_WEB_CS_ESPRESSO_DASHBOARD_PATH: string;
  ARCADE_WEB_CS_HIDE_PRIOR_AUTH_FAQ_PRIMARY_CUSTOMER_IDS: string;
  ARCADE_WEB_CS_PREMIUM_PAYMENT_PRIMARY_CUSTOMER_IDS: string;
  ARCADE_WEB_CS_PREMIUM_PAYMENT_PRIMARY_ELIGIBLE_IDS: string;
  ARCADE_WEB_CS_HELP_BH_GROUP_IDS: string;
  ARCADE_WEB_CS_MN_PLAN_IDS: string;
  ARCADE_WEB_CS_NY_PHARMACY_SUPPRESSION: string;
  ARCADE_WEB_CS_TRANSPERFECT_SCRIPT_URL: string;
  ARCADE_WEB_DATADOG_RUM_APPLICATION_ID: string;
  ARCADE_WEB_DATADOG_RUM_CLIENT_TOKEN: string;
  ARCADE_WEB_DATADOG_ENV: string;
  ARCADE_WEB_DEFAULT_AUTH: ArcadeAuthType;
  ARCADE_WEB_REWARDS_MOCK_BASE_URL: string;
  ARCADE_WEB_EI_CONTACT_US_UPDATE_BLOCKED_MEMBERIDS: string;
  ARCADE_WEB_EI_QUICKLINKS_CANOPY_NETWORK_TYPE_CODES: string;
  ARCADE_WEB_EPMP_OPT_OUT_POLICIES: string;
  ARCADE_WEB_ENVIRONMENT_NAME: ArcadeEnvironment;
  ARCADE_WEB_ESPRESSO_DASHBOARD_PATH: string;
  ARCADE_WEB_ESPRESSO_DIGITAL_ID_CARD_URL: string;
  ARCADE_WEB_ESPRESSO_IFP_DASHBOARD_PATH: string;
  ARCADE_WEB_EXPRESS_SCRIPTS_URL: string;
  ARCADE_WEB_GROUP_IDS_SUPPRESS: string;
  ARCADE_WEB_MEMBERSHIP_CATEGORY_SUPPRESS: string;
  ARCADE_WEB_GLOBAL_NAV_EI_URL: string;
  ARCADE_WEB_GLOBAL_NAV_CS_URL: string;
  ARCADE_WEB_GLOBAL_NAV_MR_URL: string;
  ARCADE_WEB_HEARTBEAT_BUFFER_MS: number;
  ARCADE_WEB_HIDE_COSTS_NAVIGATION_COMPASS_INDICATORS: string;
  ARCADE_WEB_HIDE_COSTS_POLICIES: string;
  ARCADE_WEB_HIDE_PCP_POLICIES: string;
  ARCADE_WEB_ID_CARD_ACCESSIBILITY_GROUP_NUMBERS: string;
  ARCADE_WEB_PRE_ENROLLMENT: string;
  ARCADE_WEB_ID_CARD_MODAL_REROUTE_URLS: string;
  ARCADE_WEB_ID_CARD_HIDE_GROUP_NAME_BY_GROUP_NUMBER: string;
  ARCADE_WEB_LINK_TIMER_MS: number;
  ARCADE_WEB_LIVE_PERSON_COMMON_SCRIPT_APPROACH_URL: string;
  ARCADE_WEB_LIVE_PERSON_ISSUER_URL: string;
  ARCADE_WEB_LIVE_PERSON_SCRIPT_URL_CS: string;
  ARCADE_WEB_LIVE_PERSON_SCRIPT_URL_EI: string;
  ARCADE_WEB_LIVE_PERSON_OPTUM_SCRIPT_URL_EI: string;
  ARCADE_WEB_LIVE_PERSON_SCRIPT_URL_MR: string;
  ARCADE_WEB_LWR_RP_SUFFIX: string;
  ARCADE_WEB_MR_ALLOWLIST_LINKS: string;
  ARCADE_WEB_MR_NUDGE_SUPPRESSED_POLICIES: string;
  ARCADE_WEB_MR_NUDGE_EXPLORE_SSO_LINK: string;
  ARCADE_WEB_MR_NUDGE_LEARN_MORE_SSO_LINK: string;
  ARCADE_WEB_MR_NUDGE_ALT_LINK: string;
  ARCADE_WEB_MR_RX_SUPPRESSION_2021_OVERRIDE_GROUP_NUMBERS: string;
  ARCADE_WEB_MR_RX_SUPPRESSION_2023_OVERRIDE_GROUP_NUMBERS: string;
  ARCADE_WEB_MR_SPENDING_COST_SUMMARY_SUPPRESSION_POLICIES: string;
  ARCADE_WEB_MR_SPENDING_COST_SUMMARY_SUPPRESSION_PRODUCTS: string;
  ARCADE_WEB_MR_UAW_GROUP_NUMBERS: string;
  ARCADE_WEB_MYUHC_AARP_BASE_URL: string;
  ARCADE_WEB_MYUHC_BASE_URL: string;
  ARCADE_WEB_MYUHC_COMMUNITY_BASE_URL: string;
  ARCADE_WEB_MYUHC_DOMAIN_UNIFICATION_BASE_URL: string;
  ARCADE_WEB_MYUHC_EXTEND_SESSION_PATH: string;
  ARCADE_WEB_MYUHC_LEGACY_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_ES_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_HM_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_HT_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_ILO_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_KC_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_KR_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_MY_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_SO_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_TAG_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_VI_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_ZH_BASE_URL: string;
  ARCADE_WEB_MYUHC_LOGOUT_PATH: string;
  ARCADE_WEB_MYUHC_MEDICA_BASE_URL: string;
  ARCADE_WEB_MYUHC_MEDICARE_BASE_URL: string;
  ARCADE_WEB_MYUHC_PCP_BASE_URL: string;
  ARCADE_WEB_MYUHC_RETIREE_BASE_URL: string;
  ARCADE_WEB_NOTIFICATION_MESSAGE: string;
  ARCADE_WEB_OPTUM_AARP_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_AUTH_CLIENT_ID: string;
  ARCADE_WEB_OPTUM_AUTH_PATH: string;
  ARCADE_WEB_OPTUM_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_AUTH_SCOPE: string;
  ARCADE_WEB_OPTUM_AUTH_URL: string;
  ARCADE_WEB_OPTUM_MEDICA_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_MEDICARE_AUTH_CLIENT_ID: string;
  ARCADE_WEB_OPTUM_MEDICARE_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_PCP_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_RETIREE_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_RX_CHECK_ORDER_STATUS: string;
  ARCADE_WEB_OPTUM_RX_FIND_AND_PRICE_DRUGS: string;
  ARCADE_WEB_OPTUM_RX_FIND_PHARMACY: string;
  ARCADE_WEB_OPTUM_RX_ORDER_PRESCIPTIONS: string;
  ARCADE_WEB_OPTUM_VIRTUAL_BASE_URL: string;
  ARCADE_WEB_ORDER_COVID_TESTS_STORE_URL: string;
  ARCADE_WEB_OVC_PROVIDER_IDS: string;
  ARCADE_WEB_PCP_SCHEDULING_WIDGET_URL: string;
  ARCADE_WEB_PLAN_SELECTOR_URL: string;
  ARCADE_WEB_PLAN_SELECTOR_AUTOMATIC_URL: string;
  ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL: string;
  ARCADE_WEB_RALLY_ADVANTAGE_URL: string;
  ARCADE_WEB_RALLY_AUTH_LOGOUT_PATH: string;
  ARCADE_WEB_RALLY_AUTH_URL: string;
  ARCADE_WEB_RALLY_CLAIMS_URL: string;
  ARCADE_WEB_RALLY_CONNECT_URL: string;
  ARCADE_WEB_RALLY_ENGAGE_URL: string;
  ARCADE_WEB_RALLY_HUGGIN_URL: string;
  ARCADE_WEB_RALLY_IHR_URL: string;
  ARCADE_WEB_RALLY_PAY_ENVIRONMENT: string;
  ARCADE_WEB_RALLY_PAY_WIDGET_CONFIRMATION: string;
  ARCADE_WEB_RALLY_REWARDS_URL: string;
  ARCADE_WEB_RALLY_RX_ADVANTAGE_URL: string;
  ARCADE_WEB_RALLY_RX_URL: string;
  ARCADE_WEB_RALLY_SPEAK_EASY_BASE_URL: string;
  ARCADE_WEB_RALLY_SPEAK_EASY_LOAD_BASE_URL: string;
  ARCADE_WEB_RALLY_VIRTUAL_CARE_BASE_URL: string;
  ARCADE_WEB_REDESIGN_THEMES: string;
  ARCADE_WEB_RENEW_ACTIVE_QUICK_LINK_URL: string;
  ARCADE_WEB_SAME_TAB_PROMO_LINKS: string;
  ARCADE_WEB_NEW_TAB_PROMO_LINKS: string;
  ARCADE_WEB_SHOW_CS_DENTAL_ID_CARD_POLICIES: string;
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_CREATE_URL: string;
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_LOADING_URL: string;
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_POSTER_URL: string;
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_TOKEN: string;
  ARCADE_WEB_SUNDAY_SKY_SCRIPT_URL: string;
  ARCADE_WEB_SUNDOG_PCD_CHANGE_URL: string;
  ARCADE_WEB_THEME: ArcadeWebTheme;
  ARCADE_WEB_TRACKING_DELAY_MS: number;
  ARCADE_WEB_TRACKING_INTERVAL_MS: number;
  ARCADE_WEB_TRACKING_MAX_RETRY_COUNT: number;
  ARCADE_WEB_ONBOARDING_TASKS_BFF_MAX_RETRY_COUNT: number;
  ARCADE_WEB_UCARDHUB_EXCLUSION_GROUP_IDS: string;
  ARCADE_WEB_VERSION: string;
  ARCADE_WEB_VIRTUAL_VISIT_CAMPAIGN_ID: string;
  ARCADE_WEB_WALGREENS_URL: string;
  ARCADE_WEB_WAYS_TO_SAVE_IMAGE_BASE_URL: string;
  ARCADE_WEB_MYUHC_PRELOGIN_URL: string;
  ARCADE_WEB_EXCHANGE_PRELOGIN_URL: string;
  ARCADE_WEB_UHC_PRELOGIN_URL: string;
  ARCADE_WEB_COMMUNITYPLAN_PRELOGIN_URL: string;
  ARCADE_WEB_CE_CLAIMS_AGGREGATE_BASE_URL: string;
  ARCADE_WEB_ONBOARDING_BASE_URL: string;
}

// All Feature Flags must be booleans
export interface IFeatureFlags {
  ARCADE_FEATURES_ACTIVATE_DYNAMIC_ONBOARDING_MINI_MODAL: boolean;
  ARCADE_FEATURES_ACTIVATE_DYNAMIC_ONBOARDING_MODAL_BACK_BUTTON_ENABLED: boolean;
  ARCADE_FEATURES_ACTIVATE_DYNAMIC_ONBOARDING_PAPERLESS: boolean;
  ARCADE_FEATURES_ACTIVATE_DYNAMIC_ONBOARDING_WARNING_MODAL: boolean;
  ARCADE_FEATURES_ACTIVATE_ELIGIBLE: boolean;
  ARCADE_FEATURES_ACTIVATE_HEALTH_TOPICS_MODAL: boolean;
  ARCADE_FEATURES_ACTIVATE_ONBOARDING_ENABLED: boolean;
  ARCADE_FEATURES_ACTIVATE_ONBOARDING_EI_ENABLED: boolean;
  ARCADE_FEATURES_ACTIVATE_ONBOARDING_CS_ENABLED: boolean;
  ARCADE_FEATURES_ACTIVATE_URGENT_CARE_MINI_MODAL: boolean;
  ARCADE_FEATURES_ACTIVATE_V7_ENDPOINT_USED: boolean;
  ARCADE_FEATURES_ACTIVATE_V9_ENDPOINT_USED: boolean;
  ARCADE_FEATURES_ANALYTICS_DEBUG: boolean;
  ARCADE_FEATURES_BRANCH_IO_BANNER: boolean;
  ARCADE_FEATURES_CARE_TEAM_LINK_PSX_ENABLED: boolean;
  ARCADE_FEATURES_CARE_TEAM_LINK_PSX_ALL_ENI_ENABLED: boolean;
  ARCADE_FEATURES_CLAIM_MORE_OPTIONS_DROPDOWN: boolean;
  ARCADE_FEATURES_CORONAVIRUS_PCP_STRIP: boolean;
  ARCADE_FEATURES_COVID_VIRTUAL_VISIT_DISCLAIMER: boolean;
  ARCADE_FEATURES_CS_CALL_BACK_EXPERIENCE: boolean;
  ARCADE_FEATURES_CS_CLAIMS_CARD_VIEW_ENABLED: boolean;
  ARCADE_FEATURES_CS_KS_LANG_SUPPORT_ENABLED: boolean;
  ARCADE_FEATURES_CS_KYPR_PREMIUM_PAYMENTS: boolean;
  ARCADE_FEATURES_CS_NEW_LANGUAGES_SUPPORT_ENABLED: boolean;
  ARCADE_FEATURES_CS_SECURE_MESSAGES: boolean;
  ARCADE_FEATURES_CS_EFFECTIVE_WA_SHOW_UPDATED_VERBIAGE: boolean;
  ARCADE_FEATURES_CS_SHOW_VIRTUAL_VISITS: boolean;
  ARCADE_FEATURES_CS_NY_PHARMACY_SUPPRESSION: boolean;
  ARCADE_FEATURES_DDP_DENIED_CLAIM: boolean;
  ARCADE_FEATURES_DENIED_CLAIM: boolean;
  ARCADE_FEATURES_DENTAL_CLAIMS_AVAILABLE_MODAL: boolean;
  ARCADE_FEATURES_DENTAL_QUICK_LINK: boolean;
  ARCADE_FEATURES_DEPENDENT_REFERRALS: boolean;
  ARCADE_FEATURES_EFS_LIVE_SCRIPT_ENABELED: boolean;
  ARCADE_FEATURES_EI_CALL_US_SUPPRESSION: boolean;
  ARCADE_FEATURES_EI_CONTACT_US_UPDATE_ENABLED: boolean;
  ARCADE_FEATURES_EI_NEW_COB_LINK: boolean;
  ARCADE_FEATURES_EI_PROGRAMS_FOR_YOU_SUPPRESSION: boolean;
  ARCADE_FEATURES_EI_VIRTUAL_VISIT_PCP_ROW_ITEM: boolean;
  ARCADE_FEATURES_EI_RX_SUPPRESSION: boolean;
  ARCADE_FEATURES_ENABLE_BETADASHBOARD_EI_ALL_COVERAGE_STATUSES: boolean;
  ARCADE_FEATURES_ENABLE_ESPRESSO_FLOW_FOR_CNS: boolean;
  ARCADE_FEATURES_ENABLE_MR_GROUP_RETIREE_PRE_EFFECTIVE_BANNER: boolean;
  ARCADE_FEATURES_ENABLE_MR_INDIVIDUAL_TERMED_UCARD: boolean;
  ARCADE_FEATURES_ENABLE_PLANSELECTOR_FLOW_FOR_MNR: boolean;
  ARCADE_FEATURES_ENABLE_PRELOGIN_FLOW: boolean;
  ARCADE_FEATURES_ESPRESSO_REDIRECT_ENABLED: boolean;
  ARCADE_FEATURES_FPC_PCP_FOR_GATED: boolean;
  ARCADE_FEATURES_GLOBAL_NAV_ENI_SDK_ENABLED: boolean;
  ARCADE_FEATURES_GLOBAL_NAV_MNR_SDK_ENABLED: boolean;
  ARCADE_FEATURES_GLOBAL_NAV_CNS_SDK_ENABLED: boolean;
  ARCADE_FEATURES_HEALTH_ASSESMENT: boolean;
  ARCADE_FEATURES_HIGH_COST_CLAIM: boolean;
  ARCADE_FEATURES_ID_CARDS_PAPERLESS_PREFERENCES: boolean;
  ARCADE_FEATURES_LIVE_PERSON_CHAT_CS: boolean;
  ARCADE_FEATURES_LIVE_PERSON_CHAT_EI: boolean;
  ARCADE_FEATURES_LIVE_PERSON_CHAT_MR: boolean;
  ARCADE_FEATURES_LIVE_PERSON_COMMON_SCRIPT_APPROACH_CS: boolean;
  ARCADE_FEATURES_LIVE_PERSON_COMMON_SCRIPT_APPROACH_MR: boolean;
  ARCADE_FEATURES_LINK_TO_INTERNATIONAL_CLAIMS: boolean;
  ARCADE_FEATURES_LWR_XSRF_HEADER_ENABLED: boolean;
  ARCADE_FEATURES_LYESMITH_SSO: boolean;
  ARCADE_FEATURES_MEDSUPP_ACCUMULATORS: boolean;
  ARCADE_FEATURES_MR_NUDGE_ENABLED: boolean;
  ARCADE_FEATURES_MR_P65_NUDGE_ENABLED: boolean;
  ARCADE_FEATURES_MR_NUDGE_SHOPPER_PROFILE_CHECK_ENABLED: boolean;
  ARCADE_FEATURES_MR_CARE_REC_TACO_RTOS: boolean;
  ARCADE_FEATURES_MR_CLAIM_DETAILS: boolean;
  ARCADE_FEATURES_MR_CLAIMS_CARD_VIEW_ENABLED: boolean;
  ARCADE_FEATURES_MR_DENTAL_CLAIM_PROVIDER_BILLED_PLAN_FIELD: boolean;
  ARCADE_FEATURES_MR_EOB_LINKS: boolean;
  ARCADE_FEATURES_MR_MY_SPENDING_SUPPRESSIONS: boolean;
  ARCADE_FEATURES_ENABLE_MR_CSPA_REWARDS: boolean;
  ARCADE_FEATURES_MR_PRE_EFF_PREMIUM_PAYMENTS: boolean;
  ARCADE_FEATURES_MR_PRE_EFFECTIVE_RX_TAB: boolean;
  ARCADE_FEATURES_MR_REWARDS_WIDGET_PRE_EFFECTIVE: boolean;
  ARCADE_FEATURES_MR_UCARD_RETIREE_2025_SUPPRESS: boolean;
  ARCADE_FEATURES_MR_ONBOARDING_TASKS_PRE_EFFECTIVE: boolean;
  ARCADE_FEATURES_MR_ONBOARDING_TASKS_EFFECTIVE: boolean;
  ARCADE_FEATURES_MR_SELECT_PCP_ENABLED: boolean;
  ARCADE_FEATURES_MY_PRESCRIPTIONS_UPDATED_LINKS: boolean;
  ARCADE_FEATURES_NAVIGUARD_MESSAGE: boolean;
  ARCADE_FEATURES_NOTIFICATION: boolean;
  ARCADE_FEATURES_OPTUM_REDESIGN_ACTIVE: boolean;
  ARCADE_FEATURES_ORDER_COVID_TESTS_DEEPLINK_ENABLED: boolean;
  ARCADE_FEATURES_PAPERLESS_MODAL_ENABLED: boolean;
  ARCADE_FEATURES_PAPERLESS_PREFERENCE_ESPRESSO_REDIRECT: boolean;
  ARCADE_FEATURES_PAY_NOW_MODAL: boolean;
  ARCADE_FEATURES_PAY_NOW_MODAL_WARNING: boolean;
  ARCADE_FEATURES_PCP_LINK_ENABLED: boolean;
  ARCADE_FEATURES_PCP_GROUP_OR_FACILITY_NAME: boolean;
  ARCADE_FEATURES_PCP_SCHEDULING_WIDGET_ENABLED: boolean;
  ARCADE_FEATURES_PCP_SKIP_UHC_WEST_REDIRECT: boolean;
  ARCADE_FEATURES_PLAN_TOKEN: boolean;
  ARCADE_FEATURES_PLAN_SELECTOR: boolean;
  ARCADE_FEATURES_PRESERVE_CACHE_ON_APP_EXIT: boolean;
  ARCADE_FEATURES_PROMO_SORTING_ENABLED: boolean;
  ARCADE_FEATURES_PSX_ROUTER_ENABLED: boolean;
  ARCADE_FEATURES_PSX_ROUTER_MNR_ENABLED: boolean;
  ARCADE_FEATURES_QUALTRICS_FEEDBACK_COMPONENT: boolean;
  ARCADE_FEATURES_RALLY_ANALYTICS: boolean;
  ARCADE_FEATURES_RALLY_PRODUCTS_API_V2: boolean;
  ARCADE_FEATURES_RALLY_SUPPRESS_FOOTER: boolean;
  ARCADE_FEATURES_REDUX_LOGGING: boolean;
  ARCADE_FEATURES_REFERRAL_REQUIRED: boolean;
  ARCADE_FEATURES_REWARDS_USE_KAMAJI: boolean;
  ARCADE_FEATURES_REWARDS_WIDGET_EFFECTIVE: boolean;
  ARCADE_FEATURES_SEND_PLAN_TOKEN: boolean;
  ARCADE_FEATURES_SHOW_BETADASHBOARD_UHC_REWARDS: boolean;
  ARCADE_FEATURES_SHOW_BETADASHBOARD_RALLY_REWARDS: boolean;
  ARCADE_FEATURES_SHOW_IOS_TRANSCRIPT_VIDEO: boolean;
  ARCADE_FEATURES_SHOW_BETADASHBOARD_EI_PRESCRIPTIONS: boolean;
  ARCADE_FEATURES_SHOW_CS_REWARDS_URL: boolean;
  ARCADE_FEATURES_BETADASHBOARD_EI_PRESCRIPTIONS_ACTIVE: boolean;
  ARCADE_FEATURES_SHOW_OOP_MAX_2: boolean;
  ARCADE_FEATURES_SHOW_PLAN_SELECTOR_LINK: boolean;
  ARCADE_FEATURES_SHOW_PLAN_SELECTOR_DIALOG_SPOTLIGHT: boolean;
  ARCADE_FEATURES_SHOW_REPLACE_UCARD_CTA_PRE_EFFECTIVE: boolean;
  ARCADE_FEATURES_SKIP_OIDC_LOGIN_FLOW: boolean;
  ARCADE_FEATURES_MNR_EXTRA_PARAMS_ENABLED: boolean;
  ARCADE_FEATURES_SPEAK_EASY_CHAT_ENABLED: boolean;
  ARCADE_FEATURES_SPEAK_EASY_DASHBOARD_WIDGET_ENABLED: boolean;
  ARCADE_FEATURES_SPENDING_REDESIGN: boolean;
  ARCADE_FEATURES_SUPPRESS_MAIL_ID_CARD_LINK: boolean;
  ARCADE_FEATURES_SUPPRESS_VIEW_ALL_CS: boolean;
  ARCADE_FEATURES_SUPPRESS_ENI_GENESYS_CHAT: boolean;
  ARCADE_FEATURES_SUPPRESS_SAVED_PROVIDER_LINK_CNS_MNR_ENI: boolean;
  ARCADE_FEATURES_TACO_DIRECT_BANNER_ON: boolean;
  ARCADE_FEATURES_TRANSPERFECT_TRANSLATION_ENABLED: boolean;
  ARCADE_FEATURES_TRANSPERFECT_TRANSLATION_CNS_ENABLED: boolean;
  ARCADE_FEATURES_UCARD_ACTIVATION_BETA_DASHBOARD_CS: boolean;
  ARCADE_FEATURES_UCARD_ACTIVATION_BETA_DASHBOARD_MR: boolean;
  ARCADE_FEATURES_UCARD_FLEX_ACTIVATION_BETA_DASHBOARD_CS: boolean;
  ARCADE_FEATURES_UCARD_FLEX_ACTIVATION_BETA_DASHBOARD_MR: boolean;
  ARCADE_FEATURES_UCARD_FLEX_CARD_ENABLED: boolean;
  ARCADE_FEATURES_UCARD_ACTIVATION_ENABLED: boolean;
  ARCADE_FEATURES_UCARD_DIGITAL_EXPERIENCE_ENABLED: boolean;
  ARCADE_FEATURES_UCARD_SUPPRESS_ID_CARD: boolean;
  ARCADE_FEATURES_UCARD_WEB_PRE_EFFECTIVE_NEW: boolean;
  ARCADE_FEATURES_UCARD_WEB_CSPA_API_ENABLED: boolean;
  ARCADE_FEATURES_UCARD_CTA_BALANCE_PAGE_ENABLED: boolean;
  ARCADE_FEATURES_UHC_REWARDS_ENABLED: boolean;
  ARCADE_FEATURES_VIDEO_BANNER_V2: boolean;
  ARCADE_FEATURES_VIEW_DIGITAL_ID_CARD_LINK_ENABLED: boolean;
  ARCADE_FEATURES_ID_CARD_ACCESSIBILITY: boolean;
  ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API: boolean;
  ARCADE_FEATURES_ENABLE_ENI_SAM_BANNER: boolean;
  ARCADE_FEATURES_TRANSPERFECT_TRANSLATION_CNS_USE_SCRIPT: boolean;
}

// Configuration values for Shared Header Footer that will be served via shared-header-footer.json via NGINX
export interface ISharedHeaderFooterConfig {
  ARCADE_WEB_SHARED_HEADER_FOOTER_CS_MATERIAL_ID: string;
  ARCADE_WEB_SHARED_HEADER_FOOTER_CS_MAUHCSO_MATERIAL_ID: string;
  ARCADE_WEB_SHARED_HEADER_FOOTER_CS_MOHNET_MATERIAL_ID: string;
  ARCADE_WEB_SHARED_HEADER_FOOTER_LAST_UPDATED: string;
  ARCADE_WEB_SHARED_HEADER_FOOTER_LAST_UPDATED_CS: string;
  ARCADE_WEB_SHARED_HEADER_FOOTER_LAST_UPDATED_MR: string;
  ARCADE_WEB_SHARED_HEADER_FOOTER_MR_MATERIAL_ID: string;
}

export interface IEnvironmentConstants {
  CONFIG: IConfig;
  FEATURE_FLAGS: IFeatureFlags;
}
