import { TargetingApi } from 'scripts/api/targeting/targeting-api';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import {
  currentUser as currentUserSelector,
  selectClientInfo,
  selectedUser as selectedUserSelector,
} from 'scripts/selectors/profile-service-selectors';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';
import {
  getAlertsError,
  getAlertsLoading,
  getAlertsSuccess,
  getCampaignsError,
  getCampaignsLoading,
  getCampaignsSuccess,
  getClientConfigError,
  getClientConfigLoading,
  getClientConfigSuccess,
  getCsFaqCustomizationsError,
  getCsFaqCustomizationsLoading,
  getCsFaqCustomizationsSuccess,
  getRealTimeOfferCountError,
  getRealTimeOfferCountLoading,
  getRealTimeOfferCountSuccess,
  getTacoRecommendationsError,
  getTacoRecommendationsLoading,
  getTacoRecommendationsSuccess,
} from 'scripts/reducers/targeting-service-reducer';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { formatError } from 'scripts/util/error/error';
import { isEI } from 'scripts/util/user/user';

type TargetingServiceThunk = () => ArcadeThunkAction<Promise<void>>;

type TargetingServicePayloadThunk<TPayload> = (
  payload: TPayload,
  shouldCallForRealTimeOffers?: boolean,
  maxCampaignsPerPlacement?: number,
) => ArcadeThunkAction<Promise<void>>;

export const getCampaigns: TargetingServicePayloadThunk<string[]> =
  (placements: string[]) => async (dispatch, getState) => {
    dispatch(getCampaignsLoading());
    try {
      const rallyId = selectRallyId(getState());
      const dependentSeqNbr = isEI(selectedUserSelector.selectProfile(getState()))
        ? selectedUserSelector.selectDependentSeqNum(getState())
        : undefined;
      const result = await TargetingApi.getCampaigns(rallyId, placements, dependentSeqNbr);

      dispatch(getCampaignsSuccess(result));
    } catch (_) {
      dispatch(getCampaignsError());
    }
  };

export const getClientConfig: TargetingServiceThunk = () => async (dispatch, getState) => {
  dispatch(getClientConfigLoading());
  try {
    const currentUser = currentUserSelector.selectProfile(getState());
    const clientInfo = selectClientInfo(getState());
    const result = await TargetingApi.getClientConfig(currentUser, clientInfo);
    dispatch(getClientConfigSuccess(result));
  } catch (_) {
    dispatch(getClientConfigError());
  }
};

export const getCsFaqCustomizations: TargetingServicePayloadThunk<string> =
  (primaryPolicyNumber: string) => async dispatch => {
    dispatch(getCsFaqCustomizationsLoading());
    try {
      const result = await TargetingApi.getCsFaqCustomizations(primaryPolicyNumber);
      dispatch(getCsFaqCustomizationsSuccess(result));
    } catch (_) {
      dispatch(getCsFaqCustomizationsError());
    }
  };

/**
 * Gets the count of Real Time Offers.  Since this is potentially tied to information stored on the session,
 * getting the Real Time Offer Count
 */
export const getRealTimeOfferCount: TargetingServiceThunk = () => async (dispatch, getState) => {
  dispatch(getRealTimeOfferCountLoading());
  const rallyId = selectRallyId(getState());
  const lineOfBusiness = selectedUserSelector.selectLineOfBusiness(getState());

  try {
    const apiCount = await TargetingApi.getRealTimeOfferCount(rallyId, lineOfBusiness);
    dispatch(getRealTimeOfferCountSuccess(apiCount));
  } catch (error) {
    console.warn(`failed to retrieve real time offer count: ${formatError(error)}`);
    dispatch(getRealTimeOfferCountError());
  }
};

/**
 * Gets the recommendations for the current user.  Since recommendations are stored on the user session, the first
 * session is checked first for previously loaded recommendations that match the current locale.
 */
export const getTacoRecommendations: TargetingServicePayloadThunk<string[]> =
  (placements: string[], shouldCallForRealTimeOffers?: boolean, maxCampaignsPerPlacement?: number) =>
  async (dispatch, getState) => {
    dispatch(getTacoRecommendationsLoading());
    try {
      const rallyId = selectRallyId(getState());
      const featureFlags = selectFeatureFlags(getState());
      const isMrUserWithMrCareRecTacoRtosOn =
        featureFlags.ARCADE_FEATURES_MR_CARE_REC_TACO_RTOS && shouldCallForRealTimeOffers;
      const dependentSeqNbr = isEI(selectedUserSelector.selectProfile(getState()))
        ? selectedUserSelector.selectDependentSeqNum(getState())
        : undefined;

      /**
       * ARC-11779: M&R users with the ARCADE_FEATURES_MR_CARE_REC_TACO_RTOS FF will get the Care Rec data through the TacoRealTimeOffers
       * endpoint to show the realtimeoffers on the Arcade owned M&R /recommendations page in the Care Rec container.
       * E&I users and M&R users with the FF off will show the placements through the TacoRecommendations endpoint
       */
      const result = isMrUserWithMrCareRecTacoRtosOn
        ? await TargetingApi.getTacoRealTimeOffers(rallyId, placements)
        : isEI(selectedUserSelector.selectProfile(getState()))
        ? await TargetingApi.getTacoRecommendations(rallyId, placements, dependentSeqNbr, maxCampaignsPerPlacement)
        : await TargetingApi.getTacoRecommendations(rallyId, placements, dependentSeqNbr);
      dispatch(getTacoRecommendationsSuccess(result));
    } catch (_) {
      dispatch(getTacoRecommendationsError());
    }
  };

/**
 * Updates a given recommendation: triggers a call to arcade-targeting to set a status for the recommendation.
 * @param recommendation The {@see IRecommendation} instance.
 * @param offerStatus The {@see RecommendationStatus} to set.
 * @param dismissed When true, update was triggered by dismiss.
 * @param showError When true, if the update call fails, the recommendation should flagged as well.
 */

export const getAlerts = () => async (dispatch, getState) => {
  dispatch(getAlertsLoading());
  try {
    const rallyId = selectRallyId(getState());
    const result = await TargetingApi.getAlerts(rallyId);
    dispatch(getAlertsSuccess(result));
  } catch (error) {
    dispatch(getAlertsError());
    console.warn('Unable to get Alerts from Targeting: ', error);
  }
};
