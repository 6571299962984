import { BenefitPaymentType, IBenefitExplanation } from 'scripts/api/plans/plans.interfaces';
import {
  IClientConfig,
  ICustomAccumulatorDefinitionNetwork,
  ICustomAccumulatorLabelNetwork,
} from 'scripts/api/targeting/targeting.interfaces';

const DEDUCTIBLE_HEADER_KEY = 'DEDUCTIBLE';
const OOP_MAX_HEADER_KEY = 'OOP_MAX_HEADER';

/**
 * This is a function because we get the default collection below and modify it in getCustomExplanation
 * Setting this to a pure const would cause issues with modifiying
 */
const getDefaultExplanation = (): IBenefitExplanation[] => {
  return [
    {
      label: DEDUCTIBLE_HEADER_KEY,
      definition: 'DEDUCTIBLE_EXPLANATION',
      isCustomLabel: false,
      isCustomDef: false,
    },
    {
      label: OOP_MAX_HEADER_KEY,
      definition: 'OOP_MAX_EXPLANATION',
      isCustomLabel: false,
      isCustomDef: false,
    },
    {
      label: 'COINSURANCE_HEADER',
      definition: 'COINSURANCE_EXPLANATION',
      isCustomLabel: false,
      isCustomDef: false,
    },
  ];
};

const getExplanationLabelFromAccumulatorLabel = (label: string): string => {
  switch (label) {
    case BenefitPaymentType.FamilyDeductible:
    case BenefitPaymentType.IndividualDeductible:
      return OOP_MAX_HEADER_KEY;
    case BenefitPaymentType.FamilyOop:
    case BenefitPaymentType.IndividualOop:
      return DEDUCTIBLE_HEADER_KEY;
  }
};

/**
 * Applies custom content to the defaultExplanation based on the custom definition and custom labels passed.
 * This only looks at the content from the inNetwork response
 * @param customDefinitions: Content from Targeting with optional InNetwork values that override the definition shown for EI modal content
 * @param customLabels: Content from Targeting with optional InNetwork values that override the labels shown for EI modal content
 */
const getCustomExplanations = (
  customDefinitions: ICustomAccumulatorDefinitionNetwork,
  customLabels: ICustomAccumulatorLabelNetwork,
): IBenefitExplanation[] => {
  const customExplanation = getDefaultExplanation();

  customExplanation.forEach(explanation => {
    for (const paymentType in customDefinitions?.inNetwork) {
      if (explanation.label === getExplanationLabelFromAccumulatorLabel(paymentType)) {
        explanation.isCustomDef = true;
        explanation.definition = customDefinitions.inNetwork[paymentType];
      }
    }

    for (const paymentType in customLabels?.inNetwork) {
      if (explanation.label === getExplanationLabelFromAccumulatorLabel(paymentType)) {
        explanation.isCustomLabel = true;
        explanation.label = customLabels.inNetwork[paymentType];
      }
    }
  });
  return customExplanation;
};

/**
 * Filters all explanation except for OOP Max if the Client Config has suppression enabled
 * @param explanations: Content to filter if suppressions are enabled
 * @param clientConfig: Configuration specifying suppressions
 */
const filterExplanations = (
  explanations: IBenefitExplanation[],
  clientConfig?: IClientConfig,
): IBenefitExplanation[] => {
  const { suppressDeductibleExplanation } = clientConfig?.suppressions || {};
  return explanations.filter(({ label }) => !suppressDeductibleExplanation || label === OOP_MAX_HEADER_KEY);
};

/**
 * Takes a Client Config and returns  body content for EI Account Summary Modal
 * The content can be customized based on the response from Client Config or a default response can be returned
 * @param clientConfig: ClientConfiguration to check for custom content
 */
export const getEiAccountSummaryExplanations = (clientConfig?: IClientConfig): IBenefitExplanation[] => {
  const customDefinitions = clientConfig?.contentOverrides?.customAccumulatorDefinition || {};
  const customLabels = clientConfig?.customLabels?.customAccumulatorLabels || {};

  const customExplanation = getCustomExplanations(customDefinitions, customLabels);
  return filterExplanations(customExplanation, clientConfig);
};
