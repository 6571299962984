import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const GlobalHeader: FunctionComponent = () => (
  <$GlobalHeaderDiv id="global-header" data-testid="global-header"></$GlobalHeaderDiv>
);

export default GlobalHeader;

const $GlobalHeaderDiv = styled.div`
  position: relative;
`;
