import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from 'scripts/ui/modal/modal';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { LoadingDots } from 'scripts/ui/loading-dots/loading-dots';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { MembershipCategory, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { selectConfig } from 'scripts/selectors/app-selectors';
import styled from 'styled-components';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { mobileOnly } from 'scripts/styles/breakpoints';
import { AnchorWithClickTracking } from 'scripts/ui/anchor/anchor';
import { padding_0_mobile } from 'scripts/styles/utilities';

interface ISeeYouLaterProps extends ISeeYouLaterStateToProps {}

interface ISeeYouLaterStateToProps {
  population: IPopulation;
  seeYouLaterDisplayTime: number;
  arcadeWebBaseUrl: string;
}

const getMemberCatDisplayName = (membershipCategory: MembershipCategory): string => {
  switch (membershipCategory) {
    case MembershipCategory.RETIREE:
      return 'UHC';
    case MembershipCategory.MEDICA:
      return 'PCN';
    default:
      return membershipCategory;
  }
};

export const SEE_YOU_LATER_DESINATION_LOCAL_STORAGE_KEY = 'SEE_YOU_LATER_DESTINATION_URL';

export const RawSeeYouLaterModal: React.FunctionComponent<ISeeYouLaterProps> = ({
  population,
  seeYouLaterDisplayTime,
  arcadeWebBaseUrl,
}) => {
  const { t } = useTranslation(Dictionary.SEE_YOU_LATER);
  const finalDestination = localStorage.getItem(SEE_YOU_LATER_DESINATION_LOCAL_STORAGE_KEY) || arcadeWebBaseUrl;
  const modalTitle = (
    <$ModalHeaderContainer data-testid="modal-header">
      <$HeaderImg src={population.brand.LOGO_URL} alt={population.brand.LOGO_ALT} />
    </$ModalHeaderContainer>
  );
  const modalName = 'see-you-later-modal';
  const { lineOfBusiness } = population;
  const memberCatDisplayName = getMemberCatDisplayName(population.membershipCategory);
  const isMR = lineOfBusiness === LineOfBusiness.MR;
  const isCS = lineOfBusiness === LineOfBusiness.CS;

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleNavigationFromSeeYouLater();
    }, seeYouLaterDisplayTime);

    return () => clearTimeout(timeout);
  }, []);

  const handleNavigationFromSeeYouLater = (): void => {
    localStorage.removeItem(SEE_YOU_LATER_DESINATION_LOCAL_STORAGE_KEY);
    window.location.href = finalDestination;
  };

  return (
    <$SeeYouLaterModal
      fullScreen={true}
      modalTitle={modalTitle}
      className={modalName}
      testIdSuffix={modalName}
      customCloseModal={() => {
        window.close();
      }}
    >
      <$SeeYouLaterText>{t('SEE_YOU_LATER')}</$SeeYouLaterText>
      <LoadingDots />
      {isMR && (
        <span data-testid="see-you-later-goodbye-mr">
          <Trans
            i18nKey={`${Dictionary.SEE_YOU_LATER}:YOU_ARE_LEAVING_MR`}
            values={{ category: memberCatDisplayName }}
          ></Trans>
        </span>
      )}
      {isCS && <span data-testid="see-you-later-goodbye-cs">{t('YOU_ARE_LEAVING_CS')}</span>}
      <$AnchorWithClickTrackingContainer>
        <AnchorWithClickTracking
          dataTrackId="continue-to-destination"
          data-testid="see-you-later-anchor"
          onClick={handleNavigationFromSeeYouLater}
        >
          {finalDestination}
        </AnchorWithClickTracking>
      </$AnchorWithClickTrackingContainer>
    </$SeeYouLaterModal>
  );
};

const mapStateToProps = (state: IReduxState): ISeeYouLaterStateToProps => ({
  population: selectPopulation(state),
  seeYouLaterDisplayTime: selectConfig(state).ARCADE_WEB_LINK_TIMER_MS,
  arcadeWebBaseUrl: selectConfig(state).ARCADE_WEB_BASE_URL,
});

export const SeeYouLaterModal = connect(mapStateToProps)(RawSeeYouLaterModal);

const $AnchorWithClickTrackingContainer = styled.div`
  margin: 0 auto;
  width: fit-content;
`;
const $ModalHeaderContainer = styled.h1`
  padding-left: ${fromTheme('spacing64')};
  width: 100%;
  ${padding_0_mobile}
`;

const $HeaderImg = styled.img`
  height: 60px;
  margin: 0 auto;
  ${mobileOnly`
    height: 36px;
  `}
`;

const $SeeYouLaterModal = styled(Modal)`
  span,
  a {
    font-size: ${fromTheme('fs26')};
  }
  ${mobileOnly`
    span,
    a {
      font-size: ${fromTheme('fs18')};
    }
  `}
  .loading-dots {
    justify-content: center;
    margin: ${fromTheme('spacing40')} 0;
  }
`;

const $SeeYouLaterText = styled.h2`
  border-top: 1px solid ${fromTheme('clrNeutral80')};
  font-size: ${fromTheme('fs23')};
  font-weight: ${fromTheme('fwMedium')};
  padding-top: ${fromTheme('spacing40')};
  text-align: center;
`;
