import { IResponse } from '../api.interfaces';

export enum CampaignCtaType {
  CustomEvent = 'custom_event',
  Phone = 'phone',
  Url = 'url',
}

// valid CampaignPlacementTypes must also be added to arcade-targeting to avoid filtering
// https://github.com/uhc-consumer/arcade-targeting/blob/master/web/app/com/rallyhealth/arcade/targeting/models/CampaignEnums.scala
export enum CampaignPlacementType {
  ActivateDashboardPrompt = 'activate_dashboard_prompt',
  ActivateDhpPrompt = 'activate_dhp_prompt',
  ActivateInLine = 'activate_in_line', // This is currently only ever used for Fitbit campaigns.
  ArcadeCarousel = 'arcade_carousel',
  ArcadeOnboardingPaperless = 'arcade_onboarding_paperless',
  ArcadeOptumVirtualCareBulletList = 'arcade_optum_virtual_care_bullet_list',
  ArcadeOptumVirtualCareCta = 'arcade_optum_virtual_care_cta',
  ArcadeOptumVirtualCareMain = 'arcade_optum_virtual_care_main',
  ArcadeOptumVirtualCareTitle = 'arcade_optum_virtual_care_title',
  ArcadeRecommendationRto = 'arcade_recommendation_rto',
  ArcadeResourcesGeneralPromo = 'arcade_resources_general_promo',
  ArcadeResourcesPrimaryWellness = 'arcade_resources_primary_wellness',
  ArcadeResourcesPrograms = 'arcade_resources_programs',
  ArcadeResourcesSecondaryWellness = 'arcade_resources_secondary_wellness',
  ArcadeResourcesSection1Link = 'arcade_resources_section_1_link',
  ArcadeResourcesSection2Link = 'arcade_resources_section_2_link',
  ArcadeResourcesText = 'arcade_resources_text',
  ArcadeDashboardBanner = 'arcade_dashboard_banner',
  CovidResourcesPagePromo = 'covid_resources_page_promo',
  CovidResourcesPageCarousel = 'covid_resources_page_carousel',
  CovidResourcesPageCoping = 'covid_resources_placement_3',
  UhcDashboardBenefitsOverview = 'uhc_dashboard_benefits_overview',
  UhcDashboardRecommendations = 'uhc_dashboard_recommendations',
  DhpSpotlight = 'dhp_spotlight',
  MrDhpSpotlight = 'm_r_dhp_spotlight',
  UCardDhpSpotlight = 'ucard_dhp_spotlight',
  WaysToSave = 'ways_to_save',
  WaysToSaveAdditionalResources = 'ways_to_save_additional_resources',
}

export const AllProgramsPageCampaigns = 'arcade_resources*';

export enum HelpFaqType {
  JPMC = 'JPMC',
}

export interface ICampaignCta {
  ctaType: CampaignCtaType;
  ctaText: string;
  ctaValue: string;
}

export enum OutcomeType {
  GatekeeperActivity = 'gatekeeper_activity',
  NoIncentive = 'no_incentive',
  RewardCompleted = 'reward_completed',
  RewardOpen = 'reward_open',
  Undetermined = 'undetermined',
}

export interface ICampaignMetadata {
  activityID: string;
  outcome: OutcomeType;
  rewardValue: string;
}

interface ICampaignColorPair {
  background: string;
  foreground: string;
}

export interface ICampaign {
  campaignId: string;
  campaignName: string;
  imageUrl?: string;
  logo?: string;
  headline?: string;
  body?: string;
  cta: ICampaignCta;
  priority: number;
  placementType: CampaignPlacementType;
  metadata?: ICampaignMetadata;
  theme?: string;
  colorPair?: ICampaignColorPair;
}

export interface ICampaigns {
  placements: ICampaignPlacements;
}

/**
 * Corresponding response BE model
 * https://github.com/AudaxHealthInc/taco/blob/main/service/src/main/scala/com/rallyhealth/
 * taco/service/TargetedContentAggregationService.scala#L60
 */
export interface ICampaignPlacements {
  [campaignPlacementType: string]: ICampaign[];
}

export interface ICampaignResponse extends IResponse<ICampaigns> {}

export interface IPlanInfo {
  planId: string;
  planName: string;
  planTitle: string;
  planStartDate: number;
  planEndDate: number;
  maxCap: number;
  numberOfIncentives: number;
  earnedAmount: number;
  allocationType: string;
  awardMedia: string;
  awardType: string;
  progressMessage: string;
}

export interface IClientConfigResponse extends IResponse<IClientConfig> {}

export interface IClientConfig {
  clientCustomContentId: string;
  clientMetaData: IClientMetaData;
  suppressions: IContentSuppressions;
  customLabels: ICustomLabels;
  customMessaging: ICustomMessaging;
  contentOverrides: IContentOverrides;
}

export interface ICsCustomClientContent {
  planName: string;
  contentOverrides: ICsContentOverrides;
}

export interface IClientMetaData {
  clientConfigId: string;
  clientId: string;
  policyNumber?: string;
  pvrcCodes?: string;
}
export interface IContentSuppressions {
  accumulatorSuppression?: IAccumulatorSuppressionNetwork;
  dependentAccumulatorsSuppression: boolean;
  financialAccountSuppression: string[];
  idCardSuppression: boolean;
  onlineMedicalAppeals: boolean;
  overrideShowClaimForm?: Record<ClaimFormType, ClaimFormDisplay>;
  payNowSuppression: boolean;
  rallypayNonceRolloutEnabled: boolean;
  rallypayRolloutEnabled: boolean;
  rallypaySuppression: boolean;
  showAdvantageClaims: boolean;
  showBOFAHSASSO: boolean;
  showTier1OnDashboard: boolean;
  showMERPLabel: boolean;
  suppressAllFinancialAccountLinks: string[];
  suppressClaimDocuments: boolean;
  suppressClaimStatements: boolean;
  suppressDeductibleExplanation: boolean;
  suppressFinancialAccountLinks: IFinancialAccountLinkSuppression;
  suppressInternationalClaimForm: boolean;
  suppressOnlineFSAHRASubmission: boolean;
  suppressCovid19TestingReimbursementForm: boolean;
  suppressHighCostClaims: boolean;
  suppressNetworkIndicators: boolean;
}
export interface ICustomLabels {
  customAccumulatorLabels: ICustomAccumulatorLabelNetwork;
  customFinancialAccountLabels: ICustomFinancialAccountLabelAccount;
  customFormsLabels: ICustomFormsLabel[];
  customNetworkLabels: ICustomNetworkLabel;
}
export interface ICustomMessaging {
  allClaimsCustomMessage?: IAllClaimsCustomMessage;
  findCareMessage?: string;
  pharmacyHubCustomMessage?: IPharmacyHubCustomMessage;
  customMessagePlanBalances?: string;
  memberServicesMessage?: string;
  memberServicesPhone?: string;
  nurseLineMessage?: INurseLineMessage;
  submitClaimCustomMessage?: string;
}
export interface IContentOverrides {
  customFaq: ICustomFaq;
  planBalanceFAQ: ICustomFaq;
  planBalanceHowItWorks: IPlanBalanceHowItWorks;
  customAccumulatorDefinition: ICustomAccumulatorDefinitionNetwork;
  customFinancialAccountLink: ICustomFinancialAccountLinks;
  customGlossaryDefinitions: ICustomGlossaryDefinitions;
  helpFAQTypes: HelpFaqType[];
  customAllClaimsFAQ: IFaq[];
  customPdfClaimForms: ICustomPdfClaimForms;
  customQuickLinks: ICustomQuickLinks;
  customLinks?: ICustomLinks;
}

export interface ICsContentOverrides {
  customFaq: IFaqQuestionAnswerWithKeys[];
}

export interface ICustomGlossaryDefinitions {
  claimAmountBilled: IClaimAmountBilled;
  claimPlanPaid: IClaimPlanPaid;
  claimYouMayOwe: IClaimYouMayOwe;
  claimYouPaid: IClaimYouPaid;
}

export interface ICustomFinancialAccountLinks {
  [accountType: string]: {}[];
}

export interface ICustomAccumulatorDefinitionNetwork {
  inNetwork?: ICustomAccumulatorDefinition;
  outOfNetwork?: ICustomAccumulatorDefinition;
  tier1?: ICustomAccumulatorDefinition;
}
export interface ICustomAccumulatorDefinition {
  INDIVIDUAL_DEDUCTIBLE?: string;
  FAMILY_DEDUCTIBLE?: string;
  INDIVIDUAL_OOP?: string;
  FAMILY_OOP?: string;
  INDIVIDUAL_ANNUAL_MAX?: string;
  INDIVIDUAL_LIFETIME_MAX?: string;
  PLAN_LIFETIME_MAX?: string;
}
export interface ICustomAccumulatorLabelNetwork {
  inNetwork?: ICustomAccumulatorLabel;
  outOfNetwork?: ICustomAccumulatorLabel;
  tier1?: ICustomAccumulatorLabel;
}

export interface ICustomAccumulatorLabel {
  INDIVIDUAL_DEDUCTIBLE?: string;
  FAMILY_DEDUCTIBLE?: string;
  INDIVIDUAL_OOP?: string;
  FAMILY_OOP?: string;
  INDIVIDUAL_ANNUAL_MAX?: string;
  INDIVIDUAL_LIFETIME_MAX?: string;
  PLAN_LIFETIME_MAX?: string;
}

export interface IAccumulatorSuppressionNetwork {
  inNetwork?: string[];
  outOfNetwork?: string[];
  tier1?: string[];
}
export interface IFinancialAccountLinkSuppression {
  // the string value in the array is the actual string link to suppress EX "How It Works!"
  // might be a value in the string[]
  [accountType: string]: string[];
}

export interface ICsCustomClientContentResponse extends IResponse<ICsCustomClientContent> {}

export interface ICustomFaq {
  [accountType: string]: IFaq[];
}

export interface IFaq {
  answer: string;
  question: string;
}

export interface IFaqQuestionAnswerWithKeys {
  answer: string;
  answerKey: string;
  question: string;
  questionKey: string;
}

export interface ICustomPdfClaimForms {
  [customPdfClaimFormType: string]: string;
}

export interface ICustomFinancialAccountLabelAccount {
  [accountType: string]: ICustomFinancialAccountLabel;
}

export interface ICustomFinancialAccountLabel {
  fullName: string;
  abbreviation: string;
}

export interface ICustomQuickLinks {
  FPC?: ICustomQuickLink;
}

export interface ICustomQuickLink {
  subheading?: string;
  iconUrl?: string;
  quickLinkUrl: string;
}

export interface ICustomLinks {
  EMAIL?: ICustomLink;
}

export interface ICustomLink {
  text: string;
  href: string;
}

export interface IPharmacyHubCustomMessage {
  header?: string;
  body?: string;
}
export interface IAllClaimsCustomMessage {
  header?: string;
  body?: string;
}
export interface IPlanBalanceHowItWorks {
  [accountType: string]: string[];
}
export interface INurseLineMessage {
  header?: string;
  body?: string;
}
export interface IClaimPlanPaid {
  header?: string;
  body?: string;
}
export interface IClaimYouMayOwe {
  header?: string;
  body?: string;
}
export interface IClaimYouPaid {
  header?: string;
  body?: string;
}
export interface IClaimAmountBilled {
  header?: string;
  body?: string;
}

export interface ICustomNetworkLabel {
  inNetwork?: string;
  outOfNetwork?: string;
  tier1?: string;
}

export enum ClaimFormType {
  MEDICAL_ONLINE = 'MEDICAL_ONLINE',
  MEDICAL_PDF = 'MEDICAL_PDF',
  FSA_ONLINE = 'FSA_ONLINE',
  FSA_DC_PDF = 'FSA_DC_PDF',
  RX_PDF = 'RX_PDF',
}

export enum ClaimFormDisplay {
  show = 'show',
  hide = 'hide',
}

export interface IRecommendationCampaign extends ICampaign {
  offerCode: string;
  offerKey: string;
}

/**
 * A special @{see IRecommendationCampaign} instance that includes fields not present in the @{see IRecommendationCampaign}
 * instances returned from the backend but nonetheless are used in the front end to manage control state.
 */
export interface IRecommendation extends IRecommendationCampaign {
  /**
   * When true, the recommendation was marked as "Not Now" by the user.
   */
  dismissed: boolean;
  /**
   * When true, an error has occurred during updating the recommendation and the user should be notified.
   */
  showError: boolean;
  /**
   * When true, the recommendation is marked as loading.
   */
  loading: boolean;
}

/**
 * A collection of {@see IRecommendationCampaign} instances returned by the Targeting Service.
 */
export interface IApiRecommendations {
  /**
   * The collection of {@see IRecommendationCampaign} items from the API.
   */
  realTimeOffers: IRecommendationCampaign[];
}

/**
 * Represents a structure used to store Recommendations in the current user session.
 *
 * make sure that items loaded against a wrong localeId are not displayed and a new set of recommendations are loaded
 * from the API.
 */
export interface ISessionRecommendations {
  /**
   * The collection of recommendations.
   */
  recommendations: IRecommendation[];
  /**
   * The number of recommendations that are not dismissed, used by getRealTimeOfferCount
   */
  numActive: number;
  /**
   * The locale ID selected when the Session Recommendations were stored.
   */
  localeId: string;
}

/**
 * Represents the internal store of recommendations in the Redux state.  UI components should not need
 * to access this structure directly and instead should use the collection of Recommendations.
 */
export interface IStateRecommendations {
  order: string[];
  recommendations: { [key: string]: IRecommendation };
}

export interface IRealTimeOfferCount {
  leadPromotionCount: number;
}

export interface IRealTimeOfferCountResponse extends IResponse<IRealTimeOfferCount> {}

export interface IRecommendationsImpression {
  recommendationId: string;
  slotRank: number;
  recIdConfigHash: string;
}

export const ExpatCustomPolicies = {
  Ford: ['0911754'],
  InternationalBankOfKorea: ['914234', '914235'],
};

export interface ICustomFormsLabel {
  label: string;
  url: string;
}
export interface IAlertsResponse extends IResponse<IAlerts> {}

export enum AlertCodes {
  EIOB = 'EIOB',
}

export interface IAlert {
  code: AlertCodes;
}

export interface IAlerts {
  alerts: IAlert[];
  isMemberFound: boolean;
}

// An API Response object used by the taco Recommendations API endpoint.
export interface IApiTacoRecommendationsResponse extends IResponse<ITacoRecommendationsResponse> {}

/**
 * {@see ITacoRecommendations} instances returned by the Targeting Service Sourced from Taco service as Recommendations.
 */
export interface ITacoRecommendationsResponse {
  /**
   * The collection of {@see ITacos} items from the API.
   */
  recommendations: ITacos;
}

/**
 * Items coming from Taco (Targeted Content) Service , which aggregates content from several services.
 * Tacos can be campaigns (from Campaigns), recommendations (from Pythia), or real time offers (from Member Actions).
 * Properties correlate to Taco Placement Names (CampaignPlacementType)
 */
export interface ITacos {
  arcade_carousel?: ITacoRecommendation[];
  arcade_dashboard_placements_quick_links?: ITacoRecommendation[];
  arcade_dashboard_banner?: ITacoRecommendation[];
  arcade_resources_general_promo?: ITacoRecommendation[];
  dhp_spotlight?: ITacoRecommendation[];
  m_r_dhp_spotlight?: ITacoRecommendation[];
  ucard_dhp_spotlight?: ITacoRecommendation[];
}

/**
 * Corresponding BE model in Taco: TargetedContent
 * https://github.com/AudaxHealthInc/taco/blob/main/model/src/main/scala/com/rallyhealth/taco/model/TargetedContent.scala#L8
 */
export interface ITacoRecommendation {
  campaignId: string;
  campaignName: string;
  labels?: Set<string>;
  placement: ITacoCampaignPlacement;
  metadata?: ITacoRecommendationMetadata;
  trackingInfo?: ITacoTrackingInfo;
}

/**
 * Corresponding BE model in Taco: PlacementInstance
 * https://github.com/AudaxHealthInc/taco/blob/main/model/src/main/scala/com/rallyhealth/taco/model/placement/PlacementInstance.scala#L18
 */
export interface ITacoCampaignPlacement {
  imageUrl?: string;
  logo?: string;
  headline?: string;
  body?: string;
  theme?: string;
  cta?: ICampaignCta;
  priority: number;
  placementType: CampaignPlacementType;
  productType?: string;
  productPlacementCategory?: string;
  colorPair?: ICampaignColorPair;
}

interface ITacoRecommendationMetadata {
  activityId?: ITacoRecommendationMetadataActivityId;
}

interface ITacoRecommendationMetadataActivityId {
  id?: string;
  data?: ITacoActivityData;
  outcome?: OutcomeType;
  rewardValue?: string;
}

interface ITacoActivityData {
  activityId?: string;
  isAvailable?: boolean;
  completionStatus?: string;
  requiresMoreInfo?: boolean;
  reward?: string;
  startDate?: string;
  endDate?: string;
  coinYield?: number;
}
/**
 * Corresponding Pythia model
 * https://github.com/AudaxHealthInc/pythia/blob/529395bd9c2e181d3b0920a05b09300c4efd3f21/
 * lib-definitions/src/main/scala/com/rallyhealth/pythia/lib/definitions/SuppressionReason.scala
 */
export enum SuppressionReason {
  AlreadyCompleted = 'AlreadyCompleted',
  NoTime = 'NoTime',
  NotInterested = 'NotInterested',
  NotRelevant = 'NotRelevant',
}

export interface ITacoTrackingInfo {
  source?: string;
  partner?: string;
  client?: string;
  snapshotId?: string;
  recommendationId?: string;
  placementId?: string;
  placementCriteria?: string;
  // totalSlots Should be required for Impression events, but not click events
  totalSlots?: number;
  recommendations?: IRecommendationsImpression[];
  suppressionReason?: SuppressionReason;
  memberActionsTrackingData?: IMemberActionsTrackingData;
}

export interface IMemberActionsTrackingData {
  offerKey?: string;
  blocklistDaysTellMeMore?: string;
}

export enum RecommendationState {
  Active = 'active',
  Dismissed = 'dismissed',
}
