import { ActivateUILoaded, BannerDismissedKeyBase } from 'scripts/util/constants/storage.constants';

// Clear all the session storage items set by Arcade except arcade.claim.filters and arcade.featureflag.overrides
export const clearArcadeSessionStorageItems = (rallyId: string): void => {
  const arcadeSessionStorageKeys = [
    'arcade.notification.dismissed',
    `${BannerDismissedKeyBase}_${rallyId}`,
    'arcade.recommendations',
    'mobile.app.banner.dismissed',
    'arcade.warning',
    'arcade.completed.recs',
    'arcade.selectedProfileDependentSeqNum',
  ];

  arcadeSessionStorageKeys.forEach(key => {
    if (window.sessionStorage.getItem(key) !== null) {
      window.sessionStorage.removeItem(key);
    }
  });
};

// Clear onboarding session storage items when logging out to prevent an infinite loop
export const clearOnboardingCacheSessionStorageItems = (): void => {
  sessionStorage.removeItem(ActivateUILoaded);
  Object.keys(sessionStorage).forEach(
    key => key.startsWith('arcade.cache.onboarding') && sessionStorage.removeItem(key),
  );
};

export const clearArcadeCacheSessionStorageItems = (): void => {
  Object.keys(window.sessionStorage)
    .filter(key => /^arcade\.cache\./.test(key))
    .forEach(key => sessionStorage.removeItem(key));
};

export const clearMyUHCSessionStorageItems = (): void => {
  sessionStorage.removeItem('ProfileContext');
  sessionStorage.removeItem('PlanSummaryContext');
  sessionStorage.removeItem('PlanName');
  sessionStorage.removeItem('PLANSUMMARY');
  sessionStorage.removeItem('HsidCacheContext');
  sessionStorage.removeItem('hsidCache');
  sessionStorage.removeItem('UserRecepient');
  sessionStorage.removeItem('GetMemberService');
  sessionStorage.removeItem('BINDERCONTEXT');
  sessionStorage.removeItem('PFDECODEVALUE');
  sessionStorage.removeItem('billingSource');
  sessionStorage.removeItem('MEMBER_PROFILE_CONTEXT');
  sessionStorage.removeItem('earlyPayDetailsData');
  sessionStorage.removeItem('isGalileo');
  sessionStorage.removeItem('encryptEnterpriseId');
};
