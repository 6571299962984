import { KeyboardEvent, MouseEvent } from 'react';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import {
  selectAnalyticsInitialization,
  selectConfig,
  selectFeatureFlags,
  selectCurrentPageAnalyticsData,
  selectPreviousPageAnalyticsData,
} from 'scripts/selectors/app-selectors';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { selectProfileData, selectProfileLoading } from 'scripts/selectors/profile-service-selectors';
import { selectHeartbeatData, selectHeartbeatLoading } from 'scripts/selectors/user-service-selectors';
import {
  setAABenefitsValues,
  setAAPageName,
  setAAPopulationValues,
  setAAProfileValues,
  setInitialAADataLayerValues,
  init as adobeAnalyticsInit,
} from 'scripts/util/tracking/adobe-analytics';
import { initTracking as initSendTrackingEvents } from 'scripts/util/tracking/tracking.react';
import {
  initializeAnalytics as rallyAnalyticsInit,
  setUserData as rallyAnalyticsSetUserData,
} from 'scripts/util/tracking/rally-analytics';
import {
  AnalyticsInitialization,
  changeAnalyticsInitialization,
  setCurrentPageAnalyticsData,
} from 'scripts/reducers/app-reducer';
import { selectBenefitsData } from 'scripts/selectors/plans-service-selectors';
import {
  logPageNav as rallyAnalyticsLogPageNav,
  logExternalPageNav as rallyAnalyticsLogExternalPageNav,
  captureEventTrigger as rallyAnalyticsCaptureEventTrigger,
  logModalPageNav as rallyAnalyticsLogModalPageNav,
  logCustomUserEvent as rallyAnalyticsLogCustomUserEvent,
  TriggerType,
} from '@rally/analytics';
import { KeyValueMap, PageTags } from '@rally/analytics/dist/models/events';
import { getHeartbeat } from './user-service-thunks';
import { getProfile } from './profile-service-thunks';
import { EventOptions } from '@rally/analytics/dist/interfaces/configs';

function isReactEvent(event: MouseEvent | KeyboardEvent | Event): event is KeyboardEvent | MouseEvent {
  return !!(event as MouseEvent)?.nativeEvent;
}

export function captureEventTrigger(
  event: MouseEvent | KeyboardEvent | Event,
  explicitTriggerProps?: Parameters<typeof rallyAnalyticsCaptureEventTrigger>[1],
): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      await dispatch(waitForAnalyticsInitialization());
      rallyAnalyticsCaptureEventTrigger(isReactEvent(event) ? event.nativeEvent : event, explicitTriggerProps);
    } catch (error) {
      console.error('Error occurred capturing event trigger', error);
    }
  };
}

export function initializeAllAnalytics(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const analyticsInitialization = selectAnalyticsInitialization(getState());
      if (
        analyticsInitialization === AnalyticsInitialization.Started ||
        analyticsInitialization === AnalyticsInitialization.Successful
      ) {
        return;
      }

      dispatch(changeAnalyticsInitialization(AnalyticsInitialization.Started));

      // Init Individual Tracking Services
      dispatch(initializeAdobe());
      await dispatch(initializeRally());
      await dispatch(setRallyAnalyticsData());
      initSendTrackingEvents();

      dispatch(changeAnalyticsInitialization(AnalyticsInitialization.Successful));
    } catch (error) {
      console.error('Error occurred initializing analytics: ', error);
      dispatch(changeAnalyticsInitialization(AnalyticsInitialization.Error));
    }
  };
}

function initializeAdobe(): ArcadeThunkAction<void> {
  return (_, getState) => {
    try {
      const population = selectPopulation(getState());
      const profile = selectProfileData(getState());
      const heartbeat = selectHeartbeatData(getState());
      const config = selectConfig(getState());
      const planBenefits = selectBenefitsData(getState());

      setInitialAADataLayerValues();
      setAAPopulationValues(population);
      setAAProfileValues(heartbeat, profile);
      setAAPageName();
      adobeAnalyticsInit(config);
      if (planBenefits) {
        setAABenefitsValues(planBenefits);
      }
    } catch (error) {
      console.error('Error occurred initializing adobe analytics: ', error);
    }
  };
}

export function initializeAdobeForUnauth(): ArcadeThunkAction<void> {
  return (_, getState) => {
    try {
      const config = selectConfig(getState());
      setInitialAADataLayerValues();
      setAAPageName();
      adobeAnalyticsInit(config);
    } catch (error) {
      console.error('Error occurred initializing adobe analytics: ', error);
    }
  };
}

function setRallyAnalyticsData(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      let heartbeat = selectHeartbeatData(getState());
      const heartbeatLoading = selectHeartbeatLoading(getState());
      if (!heartbeat) {
        if (!heartbeatLoading) {
          dispatch(getHeartbeat());
        }
        await waitFor(() => !!selectHeartbeatData(getState()));
        heartbeat = selectHeartbeatData(getState());
      }
      let profile = selectProfileData(getState());
      const profileLoading = selectProfileLoading(getState());
      if (!profile) {
        if (!profileLoading) {
          dispatch(getProfile());
        }
        await waitFor(() => !!selectProfileData(getState()));
        profile = selectProfileData(getState());
      }
      await rallyAnalyticsSetUserData(heartbeat, profile);
    } catch (error) {
      console.error('Error occurred setting profile data', error);
    }
  };
}

function initializeRally(): ArcadeThunkAction<void> {
  return (_, getState) => {
    try {
      const isRallyAnalyticsOn = selectFeatureFlags(getState()).ARCADE_FEATURES_RALLY_ANALYTICS;
      if (isRallyAnalyticsOn) {
        const config = selectConfig(getState());
        rallyAnalyticsInit(config);
      }
    } catch (error) {
      console.error('Error occurred initializing rally analytics: ', error);
    }
  };
}

export function logPageNav(
  pageName: string,
  pageTags: PageTags,
  pageArgs?: KeyValueMap,
  additionalData?: KeyValueMap,
): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      await dispatch(waitForAnalyticsInitialization());
      rallyAnalyticsLogPageNav(pageName, pageTags, pageArgs, {
        ...(additionalData || {}),
        'New Page Load': true,
      });
    } catch (error) {
      console.error('Error occurred logging page nav', error);
    }
  };
}

export function logModalPageNav(
  pageName?: string,
  pageTags?: PageTags,
  pageArgs?: KeyValueMap,
  additionalData?: KeyValueMap,
): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      await dispatch(waitForAnalyticsInitialization());
      const currentPageAnalyticsData = selectCurrentPageAnalyticsData(getState());
      pageName = pageName ? pageName : currentPageAnalyticsData.pageName;
      pageTags = pageTags ? pageTags : currentPageAnalyticsData.pageTags;
      pageArgs = pageArgs ? pageArgs : currentPageAnalyticsData.pageArgs;
      dispatch(
        setCurrentPageAnalyticsData({
          pageName,
          pageTags,
          pageArgs,
          additionalData,
          isModal: true,
        }),
      );
      rallyAnalyticsLogModalPageNav(pageName, pageTags, pageArgs, additionalData);
    } catch (error) {
      console.error('Error occurred logging modal page nav', error);
    }
  };
}

export function logPageInteraction(additionalData?: KeyValueMap): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      await dispatch(waitForAnalyticsInitialization());
      const currentPageAnalyticsData = selectCurrentPageAnalyticsData(getState());
      if (!currentPageAnalyticsData) {
        throw new Error('current page analytics data was not found');
      }
      rallyAnalyticsLogPageNav(
        currentPageAnalyticsData.pageName,
        currentPageAnalyticsData.pageTags,
        currentPageAnalyticsData.pageArgs,
        {
          ...(additionalData || {}),
          'New Page Load': false,
        },
      );
    } catch (error) {
      console.error('Error occurred logging page interaction event', error);
    }
  };
}

export function logModalPageClose(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      await dispatch(waitForAnalyticsInitialization());
      const currentPageAnalyticsData = selectCurrentPageAnalyticsData(getState());
      const previousPageAnalyticsData = selectPreviousPageAnalyticsData(getState());
      // adding the current page data in case the previous page data were put in the wrong order
      const lastNonModalPage = [currentPageAnalyticsData, ...previousPageAnalyticsData].find(
        data => data && !data.isModal,
      );
      if (lastNonModalPage) {
        const { pageName, pageTags, pageArgs } = lastNonModalPage;
        // using the parent/previous page data to create the page nav event, when the modal is closed
        dispatch(setCurrentPageAnalyticsData({ pageName, pageTags, pageArgs }));
        dispatch(logPageNav(pageName, pageTags, pageArgs));
      }
    } catch (error) {
      console.error('Error occurred logging modal close event', error);
    }
  };
}

export function logExternalPageNav(
  externalUrl: string,
  additionalData?: KeyValueMap,
): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      await dispatch(waitForAnalyticsInitialization());

      rallyAnalyticsLogExternalPageNav(externalUrl, additionalData);
    } catch (error) {
      console.error('Error occurred logging external page nav', error);
    }
  };
}

export function logCustomUserEvent(
  actionName: string,
  additionalData?: KeyValueMap,
  explicitTriggerType?: TriggerType,
  options?: EventOptions,
): ArcadeThunkAction<Promise<void>> {
  return async dispatch => {
    try {
      await dispatch(waitForAnalyticsInitialization());
      rallyAnalyticsLogCustomUserEvent(actionName, additionalData, explicitTriggerType, undefined, options);
    } catch (error) {
      console.error('Error occurred logging custom user event', error);
    }
  };
}

function waitForAnalyticsInitialization(): ArcadeThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    const analyticsInitialization = selectAnalyticsInitialization(getState());
    if (analyticsInitialization === AnalyticsInitialization.Successful) {
      return Promise.resolve();
    }
    if (analyticsInitialization !== AnalyticsInitialization.Started) {
      return dispatch(initializeAllAnalytics());
    }
    return waitFor(() => selectAnalyticsInitialization(getState()) === AnalyticsInitialization.Successful);
  };
}

function waitFor(prerequisite: () => boolean, maxWait = 10000): Promise<void> {
  let timer = 0;
  return new Promise<void>(resolve => {
    const interval = setInterval(() => {
      timer += 100;
      if (prerequisite() || timer >= maxWait) {
        clearInterval(interval);
        resolve();
      }
    }, 100);
  });
}
