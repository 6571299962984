import {
  initAnalytics,
  AppPlatform,
  ConfigurationKeys,
  SupportedDestinationKeys,
  AppDataKeys,
  logCustomUserEvent,
  TriggerType,
  captureEventTrigger,
  logExternalPageNav,
  setAppData,
  AppDataProperties,
} from '@rally/analytics';
import { TrackingEventName } from 'scripts/api/activate/activate.interfaces';
import { IProfile } from 'scripts/api/profile/profile.interfaces';
import { IHeartbeat } from 'scripts/api/user/user.interfaces';
import { ILogClickEvent, ILogFormSubmitAttemptEvent } from './rally-analytics.interfaces';
import { ILocale } from '../locale/locale.interfaces';
import { getMemberStatus } from '../profile/profile';

export const initializeAnalytics = (config): void => {
  initAnalytics(
    {
      [SupportedDestinationKeys.Adobe]: {
        [ConfigurationKeys.Include]: true,
        [ConfigurationKeys.Sdk]: window._satellite,
      },
      [SupportedDestinationKeys.Amplitude]: {
        [ConfigurationKeys.Include]: false,
        [ConfigurationKeys.Sdk]: {},
      },
      [SupportedDestinationKeys.Banzai]: {
        [ConfigurationKeys.Include]: false,
        [ConfigurationKeys.SendEventMethod]: () => {
          return false;
        },
      },
      [ConfigurationKeys.GlobalBlocklist]: ['secret', 'token'],
      [ConfigurationKeys.Pillar]: 'care',
    },
    {
      [AppDataKeys.AppName]: 'arcade-ui',
      [AppDataKeys.AppVersion]: config.ARCADE_WEB_VERSION || '1',
      [AppDataKeys.AppPlatform]: AppPlatform.Web,
      [AppDataKeys.AppSessionId]: undefined,
      [AppDataKeys.AppSessionType]: undefined,
      [AppDataKeys.Environment]: config.ARCADE_WEB_ENVIRONMENT_NAME || 'DEV',
      [AppDataKeys.Product]: 'arcade-ui',
    },
  );
};

export const logClickEvent: (eventData: ILogClickEvent) => void = eventData => {
  const explicitTriggerType = TriggerType.Click;
  const name = eventData.name;
  const additionalData = eventData.data;
  logCustomUserEvent(name, additionalData, explicitTriggerType, null);
};

export const logFormSubmitAttemptEvent: (eventData: ILogFormSubmitAttemptEvent) => void = eventData => {
  const explicitTriggerType = TriggerType.Click;
  const name = eventData.error === true ? TrackingEventName.formErrorEvent : TrackingEventName.formSubmitEvent;
  const additionalData = eventData.data;
  logCustomUserEvent(name, additionalData, explicitTriggerType, null);
};

export const logExternalNavEvent = (eventData): void => {
  captureEventTrigger(eventData.event, eventData.data);
  logExternalPageNav(eventData.event.target.href);
};

const NOT_AVAILABLE = 'n/a';

export function setUserData(heartbeat: IHeartbeat, profile: IProfile): void {
  const { rallyId, dependentSeqNum, lineOfBusiness, membershipCategory, planCoverages, primaryPolicyNumber, userInfo } =
    profile.currentUser;
  const memberStatus = getMemberStatus(profile.currentUser);

  const user = {
    groupId: primaryPolicyNumber,
    loginStatus: 'loggedin',
    dependentSeqNum,
    zipcode: userInfo.zipCode || NOT_AVAILABLE,
    clientId: userInfo.primaryCustomerId || NOT_AVAILABLE,
    clientName: profile.clientInfo?.displayName || NOT_AVAILABLE,
    memberPlansInfo: planCoverages.map(coverage => ({
      aco: coverage.additionalCoverageInfo?.aco || NOT_AVAILABLE,
      coverageTypeCode: coverage.coverageTypeCode || NOT_AVAILABLE,
      fundingArrangementCode: coverage.planFeatures.fundingArrangementType || NOT_AVAILABLE,
      govtProgramTypeCode: coverage.planFeatures.programType || NOT_AVAILABLE,
      memberId: coverage.memberId.id,
      policyNumber: coverage.policyNumber,
      status: coverage.planPeriod.status,
      productCode: coverage.additionalCoverageInfo?.productCode || NOT_AVAILABLE,
    })),
    lineOfBusiness,
    membershipCategory,
    userFeedback: {
      clientId: userInfo.primaryCustomerId || NOT_AVAILABLE,
      depSeqNumber: dependentSeqNum,
      lob: lineOfBusiness,
      memberId: heartbeat.externalId || undefined,
      memberType: membershipCategory || undefined,
      path: window.location.pathname,
      policyNumber: primaryPolicyNumber,
      rallyId,
      status: memberStatus,
      age: userInfo.age,
      gender: userInfo.gender,
    },
  };

  const appData = {
    [AppDataKeys.AppUserId]: rallyId,
    [AppDataKeys.AppSessionId]: dependentSeqNum,
    user,
  };

  setAppData(appData);
}

export function setLocale(locale: ILocale): void {
  const appData = {
    Locale: locale.id,
  } as Partial<AppDataProperties>;

  setAppData(appData);
}
