import React, { FunctionComponent, MutableRefObject } from 'react';
import { ITacoRecommendation, ITacoCampaignPlacement, CampaignCtaType } from '../../api/targeting/targeting.interfaces';
import { TrackingClickType } from '../../api/tracking/tracking.interfaces';
import { AnchorWithClickTracking } from '../anchor/anchor';
import { isInternalRedirectSso, shouldBeInternalRedirectSso } from 'scripts/util/uri/uri';
import { InternalRedirectAnchor } from 'scripts/ui/anchor/internal-redirect-anchor';
import { shouldOpenPromoInSameTab } from 'scripts/util/targeting/targeting';
import { LinkTarget } from 'scripts/api/api.interfaces';
import classNames from 'classnames';
import { useRenderTracking } from 'scripts/hooks/use-render-tracking/use-render-tracking';
import { TacoTrackEventType } from 'scripts/api/taco/taco.interfaces';
import { RallyCaller } from 'scripts/util/constants/rally-caller.constants';
import { $SrOnly } from 'scripts/styles/utilities';
import { useTranslation } from 'react-i18next';
import { useSeeYouLater } from 'scripts/hooks/use-see-you-later/use-see-you-later';

interface ITacoPromoCtaProps {
  recommendation: ITacoRecommendation;
  className?: string;
  secondaryClassName?: string;
  openNewTab?: boolean;
  postTacoTrackEvent: (recommendation: ITacoRecommendation, eventType: TacoTrackEventType) => void;
  ariaPanelNumber?: number;
  dataUiElementName?: string;
}

function getCampaignTrackingLabel(recommendationPlacement: ITacoCampaignPlacement): string {
  const {
    headline,
    cta: { ctaType },
  } = recommendationPlacement;

  switch (ctaType) {
    case CampaignCtaType.Phone:
      return `phone-link-${headline}`;
    case CampaignCtaType.Url:
      return `url-link-${headline}`;
    case CampaignCtaType.CustomEvent:
      return 'feedback-cta';
  }
}

/** TacoPromoCta is a component that looks at the recommendation.placement.cta.ctaType and recommendation.placement.cta.ctaValue on a
 * recommendation that returns from the the TaCo (targeted content) endpoint.
 * This component determines whether the CTA that is shown/used should be an Anchor, Link,or Button
 * based on the value received for the given recommendation.placement.
 */
export const TacoPromoCta: FunctionComponent<ITacoPromoCtaProps> = props => {
  const {
    recommendation,
    recommendation: { placement },
    className,
    openNewTab,
    postTacoTrackEvent,
    ariaPanelNumber,
  } = props;

  const { t } = useTranslation();
  const campaignTrackingLabel = placement?.cta && getCampaignTrackingLabel(placement);
  const { ref } = useRenderTracking<HTMLAnchorElement | HTMLButtonElement>({
    campaign: placement,
    isImpression: true,
    trackLabel: campaignTrackingLabel,
  });
  const { seeYouLaterRequired } = useSeeYouLater(placement?.cta && placement.cta.ctaValue);

  if (!placement.cta) {
    return null;
  }
  const { ctaText, ctaType, ctaValue } = placement.cta;
  const isInternalRedirect = ctaValue && (isInternalRedirectSso(ctaValue) || shouldBeInternalRedirectSso(ctaValue));
  const labelModifier = ariaPanelNumber === undefined ? ctaText.toLowerCase().replace(/\s/g, '-') : ariaPanelNumber;
  const opensNewWindow = seeYouLaterRequired || (!shouldOpenPromoInSameTab(ctaValue) && openNewTab);

  const handleTacoPromoCtaClick = (): void => {
    postTacoTrackEvent(recommendation, TacoTrackEventType.Click);
  };

  const setSrOnlyTagValue = (): string => {
    const value = document.getElementById('recommendation-headline-' + labelModifier)?.textContent;
    return (
      ctaText +
      '. ' +
      (value ? value : '') +
      (ctaType === CampaignCtaType.Url && opensNewWindow ? ' ' + t('OPENS_IN_NEW_WINDOW') : '')
    );
  };

  return (
    ctaValue && (
      <>
        <$SrOnly id={'card-promo-' + labelModifier}>{setSrOnlyTagValue()}</$SrOnly>
        {ctaType === CampaignCtaType.Phone && (
          <AnchorWithClickTracking
            data-testid="phone-anchor"
            dataTrackId={campaignTrackingLabel}
            className={classNames(className)}
            href={ctaValue}
            aria-labelledby={'card-promo-' + labelModifier}
            onClick={handleTacoPromoCtaClick}
            dataUiElementName={campaignTrackingLabel}
            forwardedRef={ref as MutableRefObject<HTMLAnchorElement>}
          >
            {ctaText && <span>{ctaText}</span>}
          </AnchorWithClickTracking>
        )}
        {ctaType === CampaignCtaType.Url && !openNewTab && isInternalRedirect && (
          <InternalRedirectAnchor
            data-testid="url-link-internal-redirect"
            dataTrackId={campaignTrackingLabel}
            className={classNames(className)}
            fullInternalRedirectValue={ctaValue}
            onClick={handleTacoPromoCtaClick}
            dataUiElementName={campaignTrackingLabel}
            rallyCaller={RallyCaller.HealthPromos_Taco}
            aria-labelledby={'card-promo-' + labelModifier}
          >
            {ctaText}
          </InternalRedirectAnchor>
        )}
        {ctaType === CampaignCtaType.Url && (openNewTab || !isInternalRedirect) && (
          <AnchorWithClickTracking
            data-testid="url-anchor"
            dataTrackId={campaignTrackingLabel}
            dataClickType={TrackingClickType.ExternalLink}
            className={classNames(className)}
            href={ctaValue}
            target={shouldOpenPromoInSameTab(ctaValue) && !openNewTab ? undefined : LinkTarget.Blank}
            forwardedRef={ref as MutableRefObject<HTMLAnchorElement>}
            aria-labelledby={'card-promo-' + labelModifier}
            onClick={handleTacoPromoCtaClick}
            dataUiElementName={campaignTrackingLabel}
          >
            {ctaText}
          </AnchorWithClickTracking>
        )}
      </>
    )
  );
};
