import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { selectHeartbeatData, selectRallyId } from 'scripts/selectors/user-service-selectors';
import { selectConfig, selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { getHeartbeat as getHeartbeatThunk } from 'scripts/thunks/user-service-thunks';
import { IHeartbeat } from 'scripts/api/user/user.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IConfig, IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import { PagesData } from 'scripts/config/pages-data';
import Cookies from 'js-cookie';
import { CAMPAIGN_TRACK_EVENTS_SENT, SUPPRESS_BANNER_KEY } from 'scripts/util/constants/cookies.constant';
import { destroyAll, getCache } from 'scripts/api/cache';
import { createPortal } from 'react-dom';
import { InactiveModal } from '../modals/inactive/inactive';
import { useTimeout } from 'scripts/hooks/use-timeout/use-timeout';
import debounce from 'lodash.debounce';
import { clearArcadeSessionStorageItems } from 'scripts/util/session/clear-session-storage';
import { CacheName } from 'scripts/api/api.interfaces';

const getExpiresFromNow = (expiresAt: Date, serverTime: Date): number => {
  const expiresFromNow = new Date(expiresAt.toString()).getTime() - new Date(serverTime.toString()).getTime();
  return expiresFromNow;
};

export interface IHeartbeatProps extends IHeartbeatStateToProps, IHeartbeatDispatchToProps {
  children: React.ReactNode;
}

interface IHeartbeatStateToProps {
  heartbeatData: IHeartbeat;
  config: IConfig;
  featureFlags: IFeatureFlags;
}

interface IHeartbeatDispatchToProps {
  getHeartbeatThunk: (
    heartbeatRequestId?: string,
    setHeartbeatRequestId?: React.Dispatch<React.SetStateAction<string>>,
    force?: boolean,
  ) => void;
}

export const RawHeartbeat = (props: IHeartbeatProps): JSX.Element => {
  const { heartbeatData, config, getHeartbeatThunk, children, featureFlags } = props;
  const rallyId = useSelector(selectRallyId);
  const [heartbeatRequestId, setHeartbeatRequestId] = useState('');
  const [showInactiveModal, setShowInactiveModal] = useState(false);
  const debouncedActivityRequest = useCallback(
    debounce(
      () => {
        setShowInactiveModal(false);
        getHeartbeatThunk(heartbeatRequestId, setHeartbeatRequestId, true);
      },
      config.ARCADE_WEB_ACTIVITY_TIMEOUT_MS,
      { maxWait: config.ARCADE_WEB_ACTIVITY_TIMEOUT_MS, leading: true },
    ),
    [],
  );
  const { history } = useRouter();

  /**
   * In use callback since these timeouts are generally static,
   * meaning a new heartbeat will expire in 30 minutes,
   * and buffer will be that minus 30 seconds.
   * useTimeout updates timeouts based on new delay values, passing a value
   * wrapped in useCallback ensures that timeouts update when needed, no more and no less
   */
  const calculateTimeUntilExpiration = useCallback(() => {
    return getExpiresFromNow(heartbeatData.expiresAt, heartbeatData.serverTime);
  }, [heartbeatData]);

  const calculateTimeUntilExpirationWithBuffer = useCallback(() => {
    return getExpiresFromNow(heartbeatData.expiresAt, heartbeatData.serverTime) - config.ARCADE_WEB_HEARTBEAT_BUFFER_MS;
  }, [heartbeatData, config]);

  /**
   * Timeouts **************************
   */
  const heartbeatInactivityTimeout = useTimeout(
    () => setShowInactiveModal(true),
    heartbeatData ? calculateTimeUntilExpirationWithBuffer : null,
  );

  const heartbeatExpirationTimeout = useTimeout(
    () => {
      history.push(PagesData.logout.path);
    },
    heartbeatData ? calculateTimeUntilExpiration : null,
  );
  /** ************* End of Timeouts ******************* */

  const handleUserActivity = (): void => {
    debouncedActivityRequest();
  };

  const clearAllTimeouts = (): void => {
    clearTimeout(heartbeatInactivityTimeout.current);
    clearTimeout(heartbeatExpirationTimeout.current);
  };

  const resetAndUpdateState = (): void => {
    clearAllTimeouts();
    setShowInactiveModal(false);
    getHeartbeatThunk(heartbeatRequestId, setHeartbeatRequestId, true);
  };

  // NES-1343 Clears data when leaving Arcade App to prevent unintended re-authentication
  const removeSession = (): void => {
    clearAllTimeouts();
    clearArcadeSessionStorageItems(rallyId);
    // Intentionally not removing ACTIVATE_LINEAR_SESSION_KEY cookie which is only removed on logout process
    Cookies.remove(SUPPRESS_BANNER_KEY);
    Cookies.remove(CAMPAIGN_TRACK_EVENTS_SENT);
    if (featureFlags.ARCADE_FEATURES_PRESERVE_CACHE_ON_APP_EXIT) {
      getCache(CacheName.User).destroy();
    } else {
      destroyAll();
    }
  };

  /**
   * Handles fetching heartbeat on initialization and route changes within Arcade App
   * Also handles clearing of timeouts when changing routes within Arcade App to prevent unintended logouts and heartbeat refreshes.
   * Heartbeat should be re-fetched on each route.
   */
  useEffect(() => {
    getHeartbeatThunk(undefined, undefined, true);
    // Handles sending an event when user interacts with page
    window.document.body.addEventListener('click', handleUserActivity);
    // Handles wiping stored heartbeat when leaving our application
    window.addEventListener('pagehide', removeSession);

    return () => {
      clearAllTimeouts();
      window.document.body.removeEventListener('click', handleUserActivity);
      window.removeEventListener('pagehide', removeSession);
    };
  }, []);

  /*
    I toyed around with not returning elements before the heartbeat is done loading,
    which will happen during page / state transitions.  While this prevented a brief flash of rendered page before logout,
    it caused a blank display without a great way to trigger the loading bar.  I think it's preferable to have that brief display of
    stale page before being bounced from the application rather than having a completely blank screen waiting on
    the heartbeat to finish.
   */
  if (!heartbeatData) {
    return null;
  } else {
    return (
      <>
        {showInactiveModal &&
          createPortal(<InactiveModal handleContinueSession={resetAndUpdateState} />, document.body)}
        {children}
      </>
    );
  }
};

const mapStateToProps = (state: IReduxState): IHeartbeatStateToProps => ({
  heartbeatData: selectHeartbeatData(state),
  config: selectConfig(state),
  featureFlags: selectFeatureFlags(state),
});

export const Heartbeat = connect(mapStateToProps, {
  getHeartbeatThunk,
})(RawHeartbeat);
