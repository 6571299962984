import {
  IPrimaryCareFpc,
  IPrimaryCareFpcPhysician,
  FpcPcpType,
  PhoneType,
  IPrimaryCareUserInfo,
  IPrimaryCarePhysician,
  PcpType,
  PcpMemberState,
  IProfileUser,
  IPrimaryCarePerMember,
  IPhysician,
  IPlanInformation,
  IPlanFeatures,
  MembershipCategory,
} from 'scripts/api/profile/profile.interfaces';

import {
  ISelectedUserPrimaryCareProviderInfo,
  ISelectedUserPrimaryCareDentistInfo,
  PrimaryCareStatusText,
  IChangePcdInfo,
} from './pcp-strip.interfaces';
import { CoverageType, CoverageStatus, PcpEligibility } from 'scripts/api/api.interfaces';
import { getFullName, isTermedForCoverageType } from 'scripts/util/profile/profile';
import { IBenefit } from 'scripts/api/plans/plans.interfaces';
import { isEI, isGated, isPolaris } from 'scripts/util/user/user';
import { IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import { shouldSeePcpSchedulingWidget } from 'scripts/features/pcp-scheduling-widget/pcp-scheduling-widget';

export const getIsPcpEligibleAkaGated = (selectedUser: IProfileUser): boolean => {
  return getIsTermedMedical(selectedUser) ? true : selectedUser.memberFeatures.pcpEligible;
};

export const getIsTermedMedical = (selectedUser: IProfileUser): boolean => {
  return isTermedForCoverageType(CoverageType.Medical, selectedUser.planCoverages);
};

export const getSelectedUserPrimaryCareProviderInfo =
  (featureFlags: IFeatureFlags) =>
  (
    selectedUser: IProfileUser,
    primaryCarePerMember: IPrimaryCarePerMember,
    fpcPrimaryCareProviderInfo: IPrimaryCareFpc,
  ): ISelectedUserPrimaryCareProviderInfo => {
    const isPcpEligibleAkaGated = getIsPcpEligibleAkaGated(selectedUser);
    if (
      isPcpEligibleAkaGated &&
      featureFlags.ARCADE_FEATURES_FPC_PCP_FOR_GATED &&
      fpcPrimaryCareProviderInfo &&
      primaryCarePerMember
    ) {
      const optumPrimaryCareInfo = getPrimaryCareProviderInfo(featureFlags)(
        primaryCarePerMember[selectedUser.dependentSeqNum],
        selectedUser,
      );
      const fpcPrimaryCareInfo = getFpcPrimaryCareProviderInfo(fpcPrimaryCareProviderInfo);
      return {
        ...fpcPrimaryCareInfo,
        lockedIn: fpcPrimaryCareInfo.lockedIn || optumPrimaryCareInfo.lockedIn,
        memberState: optumPrimaryCareInfo.memberState,
      };
    } else if (isPcpEligibleAkaGated && primaryCarePerMember) {
      const optumPrimaryCareInfo = getPrimaryCareProviderInfo(featureFlags)(
        primaryCarePerMember[selectedUser.dependentSeqNum],
        selectedUser,
      );
      if (shouldSeePcpSchedulingWidget(featureFlags, selectedUser) && fpcPrimaryCareProviderInfo) {
        const fpcPrimaryCareInfo = getFpcPrimaryCareProviderInfo(fpcPrimaryCareProviderInfo);
        return {
          ...optumPrimaryCareInfo,
          npi: fpcPrimaryCareInfo.npi,
        };
      } else {
        return optumPrimaryCareInfo;
      }
    } else if (fpcPrimaryCareProviderInfo) {
      return getFpcPrimaryCareProviderInfo(fpcPrimaryCareProviderInfo);
    }
  };

export const getFpcPrimaryCareProviderInfo = (
  fpcPrimaryCareProviderInfo: IPrimaryCareFpc,
): ISelectedUserPrimaryCareProviderInfo => {
  if (fpcPrimaryCareProviderInfo) {
    const isActivePcpWithMedicalFpcPcpType = (pcp: IPrimaryCareFpcPhysician): boolean =>
      pcp.status === CoverageStatus.Active && pcp.pcpType === FpcPcpType.Medical;
    const activePcp = fpcPrimaryCareProviderInfo.doctorDetails.find(isActivePcpWithMedicalFpcPcpType);
    const hasFuturePcp = fpcPrimaryCareProviderInfo.doctorDetails.some(pcp => pcp.status === CoverageStatus.Future);
    const lockedIn = fpcPrimaryCareProviderInfo.lockedIn;
    if (activePcp) {
      const activeDocId = activePcp.pcpId.split(' ').join('%7C');
      const firstPhoneNumber = activePcp.phones.filter(phone => phone.phoneType === PhoneType.phone)[0];
      const npi = activePcp.providerId;
      return {
        activeDocId,
        activeDocName: getFullName(activePcp),
        hasFuturePcp,
        lockedIn,
        phoneNumber: firstPhoneNumber && firstPhoneNumber.number,
        npi: npi,
      };
    } else if (hasFuturePcp) {
      return {
        hasFuturePcp,
        lockedIn,
      };
    }
  }

  return {};
};

export const getPrimaryCareDentistInfo = (
  selectedUserPrimaryCareInfo: IPrimaryCareUserInfo,
): ISelectedUserPrimaryCareDentistInfo => {
  if (selectedUserPrimaryCareInfo && selectedUserPrimaryCareInfo.primaryCarePhysicians) {
    const isActiveDentist = (pcd: IPrimaryCarePhysician): boolean =>
      pcd.status === CoverageStatus.Active && pcd.pcpType === PcpType.Dental;
    const activePcd = selectedUserPrimaryCareInfo.primaryCarePhysicians.find(isActiveDentist);
    if (activePcd) {
      const activeDocId = activePcd.providerId + '%7C' + activePcd.addressSequenceNumber;
      return {
        activeDocId,
        addressSequenceNumber: activePcd.addressSequenceNumber,
        activeDocName: getFullName(activePcd),
        providerId: activePcd.providerId,
      };
    }
  }

  return {};
};

export const isAddressSeqNum = (activePcp: IPrimaryCarePhysician, flag: boolean): boolean => {
  return flag
    ? activePcp && activePcp.addressSequenceNumber && activePcp.addressSequenceNumber.toLowerCase() !== 'na'
    : true;
};

export const getPrimaryCareProviderInfo =
  (featureFlags: IFeatureFlags) =>
  (
    selectedUserPrimaryCareInfo: IPrimaryCareUserInfo,
    selectedUser: IProfileUser,
  ): ISelectedUserPrimaryCareProviderInfo => {
    const lockedIn = selectedUserPrimaryCareInfo && selectedUserPrimaryCareInfo.lockedIn;
    const memberState = selectedUserPrimaryCareInfo && selectedUserPrimaryCareInfo.memberState;
    if (selectedUserPrimaryCareInfo && selectedUserPrimaryCareInfo.primaryCarePhysicians) {
      const isActivePcpWithValidPcpType = (pcp: IPrimaryCarePhysician): boolean =>
        pcp.status === CoverageStatus.Active &&
        (featureFlags.ARCADE_FEATURES_PCP_GROUP_OR_FACILITY_NAME
          ? [PcpType.Physician, PcpType.Facility, PcpType.Group, PcpType.IPA].indexOf(pcp.pcpType) > -1
          : pcp.pcpType === PcpType.Physician || pcp.pcpType === PcpType.IPA);
      const activePcp = selectedUserPrimaryCareInfo.primaryCarePhysicians.find(isActivePcpWithValidPcpType);
      const hasFuturePcp = selectedUserPrimaryCareInfo.primaryCarePhysicians.some(
        pcp => pcp.status === CoverageStatus.Future,
      );
      if (activePcp) {
        const appendAddressSeqNum =
          isEI(selectedUser) &&
          isAddressSeqNum(activePcp, featureFlags.ARCADE_FEATURES_PCP_LINK_ENABLED) &&
          (!isPolaris(selectedUser) ||
            (isGated(selectedUser) && selectedUser.membershipCategory !== MembershipCategory.OXFORD));

        const activeDocId = appendAddressSeqNum
          ? `${activePcp.providerId}%7C${activePcp.addressSequenceNumber}`
          : activePcp.providerId.toString();
        return {
          activeDocId,
          activeDocName: getFullName(activePcp),
          hasFuturePcp,
          lockedIn,
          memberState,
        };
      } else if (hasFuturePcp) {
        return {
          hasFuturePcp,
          lockedIn,
        };
      }
    }

    return {
      lockedIn,
      memberState,
    };
  };

export const getPrimaryCareDentistStatusText = (
  selectedUserPrimaryCareDentistInfo: ISelectedUserPrimaryCareDentistInfo,
): PrimaryCareStatusText => {
  if (selectedUserPrimaryCareDentistInfo.activeDocId) {
    return PrimaryCareStatusText.Change;
  }

  return PrimaryCareStatusText.Assign;
};

export const getPrimaryCareProviderStatusText = (
  selectedUserPrimaryCareProviderInfo: ISelectedUserPrimaryCareProviderInfo,
): PrimaryCareStatusText => {
  if (selectedUserPrimaryCareProviderInfo.activeDocName && selectedUserPrimaryCareProviderInfo.hasFuturePcp) {
    return PrimaryCareStatusText.ChangeInProgress;
  } else if (selectedUserPrimaryCareProviderInfo.activeDocName) {
    return selectedUserPrimaryCareProviderInfo.memberState &&
      selectedUserPrimaryCareProviderInfo.memberState === PcpMemberState.PcpChangeInProgress
      ? PrimaryCareStatusText.ChangeInProgress
      : PrimaryCareStatusText.Change;
  } else if (selectedUserPrimaryCareProviderInfo.hasFuturePcp) {
    return PrimaryCareStatusText.AssignmentInProgress;
  } else {
    return selectedUserPrimaryCareProviderInfo.memberState &&
      selectedUserPrimaryCareProviderInfo.memberState === PcpMemberState.PcpChangeInProgress
      ? PrimaryCareStatusText.AssignmentInProgress
      : PrimaryCareStatusText.Assign;
  }
};

export const getPcpEligibilityIndicator = (selectedUser: IProfileUser): boolean => {
  const medicalCoverage = selectedUser?.planCoverages?.filter(
    plan => plan?.coverageType === CoverageType.Medical && plan?.planPeriod?.status === CoverageStatus.Active,
  );
  return !!medicalCoverage[0] && medicalCoverage[0]?.planFeatures?.pcpEligibilityIndicator !== PcpEligibility.N;
};

export const getChangePcdInfo = (
  selectedUser: IProfileUser,
  dentalPlanId: IPlanFeatures['planId'],
  dentalPlanName: IBenefit['planName'],
  dentalProductNetworkId: IPlanInformation['networkId'],
  pcdProviderId: IPhysician['providerId'],
): IChangePcdInfo => {
  return {
    rallyId: selectedUser.rallyId,
    dependentSeqNum: selectedUser.dependentSeqNum,
    planId: dentalPlanId,
    planName: dentalPlanName,
    planNetworkId: dentalProductNetworkId,
    providerId: pcdProviderId,
  };
};

export const generateCorrelationId = (): string => {
  let correlationId = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 14; i++) {
    correlationId += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return correlationId;
};

export const phoneNumber = (tel: string): string => {
  {
    let areaCode;
    let num;
    const value = tel.trim().replace(/\D/g, '');

    switch (value.length) {
      case 10: // ### ### ####
        areaCode = value.slice(0, 3);
        num = value.slice(3);
        break;
      case 11: // # ### ### ####
        areaCode = value.slice(1, 4);
        num = value.slice(4);
        break;
      default:
        return tel;
    }

    num = num.slice(0, 3) + '-' + num.slice(3);
    return `(${areaCode}) ${num}`;
  }
};
