import React, { FunctionComponent } from 'react';
import { IProfileUser } from '../../api/profile/profile.interfaces';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { connect } from 'react-redux';
import { selectIsSuperUser } from 'scripts/selectors/user-service-selectors';
import { isMR } from 'scripts/util/user/user';
import styled from 'styled-components';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { Fs14_FwMedium } from 'scripts/styles/utilities';

export interface ISuperUserBannerProps {
  currentUser: IProfileUser;
  isSuperUser: boolean;
}

export const RawSuperUserBanner: FunctionComponent<ISuperUserBannerProps> = props => {
  const { currentUser, isSuperUser } = props;
  const { t } = useTranslation([Dictionary.SUPER_USER_BANNER]);

  if (!isSuperUser) {
    return null;
  }

  const bannerText = isMR(currentUser) ? 'SUPER_USER_MESSAGE_MR' : 'SUPER_USER_MESSAGE_EI';

  return (
    <$SuperUserBanner>
      <span>{t(bannerText)}</span>
    </$SuperUserBanner>
  );
};

const $SuperUserBanner = styled.div`
  background-color: ${fromTheme('clrWarningLight')};
  color: ${fromTheme('clrWarning')};
  ${Fs14_FwMedium};
  padding: ${fromTheme('spacing8')} 20px;
  text-align: center;
`;

const mapStateToProps = (state: IReduxState): ISuperUserBannerProps => {
  return {
    currentUser: currentUser.selectProfile(state),
    isSuperUser: selectIsSuperUser(state),
  };
};

export const SuperUserBanner = connect(mapStateToProps)(RawSuperUserBanner);
