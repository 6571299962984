import React, { FunctionComponent, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RdsNavButton } from '@rally/energon-react';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

export interface IModalCloseButtonProps {
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ModalCloseButton: FunctionComponent<IModalCloseButtonProps> = ({ onClose }) => {
  const { t } = useTranslation(Dictionary.COMMON);
  const buttonElement = useRef(null);
  useEffect(() => {
    buttonElement.current?.focus();
  }, []);

  return (
    <RdsNavButton
      onClick={onClose}
      variant="close"
      data-testid="modal-close-btn"
      data-track-id="modal-close"
      data-ui-element-name="modal-close"
      ref={buttonElement}
      aria-label={t('CLOSE_MODAL')}
    />
  );
};

export const TrackedModalCloseButton = withClickTracking(ModalCloseButton);
