import { IPopulation } from 'scripts/util/population/population.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: IPopulation = null;

const population = createSlice({
  name: 'population',
  initialState,
  reducers: {
    changePopulation: (state: IPopulation, action: PayloadAction<IPopulation>): IPopulation => {
      return action.payload;
    },
  },
});

export const { changePopulation } = population.actions;

export default population.reducer;
