import { ArcadeEnvironment, IConfig, IFeatureFlags } from '../constants/environment.interfaces';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    RP_TOKEN_SUFFIX: string;
  }
}

export function init(featureFlags: IFeatureFlags, config: IConfig): void {
  // Chatterbox Script does not load locally through doppelganger, so don't add it to the page
  if (
    featureFlags.ARCADE_FEATURES_SPEAK_EASY_CHAT_ENABLED &&
    config.ARCADE_WEB_ENVIRONMENT_NAME !== ArcadeEnvironment.Local
  ) {
    if (!window.RP_TOKEN_SUFFIX && config.ARCADE_WEB_LWR_RP_SUFFIX) {
      window.RP_TOKEN_SUFFIX = config.ARCADE_WEB_LWR_RP_SUFFIX;
    }
    const esmScript = document.createElement('script');
    const basePath = `${config.ARCADE_WEB_RALLY_SPEAK_EASY_LOAD_BASE_URL}/chat/chatter-box`;
    esmScript.src = `${basePath}/chatter-box.esm.js`;
    esmScript.type = 'module';
    const head = document.querySelector('head');
    head.appendChild(esmScript);
  }
}
