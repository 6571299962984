/*
  `ArcadePlanToken` stores the plan token in local storage for a user's selected plan
*/
export const BannerDismissedKeyBase = 'ARCADE_INTRO_BANNER_DISMISSED';
export const ActivateDynamicOnboardingStepsProgress = 'ACTIVATE_DYNAMIC_ONBOARDING_STEPS_PROGRESS';
export const PaperlessModalHidden = 'PAPERLESS_MODAL_HIDDEN';
export const ActivateUILoaded = 'ACTIVATE_UI_ONBOARDING_FLOW_SEEN';
export enum ActivatePaperlessStorageNames {
  ActivatePaperlessSeen = 'HIDE_VIEW_PAPERLESS',
}
export const ConsumerDetailsKey = 'consumerDetails';
